import { REHAB_STATE_CHANGE } from '~/constants/activities';

import Activity from '../Activity';

const defaults = {
  type: REHAB_STATE_CHANGE,
};

export default class RehabStateChange extends Activity {
  constructor(options = {}) {
    super({ ...defaults, ...options });
  }

  get currentRehabState() {
    return this.data.currentRehabState;
  }

  get dischargeInfo() {
    return this.data.dischargeInfo;
  }

  get previousRehabState() {
    return this.data.previousRehabState;
  }

  get rehabFacility() {
    return this.data.rehabFacility;
  }

  get typeLabel() {
    return 'Status Change';
  }
}
