import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Button } from '~/components/shared/buttons';
import { FormHeader } from '~/components/shared/form';
import { FormPageContentContainer } from '~/components/shared/pageLayout';
import { SuccessfulMatchFolderIcon } from '~/components/shared/svg';
import { Body, BodyBold } from '~/components/shared/typography';
import { Patient } from '~/models';
import { colors } from '~/styles/theme';

function MatchSuccess({ matchedPatient, patient, history }) {
  const navigateToPatient = () => history.push(`/patients/${patient.episodeId}`);
  const navigateToPortfolio = () => history.push('/patients');

  return (
    <FormPageContentContainer>
      <FormHeader title='Success!' />
      <IconContainer>
        <SuccessfulMatchFolderIcon />
      </IconContainer>
      <StyledBody>
        Success! You are now collaborating with
        <BodyBold as='span' color={colors.primaryBlue}>
          &nbsp;{matchedPatient.owner.clientName}&nbsp;
        </BodyBold>
        and the patient has been added to your portfolio.
      </StyledBody>
      <ButtonsContainer>
        <Button text='View Patient Story' onClick={navigateToPatient} />
        <Button text='Go to Patient Portfolio' color='transparent' onClick={navigateToPortfolio} />
      </ButtonsContainer>
    </FormPageContentContainer>
  );
}

MatchSuccess.propTypes = {
  matchedPatient: PropTypes.instanceOf(Patient),
  patient: PropTypes.instanceOf(Patient),
};

export default MatchSuccess;

const IconContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
`;

const StyledBody = styled(Body)`
  text-align: center;
  margin-top: 16px;
`;

const ButtonsContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  margin-top: 40px;
`;
