import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

function ConfirmationListItem({ label, value, noLines }) {
  return (
    <ItemContainer noLines={ noLines }>
      <Label>{ label }</Label>
      <Value data-dd-action-name={ label }>{ value }</Value>
    </ItemContainer>
  );
}

ConfirmationListItem.propTypes = {
  label: PropTypes.string.isRequired,
  noLines: PropTypes.bool,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.array,
    PropTypes.instanceOf(Object)
  ])
};

export default ConfirmationListItem;

const ItemContainer = styled.div`
  padding: ${(props) => props.noLines ? '12px 0' : '16px 0'};
  width: 100%;
  display: flex;
  border-bottom: ${(props) => props.noLines ? 'none' : `1px solid ${props.theme.colors.black25}`};
  word-break: break-word;
`;

const Label = styled.div`
  color: ${({ theme }) => theme.colors.black50};
  font-size: 14px;
  font-weight: bold;
  flex-basis: 50%;
`;

const Value = styled.div`
  color: ${({ theme }) => theme.colors.ebony};
  flex-basis: 50%;
`;
