import { useCallback, useLayoutEffect, useState } from 'react';

const defaultRect = {
  bottom: 0,
  height: 0,
  left: 0,
  right: 0,
  top: 0,
  width: 0,
  x: 0,
  y: 0,
};

const useDimensions = (ref) => {
  const getRect = () => {
    if (ref && ref.current) return ref.current.getBoundingClientRect();

    return defaultRect;
  };

  const [dimensions, setRect] = useState(getRect());

  const handleResize = useCallback(() => {
    if (ref !== null) setRect(getRect());
  }, [ref]);

  useLayoutEffect(() => {
    window.addEventListener('resize', handleResize);
    handleResize();

    return () => window.removeEventListener('resize', handleResize);
  }, [ref.current]);

  return dimensions;
};

export default useDimensions;
