/* eslint-disable camelcase */
import { Auth0Client } from '@auth0/auth0-spa-js';

export const AUTH0_DOMAIN = import.meta.env.AUTH0_DOMAIN;
export const AUTH0_CLIENT_ID = import.meta.env.AUTH0_CLIENT_ID;
export const AUTH0_AUDIENCE = import.meta.env.AUTH0_AUDIENCE;
export const INACTIVE_USER_ERROR_MESSAGE = 'user.inactive';
export const LINK_ACCOUNT_ERROR_MESSAGE = 'user.link-account';

const TERMINAL_ERRORS = [INACTIVE_USER_ERROR_MESSAGE, LINK_ACCOUNT_ERROR_MESSAGE];

export const authenticationFlow = {
  error: 'error',
  locallyAuthenticated: 'locally_authenticated',
  login: 'login',
  loginRedirectCallback: 'login_redirect_callback',
  logout: 'logout',
  renewSession: 'renew_session',
};

const client = new Auth0Client({
  domain: AUTH0_DOMAIN,
  clientId: AUTH0_CLIENT_ID,
  useRefreshTokens: true,
  cacheLocation: 'localstorage',
  authorizationParams: {
    audience: AUTH0_AUDIENCE,
    redirect_uri: window.location.origin,
  },
});

export class AuthService {
  constructor() {
    this._error = null;
  }

  get redirectPath() {
    return localStorage.getItem('authRedirectPath') || '/patients';
  }

  set redirectPath(path) {
    const ignoredPath = ['/', '/error', '/logout'];

    if (!ignoredPath.includes(path)) {
      localStorage.setItem('authRedirectPath', path);
    }
  }

  get error() {
    return this._error;
  }

  get hasTerminalError() {
    return TERMINAL_ERRORS.includes(this._error?.split(':')[0]);
  }

  async authenticate(path) {
    this.redirectPath = path;

    let authResult = { flow: authenticationFlow.locallyAuthenticated };

    const authenticated = await this.isAuthenticated();

    if (authenticated) {
      return authResult;
    }

    try {
      authResult = await this.loginRedirectCallback();

      return authResult;
    } catch (e) {
      if (e && e.message) {
        this._error = e.message;
        if (this.hasTerminalError) {
          return { flow: authenticationFlow.error };
        }
      }

      try {
        authResult = await this.renewSession();
      } catch (ex) {
        authResult = await this.login();
      }
    }

    return authResult;
  }

  async login() {
    await client.loginWithRedirect();

    return { flow: authenticationFlow.login };
  }

  async loginRedirectCallback() {
    await client.handleRedirectCallback();

    // clear any previous error on success
    this._error = null;

    return { flow: authenticationFlow.loginRedirectCallback };
  }

  async isAuthenticated() {
    return await client.isAuthenticated();
  }

  logout() {
    localStorage.removeItem('authRedirectPath');
    client.logout({ logoutParams: { returnTo: `${window.location.origin}/logged-out` } });

    return { flow: authenticationFlow.logout };
  }

  async renewSession({ cacheMode = 'on' } = {}) {
    const accessToken = await client.getTokenSilently({ cacheMode });

    // clear any previous error on success
    this._error = null;

    return { flow: authenticationFlow.renewSession, accessToken };
  }
}

const authService = new AuthService();

export { authService };
