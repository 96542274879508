import React from 'react';
import PropTypes from 'prop-types';

function PriorityFlag({ className, fill, size }) {
  return (
    <svg className={ className } width={ size } height={ size } viewBox='0 0 14 14' version='1.1' xmlns='http://www.w3.org/2000/svg'>
      <title>priority-note-red</title>
      <desc>Created with Sketch.</desc>
      <g id='Priority-Note---Final' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
        <g id='Portfolio-Indications' transform='translate(-481.000000, -97.000000)' fill={ fill } fillRule='nonzero'>
          <g id='Group-8' transform='translate(481.000000, 96.000000)'>
            <g id='Group-7' transform='translate(0.000000, 1.000000)'>
              <path d='M6.20950272,13.5625 L0.0845027214,2.1875 C-0.0904972786,1.75 -0.00299727857,1.225 0.434502721,0.9625 C0.872002721,0.7875 1.39700272,0.875 1.65950272,1.3125 L7.78450272,12.6875 C7.95950272,13.125 7.87200272,13.65 7.43450272,13.9125 C6.99700272,14.0875 6.47200272,14 6.20950272,13.5625 Z' id='Path' />
              <path d='M13.9970027,4.9875 C11.5470027,7.175 8.57200272,4.4625 6.73450272,7.7 L3.93450272,2.7125 C5.77200272,-0.525 8.74700272,2.1875 11.1095027,-9.23705556e-14 L13.9970027,4.9875 Z' id='Path' />
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}

PriorityFlag.defaultProps = {
  fill: '#D10D44',
  size: 10
};

PriorityFlag.propTypes = {
  className: PropTypes.string,
  fill: PropTypes.string,
  size: PropTypes.number
};

export default PriorityFlag;
