import { makeEscalationsSlice } from './escalations';

const SLICE_NAME = 'patientEscalations';

const { reducer, ...rest } = makeEscalationsSlice(SLICE_NAME);

export const {
  fetch,
  fetchNextPage,
  getAll,
  getTotalRecords,
  getPageLinks,
  getRecordsLoaded,
  getPageLoading,
  getAllData
} = rest;

export default reducer;
