import React, { useMemo } from 'react';
import { Formik } from 'formik';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import styled from 'styled-components';

import ActivityInput from '~/components/shared/ActivityInput';
import { Button, ButtonGroup } from '~/components/shared/buttons';
import { Form, FormHeader, FormSection, InputGroup } from '~/components/shared/form';
import StandardModal from '~/components/shared/modal/StandardModal';
import { HhaRefuseIcon, PatientRefuseIcon } from '~/components/shared/svg';
import ToggleSelector from '~/components/shared/ToggleSelector';
import { createReview } from '~/ducks/reviews';
import { addToast } from '~/ducks/toasts';
import { unwrapResult } from '~/lib';
import { LocationEpisode, Note, ServiceRefusal } from '~/models';
import { colors } from '~/styles/theme';

import { refusalCreateValidation } from './refusalCreateValidation';
import ServiceRefusalDescription from './ServiceRefusalDescription';

function ServiceRefusalCreateModal(props) {
  const { locationEpisode, onCancel, onSuccess, show } = props;

  const initialValues = useMemo(() => ({ ...new ServiceRefusal(), note: new Note() }), [show]);

  const handleSubmit = async (values, { setSubmitting }) => {
    try {
      const note = new Note({
        ...values.note,
        locationEpisodeId: locationEpisode.id,
      });

      const payload = {
        include: 'activities.attachments',
        locationEpisodeId: locationEpisode.id,
        refusedBy: values.data.refusedBy,
        type: values.type,
        ...(note.hasContent && { note: note.serialize() }),
      };

      const res = await props.createReview(payload);
      const review = unwrapResult(res);

      onSuccess(review);
    } catch (e) {
      let msg = 'Something went wrong. Please try again.';

      if (e.response?.status === 422) {
        msg = e.response.data.message;
      }

      props.addToast({ text: msg });
    } finally {
      setSubmitting(false);
    }
  };

  const options = [
    {
      label: locationEpisode.rehabInformation.latestRehabFacility.name,
      value: locationEpisode.rehabInformation.latestRehabFacility,
      icon: <HhaRefuseIcon />,
    },
    { label: locationEpisode.patient.name, value: locationEpisode.patient, icon: <PatientRefuseIcon /> },
  ];

  return (
    <StandardModal show={show} colorTheme='info' title='Refuse Services' onCancel={onCancel} disableBackdropClick>
      <Formik
        initialValues={initialValues}
        validationSchema={refusalCreateValidation}
        onSubmit={handleSubmit}
        enableReinitialize
        initialStatus={{ uploadingAttachments: false }}
        validateOnMount>
        {({ values, isValid, isSubmitting, status, setStatus }) => (
          <Form>
            <FormHeader subtitle={`Patient: ${locationEpisode.patient.name}`} />
            <FormSection>
              <InputGroup
                name='data.refusedBy'
                label='Who is refusing services at this time?'
                component={ToggleSelector}
                options={options}
              />
              {values.data.refusedBy?.id && <ServiceRefusalDescription />}
            </FormSection>
            <FormSection>
              <ActivityInput
                isTaggable
                label='Notes (optional)'
                locationEpisodeId={locationEpisode.id}
                onUploading={(uploadingAttachments) => setStatus({ uploadingAttachments })}
                customStyles={{ borderColor: colors.black25 }}
              />
            </FormSection>
            <FormActions>
              <Button color='transparent' text='Cancel' onClick={onCancel} />
              <Button
                type='submit'
                data-cy='serviceRefusalSubmit'
                disabled={status.uploadingAttachments || !isValid || isSubmitting}
                loading={isSubmitting}
                text='Confirm'
              />
            </FormActions>
          </Form>
        )}
      </Formik>
    </StandardModal>
  );
}

ServiceRefusalCreateModal.propTypes = {
  addToast: PropTypes.func.isRequired,
  createReview: PropTypes.func.isRequired,
  locationEpisode: PropTypes.instanceOf(LocationEpisode),
  onCancel: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
  show: PropTypes.bool,
};

const mapDispatchToProps = {
  addToast,
  createReview,
};

export default connect(null, mapDispatchToProps)(ServiceRefusalCreateModal);

const FormActions = styled(ButtonGroup)`
  margin-top: 60px;
`;
