import { createSlice } from '@reduxjs/toolkit';

import { updatePatient } from '~/ducks/patients';
import { createReview, updateReview } from '~/ducks/reviews';
import { addToast } from '~/ducks/toasts';
import { createAsyncThunk } from '~/lib';
import { ChartData, LocationEpisode, User } from '~/models';
import { locationEpisodeRehabStatesApi, locationEpisodesApi } from '~/services/api';

export const SLICE_NAME = 'locationEpisode';
export const INITIAL_STATE = {
  updating: false,
  data: { ...new LocationEpisode() },
};

export const fetchLocationEpisode = createAsyncThunk(
  `${SLICE_NAME}/fetchById`,
  async (params) => {
    const { id, ...rest } = params;
    const { data } = await locationEpisodesApi.fetchById.invoke(id, rest);

    return data;
  },
  {
    modelClass: LocationEpisode,
  }
);

export const fetchDailyPerformance = createAsyncThunk(
  `${SLICE_NAME}/fetchDailyPerformance`,
  async (locationEpisodeId) => {
    const { data } = await locationEpisodesApi.fetchDailyPerformance(locationEpisodeId);

    return data;
  },
  {
    modelClass: ChartData,
  }
);

export const updateLocationEpisode = createAsyncThunk(`${SLICE_NAME}/update`, async (params, { dispatch }) => {
  const { data } = await locationEpisodesApi.update.invoke(params.id, params);

  return data;
});

export const updateLocationEpisodeDates = createAsyncThunk(`${SLICE_NAME}/dates/update`, async (params) => {
  const { data } = await locationEpisodesApi.updateRehabStateDates(params);

  return data;
});

// This does not exist in a locationEpisodeRehabStates ducks file.
// If it were to, a circular dependency would be created as it would need to import ducks/locationEpisode,
// and ducks/locationEpisode would then need to import ducks/locationEpisodeRehabStates.
export const createLocationEpisodeRehabState = createAsyncThunk(
  'locationEpisodeRehabState/update',
  async (params, { dispatch }) => {
    try {
      const { data } = await locationEpisodeRehabStatesApi.create.invoke(params);

      return data;
    } catch (e) {
      // This code serves to re-fetch the location episode info if there was an issue with a state change.
      // Helps resolve issues with stale data if a patient's rehab state was already updated by another user.
      dispatch(fetchLocationEpisode(params.locationEpisodeId));

      if (e.response?.data) {
        if (e.response.data.message !== 'Duplicate state transition') {
          dispatch(addToast({ text: 'Error updating patient status' }));
        }
      }

      throw e;
    }
  }
);

const setUpdating = (val) => {
  return (state) => {
    state.updating = val;
  };
};

const setLocationEpisodeState = (state, { payload }) => {
  state.updating = false;
  state.data = payload;
};

const updateLocationEpisodeState = (state, { payload }) => {
  state.updating = false;
  state.data = { ...state.data, ...payload };
};

const locationEpisodeSlice = createSlice({
  name: SLICE_NAME,
  initialState: INITIAL_STATE,
  reducers: {
    clearLocationEpisode: () => INITIAL_STATE,
  },
  extraReducers: {
    [updateLocationEpisode.pending]: setUpdating(true),
    [createLocationEpisodeRehabState.pending]: setUpdating(true),
    [updateLocationEpisodeDates.pending]: setUpdating(true),
    [fetchLocationEpisode.fulfilled]: setLocationEpisodeState,
    [updateLocationEpisode.fulfilled]: updateLocationEpisodeState,
    [createLocationEpisodeRehabState.fulfilled]: updateLocationEpisodeState,
    [updateLocationEpisodeDates.fulfilled]: updateLocationEpisodeState,
    [createReview.fulfilled]: (state, { payload }) => {
      if (payload.locationEpisode) {
        state.data = payload.locationEpisode;
        delete payload.locationEpisode;
      }

      if (!state.data.reviews) {
        state.data.reviews = [];
      }

      state.data.reviews.push(payload);
    },
    [updateReview.fulfilled]: (state, { payload }) => {
      if (payload.locationEpisode) {
        state.data = payload.locationEpisode;
        delete payload.locationEpisode;
      }

      if (!state.data.reviews) {
        state.data.reviews = [payload];
      } else {
        const updatedReviewIdx = state.data.reviews.findIndex((review) => review.id === payload.id);

        if (updatedReviewIdx !== -1) {
          state.data.reviews[updatedReviewIdx] = payload;
        }
      }
    },
    [updatePatient.fulfilled]: (state, { payload }) => {
      const caseManager = payload.latestLocationEpisode?.caseManager;

      if (caseManager || caseManager === null) {
        state.data.caseManager = caseManager ? new User(caseManager) : null;
      }
    },
  },
});

export const getLocationEpisode = (state) => state[SLICE_NAME].data;
export const getLocationEpisodeUpdating = (state) => state[SLICE_NAME].updating;

export const { clearLocationEpisode } = locationEpisodeSlice.actions;
export default locationEpisodeSlice.reducer;
