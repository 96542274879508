import { PROJECTED_DISCHARGE } from '~/constants/reviews';

import Review, { defaults as reviewDefaults } from '../Review';

const defaults = {
  ...reviewDefaults,
  data: {
    date: null,
  },
  type: PROJECTED_DISCHARGE,
};

const parseProjectedDischarge = (projectedDischarge: unknown) => {
  if (!projectedDischarge) return null;
  if (projectedDischarge instanceof Date) return projectedDischarge;

  // Set time to end of day
  return new Date(`${projectedDischarge}T23:59:00.000`);
};

class ProjectedDischarge extends Review {
  constructor(options = {}) {
    const opts = { ...defaults, ...options };

    super(opts);
  }

  static get type() {
    return PROJECTED_DISCHARGE;
  }

  get active() {
    return this.pending || this.accepted;
  }

  get date() {
    return parseProjectedDischarge(this.data.date);
  }
}

export default ProjectedDischarge;
