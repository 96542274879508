import React, { useEffect, useState } from 'react';
import { useFormikContext } from 'formik';
import * as _ from 'lodash-es';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Checkbox } from '~/components/shared/form';
import { FieldLabel } from '~/components/shared/form';
import colors from '~/styles/theme/colors';

const PATIENT_NEEDS_OPTIONS = [
  'Oxygen',
  'BiPAP',
  'IV Antibiotics',
  'CPAP',
  'Wound Vac',
  'Specialty Mattress',
  'Trilogy Vent',
  'Bariatric Equipment',
];

function NeedsList(props) {
  const { name = 'needs' } = props;
  const [selected, setSelected] = useState([]);

  const { setFieldValue, initialValues } = useFormikContext();

  const activeNeed = (value) => selected.includes(value);

  const handleNeedSelect = (clicked) => {
    const updatedNeeds = activeNeed(clicked) ? selected.filter((need) => need !== clicked) : [...selected, clicked];

    setSelected(updatedNeeds);
  };

  useEffect(() => {
    setFieldValue(name, selected);
  }, [selected.length]);

  useEffect(() => {
    const initialNeeds = _.get(initialValues, name);

    if (initialNeeds?.length) {
      setSelected(initialNeeds);
    }
  }, []);

  return (
    <React.Fragment>
      <FieldLabel>Select all that apply</FieldLabel>
      <Container data-cy={`${name}List`}>
        {PATIENT_NEEDS_OPTIONS.map((txt, index) => {
          const active = activeNeed(txt);

          return (
            <NeedsItem key={index} className={active && 'active'} onClick={() => handleNeedSelect(txt)}>
              <Checkbox
                name={name}
                value={txt}
                label={txt}
                checked={active}
                checkedColor={colors.primaryBlue}
                color={active ? colors.primaryBlue : colors.black}
                labelSize='14px'
                size={16}
              />
            </NeedsItem>
          );
        })}
      </Container>
    </React.Fragment>
  );
}

const NeedsItem = styled.div`
  display: flex;
  flex: 1 0 41%;
  height: 32px;
  border: 1px solid #969696;
  border-radius: 4px;
  cursor: pointer;
  padding-left: 15px;
  margin: 0px 5px 20px 5px;
  &.active {
    background: ${({ theme }) => theme.colors.primaryBlue10};
    border-color: transparent;
    color: ${({ theme }) => theme.colors.primaryBlue};
    font-family: 'Lato Bold', 'Lato';
    font-weight: bold;
  }
`;

const Container = styled.div`
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  justify-content: space-between;
`;

NeedsList.propTypes = {
  name: PropTypes.string,
};

export default NeedsList;
