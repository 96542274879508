import { PortfolioSortOption, Profile } from '~/models';

import { SORTS } from '../constants/filterKeysConstants';
import { ASC, DESC, LOS_ATTR_NAME, PATIENT_NAME_ATTR_NAME } from '../constants/portfolioSortOptions';
import { RehabStateApiName } from '../constants/rehabStates';

import { initialState as filtersInitialState, makeFiltersSlice } from './filters';
import { rootPathnameChanged } from './navigation';
import { fetchProfile, updateProfile } from './profile';

// If the reducer action name needs to change, make sure to update
// the reducer case in profile ducks.
const sliceName = 'portfolio/filters';

export const queueSortOption = new PortfolioSortOption({
  rehabStateApiName: RehabStateApiName.QUEUE,
  attributeName: PATIENT_NAME_ATTR_NAME,
  direction: ASC,
});
export const admissionSortOption = new PortfolioSortOption({
  rehabStateApiName: RehabStateApiName.ADMISSION,
  attributeName: PATIENT_NAME_ATTR_NAME,
  direction: ASC,
});
export const inTreatmentSortOption = new PortfolioSortOption({
  rehabStateApiName: RehabStateApiName.IN_TREATMENT,
  attributeName: LOS_ATTR_NAME,
  direction: DESC,
});
export const dischargedSortOption = new PortfolioSortOption({
  rehabStateApiName: RehabStateApiName.DISCHARGED,
  attributeName: PATIENT_NAME_ATTR_NAME,
  direction: ASC,
});
const defaultSortOptions = [queueSortOption, admissionSortOption, inTreatmentSortOption, dischargedSortOption];

const initialState = {
  ...filtersInitialState,
  [SORTS]: defaultSortOptions.reduce((result, option) => ({ ...result, [option.key]: option }), {}),
};

const getInitialStateWithProfilePreferences = (_, { payload }) => {
  const profile = new Profile(payload);

  const newState = profile.actingClientPortfolioFilterPreferences?.value || {};

  return {
    ...initialState,
    ...newState,
    // Apply the sorts separately because the preferences only store the selected
    // sorts, so we want to overlay them on top of the default sorts.
    [SORTS]: {
      ...initialState[SORTS],
      ...newState[SORTS],
    },
  };
};

const getExistingPatientPortfolioFilters = (state, { payload }) => {
  // If we're navigating to Patient Portfolio from any other page,
  // we should clear any filters that weren't fetched from user preferences
  const { current, previous } = payload;
  const patientPortfolioPathRegex = /\/patients(\/?)$/;
  const toPatientPortfolio = previous && patientPortfolioPathRegex.test(current);

  if (toPatientPortfolio) {
    const { locationType, patientState, search, ...newState } = state;

    return {
      ...initialState,
      ...newState,
      // Apply the sorts separately because the preferences only store the selected
      // sorts, so we want to overlay them on top of the default sorts.
      [SORTS]: {
        ...initialState[SORTS],
        ...newState[SORTS],
      },
    };
  }
};

const extraReducers = (builder) => {
  builder
    .addCase(fetchProfile.fulfilled, getInitialStateWithProfilePreferences)
    .addCase(updateProfile.fulfilled, getInitialStateWithProfilePreferences)
    .addCase(rootPathnameChanged, getExistingPatientPortfolioFilters);
};

const { slice, ...rest } = makeFiltersSlice(sliceName, extraReducers, initialState);

export const { clearFilters, removeFilter, setFilter, setFilters, setUnpersistedFilters, setSort } = slice.actions;

export const { getFilters, getFiltersOrderedForUser, getSorts } = rest;

export default slice;
