/* eslint-disable no-case-declarations */
import React from 'react';
import PropTypes from 'prop-types';

import { LabelBold } from '~/components/shared/typography';
import { dateWithoutTimezone } from '~/lib/formatDate';
import { RehabStateChange } from '~/models';
import { ADMISSION, DISCHARGED, IN_TREATMENT, QUEUE } from '~/services/rehabState/constants';

import { FullWidthLabel } from '../shared';

function RehabStateChangeHeader({ activity }) {
  const {
    currentRehabState: { state },
    dischargeInfo: { reason, ...dischargeInfo } = {},
    patient: { name: patientName },
    rehabFacility: { name: rehabFacilityName },
  } = activity;

  const rehabFacilityText = state === DISCHARGED ? '' : `${rehabFacilityName} by `;
  const rehabStateText = () => {
    switch (state) {
      case QUEUE:
        return 'added to the queue at';
      case ADMISSION:
        return 'admitted to';
      case IN_TREATMENT:
        return 'started treatment at';
      case DISCHARGED:
        const dischargeReason = reason.charAt(0).toLowerCase() + reason.slice(1);
        const dischargeLocation = dischargeInfo.location?.name || dischargeInfo.locationOther;

        const dischargeReasonText = dischargeReason || 'discharged; no reason available';
        const dischargeLocationText = dischargeLocation ? `to ${dischargeLocation} ` : '';

        return `was ${dischargeReasonText}. Discharged from ${rehabFacilityName} ${dischargeLocationText}by`;
      default:
        return '';
    }
  };

  return (
    <FullWidthLabel>
      {`${patientName} ${rehabStateText()} ${rehabFacilityText}`}
      <LabelBold>{activity.createdBy.fullName}</LabelBold>
      {` on ${dateWithoutTimezone(activity.enteredAt)}.`}
    </FullWidthLabel>
  );
}

RehabStateChangeHeader.propTypes = {
  activity: PropTypes.instanceOf(RehabStateChange).isRequired,
};

export default RehabStateChangeHeader;
