import React from 'react';
import styled from 'styled-components';

import { Button } from '~/components/shared/buttons';

import { useSlideoutContext } from '../Slideout';

type FilterSlideoutButtonsProps = {
  handleApply: () => void;
  handleCancel: () => void;
};

const FilterSlideoutButtons = ({ handleApply, handleCancel }: FilterSlideoutButtonsProps) => {
  const { handleExit } = useSlideoutContext();

  const handleApplyAndExit = () => {
    handleApply();
    handleExit();
  };

  const handleCancelAndExit = () => {
    handleCancel();
    handleExit();
  };

  return (
    <ButtonGroup>
      <CancelButton
        data-cy='filtersCancel'
        fontSize='14px'
        fontWeight='bold'
        color='transparent'
        text='Cancel'
        onClick={handleCancelAndExit}
      />
      <Button
        data-cy='filtersApply'
        fontSize='14px'
        fontWeight='bold'
        color='primary'
        text='Apply'
        onClick={handleApplyAndExit}
      />
    </ButtonGroup>
  );
};

const ButtonGroup = styled.div`
  display: flex;
`;

const CancelButton = styled(Button)`
  &:focus {
    border-color: transparent;
  }
`;

export default FilterSlideoutButtons;
