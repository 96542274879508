import React, { useEffect, useMemo } from 'react';
import { useFormikContext } from 'formik';

import { FormSection, InputGroup, SectionHeader, Select } from '~/components/shared/form';
import { ACUTE_CLIENT_TYPES } from '~/constants/clientTypes';
import { fetchGroupTypes } from '~/ducks/admin/groupTypes';
import { fetchLocationTypes } from '~/ducks/admin/locationTypes';
import { getDisplayName, getId, getKind, getName } from '~/helpers';
import { useAsyncOptions } from '~/lib/hooks';
import { Location } from '~/models';

import AcuteAssociatedLocations from './AcuteAssociatedLocations';
import ParentGroups from './ParentGroups';
import PostAcuteAssociatedLocations from './PostAcuteAssociatedLocations';

function AssociatedLocationsSection() {
  const {
    setFieldTouched,
    setFieldValue,
    status: { isEdit },
    values,
  } = useFormikContext();

  const locationTypeFieldName = 'locationType';
  const groupTypeField = 'groupType';

  const location = useMemo(() => new Location(values), [values]);
  const disableLocationType = isEdit || !values.client;

  const acuteClientSelected = useMemo(() => {
    return ACUTE_CLIENT_TYPES.includes(values.client?.clientType);
  }, [values.client?.clientType]);

  const asyncLocationTypeOptions = useAsyncOptions(fetchLocationTypes, {
    condition: !disableLocationType && Boolean(values.client?.id),
    params: { clientId: values.client?.id },
  });

  const asyncGroupTypeOptions = useAsyncOptions(fetchGroupTypes, {
    condition: !disableLocationType && !acuteClientSelected,
    params: { clientType: values.client?.clientType },
  });

  const hasOwners = !!location.owners.length;
  const shouldShowParentGroups = location.isHospital && hasOwners;
  const shouldShowAssociatedPostAcuteLocations = values.client && !acuteClientSelected;

  useEffect(() => {
    if (!isEdit) {
      setFieldValue(locationTypeFieldName, '');
      setFieldTouched(locationTypeFieldName, false);

      setFieldValue(groupTypeField, '');
      setFieldTouched(groupTypeField, false);
    }
  }, [values.client]);

  return (
    <FormSection>
      <SectionHeader>Associated Groups</SectionHeader>

      <InputGroup
        {...asyncLocationTypeOptions}
        name='locationType'
        label='Group Type'
        data-cy='acuteLocationType'
        placeholder='Group Type'
        disabled={disableLocationType}
        visible={acuteClientSelected}
        getOptionLabel={getName}
        getOptionValue={getKind}
        component={Select}
      />

      <InputGroup
        {...asyncGroupTypeOptions}
        name='groupType'
        label='Group Type'
        data-cy='groupType'
        placeholder='Group Type'
        disabled={disableLocationType}
        visible={!acuteClientSelected}
        getOptionLabel={getDisplayName}
        getOptionValue={getId}
        component={Select}
      />
      {location.isAcute && !location.isAffiliate && <AcuteAssociatedLocations />}
      {shouldShowAssociatedPostAcuteLocations && <PostAcuteAssociatedLocations />}
      {shouldShowParentGroups && <ParentGroups />}
    </FormSection>
  );
}

export default AssociatedLocationsSection;
