import React from 'react';

function StaySelectorOptionLabel(locationEpisode) {
  const {
    rehabInformation: {
      latestRehabFacility,
      latestRehabFacilityType
    }
  } = locationEpisode;

  if (!locationEpisode.id) return null;

  return (
    <React.Fragment>
      <span style={ { fontWeight: 'bold' } }>{ locationEpisode.stayDescriptor } </span>
      - { latestRehabFacility.name } ({ latestRehabFacilityType })
    </React.Fragment>
  );
}

export default StaySelectorOptionLabel;
