import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import Chip from '~/components/shared/Chip';
import LoadingPulse from '~/components/shared/LoadingPulse';
import { LocationIcon, NoteIcon, PriorityBadgeRed } from '~/components/shared/svg';
import { LabelBold, LabelSmall } from '~/components/shared/typography';
import { Classification } from '~/models';
import { IN_TREATMENT } from '~/services/rehabState/constants';
import colors from '~/styles/theme/colors';

import EscalationHeader from './EscalationHeader';
import LengthOfStayBadge from './LengthOfStayBadge';

// eslint-disable-next-line complexity
function PatientCard(props) {
  const {
    hasActiveServiceRefusals,
    hasNewChanges,
    latest,
    name,
    onTrack,
    planTypeClassification,
    rehabType,
    rehabFacility,
    rehabState,
    statusOutOfDate,
    trackLos,
    unacknowledgedEscalations,
    unacknowledgedPriorityNotes,
  } = props;

  const days = trackLos ? props.days : null;
  const showLengthOfStayBadge = typeof days === 'number' || (onTrack && rehabState.state === IN_TREATMENT);
  const patientOnTrack = useMemo(() => {
    return rehabState?.state !== IN_TREATMENT || statusOutOfDate ? null : onTrack;
  }, [onTrack, rehabState, statusOutOfDate]);

  return (
    <Card data-dd-privacy='allow' data-cy='card'>
      {!!unacknowledgedEscalations && <EscalationHeader />}
      {name && (
        <CardBody
          unacknowledgedEscalations={unacknowledgedEscalations}
          unacknowledgedPriorityNotes={unacknowledgedPriorityNotes}>
          <Content
            indicateContinuedCare={!latest}
            unacknowledgedEscalations={unacknowledgedEscalations}
            unacknowledgedPriorityNotes={unacknowledgedPriorityNotes}>
            <TopInfoContainer>
              <PatientInfoContainer>
                <PatientName>{name}</PatientName>
                <PatientPlanType>{planTypeClassification?.name || '—'}</PatientPlanType>
              </PatientInfoContainer>
              {showLengthOfStayBadge && (
                <LengthOfStayBadge days={days} patientOnTrack={patientOnTrack} statusOutOfDate={statusOutOfDate} />
              )}
            </TopInfoContainer>
            <BottomInfoContainer>
              <RehabFacilityContainer>
                <LocationIcon size={18} fill={colors.black} />
                <LabelSmall title={`${rehabType} - ${rehabFacility}`}>
                  {rehabType} - {rehabFacility}
                </LabelSmall>
              </RehabFacilityContainer>
              <AlertsContainer data-cy='patientCardAlertsContainer'>
                {!!unacknowledgedPriorityNotes && <PriorityBadgeRed />}
                {hasNewChanges && <NoteIcon size={13} fill={colors.primaryBlue} />}
                {hasActiveServiceRefusals && <StyledChip fontSize={'8px'} label='Refused' />}
              </AlertsContainer>
            </BottomInfoContainer>
          </Content>
        </CardBody>
      )}

      {!name && (
        <CardBody>
          <Content>
            <TopInfoContainer>
              <PatientInfoContainer>
                <EmptyPatientName>
                  <LoadingPulse />
                </EmptyPatientName>
                <EmptyPatientPlanType>
                  <LoadingPulse />
                </EmptyPatientPlanType>
              </PatientInfoContainer>
              <LengthOfStayBadge loading />
            </TopInfoContainer>
            <BottomInfoContainer>
              <EmptyRehabFacilityContainer>
                <LoadingPulse />
              </EmptyRehabFacilityContainer>
            </BottomInfoContainer>
          </Content>
        </CardBody>
      )}
    </Card>
  );
}

PatientCard.propTypes = {
  days: PropTypes.number,
  episodeClassification: PropTypes.instanceOf(Classification),
  hasActiveServiceRefusals: PropTypes.bool,
  hasNewChanges: PropTypes.bool,
  latest: PropTypes.bool,
  name: PropTypes.string,
  onTrack: PropTypes.string,
  planTypeClassification: PropTypes.instanceOf(Classification),
  rehabFacility: PropTypes.string,
  rehabState: PropTypes.shape({
    state: PropTypes.string,
  }),
  rehabType: PropTypes.string,
  statusOutOfDate: PropTypes.bool,
  trackLos: PropTypes.bool,
  unacknowledgedEscalations: PropTypes.number,
  unacknowledgedPriorityNotes: PropTypes.number,
};

export default PatientCard;

// we are not using a css normalizer as our base css. Because of this labels
// and other elements cursor css properties are set to default which are
// overridding the parent's cursor properties. For now force all labels
// to inherit cursor from parent.
const Card = styled.div`
  & * label {
    cursor: inherit;
  }
`;

const CardBody = styled.div`
  border: ${({ unacknowledgedPriorityNotes, theme }) =>
    unacknowledgedPriorityNotes ? `1.5px solid ${theme.colors.accentRed}` : null};
  border-radius: ${({ unacknowledgedEscalations }) => (unacknowledgedEscalations ? '0 0 6px 6px' : '6px')};
  margin-bottom: 10px;
  transition: all 0.3s cubic-bezier(0.23, 1, 0.32, 1);
  background: ${({ theme }) => theme.colors.white};
`;

const Content = styled.div`
  padding: 10px 8px 10px 12px;
  border-left: ${({ indicateContinuedCare, theme }) =>
    indicateContinuedCare ? `5px solid ${theme.colors.accentGreen}` : null};
  border-radius: ${({ unacknowledgedPriorityNotes, unacknowledgedEscalations }) =>
    unacknowledgedPriorityNotes || unacknowledgedEscalations ? '0 0 6px 6px' : '6px'};
`;

const TopInfoContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 5px;
`;

const BottomInfoContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
`;

const PatientInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 80%;
  width: 100%;
`;

const PatientName = styled(LabelBold)`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const PatientPlanType = styled(LabelSmall)`
  color: ${({ theme }) => theme.colors.black75};
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const RehabFacilityContainer = styled.div`
  display: flex;
  align-items: center;
  margin-top: 10px;
  border-radius: 3px;
  padding: 2px;
  padding-right: 5px;
  background: ${({ theme }) => theme.colors.black05};
  min-width: 0px;
  & > svg {
    min-width: 18px;
    margin-right: 5px;
  }
  & > label {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
`;

const AlertsContainer = styled.div`
  display: flex;
  /* This was needed to prevent the alert svgs from being resized smaller
  with smaller resolutions on IE11 */
  flex-shrink: 0;
  justify-content: flex-end;
  align-items: center;
  margin-bottom: 3px;
  & > * {
    margin-left: 6px;
  }
`;

const StyledChip = styled(Chip)`
  background-color: ${({ theme }) => theme.colors.black75};
  padding: 1px 4px;
`;

const EmptyPatientName = styled(PatientName)`
  width: auto;
  margin-bottom: 4px;
`;

const EmptyPatientPlanType = styled(PatientPlanType)`
  width: 80px;
`;

const EmptyRehabFacilityContainer = styled(RehabFacilityContainer)`
  background: transparent;
  padding: 0;
  width: 120px;
  margin-top: 15px;
`;
