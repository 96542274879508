import moment from 'moment';

const daysRemaining = (startDate, endDate) => {
  const start = moment(startDate).startOf('day');
  const end = moment(endDate).startOf('day');
  const diff = end.diff(start, 'days');

  return !isNaN(diff) ? Math.max(0, diff)  : null;
};

export default daysRemaining;
