import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Button, ButtonGroup } from '~/components/shared/buttons';
import { ReassignPencilIcon, RefusalIcon } from '~/components/shared/svg';

function ActionsButtonGroup({ onConfirmClick, onReassignClick }) {
  return (
    <StyledButtonGroup>
      <StyledButton
        color='primaryWhite'
        data-cy='reassignServices'
        fontSize='12px'
        icon={<ReassignPencilIcon />}
        onClick={onReassignClick}
        text='Reassign Services'
      />
      <StyledButton
        color='accentWhite'
        data-cy='confirmServiceRefusal'
        icon={<RefusalIcon />}
        fontSize='12px'
        onClick={onConfirmClick}
        text='Confirm Refusal'
      />
    </StyledButtonGroup>
  );
}

ActionsButtonGroup.propTypes = {
  onConfirmClick: PropTypes.func,
  onReassignClick: PropTypes.func,
};

ActionsButtonGroup.defaultProps = {
  onConfirmClick: () => {},
  onReassignClick: () => {},
};

export default ActionsButtonGroup;

const StyledButtonGroup = styled(ButtonGroup)`
  justify-content: center;
`;

const StyledButton = styled(Button)`
  && {
    border-width: 1px;
    padding: 9px 25px;
  }
`;
