import React, { HTMLAttributes } from 'react';
import styled from 'styled-components';

import { Body } from '~/components/shared/typography';

function Card(props: HTMLAttributes<HTMLDivElement>) {
  return <Container {...props} />;
}

const Container = styled.div`
  background-color: ${({ theme }) => theme.colors.white};
  border: 1px solid ${({ theme }) => theme.colors.black10};
  border-radius: 8px;
  padding: 24px;
  box-sizing: border-box;
`;

const CardTitle = styled(Body)`
  margin: 0 0 24px;
  font-size: 14px;
  font-weight: bold;
  color: ${({ theme }) => theme.colors.black};
`;

Card.Title = CardTitle;
export default Card;
