import { SortOption } from './SortOption';

interface IPortfolioSortOption {
  attributeName: string;
  direction: string;
  rehabStateApiName: string;
}

const defaults: IPortfolioSortOption = {
  attributeName: '',
  direction: '',
  rehabStateApiName: ''
};

export default class PortfolioSortOption extends SortOption
  implements IPortfolioSortOption {
  rehabStateApiName: string;

  constructor(options: IPortfolioSortOption) {
    const opts = { ...defaults, ...options };

    super({ ...opts, key: opts.rehabStateApiName });

    this.rehabStateApiName = opts.rehabStateApiName;
  }
}
