import { AUTHORIZATION } from '~/constants/reviews';
import numberOfDaysRemaining from '~/lib/daysRemaining';

import Review from '../Review';

const defaults = {
  data: {
    authorizationNumber: null,
    statusUpdatedAt: null,
    approvedThroughDate: null,
  },
  type: AUTHORIZATION,
};

class Authorization extends Review {
  constructor(options = {}) {
    const opts = { ...defaults, ...options };

    super(opts);
  }

  static get type() {
    return AUTHORIZATION;
  }

  get active() {
    return this.pending || this.accepted;
  }

  get authorizationNumber() {
    return this.data.authorizationNumber;
  }

  get statusUpdatedDate() {
    return this.data.statusUpdatedAt;
  }

  get approvedThroughDate() {
    return this.data.approvedThroughDate;
  }

  get daysRemaining() {
    return numberOfDaysRemaining(new Date(), this.approvedThroughDate);
  }
}

export default Authorization;
