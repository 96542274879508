import React from 'react';

import { useSwitch } from '~/lib/hooks';
import { Attachment } from '~/models';

import ImageViewer from './ImageViewer';
import Thumbnail from './Thumbnail';

type AttachedImageProps = {
  removed?: boolean;
  attachment: Attachment;
};

export default function AttachedImage({ removed = false, attachment }: AttachedImageProps) {
  const { state: modalState, toggle: toggleModal } = useSwitch();

  const handleToggleImageModal = () => {
    if (removed) return;

    toggleModal();
  };

  return (
    <div>
      <Thumbnail attachment={attachment} onClick={handleToggleImageModal} removed={removed} />
      <ImageViewer attachment={attachment} onClose={handleToggleImageModal} show={modalState} />
    </div>
  );
}
