import { createSelector } from '@reduxjs/toolkit';

import { FLAGGED_OLIO_ATTRS, LOCATION_TYPE, PATIENT_STATE, SEARCH } from '~/constants/filterKeysConstants';

import { initialState, makeFiltersSlice } from './filters';
import { getLatestPatientsRoot, rootPathnameChanged } from './navigation';
import { getProfile } from './profile';

const sliceName = 'patient/filters';

const extraReducers = {
  [rootPathnameChanged]: (state, { payload }) => {
    // If we're navigating from/to All Patients & a patient record,
    // we should keep the filters intact
    const { current, previous } = payload;
    const patientPathRegex = /\/patients\/.*/;
    const fromPatientToAllPatients = patientPathRegex.test(previous) && current === '/all-patients';
    const fromAllPatientsToPatient = previous === '/all-patients' && patientPathRegex.test(current);

    if (fromAllPatientsToPatient || (fromPatientToAllPatients && getLatestPatientsRoot(state) === 'all-patients')) {
      return state;
    }

    return initialState;
  },
};

const { slice, ...rest } = makeFiltersSlice(sliceName, extraReducers);

export { initialState };

export const { clearFilters, setFilters, removeFilter } = slice.actions;

export const { getFilters, getFiltersOrderedForUser } = rest;

export const getFiltersForRequest = createSelector(getFilters, getProfile, (patientFilters) =>
  formatFilters(patientFilters)
);

const useNameAsValue = (filter) => FLAGGED_OLIO_ATTRS.includes(filter);

export const formatFilters = (patientFilters) =>
  Object.entries(patientFilters)
    .filter(([filterKey, filter]) => {
      switch (filterKey) {
        case SEARCH:
        case LOCATION_TYPE:
        case PATIENT_STATE:
          return filter;
        default:
          if (Array.isArray(filter)) {
            return filter?.length > 0;
          }

          return filter.id;
      }
    })
    .reduce((acc, [filterKey, filter]) => {
      switch (filterKey) {
        case SEARCH:
        case LOCATION_TYPE:
        case PATIENT_STATE:
          return { ...acc, [filterKey]: `${filter}` };
        default:
          if (Array.isArray(filter)) {
            if (useNameAsValue(filterKey)) {
              return { ...acc, [filterKey]: filter.map((f) => f['name']) };
            } else {
              return { ...acc, [filterKey]: `${filter.map((f) => f['id']).join(',')}` };
            }
          }

          return { ...acc, [filterKey]: `${filter.id}` };
      }
    }, {});

export default slice;
