export const HHA = 'home_health_agency';
export const SNF = 'skilled_nursing_facility';
export const LTACH = 'long_term_acute_care_hospital';
export const IRF = 'inpatient_rehab_facility';
export const HOME = 'home';
export const OTHER = 'Other';
export const OTP = 'outpatient_therapy_provider';
export const HOSPITAL = 'hospital';
export const HOSPICE = 'hospice';
export const ASSISTED_LIVING = 'assisted living';
export const LONG_TERM_CARE = 'long term care';
export const DECEASED = 'deceased';
export const PAYOR = 'payor';
