import React from 'react';
import PropTypes from 'prop-types';

import { ProgressUpdate } from '~/models';

import QuestionsContent from './QuestionsContent';
import Status from './Status';

function ProgressUpdateContent({ activity }) {
  return activity.isStatusUpdate ? <Status activity={activity} /> : <QuestionsContent activity={activity} expand />;
}

ProgressUpdateContent.propTypes = {
  activity: PropTypes.instanceOf(ProgressUpdate).isRequired,
  expand: PropTypes.bool,
};

export default ProgressUpdateContent;
