import React, { useCallback, useState } from 'react';
import { createPortal } from 'react-dom';
import ReactTooltip from 'react-tooltip';
import styled from 'styled-components';

import Menu from '~/components/shared/menu';
import TagIcon from '~/components/shared/svg/TagIcon';
import TrashIcon from '~/components/shared/svg/TrashIcon';
import { DOCUMENT_TYPES } from '~/models/Attachment';
import { IAttachment } from '~/models/interfaces';
import { colors } from '~/styles/theme';

type Props = {
  attachment: IAttachment;
  disabled: boolean;
  isMenuOpen?: boolean;
  onDeleteAttachment?: (id: string) => void;
  onDeleteDocumentType?: (id: string) => void;
  onDocumentTypeChange?: (id: string, docType: string) => void;
  parentScrollTop?: number;
  setIsMenuOpen?: (isMenuOpen: boolean) => void;
  toolTipText?: string;
  usePortal?: boolean;
  uploadProgress?: number;
  showDocTypeIcon?: boolean;
  showDeleteIcon?: boolean;
};

export function DocTypeMenu(props: Props) {
  const {
    attachment,
    disabled,
    isMenuOpen,
    onDeleteAttachment,
    onDocumentTypeChange,
    setIsMenuOpen,
    toolTipText,
    usePortal = false,
    showDocTypeIcon,
    showDeleteIcon,
  } = props;

  const [iconContainerRef, setIconContainerRef] = useState<HTMLDivElement | null>(null);

  const handleIconContainerRef = useCallback((ref: HTMLDivElement) => {
    setIconContainerRef(ref);
  }, []);

  const iconsContainerRect = iconContainerRef?.getBoundingClientRect();
  const menuContentStyle = usePortal
    ? {
        top: `${iconsContainerRect?.bottom}px`,
        left: `${iconsContainerRect?.left}px`,
      }
    : {};

  const menuContent = (
    <Menu.Content style={menuContentStyle}>
      {DOCUMENT_TYPES.map((docType) => (
        <Menu.Item
          key={docType}
          active={attachment.docType === docType}
          onClick={() => onDocumentTypeChange?.(attachment.id, docType)}>
          {docType}
        </Menu.Item>
      ))}
    </Menu.Content>
  );

  const renderMenuContent = () => {
    if (usePortal) return createPortal(menuContent, document.body);

    return menuContent;
  };

  return (
    <IconsContainer ref={handleIconContainerRef}>
      {showDocTypeIcon && (
        <Menu onToggle={setIsMenuOpen} open={isMenuOpen} disableOutsideClick={!!usePortal}>
          <Menu.Trigger>
            <StyledTagIcon
              size={12}
              fill={colors.black}
              disabled={disabled}
              data-gtm-id='associateDocumentTypeIcon'
              data-tip={toolTipText}
              data-for='document-type'
            />
          </Menu.Trigger>
          {!disabled && renderMenuContent()}
        </Menu>
      )}

      {showDeleteIcon && onDeleteAttachment && (
        <div>
          <TrashIcon
            onClick={() => onDeleteAttachment(attachment.id)}
            size={12}
            fill={colors.black}
            data-tip='Remove Attachment'
            data-for='remove-attachment'
            style={{ cursor: 'pointer' }}
          />
        </div>
      )}
      <ReactTooltip effect='solid' id='document-type' padding='10px 16px' />
      <ReactTooltip effect='solid' id='remove-attachment' padding='10px 16px' />
    </IconsContainer>
  );
}

export default DocTypeMenu;

const IconsContainer = styled.div`
  display: flex;
  align-items: center;

  > * + * {
    margin-left: 16px;
  }
`;

const StyledTagIcon = styled(TagIcon)<{ disabled?: boolean }>`
  cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
  opacity: ${(props) => props.disabled && 0.25};
`;
