import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDropzone } from 'react-dropzone';
import { connect } from 'react-redux';

import { addToast } from '~/ducks/toasts';

const ALLOWED_TYPES = {
  'application/msword': ['.doc'],
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document': ['.docx'],
  'application/pdf': ['.pdf'],
  'application/vnd.ms-excel': ['.xls'],
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xlsx'],
  'image/*': ['.jpeg', '.jpg', '.png', '.tiff'],
};

function FileDropZone({
  allowedTypes = ALLOWED_TYPES,
  children,
  currentFileCount = 0,
  disabled = false,
  maxFiles = 10,
  noClick = false,
  noDrag = false,
  notifyRejections = true,
  onFilesSelected,
  ...props
}) {
  const remainingAllowedFilesCount = maxFiles - currentFileCount;

  const quantityValidator = () => {
    if (currentFileCount >= maxFiles) {
      return { code: 'too-many-files' };
    }

    return null;
  };

  const dropzoneProps = useDropzone({
    onDrop: onFilesSelected,
    accept: allowedTypes,
    maxFiles: remainingAllowedFilesCount,
    noClick,
    noDrag,
    disabled,
    validator: quantityValidator,
  });

  useEffect(() => {
    if (notifyRejections && dropzoneProps.fileRejections.length) {
      let tooManyFilesNotified = false;

      dropzoneProps.fileRejections.forEach((rejection) => {
        rejection.errors.forEach((err) => {
          let text = '';

          switch (err.code) {
            case 'too-many-files':
              if (!tooManyFilesNotified) {
                tooManyFilesNotified = true;
                text = `A maximum of ${maxFiles} files can be uploaded at a time.`;
                props.addToast({ text });
              }

              break;
            case 'file-invalid-type':
              text = `${
                rejection.file.name || rejection.file.path
              } is not valid. File must be one of the following types; ${Object.keys(allowedTypes).join(', ')}`;

              props.addToast({ text });
              break;
            default:
          }
        });
      });
    }
  }, [notifyRejections, dropzoneProps.fileRejections, maxFiles]);

  return children({
    allowedTypes,
    ...dropzoneProps,
  });
}

FileDropZone.propTypes = {
  allowedTypes: PropTypes.instanceOf(Object),
  currentFileCount: PropTypes.number,
  disabled: PropTypes.bool,
  noClick: PropTypes.bool,
  noDrag: PropTypes.bool,
  onFilesSelected: PropTypes.func.isRequired,
};

export default connect(null, { addToast })(FileDropZone);
