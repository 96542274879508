import React from 'react';
import styled from 'styled-components';

import { useMenuContext } from '~/components/shared/menu';

export interface MenuContentProps {
  children: React.ReactNode;
  containerPosition?: 'left' | 'center';
  style?: React.CSSProperties;
}

export default function MenuContent({ children, containerPosition = 'left', style }: MenuContentProps) {
  const { open } = useMenuContext();

  if (open) {
    return (
      <ContentContainer containerPosition={containerPosition} style={style}>
        {children}
      </ContentContainer>
    );
  }

  return <></>;
}

/**
 * The menu content is positioned absolutely at the bottom left of the trigger.
 * Eventually, it might be nice to make the "origin" configurable to where you can position it
 * at any corner of the trigger.
 */
const ContentContainer = styled.div<{ containerPosition: 'left' | 'center' }>`
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: 4px;
  max-height: 186px;
  max-width: 256px;
  min-width: 148px;
  overflow-y: auto;
  box-shadow: 0 8px 16px 0 ${({ theme }) => theme.colors.boxShadow};
  border: 1px solid ${({ theme }) => theme.colors.black05};
  padding: 4px;
  position: absolute;
  top: 100%;
  left: 0;
  transform: ${({ containerPosition }) => (containerPosition === 'left' ? 'translate(-100%)' : 'translate(-0%)')};
  z-index: 4;
`;
