interface IDischargeOption {
  displayName?: string,
  label: string,
  value: string,
  groupTypeId?: string
}

const defaults: IDischargeOption = {
  displayName: undefined,
  label: '',
  value: '',
  groupTypeId: undefined
};

export default class DischargeOption {
  displayName?: string;
  label: string;
  value: string;
  groupTypeId?: string;
  constructor(options: IDischargeOption) {
    const opts = { ...defaults, ...options };

    this.displayName = opts.displayName;
    this.label = opts.label;
    this.value = opts.value;
    this.groupTypeId = opts.groupTypeId;
  }

  get canContinueCare() {
    const nonContinuedCareReasons = [
      'assisted living',
      'long term care',
      'skilled_nursing_facility'
    ];

    return Boolean(this.groupTypeId) && !nonContinuedCareReasons.includes(this.value);
  }
}
