import React, { useCallback } from 'react';
import { useFormikContext } from 'formik';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

import ActivityInput from '~/components/shared/ActivityInput';
import { Button } from '~/components/shared/buttons';
import { Checkbox } from '~/components/shared/form';
import { InfoIconOutline, ThumbsDownIcon, ThumbsUpIcon, UnsureIcon } from '~/components/shared/svg';
import { Body } from '~/components/shared/typography';
import { NO, UNSURE, YES } from '~/constants/statusConstants';
import Status from '~/models/activities/ProgressUpdate/Status';
import { PERMISSIONS, useProfileContext } from '~/services/profile';
import { colors } from '~/styles/theme';

import { usePatientProfileContext } from '../../patientProfile/PatientProfileContext';

export function statusComponentConfig(question) {
  return {
    QuestionComponent: StatusQuestion,
    name: question.id,
    text: question.text,
  };
}

function StatusQuestion({ text: questionText, value, helpers, locationEpisodeId }) {
  const ESCALATE_FIELD_NAME = 'progressUpdate.escalated';
  const profileSvc = useProfileContext();
  const userCanEscalate = profileSvc.has(PERMISSIONS.escalationCreate);

  const { activities } = usePatientProfileContext();

  const { setFieldTouched, setFieldValue, setStatus, values } = useFormikContext();

  const { text } = values.progressUpdate;

  const latestUpdate = activities.find((a) => a.isStatusUpdate);

  const handleStatusChange = useCallback((val) => {
    return () => {
      helpers.setValue(val);
    };
  }, []);

  const showEscalateField = value && value.match(/no/i);
  const handleNotesChanged = useCallback((e) => {
    if (!e.target.value) {
      const shouldValidate = false;

      setFieldValue(ESCALATE_FIELD_NAME, false, shouldValidate);
      setFieldTouched(ESCALATE_FIELD_NAME, false, shouldValidate);
    }
  }, []);
  const handleUploadingAttachments = (uploadingAttachments) => setStatus({ uploadingAttachments });

  return (
    <Container data-cy='statusQuestion'>
      <Body>{questionText}</Body>
      <StatusButtonGroup>
        <StatusButton
          text={YES}
          color='gray'
          data-cy='yesStatusButton'
          selected={value === YES}
          icon={<ThumbsUpIcon />}
          onClick={handleStatusChange(YES)}
        />
        <StatusButton
          text={UNSURE}
          color='gray'
          data-cy='unsureStatusButton'
          selected={value === UNSURE}
          icon={<UnsureIcon />}
          onClick={handleStatusChange(UNSURE)}
        />
        <StatusButton
          text={NO}
          color='gray'
          data-cy='noStatusButton'
          selected={value === NO}
          icon={<ThumbsDownIcon />}
          onClick={handleStatusChange(NO)}
        />
      </StatusButtonGroup>
      <NotesContainer data-cy='notes'>
        <ActivityInput
          isTaggable
          label='Please provide details regarding the patient’s status'
          name='progressUpdate'
          locationEpisodeId={locationEpisodeId}
          onUploading={handleUploadingAttachments}
          onChange={handleNotesChanged}
          customStyles={{ borderColor: colors.black25 }}
        />
        {new Status(value).isWorseThan(latestUpdate?.status) && (
          <NoteRequiredMessageContainer data-cy='noteRequiredMessage'>
            <IconContainer>
              <InfoIconOutline />
              <NoteRequiredMessage>
                A detailed description is required when there is a decline in status.
              </NoteRequiredMessage>
            </IconContainer>
          </NoteRequiredMessageContainer>
        )}
      </NotesContainer>
      {showEscalateField && userCanEscalate && (
        <EscalateContainer>
          <StyledCheckbox
            disabled={!text}
            labelSize='16px'
            label='Create an escalation for the patient'
            name={ESCALATE_FIELD_NAME}
            size={16}
            data-cy='escalationCheckbox'
          />
        </EscalateContainer>
      )}
    </Container>
  );
}

StatusQuestion.propTypes = {
  helpers: PropTypes.shape({
    setValue: PropTypes.func,
  }),
  locationEpisodeId: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  value: PropTypes.string,
};

export default StatusQuestion;

const Container = styled.div`
  padding-right: 0 !important;
`;

const StatusButtonGroup = styled.div`
  display: flex;
  margin-top: 16px;
  button {
    margin-right: 8px;
    &:last-child {
      margin-right: 0;
    }
  }
`;

const selectedStyles = css`
  background-color: ${({ theme }) => theme.colors.primaryBlue10};
  border: 1px solid ${({ theme }) => theme.colors.primaryBlue};
  &:focus {
    background-color: ${({ theme }) => theme.colors.primaryBlue10};
  }
`;

const StatusButton = styled(Button)`
  && {
    flex: 1;
    height: 50px;
    ${({ selected }) => selected && selectedStyles};
  }
`;

const NotesContainer = styled.div`
  margin-top: 24px;
  min-height: 120px;
  display: flex;
  flex-direction: column;
`;

const EscalateContainer = styled.div`
  margin-top: 24px;
`;

const StyledCheckbox = styled(Checkbox)`
  cursor: default;
  & label {
    cursor: default;
  }
`;

const NoteRequiredMessageContainer = styled.div`
  margin-top: 16px;
  min-height: 20px;
  white-space: pre-line;
`;

const NoteRequiredMessage = styled.div`
  font-size: 14px;
  font-weight: 700;
  margin-left: ${({ theme }) => theme.dimensions.marginSmallMedium};
`;

const IconContainer = styled.div`
  display: flex;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.black05};
  color: ${({ theme }) => theme.colors.black};
  padding: ${({ theme }) => theme.dimensions.marginSmall};
  border-radius: 3px;
`;
