import React from 'react';
import styled from 'styled-components';

import { Label } from '~/components/shared/typography';

function ServiceRefusalDescription() {
  return (
    <Container data-cy='serviceRefusalDescription'>
      <Label lineHeight='18px'>
        Confirm refusal of services to notify collaborators. Patient will remain in Queue until a collaborator has confirmed or reassigned patient.
      </Label>
    </Container>
  );
}

export default ServiceRefusalDescription;

const Container = styled.div`
  background-color: ${({ theme }) => theme.colors.backgroundOrange};
  border-radius: 5px;
  padding: 10px 16px;
  flex: 1;
  margin-top: -10px;
  margin-bottom: 24px;
`;
