import React, { useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { ColumnDef, createColumnHelper } from '@tanstack/react-table';

import { MetricDetailContainer, MetricDetailHeader } from '~/components/insights';
import Card from '~/components/insights/Card';
import { DataRow, parseDimensionValues, parseMetricValues, requestData } from '~/components/insights/dataUtils';
import { useInsightsContext } from '~/components/insights/InsightsContainer';
import { Histogram } from '~/components/shared/Charts';
import DataTable from '~/components/shared/DataTable';
import { LabelBold } from '~/components/shared/typography';

import { DimensionConstants, MetricConstants, RouteConstants, SortOrder, SourceConstants } from '../constants';

import ChartContainer from './ChartContainer';
import { generateExtendedChartConfig } from './oesChartUtils';
import TableContainer from './TableContainer';
import { GroupDischargesRow } from './tableUtils';

type OesTableRow = GroupDischargesRow & {
  score: number;
};

const OlioEngagementScoreDetail = () => {
  const history = useHistory();

  const insightsContext: any = useInsightsContext();
  const { dischargedId, selectedGroupType } = insightsContext;

  const [overallScore, setOverallScore] = useState(-1);
  const [totalDischarges, setTotalDischarges] = useState(-1);
  const [groupNames, setGroupNames] = useState<string[]>([]);
  const [groupScores, setGroupScores] = useState<number[]>([]);
  const [groupDischarges, setGroupDischarges] = useState<GroupDischargesRow[]>([]);

  const oesRequest = useMemo(
    () => ({
      params: {
        source: SourceConstants.LOCATION_EPISODE_DAYS,
        dimensions: [DimensionConstants.GROUP_NAME],
        metrics: [MetricConstants.OLIO_ENGAGEMENT_SCORE],
        sortBy: `${MetricConstants.OLIO_ENGAGEMENT_SCORE} ${SortOrder.DESC}`,
        rollups: true,
      },
      processData: (data: DataRow[]) => {
        const parsedData = data.reduce(
          (acc, row) => {
            const dimensionValues = parseDimensionValues(row, false) as string[];
            const metricValues = parseMetricValues(row);

            const groupName = dimensionValues[0];
            const score = metricValues[0];

            // for overall OES, we want to average the group scores instead of displaying the true rollup
            if (groupName) {
              acc.groupNames.push(groupName);
              acc.scores.push(score);
              acc.sumScores += score;
            }

            return acc;
          },
          { groupNames: [], scores: [], sumScores: 0 } as {
            groupNames: string[];
            scores: number[];
            sumScores: number;
          }
        );

        const { groupNames, scores, sumScores } = parsedData;

        const averageOes = scores.length ? Math.round(sumScores / scores.length) : -1;

        setOverallScore(averageOes);
        setGroupNames(groupNames);
        setGroupScores(scores);
      },
    }),
    []
  );

  const dischargesRequest = useMemo(
    () => ({
      params: {
        source: SourceConstants.LOCATION_EPISODES,
        dimensions: [DimensionConstants.GROUP_NAME],
        metrics: [MetricConstants.ID_COUNT],
        rehabState: dischargedId,
        rollups: true,
      },
      processData: (data: DataRow[]) => {
        const parsedData = data.reduce(
          (acc, row) => {
            const dimensionValues = parseDimensionValues(row, false) as string[];
            const metricValues = parseMetricValues(row);

            const groupName = dimensionValues[0];
            const discharges = metricValues[0];

            if (!groupName) {
              acc.totalDischarges = discharges;
            } else {
              acc.groupDischarges.push({ groupName, discharges });
            }

            return acc;
          },
          { groupDischarges: [], totalDischarges: 0 } as {
            groupDischarges: GroupDischargesRow[];
            totalDischarges: number;
          }
        );

        const { totalDischarges, groupDischarges } = parsedData;

        setTotalDischarges(totalDischarges);
        setGroupDischarges(groupDischarges);
      },
    }),
    [dischargedId]
  );

  const { loading: chartLoading } = requestData([oesRequest]);

  const { loading: tableLoading } = requestData([dischargesRequest], {
    condition: dischargedId !== undefined,
  });

  const chartConfig = useMemo(
    () => generateExtendedChartConfig({ categories: groupNames, values: groupScores }),
    [groupNames, groupScores]
  );

  const getValueString = () => {
    if (overallScore < 0) return;

    return `${overallScore}%`;
  };

  const tableData = useMemo<OesTableRow[]>(
    () =>
      groupNames.map((groupName, i) => ({
        groupName,
        score: groupScores[i],
        discharges: groupDischarges.find((gd) => gd.groupName === groupName)?.discharges ?? 0,
      })),
    [groupDischarges, groupNames, groupScores]
  );

  const columnHelper = createColumnHelper<OesTableRow>();
  const columns = useMemo<ColumnDef<OesTableRow, any>[]>(
    () => [
      columnHelper.accessor('groupName', {
        header: selectedGroupType.displayName,
        cell: (data) => <LabelBold>{data.getValue()}</LabelBold>,
        footer: () => <LabelBold>Average (All)</LabelBold>,
      }),
      columnHelper.accessor('discharges', {
        header: 'Discharges',
        footer: () => totalDischarges,
      }),
      columnHelper.accessor('score', {
        header: 'Olio Engagement Score (OES)',
        cell: (data) => `${data.getValue()}%`,
        footer: () => `${overallScore}%`,
      }),
    ],
    [overallScore, selectedGroupType, totalDischarges]
  );

  const defaultSort = [
    {
      id: 'score',
      desc: true,
    },
  ];

  return (
    <>
      <MetricDetailContainer
        onBackClick={() => history.push(RouteConstants.INSIGHTS_BASE)}
        loading={chartLoading}
        hasData={!!groupNames.length}
        header={
          <MetricDetailHeader
            label='Olio Engagement Score (OES)'
            loading={chartLoading}
            value={getValueString()}
            tooltipText='Measurement of engagement activity in Olio'
          />
        }>
        <ChartContainer>
          <Histogram config={chartConfig} />
        </ChartContainer>
      </MetricDetailContainer>
      <TableContainer>
        <Card.Title>Olio Engagement Score (OES) Overview</Card.Title>
        <DataTable
          columns={columns}
          data={tableData}
          defaultSortBy={defaultSort}
          loading={chartLoading || tableLoading}
        />
      </TableContainer>
    </>
  );
};

export default OlioEngagementScoreDetail;
