import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';

function CollapsibleContent({ children, ...rest }) {
  const container = useRef(null);
  const [height, setHeight] = useState(container?.current?.scrollHeight);

  useEffect(() => {
    setHeight(container?.current?.scrollHeight);
  }, [children]);

  return (
    <CollapsibleDiv { ...rest } height={ height } ref={ container }>
      { children }
    </CollapsibleDiv>
  );
}

export default CollapsibleContent;

const CollapsibleDiv = styled.div`
  transition: all 200ms ease-out;
  height: ${({ collapsed, height }) => collapsed ? '0' : `${height}px` };
  overflow: hidden;
`;
