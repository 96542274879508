import React, { useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';

import { Attachment as AttachmentComponent } from '~/components/shared/Attachment';
import { TaggedText } from '~/components/shared/TaggedText';
import { LabelBold } from '~/components/shared/typography';
import { updateAttachment } from '~/ducks/attachments';
import { Attachment as AttachmentModel } from '~/models';

import { useTaskModalContext } from '../TaskModalContext';

function Content() {
  const {
    selectedEscalation: { activity, summary },
  } = useTaskModalContext();
  const dispatch = useDispatch();
  const [scrollPosition, setScrollPosition] = useState(0);

  const handleScroll = (event) => {
    setScrollPosition(event.target.scrollTop);
  };

  const handleDocumentTypeChange = (attachmentId, docType = null) => {
    dispatch(updateAttachment({ id: attachmentId, docType, include: 'activity' }));
  };

  const attachments = useMemo(
    () => activity.attachments?.map((attachment) => new AttachmentModel(attachment)) || [],
    [activity.attachments]
  );

  return (
    <Container onScroll={handleScroll}>
      <LabelBold>{summary}</LabelBold>
      <TaggedText text={activity?.text} />
      <AttachmentsWrapper>
        {attachments?.map((attachment) => (
          <AttachmentComponent
            key={attachment.id}
            attachment={attachment}
            onDeleteDocumentType={() => handleDocumentTypeChange(attachment.id)}
            onDocumentTypeChange={handleDocumentTypeChange}
            parentScrollTop={scrollPosition}
            usePortal={true}
          />
        ))}
      </AttachmentsWrapper>
    </Container>
  );
}

export default Content;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  margin: 24px;
  margin-bottom: 16px;
  overflow-y: scroll;
  height: 100px;

  &::-webkit-scrollbar {
    height: 6px;
    width: 6px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: ${({ theme }) => theme.colors.scrollbarGray};
    border-radius: 5pt;
  }

  & > * {
    margin: 12px 0;
  }

  & > *:first-child {
    margin-top: 0px;
  }

  & > *:last-child {
    margin-bottom: 0px;
  }
`;

const AttachmentsWrapper = styled.div`
  width: 100%;

  & > * {
    margin-top: 0.5rem;
  }
`;
