import React, { useMemo } from 'react';
import styled from 'styled-components';

import Chip, { ICON_ALIGN } from '~/components/shared/Chip';
import { Filter, Filters } from '~/components/shared/FilterBar';
import { XIcon } from '~/components/shared/svg';
import { filterKeysToWords, NONE } from '~/constants/filterKeysConstants';
import { capitalize, humanize } from '~/helpers';
import colors from '~/styles/theme/colors';

type Props = {
  filters: Filters;
  onClickRemoveFilter: ({ filterType, id }: { filterType: string; id: string }) => void;
  getChipLabel?: (filterType: string, filterValue: Filter) => string | null;
};

function getChipLabelDefaultFn(filterType: string, filterValue: Filter) {
  if (filterValue && filterValue.name !== NONE) {
    return filterValue.name;
  }

  return `No ${filterKeysToWords[filterType] ?? capitalize(humanize(filterType))} Assigned`;
}

export default function FilterChips({ filters, onClickRemoveFilter, getChipLabel = () => null }: Props) {
  const applicableFilters: [string, Filter[]][] = useMemo(() => {
    return Object.entries(filters).filter(([, value]) => Array.isArray(value) && !!value.length) as [
      string,
      Filter[],
    ][];
  }, [filters]);

  const makeRenderableChipLabel = (type: string, value: Filter) => {
    return getChipLabel(type, value) ?? getChipLabelDefaultFn(type, value);
  };

  return (
    <>
      {!!applicableFilters.length && (
        <ChipBag>
          {applicableFilters.map(([type, selectedValues]) => {
            return selectedValues.map((value: Filter) => {
              return (
                <StyledChip
                  key={`${type}-${value.id}`}
                  label={makeRenderableChipLabel(type, value)}
                  icon={<XIcon fill={colors.black} size={8} />}
                  iconAlign={ICON_ALIGN.right}
                  iconCursor='pointer'
                  bold={true}
                  truncateText={false}
                  onClick={() => onClickRemoveFilter({ filterType: type, id: value.id })}></StyledChip>
              );
            });
          })}
        </ChipBag>
      )}
    </>
  );
}

const ChipBag = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-top: 12px;

  & > * {
    margin: 0px 4px 4px 0px;
  }

  & > *:last-child {
    margin-right: 0px;
  }
`;

const StyledChip = styled(Chip).attrs({
  backgroundColor: colors.black10,
  textColor: colors.black,
})``;
