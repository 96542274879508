import React, { useState } from 'react';
import { useFormikContext } from 'formik';
import styled from 'styled-components';

import ActivityInput from '~/components/shared/ActivityInput';
import { Button, ButtonGroup } from '~/components/shared/buttons';
import { Form, InputGroup, Select } from '~/components/shared/form';
import { BodySmallBold } from '~/components/shared/typography';
import { LONG_TERM_CARE } from '~/constants/locationTypes';
import { fetchLocations } from '~/ducks/locations';
import { getId, getName, sortBy } from '~/helpers';
import { useAsyncOptions, useThunk } from '~/lib/hooks';
import { AltcsApplication, Episode, Location } from '~/models';
import { colors } from '~/styles/theme';

export type AltcsApprovalConfirmationFormProps = {
  altcsApplicationReview: AltcsApplication;
  episode: Episode;
  locationEpisodeId: string;
  onCancel: () => void;
};

type Response = {
  items: Location[];
};

function AltcsApprovalConfirmationForm(props: AltcsApprovalConfirmationFormProps) {
  const { altcsApplicationReview, locationEpisodeId, onCancel, episode } = props;
  const {
    dirty,
    handleSubmit,
    isValid,
    isSubmitting,
    setFieldValue,
    setStatus,
    status: { uploadingAttachments },
  } = useFormikContext();

  const attrValues = altcsApplicationReview.attrValues;
  const attrValuesOptions = attrValues.map((attrValue) => ({ id: attrValue.id, name: attrValue.name }));
  const latestRehabFacilityName = episode?.rehabInformation.latestRehabFacility.name;
  const ltcGroupField = 'ltcGroup';
  const owner = episode.owner;
  const patient = episode.patient;
  const [ltcGroupDefaultOption, setLtcGroupDefaultOption] = useState<Location | undefined>();
  const asyncLocationOptions = useAsyncOptions(fetchLocations, {
    params: { ownerId: owner.id, type: LONG_TERM_CARE, viewOnly: true },
    onSuccess: ({ items }: Response) => {
      const defaultOption = items?.find((option: Location) => option.name === latestRehabFacilityName);

      if (!ltcGroupDefaultOption) {
        setLtcGroupDefaultOption(defaultOption);
        setFieldValue(ltcGroupField, defaultOption);
      }
    },
  });

  useThunk(fetchLocations, [asyncLocationOptions?.loading, ltcGroupDefaultOption], {
    condition: !asyncLocationOptions?.loading && !ltcGroupDefaultOption,
    params: { name: latestRehabFacilityName, ownerId: owner.id, type: LONG_TERM_CARE, viewOnly: true },
    onSuccess: (payload: Response) => {
      const defaultOption = payload.items[0];

      setLtcGroupDefaultOption(defaultOption);
      setFieldValue(ltcGroupField, defaultOption);
    },
  });

  const handleUploadingAttachments = (uploadingAttachments: boolean) => setStatus({ uploadingAttachments });
  const buttonDisabled = !dirty || !isValid || isSubmitting || uploadingAttachments;

  return (
    <Form>
      <Heading>Patient: {patient?.name}</Heading>
      <InputGroup
        options={sortBy(attrValuesOptions, 'name')}
        name='providerOption'
        label='ALTCS Provider'
        getOptionLabel={getName}
        getOptionValue={getId}
        component={Select}
      />
      <InputGroup
        {...asyncLocationOptions}
        name={ltcGroupField}
        label='LTC'
        getOptionLabel={getName}
        getOptionValue={getId}
        component={Select}
      />
      <ActivityInput
        isTaggable
        label='Notes (optional)'
        locationEpisodeId={locationEpisodeId}
        onUploading={handleUploadingAttachments}
        customStyles={{ borderColor: colors.black25 }}
      />
      <FormActions>
        <ButtonGroup>
          <Button onClick={onCancel} color='transparent' text='Cancel' />
          <Button
            onClick={handleSubmit}
            type='submit'
            disabled={buttonDisabled}
            loading={isSubmitting}
            text='Confirm'
          />
        </ButtonGroup>
      </FormActions>
    </Form>
  );
}

export default AltcsApprovalConfirmationForm;

const Heading = styled(BodySmallBold)`
  margin-bottom: 24px;
  text-align: center;
  width: 100%;
`;

const FormActions = styled(ButtonGroup)`
  margin-top: 24px;
`;
