import React from 'react';

function InTreatmentEmptyState() {
  return (
    <svg width='80px' height='80px' viewBox='0 0 80 80' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlnsXlink='http://www.w3.org/1999/xlink'>
      <title>In Treatment Empty State</title>
      <defs>
        <path d='M35.2777778,3.33333333 L4.72222222,3.33333333 C3.88888889,3.33333333 3.33333333,3.88888889 3.33333333,4.72222222 L3.33333333,35.2777778 C3.33333333,36.1111111 3.88888889,36.6666667 4.72222222,36.6666667 L35.2777778,36.6666667 C36.1111111,36.6666667 36.6666667,36.1111111 36.6666667,35.2777778 L36.6666667,4.72222222 C36.6666667,3.88888889 36.1111111,3.33333333 35.2777778,3.33333333 Z M28.3333333,21.3888889 C28.3333333,22.2222222 27.7777778,22.7777778 26.9444444,22.7777778 L22.7777778,22.7777778 L22.7777778,26.9444444 C22.7777778,27.7777778 22.2222222,28.3333333 21.3888889,28.3333333 L18.6111111,28.3333333 C17.7777778,28.3333333 17.2222222,27.7777778 17.2222222,26.9444444 L17.2222222,22.7777778 L13.0555556,22.7777778 C12.2222222,22.7777778 11.6666667,22.2222222 11.6666667,21.3888889 L11.6666667,18.6111111 C11.6666667,17.7777778 12.2222222,17.2222222 13.0555556,17.2222222 L17.2222222,17.2222222 L17.2222222,13.0555556 C17.2222222,12.2222222 17.7777778,11.6666667 18.6111111,11.6666667 L21.3888889,11.6666667 C22.2222222,11.6666667 22.7777778,12.2222222 22.7777778,13.0555556 L22.7777778,17.2222222 L26.9444444,17.2222222 C27.7777778,17.2222222 28.3333333,17.7777778 28.3333333,18.6111111 L28.3333333,21.3888889 Z' id='in-treatment-empty-path-1' />
      </defs>
      <g id='Feedback' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
        <g id='All-Lanes-Empty---Disabled' transform='translate(-828.000000, -590.000000)'>
          <g id='Group-3-Copy-2' transform='translate(801.000000, 590.000000)'>
            <g id='Group-2' transform='translate(27.000000, 0.000000)'>
              <circle id='Oval' fillOpacity='0.1' fill='#0F1226' cx='40' cy='40' r='40' />
              <g id='Icon/Therapy' opacity='0.25' transform='translate(20.000000, 20.000000)'>
                <mask id='in-treatment-empty-mask-2' fill='white'>
                  <use xlinkHref='#in-treatment-empty-path-1' />
                </mask>
                <g id='Mask' fillRule='nonzero' />
                <g id='Mixin/Fill/Black' mask='url(#in-treatment-empty-mask-2)' fill='#0F1226' fillRule='evenodd'>
                  <rect id='Rectangle' x='0' y='0' width='40' height='40' />
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default InTreatmentEmptyState;
