import React from 'react';

/**
 * The `Slot` allows us to merge the behavior and props passed on the Slot into the children.
 *
 * For example:
 *
 * ```tsx
 * function Toggler(props: React.HTMLAttributes<HTMLButtonElement>) {
 *   const toggle = () => {
 *     console.log('clicked!');
 *   };
 *
 *   return (
 *     <Slot onClick={ toggle } { ...props } />
 *   );
 * }
 * ```
 *
 * Because an onClick handler is passed into the Slot, the
 * button will inherit it because the button is the child.
 *
 * ```tsx
 * <Toggler>
 *  <button>click me</button>
 * </Toggler>
 * ```
 */

export default function Slot({
  children,
  ...props
}: React.HTMLAttributes<HTMLElement> & {
  children?: React.ReactNode
}) {
  if (React.isValidElement(children)) {
    return React.cloneElement(children, {
      ...props,
      ...children.props,
    });
  }
  if (React.Children.count(children) > 1) {
    React.Children.only(null);
  }
  return null;
}
