import React from 'react';
import styled from 'styled-components';

import { useTaskModalContext } from '../TaskModalContext';

import Content from './Content';
import EmptyState from './EmptyState';
import Footer from './Footer';
import Header from './Header';
import LoadingState from './LoadingState';

function Body() {
  const { escalationsData, selectedEscalation, selectedEscalationLoaded } = useTaskModalContext();

  if (!escalationsData.escalations.length) return <EmptyState />;
  if (!selectedEscalation.id || !selectedEscalationLoaded) return <LoadingState />;

  return (
    <Container>
      <Header />
      <Content />
      <Footer />
    </Container>
  );
}

export default Body;

const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  min-width: 0;
`;
