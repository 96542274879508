import * as Yup from 'yup';

const buildAuthorizationValidation = (isAdmittedOrInTreatment) => Yup.object().shape({
  authorizationNumber: Yup.string().trim().required('Authorization Number is a required field'),
  approvedThroughDate: isAdmittedOrInTreatment ?
    Yup.date()
      .nullable()
      .required('Approved Through Date is a required field')
      .typeError('Approved Through Date is a required field') : null
});

export default buildAuthorizationValidation;
