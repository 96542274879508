import React from 'react';
import PropTypes from 'prop-types';

import { InputGroup, Select } from '~/components/shared/form';
import { PHYSICIAN_TEAM } from '~/constants/locationTypes';
import { fetchAttrValues } from '~/ducks/attrValues';
import { getId, getName } from '~/helpers';
import { useAsyncOptions } from '~/lib/hooks';

import { formFields } from '../../constants';

function PhysicianTeamInputGroup(props) {
  const { ownerId, disabled } = props;
  const { physicianTeamField } = formFields;

  const asyncAttrValueOptions = useAsyncOptions(fetchAttrValues, {
    params: { type: PHYSICIAN_TEAM, distinct_display_name: true },
  });

  return (
    <InputGroup
      {...asyncAttrValueOptions}
      {...physicianTeamField}
      component={Select}
      disabled={!ownerId || disabled}
      getOptionLabel={getName}
      getOptionValue={getId}
      data-cy='physician'
      placeholder={physicianTeamField.label}
    />
  );
}

PhysicianTeamInputGroup.propTypes = {
  disabled: PropTypes.bool,
  ownerId: PropTypes.string,
};

export default PhysicianTeamInputGroup;
