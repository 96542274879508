import { PATIENT_MATCH_COLLABORATION } from '~/constants/activities';

import Activity from '../Activity';

const defaults = {
  type: PATIENT_MATCH_COLLABORATION,
};

export default class PatientMatchCollaboration extends Activity {
  constructor(options = {}) {
    super({ ...defaults, ...options });
  }

  get collaboratorType() {
    return this.data.collaboratorType;
  }

  get collaboratorName() {
    return this.data.collaboratorName;
  }

  get typeLabel() {
    return 'Patient Match Collaboration';
  }
}
