import React from 'react';
import PropTypes from 'prop-types';

function PatientAuthApprovedIcon({ size = 42 }) {
  return (
    <svg
      width={`${size}px`}
      height={`${size}px`}
      viewBox='0 0 42 42'
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'>
      <g id='Rework_Ideation' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
        <g id='PAC_ApprovedPendingAdmission' transform='translate(-262.000000, -315.000000)'>
          <g id='Group-29' transform='translate(240.000000, 199.000000)'>
            <g id='Group-11' transform='translate(22.000000, 116.000000)'>
              <g id='Group-3' fill='#61BD15' fillOpacity='0.1'>
                <circle id='Oval' cx='21' cy='21' r='21' />
              </g>
              <g
                id='a-check'
                transform='translate(13.650000, 12.600000)'
                stroke='#61BD15'
                strokeLinecap='square'
                strokeWidth='2.1'>
                <path
                  d='M11.5856276,11.4048828 C10.5081103,11.1743172 9.16353103,11.0068966 7.53103448,11.0068966 C4.30427586,11.0068966 2.17415172,11.6817931 1.02711724,12.1904276 C0.399724138,12.4690759 0,13.0912552 0,13.7777379 L0,16.8 L8.11034483,16.8'
                  id='Path'
                />
                <path
                  d='M3.47586207,4.05517241 C3.47586207,1.81555862 5.29142069,0 7.53103448,0 C9.77064828,0 11.5862069,1.81555862 11.5862069,4.05517241 C11.5862069,6.29478621 9.77064828,8.68965517 7.53103448,8.68965517 C5.29142069,8.68965517 3.47586207,6.29478621 3.47586207,4.05517241 Z'
                  id='Path'
                />
                <polyline id='Path' points='11.0068966 15.062069 12.7448276 16.8 16.8 12.7448276' />
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}

PatientAuthApprovedIcon.defaultProps = {
  size: 42,
};

PatientAuthApprovedIcon.propTypes = {
  size: PropTypes.number,
};

export default PatientAuthApprovedIcon;
