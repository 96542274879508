import React from 'react';
import PropTypes from 'prop-types';

function PatientAuthDaysRemainingIcon({ size = 42 }) {
  return (
    <svg
      width={`${size}px`}
      height={`${size}px`}
      viewBox='0 0 42 42'
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'>
      <g id='Rework_Ideation' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
        <g id='PAC_DaysRemaining' transform='translate(-262.000000, -315.000000)'>
          <g id='Group-29' transform='translate(240.000000, 199.000000)'>
            <g id='Group-33' transform='translate(22.000000, 116.000000)'>
              <circle id='Oval' fillOpacity='0.1' fill='#61BD15' cx='21' cy='21' r='21' />
              <g
                id='calendar-event-2'
                transform='translate(13.000000, 13.000000)'
                stroke='#61BD15'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeWidth='2.1'>
                <path
                  d='M16,8.53333333 L16,3.2 C16,2.6112 15.5221333,2.13333333 14.9333333,2.13333333 L1.06666667,2.13333333 C0.477866667,2.13333333 0,2.6112 0,3.2 L0,14.9333333 C0,15.5221333 0.477866667,16 1.06666667,16 L7.46666667,16'
                  id='Path'
                />
                <line x1='0' y1='5.33333333' x2='16' y2='5.33333333' id='Path' />
                <line x1='4.26666667' y1='0' x2='4.26666667' y2='2.13333333' id='Path' />
                <line x1='11.7333333' y1='0' x2='11.7333333' y2='2.13333333' id='Path' />
                <polyline id='Path' points='10.6 14.8666667 12.7333333 17 17 12.7333333' />
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}

PatientAuthDaysRemainingIcon.defaultProps = {
  size: 42,
};

PatientAuthDaysRemainingIcon.propTypes = {
  size: PropTypes.number,
};

export default PatientAuthDaysRemainingIcon;
