import React from 'react';

function StarIcon() {
  return (
    <svg width='12px' height='12px' viewBox='0 0 12 12' version='1.1' xmlns='http://www.w3.org/2000/svg'>
      <g id='match-on-add-/-connect' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
        <g id='6---pt-story' transform='translate(-439.000000, -378.000000)' fill='#00A2F9' fillRule='nonzero'>
          <path d='M444.994,378 C441.682,378 439,380.688 439,384 C439,387.312 441.682,390 444.994,390 C448.312,390 451,387.312 451,384 C451,380.688 448.312,378 444.994,378 Z M447.538,387.6 L445,386.07 L442.462,387.6 L443.134,384.714 L440.896,382.776 L443.848,382.524 L445,379.8 L446.152,382.518 L449.104,382.77 L446.866,384.708 L447.538,387.6 Z' id='Shape' />
        </g>
      </g>
    </svg>
  );
}

export default StarIcon;
