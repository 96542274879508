import React, { Fragment } from 'react';
import styled from 'styled-components';

import { CaseManager } from '~/components/patients/patientProfile/PatientStory/CaseManager';
import { ProjectedDischarge } from '~/components/patients/patientProfile/PatientStory/ProjectedDischarge';
import { Button, ButtonGroup } from '~/components/shared/buttons';
import { useSwitch } from '~/lib/hooks';
import { ALL_FLAGS } from '~/models';
import { PERMISSIONS, useProfileContext } from '~/services/profile';

import Activities from '../Activities';
import { usePatientProfileContext } from '../PatientProfileContext';

import NewActivity from './NewActivity/NewActivity';
import ServiceRefusalCreateModal from './reviews/ServiceRefusalCreateModal';
import { ServiceRefusalView } from './reviews/ServiceRefusalView';
import AuthorizationCard from './AuthorizationCard';
import OlioLineChart from './OlioLineChart';
import PatientProgressChart from './PatientProgressChart';
import PatientStatus from './PatientStatus';
import StayInfo from './StayInfo';
import StaySelector from './StaySelector';

// eslint-disable-next-line complexity
function PatientStory() {
  const {
    activities,
    canCreateProgressUpdate,
    canRefuseService,
    chartData,
    dailyPerformance,
    eligibleForProgressUpdate,
    episode,
    locationEpisode,
    mainPageScrollTop,
    onOpenProgressUpdateModal,
    setSelectedLocationEpisodeId,
  } = usePatientProfileContext();

  const profileSvc = useProfileContext();
  const progressButtonText =
    !locationEpisode.progressTemplate || locationEpisode.inTreatment || locationEpisode.discharged
      ? 'Update Progress'
      : 'Start Treatment';
  const activeServiceRefusal = locationEpisode.reviews.find((r) => r.active && r.isServiceRefusal);
  const hasActiveRefusal = !!activeServiceRefusal;
  const canActOnServiceRefusal = profileSvc.canAccessLocation(episode.owner.id);
  const isOwnerUser = profileSvc.profile.actingClient.id === locationEpisode.owner.clientId;
  const projectedDischargeReview = locationEpisode.projectedDischargeReview;
  const showProjectedDischarge = !!projectedDischargeReview && (isOwnerUser || !projectedDischargeReview?.pending);
  const canEditCaseManager = profileSvc.has(PERMISSIONS.locationEpisodeUserEdit) && profileSvc.isAcute;
  const canEditProjectedDischarge = profileSvc.has(PERMISSIONS.reviewProjectedDischargeEdit) && isOwnerUser;
  const userCanCreateNote = profileSvc.has(PERMISSIONS.activityNoteCreate);
  const serviceRefusalModalSwitch = useSwitch(false);

  const showCaseManager = () => {
    return (
      (profileSvc.hasFlag(ALL_FLAGS.caseManagerAssignment) ||
        locationEpisode.owner.client.enabledFlags.includes(ALL_FLAGS.caseManagerAssignment)) &&
      ((locationEpisode.caseManager && profileSvc.isPostAcute) || profileSvc.isAcute)
    );
  };

  return (
    <Fragment>
      <section className='patient-windows-section'>
        <div className='column-left'>
          {episode.locationEpisodes.length > 1 && (
            <StaySelector
              locationEpisode={locationEpisode}
              locationEpisodes={episode.locationEpisodes}
              onSelect={setSelectedLocationEpisodeId}
            />
          )}
          {!!locationEpisode.activeAuthorizationReview && (
            <AuthorizationCard
              showUpdateButton={!episode.archived}
              authorization={locationEpisode.activeAuthorizationReview}
              rehabState={locationEpisode.currentRehabState}
              locationEpisode={locationEpisode}
            />
          )}

          {showProjectedDischarge && (
            <ProjectedDischarge
              id={projectedDischargeReview.id}
              isOverdue={locationEpisode.dischargeOverdue}
              showUpdateButton={canEditProjectedDischarge && locationEpisode.latest}
              date={projectedDischargeReview.date}
            />
          )}

          {showCaseManager() && <CaseManager locationEpisode={locationEpisode} showUpdateButton={canEditCaseManager} />}

          <StayInfo locationEpisode={locationEpisode} episode={episode} />
          <PatientProgressChart patientDailyPerformance={dailyPerformance} />
          {chartData.map((chart, index) => (
            <OlioLineChart key={index} chart={chart} />
          ))}
        </div>

        <div className='column-right'>
          {locationEpisode.latest && !locationEpisode.archived && !episode.archived && (
            <React.Fragment>
              <ServiceRefusalView
                locationEpisode={locationEpisode}
                serviceRefusal={activeServiceRefusal}
                showActions={canActOnServiceRefusal}
              />
              <ProgressContainer>
                {locationEpisode.onTrack && eligibleForProgressUpdate && (
                  <PatientStatusContainer>
                    <PatientStatus locationEpisode={locationEpisode} />
                  </PatientStatusContainer>
                )}
                {(canRefuseService || canCreateProgressUpdate) && (
                  <PatientActions>
                    {canRefuseService && (
                      <RefuseServiceButton
                        fontSize='14px'
                        data-cy='serviceRefusalButton'
                        text='Refuse Services'
                        color='white'
                        disabled={hasActiveRefusal}
                        onClick={serviceRefusalModalSwitch.turnOn}
                      />
                    )}
                    {canCreateProgressUpdate && (
                      <Button
                        onClick={onOpenProgressUpdateModal}
                        disabled={
                          !locationEpisode.progressTemplate.id || !eligibleForProgressUpdate || hasActiveRefusal
                        }
                        data-cy='updateProgressButton'
                        fontSize='14px'
                        text={progressButtonText}
                      />
                    )}
                  </PatientActions>
                )}
              </ProgressContainer>
              {userCanCreateNote && (
                <NewActivity
                  locationEpisodeId={locationEpisode.id}
                  locationId={locationEpisode.locationId}
                  mainPageScrollTop={mainPageScrollTop}
                />
              )}
            </React.Fragment>
          )}

          <Activities activities={activities} />
        </div>
      </section>
      <ServiceRefusalCreateModal
        show={serviceRefusalModalSwitch.state}
        onCancel={serviceRefusalModalSwitch.turnOff}
        onSuccess={serviceRefusalModalSwitch.turnOff}
        locationEpisode={locationEpisode}
      />
    </Fragment>
  );
}

export default PatientStory;

const PatientStatusContainer = styled.div`
  margin-right: 8px;
  margin-bottom: 24px;
`;

const PatientActions = styled(ButtonGroup)`
  margin-left: auto;
  margin-bottom: 24px;
`;

const ProgressContainer = styled.div`
  display: flex;
`;

const RefuseServiceButton = styled(Button)`
  && {
    border-color: ${({ theme }) => theme.colors.black};
    border-width: 1px;
    padding: 9px 25px;

    &:disabled {
      border-color: ${({ theme }) => theme.colors.black25};
    }
  }
`;
