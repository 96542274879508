import React, { useContext, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import styled from 'styled-components';

import TabFilters from '~/components/shared/TabFilters';
import { LOCATION_TYPE, PATIENT_STATE, PatientState } from '~/constants/filterKeysConstants';
import { getFilters, setFilter, setUnpersistedFilters } from '~/ducks/portfolioFilters';
import { ALL_FLAGS } from '~/models';
import { ProfileContext } from '~/services/profile';

import PatientTabFiltersService from './PatientTabFiltersService';

function PatientTabFilters(props) {
  const { dataRequestCount, filters } = props;

  const profileSvc = useContext(ProfileContext);
  const profile = profileSvc.profile;
  const hasImportImprovements = profileSvc.hasFlag(ALL_FLAGS.importImprovements);

  const filterOptions = useMemo(() => {
    if (profileSvc.actingClient.isAffiliate) {
      return [{ label: PatientState.LATEST, value: PatientState.LATEST, filterType: PATIENT_STATE }];
    }

    const options = profile.enabledProviderTypes?.map((providerType) => ({
      label: providerType.displayName,
      value: providerType.apiName,
      filterType: LOCATION_TYPE,
    }));

    if (profileSvc.isPostAcute) {
      options.push({
        label: 'Continued Care',
        value: PatientState.CONTINUED,
        filterType: PATIENT_STATE,
      });
    }

    return options;
  }, [profile]);

  const requestSvc = useMemo(() => {
    return new PatientTabFiltersService({
      filterOptions,
      hasImportImprovements,
      isPostAcuteClient: profileSvc.isPostAcute,
    });
  }, [filterOptions]);

  const [counts, setCounts] = useState(filterOptions.map(() => null));

  const visible = filterOptions.length > 1;

  useEffect(() => {
    if (dataRequestCount) {
      fetchCounts();
    }
  }, [dataRequestCount]);

  const tabs = useMemo(() => {
    return filterOptions.map((option, index) => {
      const countString =
        filterOptions.length === counts.length && counts[index] !== null ? ` (${counts[index]})` : ' ( )';

      return {
        label: option.label + countString,
        value: option.value,
        filterType: option.filterType,
      };
    });
  }, [filterOptions, counts]);

  const [selectedTab, setSelectedTab] = useState(tabs[0]);

  const fetchCounts = () => {
    requestSvc.requestAllCounts({ filters }).then((newCounts) => setCounts(newCounts));
  };

  useEffect(() => {
    const { locationType, patientState } = props.filters;

    const selectedTabValue = locationType || patientState || tabs[0]?.value;

    if (selectedTabValue) {
      handleFilterSelect(selectedTabValue);
    }
  }, [profileSvc.actingClient.id]);

  function handleFilterSelect(value) {
    const tab = tabs.find((tab) => tab.value === value);

    setSelectedTab(tab);

    if (value !== PatientState.CONTINUED && profileSvc.isPostAcute) {
      props.setUnpersistedFilters({ [tab.filterType]: value, [PATIENT_STATE]: PatientState.CURRENT });
    } else {
      // setFilter does not persist the filter to the profile
      props.setFilter({ filterType: tab.filterType, value });
    }
  }

  return (
    <div>
      {visible ? (
        <TabFiltersContainer>
          <StyledTabFilters onTabClick={handleFilterSelect} selectedTab={selectedTab} tabs={tabs} />
        </TabFiltersContainer>
      ) : (
        <Spacer />
      )}
    </div>
  );
}

PatientTabFilters.propTypes = {
  dataRequestCount: PropTypes.number,
  filters: PropTypes.instanceOf(Object),
  setFilter: PropTypes.func,
  setUnpersistedFilters: PropTypes.func,
};

const mapStateToProps = (state) => ({
  filters: getFilters(state),
});

const mapDispatchToProps = {
  setFilter,
  setUnpersistedFilters,
};

export default connect(mapStateToProps, mapDispatchToProps)(PatientTabFilters);

const TabFiltersContainer = styled.div`
  margin-left: 24px;
`;

const StyledTabFilters = styled(TabFilters)`
  border-top: none;
`;

const Spacer = styled.div`
  height: 24px;
`;
