const defaults = {
  id: '',
  name: '',
  type: '',
  groups: []
};

export default class FilterOption {
  constructor(options = {}) {
    const opts = { ...defaults, ...options };

    this.id = opts.id;
    this.name = opts.name;
    this.type = opts.type;
    this.groups = opts.groups;
  }
}
