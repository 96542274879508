import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import styled from 'styled-components';

import { fetchAttachment } from '~/ducks/attachments';

const DocumentLink = ({ entry, children, disabled, ...props }) => {
  const [clicked, setClicked] = useState(false);
  const [documentURL, setDocumentURL] = useState('');
  // "entry" can be an object where the "id" prop is a number or a GUID. We only
  // want to make the link clickable and fetch the attachment if the "id" prop
  // is a GUID.
  const guidRegex = /^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i;

  const _handleOpenDocument = (url) => {
    window.open(url, '_blank');
  };

  const openDocumentIfClickedAndPresent = () => {
    const url = documentURL;

    if (clicked && url !== '') {
      _handleOpenDocument(url);
      setClicked(false);
      setDocumentURL('');
    }
  };

  const _handleClick = () => {
    if (!entry.id || !guidRegex.test(entry.id) || disabled) return;

    setClicked(true);

    props.fetchAttachment({ id: entry.id, include: 'url' }).then((res) => setDocumentURL(res.payload.url));
  };

  useEffect(openDocumentIfClickedAndPresent, [clicked, documentURL]);

  return (
    <Link onClick={_handleClick} disabled={disabled} clickable={guidRegex.test(entry.id)}>
      {children}
    </Link>
  );
};

DocumentLink.propTypes = {
  disabled: PropTypes.bool,
  entry: PropTypes.instanceOf(Object),
  fetchAttachment: PropTypes.func,
};

DocumentLink.defaultProps = {
  disabled: false,
  entry: {},
};

export default connect(null, { fetchAttachment })(DocumentLink);

const Link = styled.a`
  cursor: ${(props) => (props.clickable ? 'pointer' : 'default')};
  cursor: ${(props) => props.disabled && 'not-allowed'};
  flex: 1 1 75%;
  opacity: ${(props) => props.disabled && 0.25};
  width: 0px;
`;
