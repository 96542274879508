import * as Yup from 'yup';

export const caseManagerFormValidation = Yup.object().shape({
  caseManager: Yup.object()
    .shape({
      id: Yup.string(),
      name: Yup.string(),
    })
    .nullable(),
});
