import React, { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import FilterBar from '~/components/shared/FilterBar';
import FilterChips from '~/components/shared/FilterChips';
import SimplePage from '~/components/shared/pageLayout/SimplePage';
import { ControlledTable } from '~/components/shared/table';
import { HEALTH_SYSTEM, PAYOR, PHYSICIAN_GROUP as PHYSICIAN_GROUP_CLIENT } from '~/constants/clientTypes';
import { SEARCH } from '~/constants/filterKeysConstants';
import { fetchImportedPatients, fetchImportedPatientsCount } from '~/ducks/importedPatients';
import {
  clearFilters,
  formatFilters,
  getFilters,
  getFiltersForRequest,
  getFiltersOrderedForUser,
  removeFilter,
  setFilters,
} from '~/ducks/patientFilters';
import { useThunk } from '~/lib/hooks';
import { parseSortBy } from '~/lib/hooks/usePagination';
import { useProfileContext } from '~/services/profile';

import connectPatientsTableColumns from './connectPatientsTableColumns';
import { renderableFilterDropdowns } from './renderableFilterDropdowns';

function ConnectPatients(props) {
  const { patientFilters, patientFiltersMap } = props;
  const profileSvc = useProfileContext();
  const [pagingFilters, setPagingFilters] = useState({});
  const [importedPatientsMeta, setImportedPatientsMeta] = useState({ totalPages: 0, totalRecords: 0 });
  const [tableProps, setTableProps] = useState({
    setSorting: () => {},
  });

  const defaultSortBy = useMemo(() => 'patientName asc', []);
  const { locationTitle } = useMemo(() => {
    const clientTypeMap = {
      [PAYOR]: { locationTitle: 'Payer' },
      [PHYSICIAN_GROUP_CLIENT]: { locationTitle: 'Physician Group' },
      [HEALTH_SYSTEM]: { locationTitle: 'Hospital' },
    };

    return clientTypeMap[profileSvc.clientType] || clientTypeMap[HEALTH_SYSTEM];
  }, [profileSvc.clientType]);

  const { data: importedPatients, loaded } = useThunk(fetchImportedPatients, [pagingFilters, patientFilters], {
    debounce: 50,
    onSuccess: (payload) => {
      setImportedPatientsMeta({
        totalPages: payload?.meta?.totalPages,
        totalRecords: payload?.meta?.totalRecords,
      });
    },
    params: {
      include: 'owner',
      ...pagingFilters,
      ...patientFilters,
    },
  });

  const handleConnectClick = (id) => props.history.push(`/connect-patients/${id}`);

  const columns = useMemo(
    () =>
      connectPatientsTableColumns({
        locationTitle,
        onConnectClick: handleConnectClick,
        userClientType: profileSvc.clientType,
        showConnect: profileSvc.canCreatePatient,
      }),
    [locationTitle]
  );

  const handlePagingFiltersChange = useCallback((newPagingFilters) => {
    setPagingFilters(newPagingFilters);
  }, []);

  const searchValue = patientFilters[SEARCH];

  useEffect(() => {
    const sortBy = searchValue ? [{ id: SEARCH, desc: true }] : parseSortBy(defaultSortBy);

    tableProps.setSorting(sortBy);
  }, [searchValue]);

  const handleTableInit = setTableProps;

  const filterBarFilterSections = useMemo(
    () => renderableFilterDropdowns(profileSvc.clientType),
    [profileSvc.clientType]
  );

  return (
    <SimplePage>
      <SimplePage.Header>
        <FilterBar
          title='Connect Patients'
          filterSections={filterBarFilterSections}
          filters={patientFiltersMap}
          onClearFilters={props.clearFilters}
          applyFilters={props.setFilters}
          fetchCountThunk={fetchImportedPatientsCount}
          filterFormatter={formatFilters}
        />
      </SimplePage.Header>
      <SimplePage.SubHeader>
        <FilterChips filters={props.orderedFilters} onClickRemoveFilter={props.removeFilter} />
        <p>{importedPatientsMeta.totalRecords} patients found</p>
      </SimplePage.SubHeader>
      <SimplePage.Content>
        <ControlledTable
          data={importedPatients}
          defaultSortBy={defaultSortBy}
          columns={columns}
          filters={patientFiltersMap}
          loading={!loaded}
          onInitialize={handleTableInit}
          onPagingFiltersChange={handlePagingFiltersChange}
          pageCount={importedPatientsMeta.totalPages}
        />
      </SimplePage.Content>
    </SimplePage>
  );
}

ConnectPatients.propTypes = {
  clearFilters: PropTypes.func.isRequired,
  orderedFilters: PropTypes.instanceOf(Object),
  patientFilters: PropTypes.instanceOf(Object),
  patientFiltersMap: PropTypes.instanceOf(Object),
  removeFilter: PropTypes.func.isRequired,
  setFilters: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  patientFilters: getFiltersForRequest(state),
  patientFiltersMap: getFilters(state),
  orderedFilters: getFiltersOrderedForUser(state),
});

const mapDispatchToProps = {
  clearFilters,
  setFilters,
  removeFilter,
};

export default connect(mapStateToProps, mapDispatchToProps)(ConnectPatients);
