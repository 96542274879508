import { createAsyncThunk } from '~/lib';
import { reviewsApi } from '~/services/api';

const sliceName = 'reviews';

export const createReview = createAsyncThunk(`${sliceName}/create`, async (payload) => {
  const res = await reviewsApi.create(payload);

  return res.data;
});

export const updateReview = createAsyncThunk(`${sliceName}/update`, async (params) => {
  const { id, ...rest } = params;
  const { data } = await reviewsApi.update.invoke(id, rest);

  return data;
});
