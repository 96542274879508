import React from 'react';
import { Formik } from 'formik';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import styled from 'styled-components';

import ActivityInput from '~/components/shared/ActivityInput';
import { Button, ButtonGroup } from '~/components/shared/buttons';
import { Form, FormSection } from '~/components/shared/form';
import { StandardModal } from '~/components/shared/modal';
import { H3, Label } from '~/components/shared/typography';
import { createActivity } from '~/ducks/activities';
import { Escalation, Note } from '~/models';
import colors from '~/styles/theme/colors';

function NewEscalationModal(props) {
  const { locationEpisodeId, onClear, onClose, patientName, showEscalationModal } = props;
  const handleSubmit = (values) => {
    const escalatedActivity = new Note({
      ...values.note,
      escalation: new Escalation(),
      locationEpisodeId,
    });

    props.createActivity({ ...escalatedActivity.serialize(), include: 'attachments.blob' });
    onClear();
    onClose();
  };

  return (
    <StandardModal
      colorTheme='danger'
      disableBackdropClick
      onCancel={onClose}
      show={showEscalationModal}
      data-cy='escalationModal'
      title='Escalation'>
      <PatientName>Patient: {patientName}</PatientName>
      <FormContainer>
        <Formik initialValues={{ note: new Note() }} onSubmit={handleSubmit} status={{ uploadingAttachments: false }}>
          {({ values, status, setStatus }) => (
            <Form>
              <FormSection>
                <ActivityInput
                  isTaggable
                  label='Please provide details surrounding the escalation'
                  locationEpisodeId={locationEpisodeId}
                  onUploading={(uploadingAttachments) => setStatus({ uploadingAttachments })}
                  placeholder='Enter details surrounding the escalation here...'
                  customStyles={{ borderColor: colors.black25 }}
                />
                <WarningLabel>*Sending an escalation will notify all members of the co-management team</WarningLabel>
              </FormSection>
              <ButtonGroup>
                <Button text='Cancel' color='transparent' onClick={onClose} />
                <Button
                  data-cy='escalationConfirm'
                  text='Confirm'
                  disabled={
                    (!values.note.text.trim() && !values.note.attachments.length) || status?.uploadingAttachments
                  }
                  type='submit'
                />
              </ButtonGroup>
            </Form>
          )}
        </Formik>
      </FormContainer>
    </StandardModal>
  );
}

const mapDispatchToProps = {
  createActivity,
};

NewEscalationModal.defaultProps = {
  onClear: () => {},
};

NewEscalationModal.propTypes = {
  createActivity: PropTypes.func.isRequired,
  locationEpisodeId: PropTypes.string,
  onClear: PropTypes.func.isRequired,
  onClose: PropTypes.func,
  patientName: PropTypes.string,
  showEscalationModal: PropTypes.bool.isRequired,
};

export default connect(null, mapDispatchToProps)(NewEscalationModal);

const FormContainer = styled.div`
  display: flex;
  justify-content: center;
`;

const WarningLabel = styled(Label)`
  background-color: ${colors.black05};
  display: flex;
  font-style: italic;
  padding: 10px;
  margin: 20px 0px 40px;
`;

const PatientName = styled(H3)`
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
`;
