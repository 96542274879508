import attrs from './attrs';
import attrsFilters from './attrsFilters';
import attrValues from './attrValues';
import attrValuesFilters from './attrValuesFilters';
import clients from './clients';
import collaborations from './collaborations';
import collaborationsFilters from './collaborationsFilters';
import groupTypeFilters from './groupTypesFilters';
import importConfigurations from './importConfigurations';
import importConfigurationsFilters from './importConfigurationsFilters';
import importFilters from './importFilters';
import locations from './locations';
import locationsFilters from './locationsFilters';
import users from './users';
import usersFilters from './usersFilters';

export default {
  [attrs.name]: attrs.reducer,
  [attrsFilters.name]: attrsFilters.reducer,
  [attrValues.name]: attrValues.reducer,
  [attrValuesFilters.name]: attrValuesFilters.reducer,
  [clients.name]: clients.reducer,
  [collaborations.name]: collaborations.reducer,
  [collaborationsFilters.name]: collaborationsFilters.reducer,
  [groupTypeFilters.name]: groupTypeFilters.reducer,
  [importFilters.name]: importFilters.reducer,
  [importConfigurations.name]: importConfigurations.reducer,
  [importConfigurationsFilters.name]: importConfigurationsFilters.reducer,
  [locations.name]: locations.reducer,
  [locationsFilters.name]: locationsFilters.reducer,
  [users.name]: users.reducer,
  [usersFilters.name]: usersFilters.reducer,
};
