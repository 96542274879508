import { PAYOR } from '~/constants/clientTypes';
import { AFFILIATE, HHA, PHYSICIAN_TEAM } from '~/constants/locationTypes';
import { Escalation, Profile } from '~/models';

export const PERMISSIONS = {
  adminAttrView: 'adminAttrView',
  adminAttrCreate: 'adminAttrCreate',
  adminAttrEdit: 'adminAttrEdit',
  adminAttrValueView: 'adminAttrValueView',
  adminAttrValueCreate: 'adminAttrValueCreate',
  adminAttrValueEdit: 'adminAttrValueEdit',
  adminClassificationCreate: 'adminClassificationCreate',
  adminClassificationEdit: 'adminClassificationEdit',
  adminClassificationView: 'adminClassificationView',
  adminClientCreate: 'adminClientCreate',
  adminClientEdit: 'adminClientEdit',
  adminClientView: 'adminClientView',
  adminClientGroupTypeEdit: 'adminClientGroupTypeEdit',
  adminClientGroupTypeView: 'adminClientGroupTypeView',
  adminCollaborationEdit: 'adminCollaborationEdit',
  adminCollaborationView: 'adminCollaborationView',
  adminFeatureFlagEdit: 'adminFeatureFlagEdit',
  adminFeatureFlagView: 'adminFeatureFlagView',
  adminGroupCreate: 'adminGroupCreate',
  adminGroupDelete: 'adminGroupDelete',
  adminGroupEdit: 'adminGroupEdit',
  adminGroupView: 'adminGroupView',
  adminGroupTypeCreate: 'adminGroupTypeCreate',
  adminGroupTypeEdit: 'adminGroupTypeEdit',
  adminGroupTypeView: 'adminGroupTypeView',
  adminImportedPatientEdit: 'adminImportedPatientEdit',
  adminImportedPatientView: 'adminImportedPatientView',
  adminImportConfigurationCreate: 'adminImportConfigurationCreate',
  adminImportConfigurationEdit: 'adminImportConfigurationEdit',
  adminImportConfigurationView: 'adminImportConfigurationView',
  adminQuestionTemplateCreate: 'adminQuestionTemplateCreate',
  adminQuestionTemplateView: 'adminQuestionTemplateView',
  adminQuestionTemplateEdit: 'adminQuestionTemplateEdit',
  adminUserCreate: 'adminUserCreate',
  adminUserEdit: 'adminUserEdit',
  adminUserInvitationCreate: 'adminUserInvitationCreate',
  adminUserDelete: 'adminUserDelete',
  adminUserView: 'adminUserView',
  activityNoteCreate: 'activityNoteCreate',
  activityProgressUpdateCreate: 'activityProgressUpdateCreate',
  escalationCreate: 'escalationCreate',
  escalationEdit: 'escalationEdit',
  locationEpisodeUserEdit: 'locationEpisodeUserEdit',
  patientCreate: 'patientCreate',
  patientDelete: 'patientDelete',
  patientEdit: 'patientEdit',
  rehabStateAdmissionCreate: 'rehabStateAdmissionCreate',
  rehabStateDischargeCreate: 'rehabStateDischargeCreate',
  rehabStateEdit: 'rehabStateEdit',
  reviewAuthorizationEdit: 'reviewAuthorizationEdit',
  reviewProjectedDischargeEdit: 'reviewProjectedDischargeEdit',
  reviewServiceRefusalCreate: 'reviewServiceRefusalCreate',
  reviewServiceRefusalEdit: 'reviewServiceRefusalEdit',
  reviewAltcsApplicationEdit: 'reviewAltcsApplicationEdit',
};

export default class ProfileService {
  constructor(profile = new Profile()) {
    this.profile = profile;
  }

  get actingClient() {
    return this.profile.actingClient;
  }

  get allPermissions() {
    return PERMISSIONS;
  }

  get canEditEscalation() {
    if (!this.isAcute) return false;
    return this.has(PERMISSIONS.escalationEdit);
  }

  get canAddPriorityNote() {
    return this.isAcute && this.has(PERMISSIONS.escalationCreate);
  }

  get canCreatePatient() {
    if (this.isAffiliateUser) return false;

    return this.has(PERMISSIONS.patientCreate);
  }

  get client() {
    return this.profile.client;
  }

  get clientType() {
    return this.actingClient?.clientType;
  }

  get enabledProviderTypes() {
    return this.profile.enabledProviderTypes;
  }

  get isAcute() {
    return this.profile.isAcute;
  }

  get isPostAcute() {
    return !this.isAcute;
  }

  get isAdmin() {
    return this.profile.isAdmin;
  }

  get isPhysicianTeamUser() {
    return this.profile.groupType === PHYSICIAN_TEAM;
  }

  get isInpatientPostAcute() {
    return this.profile.isInpatientPostAcute;
  }

  get isOutpatientPostAcute() {
    return this.profile.isOutpatientPostAcute;
  }

  get isAffiliateUser() {
    return this.clientType?.toLowerCase() === AFFILIATE;
  }

  get isPayerUser() {
    return this.clientType === PAYOR;
  }

  get groupType() {
    return this.profile.groupType;
  }

  canAccessLocation(locationId) {
    return this.profile.allowedLocationIds.includes(locationId);
  }

  canAcknowledge(escalation, locationId) {
    if (!escalation) return false;
    if (!(escalation instanceof Escalation)) {
      throw new TypeError('Argument must be instance of Escalation');
    }

    if (escalation.isDefault) return this.canEditEscalation;
    return this.canEditPriorityNote(locationId);
  }

  canEditAuthorization(ownerClientId) {
    return this.isOwnerUser(ownerClientId) && this.has(PERMISSIONS.reviewAuthorizationEdit);
  }

  canEditPriorityNote(locationId) {
    return !this.canAddPriorityNote && this.canAccessLocation(locationId) && this.has(PERMISSIONS.escalationEdit);
  }

  canCreateProgressUpdate(locationId) {
    if (this.isAcute) return false;
    return this.has(PERMISSIONS.activityProgressUpdateCreate) && this.canAccessLocation(locationId);
  }

  canDischarge(locationId) {
    return this.has(PERMISSIONS.rehabStateDischargeCreate) && (this.isAcute || this.canAccessLocation(locationId));
  }

  canEscalate(locationId) {
    if (this.isAcute) return false;
    return this.has(PERMISSIONS.escalationCreate) && this.canAccessLocation(locationId);
  }

  canRefuseService(locationId, locationType) {
    return (
      this.canAccessLocation(locationId) && this.has(PERMISSIONS.reviewServiceRefusalCreate) && locationType === HHA
    );
  }

  canEditPatient(episode) {
    return (
      this.has(PERMISSIONS.patientEdit) &&
      (this.isOwnerUser(episode.owner.clientId) || this.isCurrentLocationUser(episode.latestRehabFacility))
    );
  }

  has(permission) {
    if (!this.profile.permissions) return false;

    return this[permission] || this.profile.permissions[permission] || false;
  }

  hasAll(permissions) {
    return permissions.every((permission) => this.has(permission));
  }

  hasAny(permissions) {
    return permissions.some((permission) => this.has(permission));
  }

  hasFlag(name) {
    if (!this.profile.flags) return false;

    return this.profile.flags.includes(name);
  }

  isOwnerUser(ownerClientId) {
    return this.actingClient && this.actingClient.id === ownerClientId;
  }

  isCurrentLocationUser(currentLocation) {
    return this.canAccessLocation(currentLocation.id);
  }
}
