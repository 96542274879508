import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { LabelBold, LabelSmall } from '~/components/shared/typography';
import { usePrevious } from '~/lib/hooks';

import colors from '../../styles/theme/colors';

function ProgressBar({ backgroundColor, color, completedColor, height = 7, max = 100, showLabel = true, value = 0 }) {
  const currentProgress = value / max;
  const currentProgressPercent = currentProgress * 100;
  const previousValue = usePrevious(value);
  const [progressStart, setProgressStart] = useState(null);

  useEffect(() => {
    if (value && !previousValue) {
      setProgressStart(new Date());
    }
  }, [value]);

  const timeRemaining = useMemo(() => {
    if (!progressStart) return '';
    if (value === max) return '— Complete';

    const elapsedTime = new Date() - progressStart;
    const estimatedTotalTime = elapsedTime / currentProgress;
    const estimatedRemainingTime = Math.round((estimatedTotalTime - elapsedTime) / 1000);

    return `— ${estimatedRemainingTime} seconds left`;
  }, [currentProgress]);

  return (
    <Container>
      <ProgressBarContainer backgroundColor={backgroundColor} height={height}>
        <ProgressBarProgress
          color={color}
          completedColor={completedColor}
          height={height}
          progressPercent={currentProgressPercent}
        />
      </ProgressBarContainer>
      {showLabel && (
        <ProgressLabel>
          <LabelBold>{Math.round(currentProgressPercent)}%</LabelBold>
          <LabelSmall color={colors.black75}>{timeRemaining}</LabelSmall>
        </ProgressLabel>
      )}
    </Container>
  );
}

ProgressBar.propTypes = {
  backgroundColor: PropTypes.string,
  color: PropTypes.string,
  completedColor: PropTypes.string,
  height: PropTypes.number,
  max: PropTypes.number,
  showLabel: PropTypes.bool,
  value: PropTypes.number,
};

export default ProgressBar;

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  justify-content: flex-end;
`;

const ProgressBarContainer = styled.div`
  width: 100%;
  border-radius: ${({ height }) => height}px;
  background-color: ${({ backgroundColor, theme }) => backgroundColor || theme.colors.white};
  height: ${({ height }) => height}px;
`;

const progressBarColor = ({ color, completedColor, progressPercent, theme }) => {
  if (progressPercent === 100) {
    return completedColor || theme.colors.accentGreen;
  }

  return color || theme.colors.primaryBlue;
};

const ProgressBarProgress = styled.div`
  border-radius: ${({ height }) => height}px;
  width: ${({ progressPercent }) => progressPercent}%;
  background-color: ${progressBarColor};
  height: ${({ height }) => height}px;
`;

const ProgressLabel = styled.div`
  margin-top: 4px;
`;
