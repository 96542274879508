import React from 'react';
import styled from 'styled-components';

import colors from '~/styles/theme/colors';

import { useSlideoutContext } from './Slideout';

const SlideoutHeader = ({ children }: { children: React.ReactNode }) => {
  const { handleExit } = useSlideoutContext();

  return (
    <Header>
      {children}
      <CloseIcon onClick={handleExit}>&times;</CloseIcon>
    </Header>
  );
};

const CloseIcon = styled.div`
  margin-left: auto;
  color: ${colors.black75};
  cursor: pointer;
  font-size: 24px;
`;

const Header = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  border-bottom: 1px solid ${colors.black10};
  padding: 24px;
`;

export default SlideoutHeader;
