import { createSelector, createSlice } from '@reduxjs/toolkit';

import { SORTS } from '~/constants/filterKeysConstants';
import { addToast } from '~/ducks/toasts';
import { createAsyncThunk } from '~/lib';
import { PortfolioFilterUserPreference, Profile, User, UserPreference } from '~/models';
import { meApi } from '~/services/api';

import { updateUser } from './admin/users';
import { updateUserPreference } from './userPreferences';

export const updateAgreements = createAsyncThunk('agreements/update', async (payload) => {
  const res = await meApi.updateAgreements.invoke(payload);

  return res.data;
});

export const fetchProfile = createAsyncThunk('profile/fetch', async (params) => {
  const res = await meApi.profile.invoke(params);

  return res.data;
});

export const updateProfile = createAsyncThunk('profile/update', async (params, { dispatch }) => {
  try {
    const res = await meApi.update.invoke(params);

    return res.data;
  } catch (e) {
    const errorMessage = e?.response?.data?.error || 'There was an error updating. Please try again.';

    dispatch(addToast({ text: errorMessage }));
    throw e;
  }
});

const SLICE_NAME = 'profile';
const initialState = new Profile();
const anyProfileThunkFulfilled = (action) =>
  [fetchProfile.fulfilled, updateAgreements.fulfilled, updateProfile.fulfilled].toString().includes(action.type);

const slice = createSlice({
  name: SLICE_NAME,
  initialState,
  extraReducers: (builder) =>
    builder
      .addCase(updateUser.fulfilled, (state, { payload }) => {
        const updatedUser = new User(payload);

        if (state.id === updatedUser.id) {
          state.permissions = updatedUser.permissions;
        }
      })
      .addCase(updateUserPreference.fulfilled, (state, { payload }) => {
        const updatedUserPreference = new UserPreference(payload);
        const index = state.preferences.findIndex((pref) => pref.id === updatedUserPreference.id);

        if (index !== -1) {
          state.preferences[index] = updatedUserPreference;
        } else {
          state.preferences.push(updatedUserPreference);
        }
      })
      .addCase('portfolio/filters/removeFilter', (state, { payload }) => {
        const index = state.preferences
          .map((pref) => new UserPreference(pref))
          .findIndex((pref) => pref.isPortfolioFilter && pref.clientId === state.actingClient?.id);

        if (index === -1) return;

        const pref = state.preferences[index];

        if (!pref.value[payload.filterType]) return;

        const newFilterTypeValues = pref.value[payload.filterType].filter((filter) => filter.id !== payload.id);

        // We have to reconstruct the preference instead of setting the "value"
        // prop directly. Immer won't make it immutable and dependencies will not trigger.
        state.preferences[index] = {
          ...pref,
          value: {
            ...pref.value,
            [payload.filterType]: newFilterTypeValues,
          },
        };
      })
      .addCase('portfolio/filters/clearFilters', (state) => {
        const index = state.preferences
          .map((pref) => new UserPreference(pref))
          .findIndex((pref) => pref.isPortfolioFilter && pref.clientId === state.actingClient?.id);

        if (index === -1) return;

        const pref = state.preferences[index];

        // We have to reconstruct the preference instead of setting the "value"
        // prop directly. Immer won't make it immutable and dependencies will not trigger.
        state.preferences[index] = { ...pref, value: {} };
      })
      .addCase('portfolio/filters/setFilters', (state, { payload }) => {
        const index = state.preferences
          .map((pref) => new UserPreference(pref))
          .findIndex((pref) => pref.isPortfolioFilter && pref.clientId === state.actingClient?.id);

        if (index !== -1) {
          const pref = state.preferences[index];

          // We have to reconstruct the preference instead of setting the "value"
          // prop directly. Immer won't make it immutable and dependencies will not trigger.
          state.preferences[index] = {
            ...pref,
            value: {
              ...pref.value,
              ...payload,
            },
          };
        } else {
          const pref = new PortfolioFilterUserPreference({
            clientId: state.actingClient.id,
            userId: state.id,
            value: payload,
          });

          state.preferences.push(pref);
        }
      })
      .addCase('portfolio/filters/setSort', (state, { payload }) => {
        const index = state.preferences
          .map((pref) => new UserPreference(pref))
          .findIndex((pref) => pref.isPortfolioFilter && pref.clientId === state.actingClient?.id);

        if (index !== -1) {
          const pref = state.preferences[index];

          // We have to reconstruct the preference instead of setting the "value"
          // prop directly. Immer won't make it immutable and dependencies will not trigger.
          state.preferences[index] = {
            ...pref,
            value: {
              ...pref.value,
              [SORTS]: {
                ...pref.value[SORTS],
                [payload.key]: payload,
              },
            },
          };
        } else {
          const pref = new PortfolioFilterUserPreference({
            clientId: state.actingClient.id,
            userId: state.id,
            value: { [SORTS]: { [payload.key]: payload } },
          });

          state.preferences.push(pref);
        }
      })
      .addMatcher(anyProfileThunkFulfilled, (state, { payload }) => ({ ...state, ...payload })),
});

export const getSlice = (state) => state[SLICE_NAME];
export const getProfile = createSelector(getSlice, (profile) => new Profile(profile));

export const getProfileLoaded = (state) => !!getProfile(state).id;

export default slice.reducer;
