import styled from 'styled-components';

import { center, title } from '~/styles/mixins';

export const EmptyStateContainer = styled.div`
  ${center('column')};
  background-color: ${({ theme }) => theme.colors.white};
  margin-bottom: 25px;
  padding: 40px;
`;

export const EmptyStateContent = styled.div`
  color: ${({ theme }) => theme.colors.black75};
`;

export const EmptyStateTitle = styled.div`
  ${title()};
  margin-bottom: 8px;
`;

export const IconContainer = styled.div`
  margin-bottom: 16px;
`;
