import { makeFilterOptionsSlice } from './filterOptions';

export const SLICE_NAME = 'portfolio/filterOptions';

const { slice, ...rest } = makeFilterOptionsSlice(SLICE_NAME);

export const {
  fetch: fetchPortfolioFilterOptions,
  getOptions: getPortfolioFilterOptions,
  getOrderedOptions: getOrderedPortfolioFilterOptions
} = rest;

export default slice;
