import React from 'react';
import PropTypes from 'prop-types';

import { DatePicker, FormSection, InputGroup, SectionHeader } from '~/components/shared/form';

function PostAcuteInfoSection(props) {
  const { isAcute, connecting } = props;
  const visible = isAcute && connecting;

  if (!visible) return null;

  return (
    <FormSection>
      <SectionHeader>Post-Acute Information</SectionHeader>
      { connecting ?
        <InputGroup name='antHospitalDischarge'
          label='Anticipated Hospital Discharge (optional)'
          component={ DatePicker } /> : null
      }
    </FormSection>
  );
}

PostAcuteInfoSection.propTypes = {
  connecting: PropTypes.bool,
  isAcute: PropTypes.bool.isRequired
};

export default PostAcuteInfoSection;
