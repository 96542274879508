import { DISCHARGE, PROGRESS } from '~/constants/questionTemplateTypes';

import ModelFactory from '../ModelFactory';

import DischargeTemplate from './DischargeTemplate';
import ProgressTemplate from './ProgressTemplate';

export default class QuestionTemplateFactory extends ModelFactory {
  static classMap = {
    [DISCHARGE]: DischargeTemplate,
    [PROGRESS]: ProgressTemplate,
  };

  static defaultClass = ProgressTemplate;
}
