
import React from 'react';
import PropTypes from 'prop-types';

import { InputGroup, Select } from '~/components/shared/form';
import { HOSPITAL } from '~/constants/locationTypes';
import { fetchLocations } from '~/ducks/locations';
import { getId, getName } from '~/helpers';
import { useAddressOptions, useAsyncOptions } from '~/lib/hooks';

import { formFields } from '../../constants';

function HospitalInputGroup(props) {
  const { ownerId, disabled } = props;
  const { hospitalField } = formFields;

  const addressProps = useAddressOptions();
  const asyncLocationOptions = useAsyncOptions(fetchLocations, {
    condition: Boolean(ownerId),
    params: { ownerId, type: HOSPITAL }
  });

  return (
    <InputGroup { ...asyncLocationOptions }
      { ...hospitalField }
      { ...addressProps }
      component={ Select }
      disabled={ !ownerId || disabled }
      getOptionLabel={ getName }
      getOptionValue={ getId }
      data-cy={ hospitalField.name }
      placeholder={ hospitalField.label } />
  );
}

HospitalInputGroup.propTypes = {
  disabled: PropTypes.bool,
  ownerId: PropTypes.string
};

export default HospitalInputGroup;
