import theme from '../theme';

import { cssProp } from './helpers';

const font = ({ fontColor, fontSize, fontWeight, lineHeight } = {}) => {
  const color = cssProp('color', fontColor);
  const size = cssProp('font-size', fontSize);
  const weight = cssProp('font-weight', fontWeight);
  const height = cssProp('line-height', lineHeight);

  return `${color} ${size} ${weight} ${height}`;
};

const body = (color = theme.colors.ebony) => {
  return font({
    fontColor: color,
    fontSize: '14px',
    fontWeight: 400,
    lineHeight: '20px',
  });
};

const title = (color = theme.colors.ebony) => {
  return font({
    fontColor: color,
    fontSize: '20px',
    fontWeight: 700,
    lineHeight: '32px',
  });
};

const subheading2 = (color = theme.colors.ebony) => {
  return font({
    fontColor: color,
    fontSize: '16px',
    fontWeight: 400,
    lineHeight: '24px',
  });
};

const link = (color = theme.colors.primaryBlue) => {
  return font({
    fontColor: color,
    fontSize: '14px',
    fontWeight: 700,
    lineHeight: '24px',
  });
};

export { body, font, link, subheading2, title };
