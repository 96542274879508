import React, { memo } from 'react';
import Highcharts from 'highcharts';
import ChartModuleMore from 'highcharts/highcharts-more.js';
import HCSoldGauge from 'highcharts/modules/solid-gauge';
import HighchartsReact from 'highcharts-react-official';

import { colors } from '~/styles/theme';

ChartModuleMore(Highcharts);
HCSoldGauge(Highcharts);

type Props = {
  value: number;
  min?: number;
  max?: number;
  label: string;
  subLabel: string;
};

// eslint-disable-next-line react/display-name
const OlioSolidGauge = memo(({ value, min = 0, max = 100, label, subLabel }: Props) => {
  const config = {
    chart: {
      type: 'solidgauge',
      height: null,
    },
    title: null,
    pane: {
      center: ['50%', '80%'],
      size: '135%',
      startAngle: -90,
      endAngle: 90,

      background: {
        backgroundColor: `${colors.black15}`,
        innerRadius: '92%',
        outerRadius: '100%',
        borderColor: 'none',
        borderWidth: 0,
        shape: 'arc',
      },
    },
    exporting: {
      enabled: false,
    },
    yAxis: {
      stops: [
        [0, '#DC2853'], // red
        [0.74, '#DC2853'], // red
        [0.75, '#F0C02F'], // yellow
        [0.99, '#F0C02F'], // yellow
        [1, '#2EA310'], // green
      ],
      lineWidth: 0,
      tickWidth: 0,
      minorTickInterval: null,
      tickAmount: 0,
      labels: {
        enabled: false,
      },
      min: min,
      max: max,
    },
    credits: {
      enabled: false,
    },
    series: [
      {
        name: 'OES',
        data: [value],
        dataLabels: {
          format:
            '<div style="text-align:center">' +
            `<span style="font-size:24px; font-weight:900">${label}</span><br/>` +
            `<div style="font-size:14px; color: #0f1226; font-weight: bold; margin-top: 32px">${subLabel}</div>` +
            '</div>',
        },
      },
    ],
    tooltip: {
      enabled: false,
    },
    plotOptions: {
      solidgauge: {
        innerRadius: '92%',
        dataLabels: {
          y: -55,
          borderWidth: 0,
          useHTML: true,
        },
      },
    },
  };

  return <HighchartsReact highcharts={Highcharts} options={config} containerProps={{ style: { flex: '1 1 0' } }} />;
});

export default OlioSolidGauge;
