import React from 'react';
import moment from 'moment';
import styled from 'styled-components';

import { createColumnHelper } from '@tanstack/react-table';

import DocumentLink from '~/components/shared/DocumentLink';
import DocumentTypeTagButton from '~/components/shared/DocumentTypeTagButton';
import Tag from '~/components/shared/Tag';
import { BodySmallBold } from '~/components/shared/typography';
import { Attachment } from '~/models';
import colors from '~/styles/theme/colors';

const columnHelper = createColumnHelper<Attachment>();

type Props = {
  handleDocumentTypeChange: (attachmentId: string | null, docType?: string | null) => void;
};

export default function filesTableColumns({ handleDocumentTypeChange }: Props) {
  return [
    columnHelper.accessor((row) => row.filename, {
      id: 'filename',
      header: 'File Name',
      cell: (info) => (
        <DocumentLink entry={info.row.original}>
          <BodySmallBold className='first-column' color={colors.primaryBlue}>
            {info.getValue() || `Untitled ${info.row.original.contentType} ${info.row.index}`}
          </BodySmallBold>
        </DocumentLink>
      ),
    }),
    columnHelper.accessor((row) => row.createdByUser?.name, {
      id: 'createdByUser',
      header: 'Uploaded By',
    }),
    columnHelper.accessor((row) => row.createdAt, {
      id: 'createdAt',
      header: 'Uploaded On',
      cell: (info) => (
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <div>{`${moment(info.getValue()).format('L')} at ${moment(info.getValue()).format('LT')}`}</div>
        </div>
      ),
    }),
    columnHelper.accessor((row) => row.docType, {
      id: 'docType',
      header: 'Document Type',
      cell: (info) => (
        <DocumentTypeContainer className='last-column'>
          {info.getValue() ? (
            <TagContainer>
              <Tag label={info.getValue() || ''} onClear={() => handleDocumentTypeChange(info.row.original?.id)} />
            </TagContainer>
          ) : (
            <>&mdash;</>
          )}
          <IconsContainer>
            <DocumentTypeTagButton
              attachmentId={info.row.original?.id}
              documentType={info.getValue()}
              onDocumentTypeChange={handleDocumentTypeChange}
              size={14}
            />
          </IconsContainer>
        </DocumentTypeContainer>
      ),
    }),
  ];
}

const DocumentTypeContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

const IconsContainer = styled.div`
  display: flex;
  align-items: center;
  margin-left: 16px;
  margin-right: 16px;
`;

const TagContainer = styled.div``;
