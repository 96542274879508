import React from 'react';
import PropTypes from 'prop-types';

import { Label } from '~/components/shared/typography';
import colors from '~/styles/theme/colors';

function CollapsibleTrigger({ children, onClick, ...rest }) {
  return (
    <React.Fragment>
      { React.Children.map(children, (child) => {
        if (typeof child === 'string') {
          return (
            <Label style={ { cursor: 'pointer' } }
              color={ colors.primaryBlue }
              onClick={ onClick }
              { ...rest }>
              { child }
            </Label>
          );
        }
        return React.cloneElement(child, { onClick, ...rest });
      }) }
    </React.Fragment>
  );
}

CollapsibleTrigger.propTypes = {
  onClick: PropTypes.func.isRequired
};

export default CollapsibleTrigger;
