import React, { ComponentProps } from 'react';
import ReactTooltip from 'react-tooltip';
import styled from 'styled-components';

const StyledTooltip = styled(ReactTooltip)`
  background-color: #4b4d5c;
  color: ${({ theme }) => theme.colors.white};
  max-width: 200px;
  border-radius: 5px;
  opacity: 1 !important;

  &::after {
    background-color: #4b4d5c !important;
  }
`;

export default function Tooltip(props: ComponentProps<typeof ReactTooltip>) {
  return <StyledTooltip place='right' effect='solid' multiline {...props} />;
}
