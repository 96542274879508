import { simpleDateAtTime } from '~/lib/formatDate';

import Episode from './Episode';
import LocationEpisode from './LocationEpisode';
import Patient from './Patient';
import Profile from './Profile';

const defaults = {
  id: '',
  createdAt: '',
  episode: new Episode(),
  text: '',
  patient: new Patient(),
  type: '',
  acknowledgedAt: '',
  acknowledgedBy: '',
  acknowledged: false,
  activity: {
    text: '',
    plaintext: '',
    mentions: [],
  },
  locationEpisode: new LocationEpisode(),
};

export const PRIORITY_NOTE_TYPE = 'Escalation::PriorityNote';

class Escalation {
  constructor(options = {}) {
    const opts = {
      ...defaults,
      ...options,
      activity: {
        ...defaults.activity,
        ...options.activity,
      },
    };

    this.id = opts.id;
    this.createdAt = opts.createdAt;
    this.episode = opts.episode;
    this.text = opts.text;
    this.patient = opts.patient;
    this.type = opts.type;
    this.acknowledgedAt = opts.acknowledgedAt;
    // eslint-disable-next-line no-warning-comments
    // TODO: Remove type check after escalations#show API TODO is cleaned up
    this.acknowledgedBy = typeof opts.acknowledgedBy === 'string' ? opts.acknowledgedBy : opts.acknowledgedBy?.fullName;
    this.acknowledged = opts.acknowledged;
    this.activity = opts.activity;
    this.locationEpisode = opts.locationEpisode;
  }

  get isDefault() {
    return !this.type;
  }

  get isPriorityNote() {
    return this.type === PRIORITY_NOTE_TYPE;
  }

  get typeLabel() {
    return this.isPriorityNote ? 'Priority note' : 'Escalation';
  }

  get summary() {
    const { createdBy = {}, createdByLocation = {} } = this.activity;
    const locationText = createdByLocation.name ? ` at ${createdByLocation.name}` : '';

    return `${this.typeLabel} from ${createdBy.fullName}${locationText} on ${simpleDateAtTime(this.createdAt)}`;
  }

  static typeForUser(user = new Profile()) {
    return user.isAcute ? PRIORITY_NOTE_TYPE : null;
  }
}

export default Escalation;
