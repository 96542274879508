import React from 'react';
import { Formik, FormikHelpers } from 'formik';
import moment from 'moment';
import PropTypes, { InferProps } from 'prop-types';
import { connect } from 'react-redux';
import styled from 'styled-components';

import { unwrapResult } from '@reduxjs/toolkit';

import { usePatientProfileContext } from '~/components/patients/patientProfile/PatientProfileContext';
import ContentHeading from '~/components/patients/shared/ContentHeading';
import { Button, ButtonGroup } from '~/components/shared/buttons';
import { DatePicker, Form, InputGroup } from '~/components/shared/form';
import FormSection from '~/components/shared/form/FormSection';
import StandardModal from '~/components/shared/modal/StandardModal';
import { ACCEPTED } from '~/constants/reviews';
import { updateReview } from '~/ducks/reviews';
import { addToast } from '~/ducks/toasts';

import { validation } from './ProjectedDischargeValidation';

type ProjectedDischargeFormValues = {
  projectedDischargeDate: string | null;
};

function ProjectedDischargeModal(props: Props) {
  const { date, id, onCancel, onSuccess, show } = props;
  const { patientName }: any = usePatientProfileContext();

  const initialValues = { projectedDischargeDate: date };

  const handleSubmit = async (
    values: ProjectedDischargeFormValues,
    { setSubmitting }: FormikHelpers<ProjectedDischargeFormValues>
  ) => {
    try {
      const payload = {
        ...values,
        id: id,
        include: 'activities',
        // date objects are automatically converted to UTC
        // when stringified for api requests, so we need to
        // send just the date portion of the date object
        projectedDischargeDate: moment(values.projectedDischargeDate).format('YYYY-MM-DD'),
        status: ACCEPTED,
      };

      await props.updateReview(payload).then(unwrapResult);

      onSuccess();
    } catch (e: any) {
      let msg = 'Something went wrong. Please try again.';

      if (e.response?.status === 422) {
        msg = e.response.data.message;
      }

      props.addToast({ text: msg });
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <StandardModal show={show} disableBackdropClick title='Update Projected Discharge' onCancel={onCancel}>
      <Formik initialValues={initialValues} validationSchema={validation} onSubmit={handleSubmit} validateOnMount>
        {({ isValid, isSubmitting }) => (
          <Form>
            <FormSection>
              <ContentHeading title='' subtitle={`Patient: ${patientName}`} />
              <DatePickerWrapper>
                <InputGroup
                  name='projectedDischargeDate'
                  label='Projected Discharge Date'
                  dateFormat='MM/dd/yyyy'
                  minDate={new Date()}
                  component={DatePicker}
                />
              </DatePickerWrapper>
            </FormSection>
            <ButtonGroup>
              <Button onClick={onCancel} color='transparent' text='Cancel' />
              <Button type='submit' disabled={!isValid || isSubmitting} loading={isSubmitting} text='Confirm' />
            </ButtonGroup>
          </Form>
        )}
      </Formik>
    </StandardModal>
  );
}

ProjectedDischargeModal.propTypes = {
  addToast: PropTypes.func.isRequired,
  date: PropTypes.instanceOf(Date),
  id: PropTypes.string.isRequired,
  onCancel: PropTypes.func,
  onSuccess: PropTypes.func.isRequired,
  show: PropTypes.bool.isRequired,
  updateReview: PropTypes.func.isRequired,
};

const noop = () => undefined;

ProjectedDischargeModal.defaultProps = {
  onCancel: noop,
  onSuccess: noop,
};

type Props = InferProps<typeof ProjectedDischargeModal.propTypes>;

const mapDispatchToProps = {
  addToast,
  updateReview,
};

export default connect(null, mapDispatchToProps)(ProjectedDischargeModal);

const DatePickerWrapper = styled.div`
  margin: 0 100px;
`;
