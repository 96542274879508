import React, { forwardRef } from 'react';
import styled from 'styled-components';

import calendarIcon  from '../../../assets/images/calendar-icon.svg';

function noop() {}

const InputWrapper = styled.div`
  border: 1px solid rgba(15, 18, 38, 0.5);
  border-radius: 3px;
  display: flex;
  align-items: center;
  padding: 4px;

  & > input {
    border: none;
    color: #0f1226;
    font-size: 16px;
    line-height: 1.5;
    width: 125px;
  }

  & > img {
    pointer-events: none;
  }
`;

// prop-types are not allowed for forwardRefs
// eslint-disable-next-line react/prop-types
const CalendarInput = ({ value, onClick }, ref) => {
  return (
    <InputWrapper onClick={ onClick } ref={ ref }>
      <input data-cy='dateInput' value={ value } placeholder='Select Date' onChange={ noop } />
      <img src={ calendarIcon } />
    </InputWrapper>
  );
};

export default forwardRef(CalendarInput);
