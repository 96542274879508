import { createEntityAdapter, createSlice } from '@reduxjs/toolkit';

import { createAsyncThunk } from '~/lib';
import hospitalsAPI from '~/sources/api/hospitalsAPI';

const SLICE_NAME = 'hospitals';

export const fetchHospitals = createAsyncThunk(`${SLICE_NAME}/fetch`, async () => {
  const res = await hospitalsAPI.fetch();

  return res.data.items;
});

const hospitalsAdapter = createEntityAdapter();

const hospitalsSlice = createSlice({
  name: SLICE_NAME,
  initialState: hospitalsAdapter.getInitialState(),
  reducers: {},
  extraReducers: {
    [fetchHospitals.fulfilled]: hospitalsAdapter.setAll,
  },
});

const getHospitalsSlice = (state) => state[SLICE_NAME];

export const { selectAll: getHospitals } = hospitalsAdapter.getSelectors(getHospitalsSlice);

export default hospitalsSlice.reducer;
