import { ALTCS_APPLICATION, AUTHORIZATION, PROJECTED_DISCHARGE, SERVICE_REFUSAL } from '~/constants/reviews';

import ModelFactory from '../ModelFactory';

import AltcsApplication from './AltcsApplication';
import Authorization from './Authorization';
import ProjectedDischarge from './ProjectedDischarge';
import ServiceRefusal from './ServiceRefusal';

class ReviewFactory extends ModelFactory {
  static classMap = {
    [SERVICE_REFUSAL]: ServiceRefusal,
    [AUTHORIZATION]: Authorization,
    [PROJECTED_DISCHARGE]: ProjectedDischarge,
    [ALTCS_APPLICATION]: AltcsApplication,
  };

  static defaultClass = ServiceRefusal;
}

export default ReviewFactory;
