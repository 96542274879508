import { createAsyncThunk } from '~/lib';
import { Location } from '~/models';
import { locationsApi } from '~/services/api';

export const fetchLocations = createAsyncThunk(
  'locations/fetch',
  async (payload) => {
    const defaults = { pageSize: 5000, sortBy: 'name asc' };
    const res = await locationsApi.fetch.invoke({ ...defaults, ...payload });

    return res.data;
  },
  {
    defaultValue: [],
    modelClass: Location
  }
);
