import { NOTIFICATION, PORTFOLIO_FILTER } from '~/constants/userPreferences';

import ModelFactory from '../ModelFactory';
import UserPreference from '../UserPreference';

import NotificationUserPreference from './NotificationUserPreference';
import PortfolioFilterUserPreference from './PortfolioFilterUserPreference';

class UserPreferenceFactory extends ModelFactory {
  static classMap = {
    [NOTIFICATION]: NotificationUserPreference,
    [PORTFOLIO_FILTER]: PortfolioFilterUserPreference,
  };

  static defaultClass = UserPreference;
}

export default UserPreferenceFactory;
