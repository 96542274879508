import React, { forwardRef, useMemo } from 'react';
import { uniqueId } from 'lodash';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import Badge from '~/components/shared/Badge';
import CircleSpinner from '~/components/shared/CircleSpinner';
import { InfoIconOutline } from '~/components/shared/svg';
import ChartIcon from '~/components/shared/svg/ChartIcon';
import ChevronRight from '~/components/shared/svg/ChevronRight';
import Tooltip from '~/components/shared/Tooltip';
import { colors } from '~/styles/theme';

type Props = {
  label: string;
  value?: string;
  tooltipText?: string;
  benchmark?: {
    value: string;
    met: boolean;
  };
  hasData?: boolean;
  children: React.ReactNode;
  showValue?: boolean;
  loading?: boolean;
  navigateTo: string;
};

function MetricTile(props: Props, ref: React.Ref<HTMLDivElement>) {
  const {
    label,
    hasData = true,
    value,
    tooltipText,
    benchmark,
    children,
    showValue = true,
    loading = false,
    navigateTo,
  } = props;

  const history = useHistory();

  const handleTileClick = () => {
    history.push(navigateTo);
  };

  const tooltipId = useMemo(() => `metric-tooltip-${uniqueId()}`, []);

  const metricValue = useMemo(() => (value && !loading ? value : <>&mdash;</>), [value, loading]);

  return (
    <Tile onClick={handleTileClick} ref={ref}>
      <MetricContainer>
        <MetricLabel>
          {label}{' '}
          {!!tooltipText && (
            <>
              <InfoIconOutline
                size={12}
                fill={colors.black50}
                style={{ marginLeft: 8 }}
                data-for={tooltipId}
                data-tip={tooltipText}
              />
              <Tooltip id={tooltipId} />
            </>
          )}
          <Chevron />
        </MetricLabel>
        {showValue && <MetricValue>{metricValue}</MetricValue>}
        {loading ? (
          <SpinnerContainer>
            <CircleSpinner />
          </SpinnerContainer>
        ) : (
          <MetricContent>
            {hasData ? (
              children
            ) : (
              <EmptyState>
                <ChartIcon color='#3253EF' />
                <EmptyText>No data to show for the selected date range</EmptyText>
              </EmptyState>
            )}
          </MetricContent>
        )}
      </MetricContainer>

      {!!benchmark && !loading && (
        <BenchmarkContainer>
          <BenchmarkLabel>
            Benchmark: <BenchmarkValue>{benchmark?.value}</BenchmarkValue>
            {benchmark?.met !== undefined && (
              <Badge style={{ marginLeft: 8 }} color={benchmark?.met ? 'green' : 'red'}>
                {benchmark?.met ? 'Met' : 'Not Met'}
              </Badge>
            )}
          </BenchmarkLabel>
        </BenchmarkContainer>
      )}
    </Tile>
  );
}

export default forwardRef(MetricTile);

const EmptyText = styled.p`
  color: ${({ theme }) => theme.colors.black50};
  font-size: 12px;
`;

const EmptyState = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

const BenchmarkContainer = styled.div`
  border-top: 1px solid ${({ theme }) => theme.colors.black10};
  padding: 16px 24px;
`;

const MetricContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 24px 24px 16px;
`;

const BenchmarkLabel = styled.p`
  margin: 0;
  font-size: 12px;
  color: ${({ theme }) => theme.colors.black};
`;

const BenchmarkValue = styled.span`
  font-weight: bold;
`;

const Chevron = styled(ChevronRight)`
  margin-left: auto;
  color: ${({ theme }) => theme.colors.black};
`;

const Tile = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme.colors.white};
  border: 1px solid ${({ theme }) => theme.colors.black10};
  border-radius: 8px;
  max-width: 412px;
  box-sizing: border-box;
  cursor: pointer;
  height: 280px;

  &:hover {
    border-color: ${({ theme }) => theme.colors.primaryBlue};
  }
`;

const MetricLabel = styled.div`
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.colors.black};
  font-size: 12px;
  font-weight: normal;
`;

const MetricValue = styled.div`
  color: ${({ theme }) => theme.colors.black};
  font-size: 24px;
  font-weight: 900;
  margin-top: 8px;
  height: 24px;
`;

const MetricContent = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  margin: 16px 0 0;
`;

const SpinnerContainer = styled.div`
  flex: 1;
  display: flex;
`;
