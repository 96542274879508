import React, { useMemo, useState } from 'react';
import * as _ from 'lodash-es';
import styled from 'styled-components';

import FilterSlideout from '~/components/shared/FilterSlideout';
import { FilterSection } from '~/components/shared/FilterSlideout/FilterSlideout';
import PageTitle from '~/components/shared/pageLayout/PageTitle';
import Search from '~/components/shared/Search';
import FilterIcon from '~/components/shared/svg/FilterIcon';
import { SEARCH } from '~/constants/filterKeysConstants';
import { useDeepMemo } from '~/lib/hooks';
import { colors } from '~/styles/theme';

export type Filter = Record<string, any>;

export type Filters = {
  [key: string]: Filter[] | string | Date;
};

type Props = {
  applyFilters: (filters: Filters) => void;
  filters: Filters;
  filterSections: FilterSection[];
  onClearFilters: () => void;
  title: string | React.ReactNode;
  callToAction?: React.ReactNode;
  className?: string;
  fetchCountThunk?: () => void;
  filterFormatter?: (filters: Filters) => object;
  onClick?: () => void;
  parseCount?: (data: any) => number;
  quickFilter?: React.ReactNode;
  showLevelOfCare?: boolean;
  showSearchInput?: boolean;
  showFiltersButton?: boolean;
  showResults?: boolean;
};

export default function FilterBar(props: Props) {
  const {
    applyFilters = _.noop,
    callToAction,
    className,
    filters,
    filterSections,
    title,
    quickFilter,
    onClearFilters = _.noop,
    fetchCountThunk,
    filterFormatter,
    parseCount,
    showFiltersButton = true,
    showSearchInput = true,
    showResults = true,
  } = props;

  const [search, setSearch] = useState(filters.search || '');

  const [showFilterSlideout, setShowFilterSlideout] = useState(false);

  const debouncedApplyFilters = useMemo(
    () => _.debounce((search) => applyFilters({ ...filters, search }), 500),
    [filters]
  );

  const updateSearch = (value: string) => {
    setSearch(value);

    debouncedApplyFilters(value);
  };

  const filterCount = useDeepMemo(
    () => Object.entries(filters).reduce((acc, [, value]) => (Array.isArray(value) ? acc + value.length : acc), 0),
    [filters]
  );

  return (
    <>
      <FilterBarWrapper className={className}>
        <LeftContent>
          {React.isValidElement(title) ? title : <Title>{title}</Title>}
          {showSearchInput && (
            <SearchInput
              value={search}
              name={SEARCH}
              placeholder='Search Patients'
              data-cy='filterBarSearch'
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => updateSearch(e.target.value)}
              onClear={() => updateSearch('')}
            />
          )}

          {!!quickFilter && <QuickFilterWrapper>{quickFilter}</QuickFilterWrapper>}

          {showFiltersButton && (
            <FiltersButton data-cy='filtersButton' onClick={() => setShowFilterSlideout(true)}>
              <FilterIcon fill={colors.primaryBlue} />
              <span>Filters {!!filterCount && ` (${filterCount})`}</span>
            </FiltersButton>
          )}

          {!!filterCount && <ClearFiltersButton onClick={onClearFilters}>Clear Filters</ClearFiltersButton>}
        </LeftContent>

        {callToAction}
      </FilterBarWrapper>
      {showFilterSlideout && (
        <FilterSlideout
          showResults={showResults}
          handleClose={() => setShowFilterSlideout(false)}
          filters={filters}
          filterSections={filterSections}
          resultsText='patients'
          handleApply={applyFilters}
          fetchCountThunk={fetchCountThunk}
          filterFormatter={filterFormatter}
          parseCount={parseCount}
        />
      )}
    </>
  );
}

const SearchInput = styled(Search)`
  max-width: 200px;
  min-width: 160px;
  width: 100%;
  box-sizing: border-box;
`;

const ClearFiltersButton = styled.button`
  color: ${({ theme }) => theme.colors.accentRed};
  text-decoration: underline;
  font-size: 14px;
  cursor: pointer;
  background: none;
  border: none;
`;

const Title = styled(PageTitle)`
  font-size: 20px;
`;

const FilterBarWrapper = styled.div`
  background: ${({ theme }) => theme.colors.white};
  display: flex;
  padding: 24px;
  align-items: center;
  border-bottom: 1px solid ${({ theme }) => theme.colors.black10};

  > * + * {
    margin-left: 16px;
  }
`;

const QuickFilterWrapper = styled.div`
  max-width: 216px;
  min-width: 160px;
  width: 100%;
`;

const FiltersButton = styled.button`
  border: none;
  background: none;
  color: ${({ theme }) => theme.colors.primaryBlue};
  font-size: 14px;
  cursor: pointer;

  span {
    margin-left: 8px;
  }
`;

const LeftContent = styled.div`
  display: flex;
  align-items: center;
  flex: 1;
  margin-right: 16px;

  > * + * {
    margin-left: 16px;
  }
`;
