import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { unwrapResult } from '@reduxjs/toolkit';

import LoadingSpinner from '~/components/shared/loadingSpinner';
import { FormPage } from '~/components/shared/pageLayout';
import StepWizard from '~/components/shared/StepWizard';
import { fetchImportedPatient } from '~/ducks/importedPatients';
import { createPatient } from '~/ducks/patients';
import { addToast } from '~/ducks/toasts';
import { useThunk } from '~/lib/hooks';
import { Note, Patient } from '~/models';
import { patientMatchApi } from '~/services/api';
import { useProfileContext } from '~/services/profile';

import { IntakeForm } from '../intake/IntakeForm';
import MatchConfirm from '../shared/MatchConfirm';
import MatchSuccess from '../shared/MatchSuccess';
import PatientConfirm from '../shared/PatientConfirm';

import PatientConnectNeeds from './PatientConnectNeeds';

function ConnectPatient(props) {
  const profileSvc = useProfileContext();
  const [matchedPatient, setMatchedPatient] = useState(null);
  const id = props.match.params.id;

  const {
    data: patient,
    loaded,
    setData: setPatient,
  } = useThunk(fetchImportedPatient, [id], {
    params: {
      id,
      include: 'owner,hospital,payer,physician_group,physician_team,episode_classification,plan_type_classification',
    },
  });

  const notifyError = () => {
    props.addToast({ text: 'There was an error connecting the patient. Please try again.' });
  };

  const notifySuccess = () => {
    props.addToast({ text: 'Patient connected successfully.' });
  };

  const handleNavigate = (episodeId) => {
    const path = episodeId ? `/patients/${episodeId}` : '/connect-patients';

    props.history.push(path);
  };

  const handleIntakeComplete = async (patientInfo, { goToStep, setSubmitting }) => {
    setPatient(patientInfo);

    try {
      const { data: match } = await patientMatchApi.search.invoke(patientInfo.serialize());

      setMatchedPatient(Patient.fromLocationEpisodeData(match));
      goToStep(MatchConfirm);
    } catch (e) {
      const noMatchFound = e.response?.status === 404;

      if (noMatchFound) {
        goToStep(PatientConnectNeeds);
      } else {
        props.addToast({ text: 'Something went wrong. Please try again.' });
        setSubmitting(false);
      }
    }
  };

  const handleMatchResponse = async (patientInfo, { goToStep, setConfirmed }) => {
    if (patientInfo instanceof Patient) {
      try {
        const serializedPatient = patientInfo.serialize();
        const { data: mergedPatient } = await patientMatchApi.create.invoke(serializedPatient);

        setPatient(Patient.fromLocationEpisodeData(mergedPatient));
        goToStep(MatchSuccess);
      } catch (e) {
        props.addToast({ text: 'There was an error connecting the patient. Please try again.' });
        setConfirmed(false);
      }
    } else {
      goToStep(PatientConnectNeeds);
    }
  };

  const handleConnectComplete = async (updatedPatient) => {
    const patientInfo = new Patient({
      ...updatedPatient,
      note: new Note(updatedPatient.note),
    });

    const serializedPatient = patientInfo.serialize();

    try {
      const { episodeId } = await props.createPatient(serializedPatient).then(unwrapResult);

      notifySuccess();
      handleNavigate(episodeId);
    } catch (error) {
      notifyError(error);
      handleNavigate();
    }
  };

  const [patientIntakeSteps] = useState([
    { component: IntakeForm, onStepSuccess: handleIntakeComplete },
    { component: PatientConnectNeeds },
    { component: PatientConfirm, onStepSuccess: handleConnectComplete },
    { component: MatchConfirm, onStepSuccess: handleMatchResponse },
    { component: MatchSuccess },
  ]);

  if (!loaded && !patient.id) return <LoadingSpinner />;

  return (
    <FormPage>
      <StepWizard
        {...props}
        matchedPatient={matchedPatient}
        patient={patient}
        profileSvc={profileSvc}
        steps={patientIntakeSteps}
        setPatient={setPatient}
        onSequenceComplete={handleConnectComplete}
        onSequenceAbort={handleNavigate}
      />
    </FormPage>
  );
}

ConnectPatient.propTypes = {
  addToast: PropTypes.func.isRequired,
  createPatient: PropTypes.func.isRequired,
};

const mapDispatchToProps = {
  addToast,
  createPatient,
};

export default connect(null, mapDispatchToProps)(ConnectPatient);
