/* eslint-disable @typescript-eslint/no-explicit-any */
import { createEntityAdapter, createSlice } from '@reduxjs/toolkit';

import { addToast } from '~/ducks/toasts';
import { createAsyncThunk } from '~/lib';
import { Attachment } from '~/models';
import { attachmentsApi } from '~/services/api';

import { API_STATES, createApiHasStatusSelector } from './api';
import PaginationState from './PaginationState';

const sliceName = 'attachments';

export type FetchAttachmentsAPIParams = {
  include: string;
  locationEpisodeId: string;
  activityId?: string;
};

export type FetchAttachmentAPIParams = {
  id: string;
  include: string;
};

export type UpdateAttachmentAPIParams = {
  id: string;
  docType: string;
};

export const fetchAttachments = createAsyncThunk(
  `${sliceName}/fetch`,
  async (params: FetchAttachmentsAPIParams, { dispatch }: any) => {
    try {
      const { data } = await attachmentsApi.fetch.invoke(params);

      return data;
    } catch (e) {
      dispatch(addToast({ text: 'There was an error fetching attachments. Please try again.' }));
      throw e;
    }
  }
);

export const fetchAttachment = createAsyncThunk(
  `${sliceName}/fetchById`,
  async ({ id, ...params }: FetchAttachmentAPIParams, { dispatch }: any) => {
    try {
      const { data } = await attachmentsApi.fetchById.invoke(id, params);

      return data;
    } catch (e) {
      dispatch(addToast({ text: 'There was an error fetching the attachment. Please try again.' }));
      throw e;
    }
  }
);

export const fetchActivityAttachments = createAsyncThunk(
  `${sliceName}/fetchActivityAttachments`,
  async (params: FetchAttachmentsAPIParams, { dispatch }: any) => {
    try {
      const { data } = await attachmentsApi.fetch.invoke(params);

      return { ...data, activityId: params.activityId };
    } catch (e) {
      dispatch(addToast({ text: 'There was an error fetching activity attachments. Please try again.' }));
      throw e;
    }
  }
);

export const updateAttachment = createAsyncThunk(
  `${sliceName}/update`,
  async ({ id, ...params }: UpdateAttachmentAPIParams, { dispatch }: any) => {
    try {
      const { data } = await attachmentsApi.update.invoke(id, params);

      return data;
    } catch (e) {
      dispatch(addToast({ text: 'There was an error updating the attachment. Please try again.' }));
      throw e;
    }
  }
);

const attachmentsAdapter = createEntityAdapter();

const initialState = attachmentsAdapter.getInitialState({ pagination: new PaginationState(), tabCount: 0 });

const attachmentsSlice = createSlice({
  name: sliceName,
  initialState,
  reducers: {
    clearAttachments: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(fetchAttachments.fulfilled, (state: any, { payload: { links, meta, data } }) => {
      state.pagination = { links, meta };
      attachmentsAdapter.setAll(state, data);
    });

    builder.addCase(updateAttachment.fulfilled, (state, { payload }) => {
      const { id, ...rest } = payload;

      attachmentsAdapter.updateOne(state, { id, changes: rest });
    });

    builder.addCase('activities/fetch/fulfilled', (state, { payload }: any) => {
      state.tabCount = payload.meta?.totalAttachmentRecords;
    });

    builder.addCase('activities/create/fulfilled', (state, { payload }: any) => {
      state.tabCount = payload.meta?.totalAttachmentRecords;
    });

    builder.addCase('activities/update/fulfilled', (state, { payload }: any) => {
      if (typeof payload.attachments !== 'undefined' && typeof payload.data.textRemoved !== 'undefined') {
        const attachmentCount = payload.attachments.length;

        if (payload.data.textRemoved) {
          state.tabCount -= attachmentCount;
        } else {
          state.tabCount += attachmentCount;
        }
      }
    });
  },
});

export const { clearAttachments } = attachmentsSlice.actions;

export const { selectAll } = attachmentsAdapter.getSelectors((state: any) => state[sliceName]);

// Custom selectors
export const getAttachments = (state: any) => selectAll(state).map((attachment: any) => new Attachment(attachment));
export const getAttachmentsCount = (state: any) => state[sliceName].tabCount;
export const getAttachmentsFetchLoaded = createApiHasStatusSelector(fetchAttachments, [
  API_STATES.complete,
  API_STATES.failed,
]);
export const getTotalPages = (state: any) => state.attachments.pagination.meta.totalPages;

export default attachmentsSlice;
