import {
  ADDITIONAL_FILTERS,
  CASE_MANAGER_DROPDOWN,
  COMANAGEMENT_TEAM,
  EPISODE_TYPE_DROPDOWN,
  getComanagementTeamAcuteFilters,
  PLAN_TYPE_DROPDOWN,
} from '~/constants/filterDropdownConfigs';

import { FilterMultiSelectFilter } from '../../shared/FilterMultiSelect/FilterMultiSelect';

export const renderableFilterDropdowns = (
  actingClientType: string,
  tabProviderTypeFilter: FilterMultiSelectFilter,
  hasCaseManagerFlag: boolean
) => {
  const additionalFilters: FilterMultiSelectFilter[] = [
    PLAN_TYPE_DROPDOWN,
    EPISODE_TYPE_DROPDOWN,
    ...(hasCaseManagerFlag ? [CASE_MANAGER_DROPDOWN] : []),
  ];

  return [
    {
      title: COMANAGEMENT_TEAM,
      filters: [
        ...(tabProviderTypeFilter ? [tabProviderTypeFilter] : []),
        ...getComanagementTeamAcuteFilters(actingClientType),
      ],
    },
    {
      title: ADDITIONAL_FILTERS,
      filters: additionalFilters,
    },
  ];
};
