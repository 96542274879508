import { PROGRESS } from '~/constants/questionTemplateTypes';

import QuestionTemplate from '../QuestionTemplate';

const defaults = {
  type: PROGRESS,
};

export default class ProgressTemplate extends QuestionTemplate {
  constructor(options = {}) {
    super({ ...defaults, ...options });
  }
}
