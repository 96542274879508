import React from 'react';
import { FormatOptionLabelMeta } from 'react-select';

import { fetchLocations } from '~/ducks/admin/locations';
import { getId, getName } from '~/helpers';
import { useAddressOptions, useAsyncOptions } from '~/lib/hooks';
import { Location } from '~/models';

interface useAsyncLocationOptionsArgs {
  params?: any;
  showAddress?: boolean;
  showGroupType?: boolean;
}

export default function useAsyncLocationOptions(args: useAsyncLocationOptionsArgs = {}) {
  const { showAddress = true, showGroupType = true, ...asyncOptions } = args;

  const addressProps = showAddress ? useAddressOptions() : { formatOptionLabel: getName };

  const formatOptionLabelWithGroupType = (option: Location, meta: FormatOptionLabelMeta<Location>) => {
    const { context, selectValue } = meta;
    const { name, groupType, ...restOption } = option;

    const groupTypeDisplayName = groupType?.displayName;
    const groupTypeLabel = ` (${groupTypeDisplayName})`;

    const displayName = (
      <span>
        {name}
        {groupTypeDisplayName ? <em>{groupTypeLabel}</em> : ''}
      </span>
    );

    return addressProps.formatOptionLabel({ ...restOption, name: displayName }, { context, selectValue });
  };

  const formatOptionLabel = showGroupType ? formatOptionLabelWithGroupType : addressProps.formatOptionLabel;

  return {
    ...useAsyncOptions(fetchLocations, asyncOptions),
    ...addressProps,
    formatOptionLabel,
    getOptionLabel: getName,
    getOptionValue: getId,
  };
}
