import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { useDrag } from 'react-dnd';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components';

import { clearLocationEpisode } from '~/ducks/locationEpisode';
import { DragType } from '~/helpers/DragType';
import { LocationEpisode } from '~/models';
import { PERMISSIONS, ProfileContext } from '~/services/profile';

import PatientCard from './PatientCard';
function DraggablePatientCard(props) {
  const { locationEpisode, rehabState } = props;

  const {
    id,
    currentRehabState,
    episodeId,
    hasActiveServiceRefusals,
    lengthOfStay: days,
    owner: { id: ownerId },
    patient: { name: name },
    rehabInformation: {
      latestRehabFacility: { id: rehabFacilityId, name: rehabFacility },
      latestRehabFacilityType: rehabType,
    },
  } = locationEpisode;

  const profileSvc = useContext(ProfileContext);
  const isDraggable =
    !hasActiveServiceRefusals &&
    profileSvc.hasAny([PERMISSIONS.rehabStateAdmissionCreate, PERMISSIONS.rehabStateDischargeCreate]) &&
    (profileSvc.isAcute || profileSvc.canAccessLocation(rehabFacilityId));

  const [{ isDragging }, dragSource] = useDrag({
    type: DragType.CARD,
    canDrag: () => isDraggable,
    item: () => ({
      id,
      episodeId,
      ownerId,
      rehabState,
      currentRehabStateEnteredAt: currentRehabState.enteredAt,
      patientName: name,
      rehabFacilityType: rehabType,
    }),
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  const handleRedirectToUserProfile = (e) => {
    if (name) {
      props.clearLocationEpisode();
      //TEMP
      window.location.href = `https://load-test-patient.olio.health/patients/${episodeId}`;
      //props.history.push(`https://load-test-patient.olio.health/patients/${episodeId}`);
    }

    e.stopPropagation();
  };

  const patientCardProps = {
    ...locationEpisode,
    days,
    hasActiveServiceRefusals,
    name,
    rehabFacility,
    rehabType,
    rehabState,
  };

  return (
    <DragContainer
      key={id}
      ref={dragSource}
      data-id={id}
      data-dd-action-name={`Patient card: ${id}`}
      style={{ opacity: isDragging ? 0 : 1 }}
      onClick={handleRedirectToUserProfile}
      draggable={isDraggable}>
      <PatientCard {...patientCardProps} />
    </DragContainer>
  );
}

DraggablePatientCard.propTypes = {
  clearLocationEpisode: PropTypes.func,
  locationEpisode: PropTypes.instanceOf(LocationEpisode),
  rehabState: PropTypes.shape({
    rehabStateId: PropTypes.string,
    rehabState: PropTypes.string,
  }),
};

const mapDispatchToProps = {
  clearLocationEpisode,
};

export default withRouter(connect(null, mapDispatchToProps)(React.memo(DraggablePatientCard)));

const DragContainer = styled.div`
  ${(props) => (props.draggable ? 'cursor: move; cursor: grab;' : 'cursor: pointer;')};
`;
