import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';

/*
  The StepWizard requires a 'steps' array that contains an object per
  step in the sequence. It iterates through the provided steps,
  advancing one step at a time until there are no more left. It optionally
  accepts onSequenceComplete and onAbort functions.

  Example:

  const steps = [
    {
      component: FirstComponent,
      onStepAbort: () => alert('NOOOOOO')
    },
    {
      component: SecondComponent,
      onStepComplete: () => console.log('complete!'),
      onStepRevert: () => console.log('step canceled')
    }
  ]
*/

function StepWizard(props) {
  const { steps, onSequenceComplete, onSequenceAbort } = props;
  const [currentStepIndex, setCurrentStepIndex] = useState(0);
  const [history, setHistory] = useState([]);

  const handleStepSuccess = ({ onStepSuccess }, arg, options) => {
    const { completeSequence, manualNavigation = false } = options;

    if (onStepSuccess) {
      onStepSuccess(arg, { ...options, goToStep });
    }

    const nextStepNumber = currentStepIndex + 1;

    if (completeSequence || !steps[nextStepNumber]) {
      onSequenceComplete(arg);
    } else if (!manualNavigation) {
      handleForwardNavigate();
    }
  };

  const handleStepRevert = ({ onStepRevert }, arg, options) => {
    if (onStepRevert) {
      onStepRevert(arg, { ...options });
    }

    if (currentStepIndex === 0) {
      onSequenceAbort(arg);
    } else {
      handleBackwardsNavigate();
    }
  };

  const handleStepAbort = ({ onStepAbort }, arg) => {
    if (onStepAbort) {
      onStepAbort(arg);
    }

    resetNavigationState();
    onSequenceAbort(arg);
  };

  const findStepIndexByName = (name) => steps.findIndex((s) => s.name === name);

  const findStepIndex = (stepItem) => {
    return steps.findIndex((s) => stepItem === s || stepItem === s.component);
  };

  const goToStep = (step) => {
    if (typeof step === 'number') {
      handleForwardNavigate(step - 1);
    } else if (typeof step === 'string') {
      handleForwardNavigate(findStepIndexByName(step));
    } else {
      handleForwardNavigate(findStepIndex(step));
    }
  };

  const resetNavigationState = () => {
    setCurrentStepIndex(0);
    setHistory([]);
  };

  const handleForwardNavigate = (targetIndex) => {
    const nextIndex = targetIndex ?? currentStepIndex + 1;

    setHistory([...history, currentStepIndex]);
    setCurrentStepIndex(nextIndex);
  };

  const handleBackwardsNavigate = () => {
    const prevStepIndex = history[history.length - 1];

    setHistory(history.slice(0, history.length - 1));
    setCurrentStepIndex(prevStepIndex);
  };

  return (
    <Fragment>
      {steps.map((stepParams, stepIndex) => {
        const ComponentObject = stepParams.component;

        return (
          currentStepIndex === stepIndex && (
            <ComponentObject
              key={stepIndex}
              goToStep={goToStep}
              onComplete={(arg, options = {}) => handleStepSuccess(stepParams, arg, options)}
              onRevert={(arg, options = {}) => handleStepRevert(stepParams, arg, options)}
              onAbort={(arg) => handleStepAbort(stepParams, arg)}
              setCurrentStepIndex={setCurrentStepIndex}
              stepIndex={stepIndex}
              {...props}
            />
          )
        );
      })}
    </Fragment>
  );
}

StepWizard.propTypes = {
  onSequenceAbort: PropTypes.func,
  onSequenceComplete: PropTypes.func,
  steps: PropTypes.instanceOf(Object).isRequired,
};

const noop = () => {};

StepWizard.defaultProps = {
  onSequenceAbort: noop,
  onSequenceComplete: noop,
};

export default StepWizard;
