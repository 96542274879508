import styled from 'styled-components';

import { colors } from '~/styles/theme';

const BasePulse = styled.div`
  display: inline-block;
  height: 100%;
  width: 100%;
  background: linear-gradient(-90deg, ${colors.black10} 0%, ${colors.black05} 50%, ${colors.black10} 100%);
  background-size: 400% 400%;
  animation: pulse 1s ease-in-out infinite;
  @keyframes pulse {
    0% {
      background-position: 0% 0%;
    }
    100% {
      background-position: -135% 0%;
    }
  }
`;

export default styled(BasePulse)`
  min-width: 5.5em;
  border-radius: 3px;

  &::before {
    content: "\00a0";
  }
`;
