import React, { useMemo } from 'react';
import styled from 'styled-components';

import Card from '~/components/insights/Card';

type Props = {
  children: React.ReactNode;
  title: string;
  wrapperWidth: number;
};

function MetricsSection({ children, title, wrapperWidth }: Props) {
  // adjusts for extra margin of surrounding card
  const gridWidth = useMemo(() => wrapperWidth - 48, [wrapperWidth]);

  return (
    <Card>
      <Card.Title>{title}</Card.Title>
      <Grid gridWidth={gridWidth}>{children}</Grid>
    </Card>
  );
}

const getNumColumns = (gridWidth: number) => {
  const columnMinWidth = 324;

  // max number of columns that can safely fit inside the wrapper
  const maxColumns = Math.floor(gridWidth / columnMinWidth);

  // results in desired min-width of ~320px for each column (no matter how many)
  const adjustedMaxColumns = Math.floor(gridWidth / (columnMinWidth + maxColumns * 4));

  // grid should have at least 1 column and at most 3 columns
  return Math.max(1, Math.min(3, adjustedMaxColumns));
};

/**
 * Acute card surrounding the grid has a max width of 1280px with 24px padding on each side.
 * So the max width of the row would be 1280 - 24 - 24 = 1232px.
 * We want to have 24px gap between each column, so each column should be 394px.
 * (394px * 3) + (24px * 2) = 1232px
 */
const Grid = styled.div<{ columnMaxWidth?: number; gridWidth: number }>`
  display: grid;
  grid-template-columns: ${({ columnMaxWidth = 394, gridWidth }) =>
    `repeat(${getNumColumns(gridWidth)}, minmax(200px, ${columnMaxWidth}px))`};
  grid-gap: 24px;
  justify-content: center;
`;

MetricsSection.Grid = Grid;

export default MetricsSection;
