import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { LabelBold } from '~/components/shared/typography';
import {
  AltcsApplicationStatusUpdate,
  AuthorizationUpdates,
  DischargeQuestions,
  FieldChanges,
  Note,
  PatientFieldChanges,
  PatientMatchCollaboration,
  ProgressUpdate,
  ProjectedDischargeUpdates,
  RehabStateChange,
  ServiceRefusalActivity
} from '~/models';
import { colors } from '~/styles/theme';

import { FullWidthLabel } from '../shared';

function StandardActivityHeader({ activity }) {
  return (
    <FullWidthLabel>
      { activity.isDischargeQuestions && activity.edited && <Italicized>(Edited) </Italicized> }
      { activity.escalation?.isDefault ?
        <LabelBold color={ colors.accentRed }>Escalation { activity.typeLabel }</LabelBold> :
        activity.typeLabel
      }
      { !!activity.attachments.length && ' (with attachments)' }
      { activity instanceof FieldChanges ? ' updated by ' : ' added by '}
      <LabelBold>{ activity.createdBy.fullName }</LabelBold>
      { activity.createdByLocation?.name && ` at ${activity.createdByLocation.name}` }
    </FullWidthLabel>
  );
}

StandardActivityHeader.propTypes = {
  activity: PropTypes.oneOfType([
    PropTypes.instanceOf(AltcsApplicationStatusUpdate),
    PropTypes.instanceOf(AuthorizationUpdates),
    PropTypes.instanceOf(DischargeQuestions),
    PropTypes.instanceOf(Note),
    PropTypes.instanceOf(PatientFieldChanges),
    PropTypes.instanceOf(PatientMatchCollaboration),
    PropTypes.instanceOf(ProgressUpdate),
    PropTypes.instanceOf(ProjectedDischargeUpdates),
    PropTypes.instanceOf(RehabStateChange),
    PropTypes.instanceOf(ServiceRefusalActivity)
  ]).isRequired
};

export default StandardActivityHeader;

const Italicized = styled.span`
  font-style: italic;
`;

