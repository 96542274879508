import { createAsyncThunk } from '~/lib';
import episodeExportsApi from '~/services/api/episodeExportsApi';

const sliceName = 'episodes/exports';

export const createEpisodeExport = createAsyncThunk(`${sliceName}/create`, async (params: { id: string }) => {
  const { data } = await episodeExportsApi.create.invoke(params);

  return data;
});
