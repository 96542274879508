import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import styled from 'styled-components';

import { Button, ButtonGroup } from '~/components/shared/buttons';
import StandardModal from '~/components/shared/modal/StandardModal';
import { BodySmall, H3 } from '~/components/shared/typography';
import { ACCEPTED } from '~/constants/reviews';
import { getLatestPatientsRoot } from '~/ducks/navigation';
import { updateReview } from '~/ducks/reviews';
import { addToast } from '~/ducks/toasts';
import { unwrapResult } from '~/lib';
import { LocationEpisode, ServiceRefusal } from '~/models';
import { history } from '~/store';
function ServiceRefusalConfirmModal(props) {
  const { latestPatientsRoot, locationEpisode, onCancel, onSuccess, serviceRefusal, show } = props;

  const {
    patient,
    rehabInformation: { latestRehabFacility },
  } = locationEpisode;
  const isContinuedCare = !!locationEpisode.lastValidPredecessor;

  const handleSuccess = () => {
    const noncontinuedCareSuccessMsg = 'The patient will no longer display on the Patient Portfolio.';
    const continuedCareSuccessMsg = 'The current stay will be removed from the Patient Portfolio.';

    if (!isContinuedCare) {
      history.replace(`/${latestPatientsRoot || 'patients'}`);
    }

    props.addToast({
      text: `Refusal of services confirmed for ${patient.name}. ${
        isContinuedCare ? continuedCareSuccessMsg : noncontinuedCareSuccessMsg
      }`,
    });

    onSuccess();
  };

  const handleRefusalConfirmation = async () => {
    try {
      const payload = {
        id: serviceRefusal.id,
        include: 'activities,location_episode,episode',
        status: ACCEPTED,
      };

      await props.updateReview(payload).then(unwrapResult);

      handleSuccess();
    } catch (e) {
      props.addToast({ text: 'Something went wrong. Please try again.' });

      onCancel();
    }
  };

  const noncontinuedCareMsg = 'remove the patient from Olio';
  const continuedCareMsg = `disable the ability to add notes for the patient's stay at ${latestRehabFacility.name}`;

  return (
    <StyledStandardModal
      show={show}
      colorTheme='primaryRed'
      title='Confirm Refusal'
      data-cy='confirmRefusalModal'
      onCancel={onCancel}>
      <Patient>
        <H3>Patient Name: {patient.name}</H3>
      </Patient>
      <ConfirmText>
        Confirming the refusal of services will {isContinuedCare ? continuedCareMsg : noncontinuedCareMsg}.
      </ConfirmText>
      <Actions>
        <Button color='transparent' text='Cancel' onClick={onCancel} />
        <Button
          type='submit'
          data-cy='confirmRefusalSubmit'
          text='Confirm'
          color='danger'
          onClick={handleRefusalConfirmation}
        />
      </Actions>
    </StyledStandardModal>
  );
}

ServiceRefusalConfirmModal.propTypes = {
  addToast: PropTypes.func.isRequired,
  latestPatientsRoot: PropTypes.string,
  locationEpisode: PropTypes.instanceOf(LocationEpisode),
  onCancel: PropTypes.func,
  onSuccess: PropTypes.func,
  serviceRefusal: PropTypes.instanceOf(ServiceRefusal),
  show: PropTypes.bool,
  updateReview: PropTypes.func.isRequired,
};

const noop = () => {};

ServiceRefusalConfirmModal.defaultProps = {
  onCancel: noop,
  onSuccess: noop,
};

const mapStateToProps = (state) => ({
  latestPatientsRoot: getLatestPatientsRoot(state),
});

const mapDispatchToProps = {
  addToast,
  updateReview,
};

export default connect(mapStateToProps, mapDispatchToProps)(ServiceRefusalConfirmModal);

const StyledStandardModal = styled(StandardModal)`
  display: flex;
  flex-direction: column;
  text-align: center;
  width: 540px;
  overflow: hidden;
`;

const Patient = styled.div`
  margin-bottom: 24px;
`;

const ConfirmText = styled(BodySmall)`
  margin: 0 58px 50px 64px;
`;

const Actions = styled(ButtonGroup)`
  justify-content: end;
`;
