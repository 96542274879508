import { NOTE } from '~/constants/activities';

import Activity from '../Activity';

const defaults = {
  data: {},
  type: NOTE,
};

export default class Note extends Activity {
  constructor(options = {}) {
    const { mentions = [], plaintext = '', text = '' } = options;
    const opts = {
      ...defaults,
      ...options,
      // The following supports forms that will pass the "data" field values
      // as top level properties in the "options" parameter
      data: {
        mentions,
        plaintext,
        text,
        ...options.data,
      },
    };

    super(opts);
  }

  get hasContent() {
    return !!(this.text.trim() || this.attachments.length);
  }

  get mentions() {
    return this.data.mentions;
  }

  set mentions(value) {
    this.data = { ...this.data, mentions: value };
  }

  get plaintext() {
    return this.data.plaintext;
  }

  set plaintext(value) {
    this.data = { ...this.data, plaintext: value };
  }

  get text() {
    return this.data.text;
  }

  set text(value) {
    this.data = { ...this.data, text: value };
  }

  get textRemoved() {
    return this.data.textRemoved;
  }

  set textRemoved(value) {
    this.data = { ...this.data, textRemoved: value };
  }

  get typeLabel() {
    return 'Note';
  }
}
