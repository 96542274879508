import { CreateRequest } from '~/services/api/core';

export default {
  create: new CreateRequest({
    formatArgsForConfig: ({ id, ...params }: { id: string }) => ({
      url: `episodes/${id}/exports`,
      params,
    }),
  }),
};
