import React from 'react';

function ActivityRefuseServiceIcon() {
  return (
    <svg width='24px' height='24px' viewBox='0 0 24 24' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlnsXlink='http://www.w3.org/1999/xlink'>
      <title>Group 17</title>
      <g id='web' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
        <g id='4---HHA-Pt-Story-Refused-State' transform='translate(-573.000000, -635.000000)'>
          <g id='Group-15' transform='translate(558.000000, 314.000000)'>
            <g id='Group-14' transform='translate(0.000000, 304.000000)'>
              <g id='Group-17' transform='translate(15.000000, 17.000000)'>
                <rect id='Rectangle-Copy-2' fill='#F3F3F4' x='0' y='0' width='24' height='24' rx='3' />
                <g id='f-delete' transform='translate(6.000000, 6.000000)' fill='#0F1226' fillOpacity='0.5' fillRule='nonzero'>
                  <path d='M6,0 C2.6862915,0 0,2.6862915 0,6 C0,9.3137085 2.6862915,12 6,12 C9.3137085,12 12,9.3137085 12,6 C12,2.6862915 9.3137085,0 6,0 Z M9.13043478,6.7826087 L2.86956522,6.7826087 L2.86956522,5.2173913 L9.13043478,5.2173913 L9.13043478,6.7826087 Z' id='ActivityRefuseServiceIconShape' />
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default ActivityRefuseServiceIcon;
