import React, { useEffect, useMemo, useRef } from 'react';
import PropTypes from 'prop-types';
import Icon from 'react-fontawesome';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components';

import help from '~/assets/images/help.svg';
import { Logo, OlioOLogo, PriorityFlag, WarnIcon } from '~/components/shared/svg';
import { getTotalRecords as getEscalationsTotalRecords } from '~/ducks/escalations';
import { useSwitch } from '~/lib/hooks';
import { Escalation } from '~/models';
import { authService } from '~/services/auth';
import { useProfileContext } from '~/services/profile';
import { flexbox } from '~/styles/mixins';
import theme from '~/styles/theme';

const HeaderNavbar = (props) => {
  const profileSvc = useProfileContext();
  const profile = profileSvc.profile;

  const { history, onSelectClientClick, onTasksClick, taskCount } = props;

  const { state: menuVisible, toggle: toggleMenu, turnOff: turnOffMenu } = useSwitch();
  const node = useRef(null);

  const handleClickOutside = (e) => {
    if (!node.current || node.current.contains(e.target)) {
      return;
    }
    turnOffMenu();
  };

  const handleLogout = (e) => {
    e.preventDefault();
    turnOffMenu();

    authService.logout();
  };

  const handlePreferences = () => {
    turnOffMenu();
    history.push('/preferences');
  };

  const handleSelectClient = () => {
    turnOffMenu();
    onSelectClientClick();
  };

  const handleTasksClick = () => {
    onTasksClick();
  };

  useEffect(() => {
    if (menuVisible) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [menuVisible]);

  const escalationType = useMemo(() => Escalation.typeForUser(profile), [profile]);
  const TasksIcon = useMemo(() => (escalationType ? WarnIcon : PriorityFlag), [escalationType]);
  const StyledTasksIcon = useMemo(
    () => styled(TasksIcon)`
      margin-right: 8px;
    `,
    [TasksIcon]
  );
  const tasksLabelText = useMemo(() => (escalationType ? 'Escalations' : 'Priorities'), [escalationType]);

  return (
    <Header data-dd-privacy='allow' ref={node}>
      <Logo />
      <HeaderIcons>
        {
          <OlioNavLinks>
            <BaseLink onClick={handleTasksClick} data-cy='tasksLink'>
              <TasksIconContainer taskCount={taskCount}>
                <StyledTasksIcon fill='#FFFFFF' size={16} />
                {taskCount > 0 && <TasksBadge>{taskCount}</TasksBadge>}
              </TasksIconContainer>
              <span>{tasksLabelText}</span>
            </BaseLink>
            <BaseLink
              as='a'
              href='https://support.olio.health/hc/en-us'
              data-cy='supportLink'
              target='_blank'
              rel='noopener'>
              <img src={help} style={{ marginRight: '8px' }} />
              <span>Help &amp; Support</span>
            </BaseLink>

            <UserMenu onClick={toggleMenu} data-cy='userMenu'>
              <StyledIcon name={`${menuVisible ? 'caret-down' : 'caret-right'}`} />
              {profile.fullName}
              {profile.actingClient && <ActingClientName>({profile.actingClient.name})</ActingClientName>}
            </UserMenu>
          </OlioNavLinks>
        }
        {menuVisible && (
          <Menu>
            <UserInfoContainer>
              <div>
                <OlioOLogo fill={theme.colors.primaryBlue} size='36' />
              </div>
              <UserInfo>
                <UserName>{profile.fullName}</UserName>
                <UserEmail>{profile.email}</UserEmail>
              </UserInfo>
            </UserInfoContainer>

            {Boolean(profile.isAdmin || profile.client.leafDescendants.length > 1) && (
              <MenuItem onClick={handleSelectClient} data-cy='userMenuSelectClientView'>
                Select View
              </MenuItem>
            )}
            <MenuItem onClick={handlePreferences} data-cy='userMenuNotificationPreferences'>
              Notification Preferences
            </MenuItem>
            <MenuItem onClick={handleLogout} data-cy='userMenuLogout'>
              Logout
            </MenuItem>
          </Menu>
        )}
      </HeaderIcons>
    </Header>
  );
};

HeaderNavbar.propTypes = {
  history: PropTypes.instanceOf(Object),
  onSelectClientClick: PropTypes.func.isRequired,
  onTasksClick: PropTypes.func.isRequired,
  taskCount: PropTypes.number,
};

const mapStateToProps = (state) => ({
  taskCount: getEscalationsTotalRecords(state),
});

export default withRouter(connect(mapStateToProps)(HeaderNavbar));

const headerHeight = 48;

const Header = styled.div`
  background-color: ${(props) => props.theme.colors.primaryBlue};
  height: ${headerHeight + 'px'};
  padding: 0 24px 0 16px;
  position: relative;

  ${flexbox({ justifyContent: 'space-between' })};
`;

const HeaderIcons = styled.div`
  display: flex;
`;

const OlioNavLinks = styled.div`
  display: flex;
  margin: auto 8px;
`;

const BaseLink = styled.div`
  color: ${(props) => props.theme.colors.white};
  cursor: pointer;
  font-size: 16px;
  height: 16px;
  padding: 16px 12px;
  display: flex;
  align-items: center;

  &:hover {
    background-color: ${(props) => props.theme.colors.primaryBlue10};
  }
`;

const UserMenu = styled(BaseLink)`
  margin-left: 8px;
  min-width: 148px;
`;

const Menu = styled.div`
  border: none;
  border-radius: 2px;
  background: ${(props) => props.theme.colors.white};
  box-shadow:
    0 2px 2px 0 rgba(0, 0, 0, 0.24),
    0 0 2px 0 rgba(0, 0, 0, 0.12);
  position: absolute;
  top: ${headerHeight - 5 + 'px'};
  right: 24px;
  z-index: 100;

  & > *:last-child {
    border-bottom: none;
  }
`;

const MenuItem = styled.div`
  color: ${(props) => props.theme.colors.black75};
  cursor: pointer;
  padding: 12px;
  min-width: 100px;
  border-bottom: 1px solid ${(props) => props.theme.colors.black25};

  &:hover {
    background: ${(props) => props.theme.colors.black05};
  }
`;

const UserInfoContainer = styled.div`
  display: flex;
  flex-wrap: nowrap;
  justify-content: center;
  padding: 12px;
  min-width: 100px;
  border-bottom: 1px solid ${(props) => props.theme.colors.black25};
`;

const UserInfo = styled.div`
  margin-left: 8px;
  text-align: left;
`;

const UserName = styled.div`
  font-weight: bold;
  color: ${(props) => props.theme.colors.primaryBlue};
  padding-bottom: 5px;
`;

const UserEmail = styled.div`
  color: ${(props) => props.theme.colors.mediumGrey};
`;

const StyledIcon = styled(Icon)`
  margin-right: 4px;
  color: ${(props) => props.theme.colors.white};
  width: 10px;
`;

const ActingClientName = styled.div`
  font-style: italic;
  margin-left: 5px;
`;

const TasksIconContainer = styled.div`
  display: flex;
  margin-right: ${({ taskCount }) => (taskCount > 0 ? 7 : 0)}px;
  position: relative;
`;

const TasksBadge = styled.span`
  align-items: center;
  align-self: flex-end;
  background: ${theme.colors.accentRed};
  border-radius: 50%;
  bottom: -8px;
  color: ${theme.colors.white};
  display: flex;
  font-size: 10px;
  height: 14px;
  justify-content: center;
  margin-left: 1px;
  position: absolute;
  right: 0px;
  width: 14px;
`;
