import { immerable } from 'immer';

import RehabFacility from './locations/RehabFacility';
import ReviewFactory from './reviews/ReviewFactory';
import Classification from './Classification';
import Location from './Location';
import Patient from './Patient';
import { ProgressTemplate, QuestionTemplateFactory } from './questionTemplates';
import RehabState from './RehabState';
import User from './User';

const defaults = {
  id: '',
  archived: false,
  currentRehabState: new RehabState(),
  endDate: '',
  episodeId: '',
  episodeClassification: null,
  hasActiveServiceRefusals: false,
  hasNewChanges: false,
  hospital: {
    name: '',
  },
  lastValidPredecessor: {},
  latest: true,
  lengthOfStay: 0,
  locationId: '',
  owner: new Location(),
  patient: new Patient(),
  planTypeClassification: null,
  physicianTeam: {
    name: '',
  },
  questionTemplates: [],
  rehabInformation: {
    eligibleForProgressUpdate: false,
    latestRehabFacility: {
      id: '',
      name: '',
    },
  },
  rehabStates: [new RehabState()],
  reviews: [],
  startDate: '',
  onTrack: '',
  onTrackUpdatedAt: '',
  statusOutOfDate: false,
  caseManager: null,
};

const buildRehabInformation = (rehabInfo) => {
  return {
    ...rehabInfo,
    latestRehabFacility: new RehabFacility({
      ...rehabInfo.latestRehabFacility,
      subType: rehabInfo.latestRehabFacilityType,
    }),
  };
};

export default class LocationEpisode {
  [immerable] = true;

  constructor(options = {}) {
    const opts = { ...defaults, ...options };

    this.id = opts.id;
    this.admittedOn = opts.admittedOn;
    this.archived = opts.archived;
    this.currentRehabState = new RehabState(opts.currentRehabState);
    this.caseManager = opts.caseManager ? new User(opts.caseManager) : opts.caseManager;
    this.endDate = opts.endDate;
    this.episodeId = opts.episodeId;
    this.episodeClassification = new Classification(opts.episodeClassification);
    this.escalated = opts.escalated;
    this.externalId = opts.externalId;
    this.hasActiveRefusals = opts.hasActiveServiceRefusals;
    this.hasNewChanges = opts.hasNewChanges;
    this.hospital = opts.hospital;
    this.lastValidPredecessor = opts.lastValidPredecessor;
    this.latest = opts.latest;
    this.lengthOfStay = opts.lengthOfStay;
    this.locationId = opts.locationId;
    this.medicare = opts.medicare;
    this.owner = opts.owner ? new Location(opts.owner) : opts.owner;
    this.patient = new Patient(opts.patient);
    this.planTypeClassification = new Classification(opts.planTypeClassification);
    this.physicianTeam = opts.physicianTeam;
    this.questionTemplates = opts.questionTemplates.map((template) => QuestionTemplateFactory.get(template));
    this.rehabInformation = buildRehabInformation(opts.rehabInformation);
    this.rehabStates = opts.rehabStates.map((state) => new RehabState(state));
    this.reviews = opts.reviews.map((review) => ReviewFactory.get(review));
    this.startDate = opts.startDate;
    this.unacknowledgedEscalations = opts.unacknowledgedEscalations;
    this.unacknowledgedPriorityNotes = opts.unacknowledgedPriorityNotes;
    this.onTrack = opts.onTrack;
    this.onTrackUpdatedAt = opts.onTrackUpdatedAt;
    this.statusOutOfDate = opts.statusOutOfDate;
    this.trackLos = opts.trackLos;
    this.caseManager = opts.caseManager ? new User(opts.caseManager) : opts.caseManager;
  }

  get rehabFacilityType() {
    return this.rehabInformation.latestRehabFacilityType;
  }

  get latestRehabFacility() {
    return this.rehabInformation.latestRehabFacility.name;
  }

  get inQueue() {
    return this.currentRehabState.queue;
  }

  get inAdmission() {
    return this.currentRehabState.admission;
  }

  get inTreatment() {
    return this.currentRehabState.inTreatment;
  }

  get discharged() {
    return this.currentRehabState.discharged;
  }

  get dischargedBeforeProjectedDate() {
    if (!this.discharged) return false;

    return this.currentRehabState.enteredAt < this.projectedDischargeReview?.date;
  }

  get dischargeOverdue() {
    if (!this.projectedDischargeReview?.date) return false;
    if (this.dischargedBeforeProjectedDate) return false;

    return new Date() > this.projectedDischargeReview.date;
  }

  get hasActiveServiceRefusals() {
    return this.hasActiveRefusals || !!this.reviews.find((r) => r.active && r.isServiceRefusal);
  }

  set hasActiveServiceRefusals(value) {
    this.hasActiveRefusals = value;
  }

  get activeAuthorizationReview() {
    return this.reviews.find((r) => r.active && r.isAuthorization) || null;
  }

  get projectedDischargeReview() {
    return this.reviews.find((r) => r.isProjectedDischarge) || null;
  }

  get altcsApplicationReview() {
    return this.reviews.find((r) => r.isAltcsApplication) || null;
  }

  get stayDescriptor() {
    if (this.archived) return 'Canceled';

    return this.latest ? 'Current' : 'Past';
  }

  get progressTemplate() {
    return this.questionTemplates.find((template) => template.isProgress) || new ProgressTemplate();
  }

  get dischargeTemplate() {
    return this.questionTemplates.find((template) => template.isDischarge) || null;
  }
}
