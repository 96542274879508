import { ALTCS_APPLICATION_STATUS_UPDATE } from '~/constants/activities';

import Note from './Note';

const defaults = {
  type: ALTCS_APPLICATION_STATUS_UPDATE,
};

export default class AltcsApplicationStatusUpdate extends Note {
  constructor(options = {}) {
    super({ ...defaults, ...options });
  }

  get typeLabel() {
    return 'ALTCS Status Update';
  }
}
