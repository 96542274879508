import React from 'react';

interface Props extends React.SVGProps<SVGSVGElement> {
  size?: number;
}

export default function PatientRefuseIcon({ size = 14, fill, ...rest }: Props) {
  return (
    <svg width={ size } height={ size } viewBox='0 0 14 14' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlnsXlink='http://www.w3.org/1999/xlink' { ...rest }>
      <g id='demo-walkthrough' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
        <g id='Refusal-Form' transform='translate(-681.000000, -262.000000)' fill={ fill || 'currentColor' } fillRule='nonzero'>
          <g id='Group-20' transform='translate(330.000000, 80.000000)'>
            <g id='Group-27' transform='translate(275.000000, 169.000000)'>
              <g id='Group-17' transform='translate(76.000000, 11.000000)'>
                <g id='circle-09' transform='translate(0.000000, 2.000000)'>
                  <path d='M7,0 C3.15,0 0,3.15 0,7 C0,10.85 3.15,14 7,14 C10.85,14 14,10.85 14,7 C14,3.15 10.85,0 7,0 Z M10.5875,10.7625 C10.2375,10.15 9.5375,9.8875 8.75,9.625 C8.1375,9.45 7.9625,9.1 7.875,8.575 C8.8375,8.225 9.625,7.2625 9.625,6.125 C9.625,4.6375 8.4875,3.5 7,3.5 C5.5125,3.5 4.375,4.6375 4.375,6.125 C4.375,7.2625 5.075,8.225 6.125,8.575 C6.0375,9.1 5.8625,9.45 5.25,9.625 C4.4625,9.8875 3.7625,10.15 3.4125,10.7625 C2.3625,9.8 1.75,8.4875 1.75,7 C1.75,4.1125 4.1125,1.75 7,1.75 C9.8875,1.75 12.25,4.1125 12.25,7 C12.25,8.4875 11.6375,9.8 10.5875,10.7625 Z' id='Refuse-Shape' />
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}
