import React from 'react';
import styled from 'styled-components';

import LazyImage from '~/components/shared/LazyImage';
import { RemovedImageIcon } from '~/components/shared/svg';
import { BodySmall } from '~/components/shared/typography';
import { Attachment } from '~/models';
import { colors } from '~/styles/theme';

type ThumbnailProps = {
  attachment: Attachment;
  className?: string;
  onClick?: () => void;
  removed?: boolean;
};

function Thumbnail({ attachment, className = '', onClick = () => ({}), removed = false }: ThumbnailProps) {
  return (
    <React.Fragment>
      {removed ? (
        <RemovedImage className={className}>
          <RemovedImageIcon />
          <StyledBodySmall>Image has been removed</StyledBodySmall>
        </RemovedImage>
      ) : (
        <LazyThumbnail
          className={className}
          customStyles={{
            maxHeight: '80px',
            maxWidth: '80px',
          }}
          src={attachment?.thumbnailUrl}
          alt='attached image'
          onClick={onClick}
        />
      )}
    </React.Fragment>
  );
}

export default Thumbnail;

const RemovedImage = styled.div`
  display: flex;
  align-items: center;
`;

const StyledBodySmall = styled(BodySmall)`
  margin-left: 6px;
  font-style: italic;
  color: ${colors.black50};
`;

const LazyThumbnail = styled(LazyImage)`
  cursor: pointer;
`;
