import React from 'react';
import PropTypes from 'prop-types';

import theme from '~/styles/theme';

const CalendarIcon = ({ fill, secondaryFill, size }) => {
  return (
    <svg height={size} width={size} viewBox='0 0 48 48' xmlns='http://www.w3.org/2000/svg'>
      <g fill={secondaryFill}>
        <rect height='8' width='8' rx='1' x='10' y='32' />
        <rect height='8' width='8' rx='1' x='10' y='22' />
        <rect height='8' width='8' rx='1' x='20' y='32' />
        <rect height='8' width='8' rx='1' x='20' y='22' />
        <rect height='8' width='8' rx='1' x='30' y='22' />
        <path d='M12,11a1,1,0,0,1-1-1V2a1,1,0,0,1,2,0v8A1,1,0,0,1,12,11Z' />
        <path
          d='M44,5H39v5a3,3,0,0,1-6,0V5H15v5a3,3,0,0,1-6,0V5H4A3,3,0,0,0,1,8V43a3,3,0,0,0,3,3H44a3,3,0,0,0,3-3V8A3,3,0,0,0,44,5Zm1,38a1,1,0,0,1-1,1H4a1,1,0,0,1-1-1V18H45Z'
          fill={fill}
        />
        <path d='M36,11a1,1,0,0,0,1-1V2a1,1,0,0,0-2,0v8A1,1,0,0,0,36,11Z' />
      </g>
    </svg>
  );
};

CalendarIcon.defaultProps = {
  fill: theme.colors.primaryBlue,
  secondaryFill: theme.colors.primaryBlue,
  size: 24,
};

CalendarIcon.propTypes = {
  fill: PropTypes.string,
  secondaryFill: PropTypes.string,
  size: PropTypes.number,
};

export default CalendarIcon;
