import React from 'react';
import PropTypes from 'prop-types';

import DischargeQuestionsForm from '~/components/patients/shared/DischargeQuestionsForm';
import { StandardModal } from '~/components/shared/modal';
import { Activity } from '~/models';

import { formHelpers } from '../../shared/QuestionForm';
import { usePatientProfileContext } from '../PatientProfileContext';

function EditDischargeDetailsModal(props) {
  const { activity, onCancel, onComplete, show } = props;
  const { locationEpisode } = usePatientProfileContext();

  const handleOnComplete = (values) => {
    const answeredQuestions = formHelpers.buildAnsweredQuestionsFromFormValues(
      locationEpisode.dischargeTemplate.questions,
      values.dischargeQuestions
    );

    onComplete({ questions: answeredQuestions });
  };

  const initialValues = activity.questions.reduce((acc, { id, answer }) => ({ ...acc, [id]: answer.text }), {});

  return (
    <StandardModal show={show} data-cy='editDischargeDetailsModal' title={'Edit Discharge Details'} onCancel={onCancel}>
      {show && (
        <DischargeQuestionsForm
          dischargeTemplate={locationEpisode.dischargeTemplate}
          formValues={{
            dischargeQuestions: initialValues,
          }}
          onComplete={handleOnComplete}
          onRevert={onCancel}
        />
      )}
    </StandardModal>
  );
}

EditDischargeDetailsModal.propTypes = {
  activity: PropTypes.instanceOf(Activity),
  onCancel: PropTypes.func,
  onComplete: PropTypes.func,
  show: PropTypes.bool,
};

export default EditDischargeDetailsModal;
