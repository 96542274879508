import React from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { createRoot } from 'react-dom/client';
import TagManager from 'react-gtm-module';
import ReactModal from 'react-modal';
import { Provider } from 'react-redux';
import { Route } from 'react-router';
import { Router, Switch } from 'react-router-dom';

import { datadogRum } from '@datadog/browser-rum';

import { LoggedOut } from '~/components/auth';
import { history, store } from '~/store';

import App from './components/app';
import { Error, ErrorBoundary } from './components/error';
import { External } from './components/external';

import './assets/stylesheets/index.scss';

TagManager.initialize({
  gtmId: import.meta.env.GOOGLE_TAG_MANAGER_ID,
  auth: import.meta.env.GOOGLE_TAG_MANAGER_AUTH,
  preview: import.meta.env.GOOGLE_TAG_MANAGER_PREVIEW,
});

datadogRum.init({
  applicationId: import.meta.env.DATADOG_APPLICATION_ID,
  clientToken: import.meta.env.DATADOG_CLIENT_TOKEN,
  site: 'datadoghq.com',
  service: import.meta.env.DATADOG_SERVICE_NAME,
  env: import.meta.env.DATADOG_ENVIRONMENT,
  version: '1.0.0',
  sessionSampleRate: 100,
  sessionReplaySampleRate: Number(import.meta.env.DATADOG_PREMIUM_SAMPLE_RATE) || 0,
  trackUserInteractions: true,
  trackFrustrations: true,
  defaultPrivacyLevel: 'mask',
  traceSampleRate: Number(import.meta.env.DATADOG_TRACE_SAMPLE_RATE) || 0,
  ...(import.meta.env.DATADOG_TRACE_URL
    ? {
        allowedTracingUrls: [(url) => url.startsWith(`https://${import.meta.env.DATADOG_TRACE_URL}.olio.health/api`)],
      }
    : {}),
});

datadogRum.startSessionReplayRecording();

// setup or modal parent element to attach to
ReactModal.setAppElement('#app');

const root = (
  <ErrorBoundary>
    <Provider store={store}>
      <DndProvider backend={HTML5Backend}>
        <Router history={history}>
          <Switch>
            <Route path='/external' component={External} />
            <Route path='/error' component={Error} />
            <Route path='/logged-out' component={LoggedOut} />
            <Route path='' component={App} />
          </Switch>
        </Router>
      </DndProvider>
    </Provider>
  </ErrorBoundary>
);

createRoot(document.getElementById('app')).render(root);

export { store };
