import React, { useCallback, useState } from 'react';
import { Form, Formik } from 'formik';
import PropTypes from 'prop-types';

import { Button, ButtonGroup } from '~/components/shared/buttons';
import { FormContainer, FormHeader } from '~/components/shared/form';
import NeedsAndDocuments from '~/components/shared/NeedsAndDocuments';
import { FormPageContentContainer } from '~/components/shared/pageLayout';
import { ImportedPatient, Note } from '~/models';
function PatientConnectNeeds(props) {
  const { onRevert, patient } = props;
  const [uploading, setUploading] = useState(false);

  const handleNext = useCallback(
    async (values) => {
      const updatedPatient = { ...patient, note: values.note, needs: values.needs };

      props.setPatient(new ImportedPatient(updatedPatient));
      props.onComplete();
    },
    [patient]
  );

  const initialFormValues = { note: patient.note || new Note(), needs: patient.needs || [] };

  return (
    <Formik initialValues={initialFormValues} onSubmit={handleNext} enableReinitialize validateOnMount>
      <Form>
        <FormPageContentContainer>
          <FormContainer>
            <FormHeader title='Post Acute Needs' subtitle={`Name: ${patient.name}`} />
            <NeedsAndDocuments onUploading={setUploading} />

            <ButtonGroup>
              <Button color='transparent' onClick={onRevert} text='Back' />
              <Button type='submit' disabled={uploading} data-cy='postAcuteNext' text='Next' />
            </ButtonGroup>
          </FormContainer>
        </FormPageContentContainer>
      </Form>
    </Formik>
  );
}

PatientConnectNeeds.propTypes = {
  onAbort: PropTypes.func.isRequired,
  onComplete: PropTypes.func.isRequired,
  onRevert: PropTypes.func.isRequired,
  patient: PropTypes.oneOfType([PropTypes.instanceOf(ImportedPatient)]),
  setPatient: PropTypes.func.isRequired,
};

export default PatientConnectNeeds;
