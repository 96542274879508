import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import styled, { css } from 'styled-components';

import LazyImage from '~/components/shared/LazyImage';
import { StandardModal } from '~/components/shared/modal';
import { fetchAttachment } from '~/ducks/attachments';
import { Attachment } from '~/models';

function ImageViewer(props) {
  const { attachment, onClose, show } = props;

  const [imageUrl, setImageUrl] = useState(null);
  const [imageLoaded, setImageLoaded] = useState(false);

  useEffect(() => {
    const fetchImageUrl = async () => {
      if (show) {
        const response = await props.fetchAttachment({
          id: attachment.id,
          include: 'url',
        });

        setImageUrl(response.payload.url);
      }
    };

    fetchImageUrl();
  }, [show]);

  return (
    <StandardModal title='Attached Image' show={show} onCancel={onClose}>
      <ImageContainer imageLoaded={imageLoaded}>
        <LazyImage
          src={imageUrl}
          customStyles={{ maxWidth: '100%' }}
          alt='attached image'
          onLoaded={setImageLoaded}
          useSpinner
        />
      </ImageContainer>
    </StandardModal>
  );
}

ImageViewer.propTypes = {
  attachment: PropTypes.instanceOf(Attachment),
  fetchAttachment: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  show: PropTypes.bool.isRequired,
};

const mapDispatchToProps = {
  fetchAttachment,
};

export default connect(null, mapDispatchToProps)(ImageViewer);

const ImageContainer = styled.div`
  display: flex;
  margin: auto;
  max-width: 10vw;
  max-height: 10vw;
  overflow: auto;
  padding: 16px;

  ${({ imageLoaded }) =>
    imageLoaded &&
    css`
      max-width: 60vw;
      max-height: 40vw;
      transition: all 0.5s linear;
    `};

  /*
    Set margin on the children to fix issue
    with centering & scrolling larger images
  */
  & > * {
    margin: auto;
  }
`;
