import client from '~/sources/api/client';

import { Api, CreateRequest, FetchRequest, UpdateRequest } from './core';

export const url = 'location_episodes';
const activitiesUrl = 'activities';

const { create, update, fetch, fetchById } = new Api({ url });

export default {
  allPatients: new FetchRequest({ url: `${url}/all_patients` }),
  create,
  fetch,
  update,
  fetchById,
  fetchDailyPerformance(locationEpisodeId) {
    return client.get(`${url}/${locationEpisodeId}/progress_chart`);
  },
  createActivity: new CreateRequest({
    formatArgsForConfig: (data) => ({
      url: `${url}/${data.locationEpisodeId}/${activitiesUrl}`,
      data,
    }),
  }),
  fetchActivities: new FetchRequest({
    formatArgsForConfig: ({ locationEpisodeId, ...params }) => ({
      url: `${url}/${locationEpisodeId}/${activitiesUrl}`,
      params,
    }),
  }),
  updateActivity: new UpdateRequest({ url: activitiesUrl }),

  updateRehabStateDates(params) {
    return client.post(`${url}/${params.locationEpisodeId}/date_changes`, {
      states: params.states,
    });
  },
};
