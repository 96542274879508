import React, { useMemo, useState } from 'react';

import { FullGauge } from '~/components/shared/Charts';
import { colors } from '~/styles/theme';

import { MetricConstants, RouteConstants, SourceConstants } from './constants';
import { DataRow, getRollup, requestData } from './dataUtils';
import MetricTile from './MetricTile';
import { useInsightsContext } from '.';

export default function ReadmissionRateTile() {
  const [dischargesCount, setDischargesCount] = useState(-1);
  const [readmissionsCount, setReadmissionsCount] = useState(-1);
  const [readmissionsRate, setReadmissionRate] = useState(-1);

  const insightsContext: any = useInsightsContext();
  const { dischargedId } = insightsContext;

  const request = useMemo(
    () => ({
      params: {
        source: SourceConstants.LOCATION_EPISODES,
        metrics: [MetricConstants.ID_COUNT, MetricConstants.READMISSIONS_COUNT, MetricConstants.READMISSION_RATE],
        rehabState: dischargedId,
      },
      processData: (data: DataRow[]) => {
        const [dischargesCount, readmissionsCount, readmissionsRate] = getRollup(data);

        const rate = dischargesCount && readmissionsRate >= 0 ? readmissionsRate : -1;

        setDischargesCount(dischargesCount);
        setReadmissionsCount(readmissionsCount);
        setReadmissionRate(rate);
      },
    }),
    [dischargedId]
  );

  const { loading, setContainerRef } = requestData([request], {
    condition: dischargedId !== undefined,
    onIntersecting: true,
  });

  const legend = [
    { name: 'Readmissions', value: readmissionsCount, color: '#DC2853' },
    { name: 'Discharges', value: dischargesCount, color: colors.black10 },
  ];

  return (
    <MetricTile
      ref={setContainerRef}
      data-gtm-id='insightsReadmissionRate'
      data-cy='insightsReadmissionRate'
      label='All Cause Readmission Rate'
      showValue={false}
      loading={loading}
      hasData={readmissionsRate !== -1}
      navigateTo={RouteConstants.READMISSION_RATE}>
      <FullGauge
        minValue={0}
        maxValue={dischargesCount}
        value={readmissionsCount}
        colorStops={[[0, '#DC2853']]}
        legend={legend}
        label={`${readmissionsRate}%`}
      />
    </MetricTile>
  );
}
