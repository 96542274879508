import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Button, ButtonGroup } from '~/components/shared/buttons';
import { StandardModal } from '~/components/shared/modal';
import { Body, H3 } from '~/components/shared/typography';

function ConfirmEscalationModal({ show, onConfirm, onCancel }) {
  return (
    <StandardModal
      colorTheme='danger'
      onCancel={onCancel}
      show={show}
      title='Confirm Escalation'
      data-cy='confirmEscalationModal'>
      <Header>
        <H3>Are you sure you want to send an escalation?</H3>
        <Paragraph>This will notify all collaborating providers.</Paragraph>
      </Header>
      <Actions>
        <Button color='transparent' text='Cancel' onClick={onCancel} />
        <Button text='Confirm' onClick={onConfirm} data-cy='confirmEscalationModalConfirmButton' />
      </Actions>
    </StandardModal>
  );
}

ConfirmEscalationModal.propTypes = {
  onCancel: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  show: PropTypes.bool,
};

export default ConfirmEscalationModal;

const Header = styled.div`
  margin: 26px 0 50px;
  text-align: center;
`;

const Paragraph = styled(Body)`
  display: block;
`;

const Actions = styled(ButtonGroup)`
  justify-content: flex-end;
`;
