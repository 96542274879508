import React from 'react';
import styled from 'styled-components';

import { BodySmall, H1, H2 } from '~/components/shared/typography';

import { useTaskModalContext } from '../TaskModalContext';

function EmptyState() {
  const { escalationTypeLabel } = useTaskModalContext();

  return (
    <Container>
      <TextContainer data-cy='taskModalEmptyState'>
        <H1 display='block'>🎉</H1>
        <H2 display='block' fontSize='20px'>
          You're all caught up!
        </H2>
        <BodySmall>No {escalationTypeLabel.toLowerCase()} to take care of right now. Check back soon!</BodySmall>
      </TextContainer>
    </Container>
  );
}

export default EmptyState;

const Container = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
`;

const TextContainer = styled.div`
  width: 100%;
  text-align: center;
  & > * {
    margin-bottom: 14px;
  }
`;
