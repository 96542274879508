import { createSlice } from '@reduxjs/toolkit';

import { ALL, SEARCH } from '~/constants/filterKeysConstants';
import { rootPathnameChanged } from '~/ducks/navigation';

export const allOption = { label: ALL, value: ALL.toLowerCase() };
export const FILTER_KEYS = {
  CLIENT: 'clientId',
  LOCATION_TYPE: 'type'
};

const sliceName = 'admin/locations/filters';

export const initialState = {
  [FILTER_KEYS.CLIENT]: allOption.value,
  [FILTER_KEYS.LOCATION_TYPE]: allOption.value,
  [SEARCH]: ''
};

const locationsFiltersSlice = createSlice({
  name: sliceName,
  initialState,
  reducers: {
    clearFilters: (state) => ({ ...initialState, [SEARCH]: state[SEARCH] }),
    setFilter: (state, { payload }) => {
      state[payload.key] = payload.value;
    },
    setSearch: (state, { payload }) => {
      state[SEARCH] = payload;
    }
  },
  extraReducers: {
    [rootPathnameChanged]: () => initialState
  }
});

export const {
  clearFilters,
  setFilter,
  setSearch
} = locationsFiltersSlice.actions;

const getLocationsFiltersState = (state) => state[sliceName];

export const getFilters = (state) => getLocationsFiltersState(state);
export const getSearch = (state) => getLocationsFiltersState(state)[SEARCH];

export default locationsFiltersSlice;
