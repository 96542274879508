import React, { useEffect } from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import styled from 'styled-components';

import StandardModal from '~/components/shared/modal/StandardModal';
import { BodySmall, BodySmallBold } from '~/components/shared/typography';
import { getLocationEpisodeUpdating, updateLocationEpisodeDates } from '~/ducks/locationEpisode';
import { usePrevious } from '~/lib/hooks';
import { LocationEpisode } from '~/models';

import StatusDatesForm from './StatusDatesForm';

function StatusDatesModal(props) {
  const { onClose, locationEpisode, locationEpisodeUpdating, showStatusDatesModal } = props;

  const previousLocationEpisodeUpdating = usePrevious(locationEpisodeUpdating);

  useEffect(() => {
    if (previousLocationEpisodeUpdating && !locationEpisodeUpdating) {
      onClose();
    }
  }, [locationEpisodeUpdating]);

  const handleSave = (values) => {
    const states = Object.entries(values).map(([rehabState, enteredAt]) => ({
      rehabState,
      // date objects are automatically converted to UTC
      // when stringified for api requests, so we need to
      // send just the date portion of the date object
      enteredAt: moment(enteredAt).format('YYYY-MM-DD'),
    }));

    props.updateLocationEpisodeDates({ locationEpisodeId: locationEpisode.id, states });
  };

  return (
    <StandardModal show={showStatusDatesModal} title='Edit Dates' onCancel={onClose} disableBackdropClick>
      <Heading>Patient: {locationEpisode.patient?.name}</Heading>
      <Text>Each field must have the same date or a later date selected than the field listed above it.</Text>
      <StatusDatesForm onSave={handleSave} onCancel={onClose} rehabStates={locationEpisode.rehabStates} />
    </StandardModal>
  );
}

StatusDatesModal.propTypes = {
  locationEpisode: PropTypes.instanceOf(LocationEpisode),
  locationEpisodeUpdating: PropTypes.bool,
  onClose: PropTypes.func,
  showStatusDatesModal: PropTypes.bool,
  updateLocationEpisodeDates: PropTypes.func,
};

const mapStateToProps = (state) => ({
  locationEpisodeUpdating: getLocationEpisodeUpdating(state),
});

const mapDispatchToProps = {
  updateLocationEpisodeDates,
};

export default connect(mapStateToProps, mapDispatchToProps)(StatusDatesModal);

const Heading = styled(BodySmallBold)`
  text-align: center;
  width: 100%;
`;

const Text = styled(BodySmall)`
  margin: 1.5rem 0;
`;
