import { createSlice } from '@reduxjs/toolkit';

export const sliceName = 'insightsV1/rollups';

export const initialState = {
  discharges: 0,
};

const slice = createSlice({
  name: sliceName,
  initialState,
  reducers: {
    setDischarges: (state, { payload: discharges }) => {
      state.discharges = discharges || 0;
    },
  },
});

export const getInsightsRollups = (state) => state[sliceName];

export const { setDischarges } = slice.actions;

export default slice.reducer;
