import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { LabelBold } from '~/components/shared/typography';
import colors from '~/styles/theme/colors';

function PatientInfoItem({ className, label, value }) {
  return (
    <InfoItem className={ className }>
      <LabelBold color={ colors.black50 }>{ label }:&nbsp;</LabelBold>
      <LabelBold>{ value }</LabelBold>
    </InfoItem>
  );
}

PatientInfoItem.propTypes = {
  label: PropTypes.string,
  value: PropTypes.string
};

export default PatientInfoItem;

const InfoItem = styled.div`
  margin-right: 24px;
`;
