import React from 'react';
import PropTypes from 'prop-types';

import theme from '~/styles/theme';

const EditIcon = ({ fill, size }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox='0 0 24 24'
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'>
      <title>Edit Icon</title>
      <defs>
        <path
          d='M12.1,7.5 L16.5,11.9 L8.7,19.7 C8.5,19.9 8.3,20 8,20 L5,20 C4.4,20 4,19.6 4,19 L4,16 C4,15.7 4.1,15.5 4.3,15.3 L12.1,7.5 Z M19.7,7.3 C20.1,7.7 20.1,8.3 19.7,8.7 L17.9,10.5 L13.5,6.1 L15.3,4.3 C15.7,3.9 16.3,3.9 16.7,4.3 L19.7,7.3 Z'
          id='edit-path'
        />
      </defs>
      <g id='Icon/Edit' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
        <mask id='edit-mask' fill='white'>
          <use xlinkHref='#edit-path' />
        </mask>
        <g id='Mask' fillRule='nonzero' />
        <g id='Mixin/Fill/Black' mask='url(#edit-mask)' fill={fill}>
          <rect id='Rectangle' x='0' y='0' width='24' height='24' />
        </g>
      </g>
    </svg>
  );
};

EditIcon.defaultProps = {
  fill: theme.colors.black,
  size: 24,
};

EditIcon.propTypes = {
  fill: PropTypes.string,
  size: PropTypes.number,
};

export default EditIcon;
