import React, { useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import { ColumnDef, createColumnHelper } from '@tanstack/react-table';

import { MetricDetailContainer, MetricDetailHeader } from '~/components/insights';
import { useInsightsContext } from '~/components/insights/InsightsContainer';
import { Histogram } from '~/components/shared/Charts';
import DataTable from '~/components/shared/DataTable';
import { LabelBold } from '~/components/shared/typography';

import Card from '../Card';
import { DimensionConstants, MetricConstants, RouteConstants, SortOrder, SourceConstants } from '../constants';
import { DataRow, parseDimensionValues, parseMetricValues, requestData } from '../dataUtils';

import ChartContainer from './ChartContainer';
import { ChartData, generateExtendedChartConfig } from './readmissionRateChartUtils';
import TableContainer from './TableContainer';
import { GroupDischargesRow } from './tableUtils';

type ReadmissionRateTableRow = GroupDischargesRow & {
  readmissions: number;
  readmissionRate: number;
};

const ReadmissionRateDetail = () => {
  const history = useHistory();

  const insightsContext: any = useInsightsContext();
  const { dischargedId, selectedGroupType } = insightsContext;

  const [averageRate, setAverageRate] = useState(0);
  const [totalDischarges, setTotalDischarges] = useState(0);
  const [totalReadmissions, setTotalReadmissions] = useState(0);
  const [chartData, setChartData] = useState<ChartData>({
    categories: [],
    dischargeCounts: [],
    readmissionCounts: [],
    readmissionRates: [],
  });

  const request = useMemo(
    () => ({
      params: {
        source: SourceConstants.LOCATION_EPISODES,
        dimensions: [DimensionConstants.GROUP_NAME],
        metrics: [MetricConstants.ID_COUNT, MetricConstants.READMISSIONS_COUNT, MetricConstants.READMISSION_RATE],
        sortBy: `${MetricConstants.READMISSION_RATE} ${SortOrder.DESC}`,
        rehabState: dischargedId,
        rollups: true,
      },
      processData: (data: DataRow[]) => {
        const parsedData = data.reduce(
          (acc, row) => {
            const dimensionValues = parseDimensionValues(row, false) as string[];
            const metricValues = parseMetricValues(row);

            const groupName = dimensionValues[0];
            const discharges = metricValues[0];
            const readmissions = metricValues[1];
            const readmissionRate = metricValues[2];

            if (!groupName) {
              acc.averageRate = readmissionRate;
              acc.totalDischarges = discharges;
              acc.totalReadmissions = readmissions;
            } else {
              acc.categories.push(groupName);
              acc.dischargeCounts.push(discharges);
              acc.readmissionCounts.push(readmissions);
              acc.readmissionRates.push(readmissionRate);
            }

            return acc;
          },
          {
            categories: [],
            dischargeCounts: [],
            readmissionCounts: [],
            readmissionRates: [],
            averageRate: 0,
            totalDischarges: 0,
            totalReadmissions: 0,
          } as {
            categories: string[];
            dischargeCounts: number[];
            readmissionCounts: number[];
            readmissionRates: number[];
            averageRate: number;
            totalDischarges: number;
            totalReadmissions: number;
          }
        );

        const { totalDischarges, totalReadmissions, averageRate, ...chartData } = parsedData;

        setChartData(chartData);
        setAverageRate(averageRate);
        setTotalDischarges(totalDischarges);
        setTotalReadmissions(totalReadmissions);
      },
    }),
    [dischargedId]
  );

  const { loading } = requestData([request], {
    condition: dischargedId !== undefined,
  });

  const chartConfig = useMemo(() => generateExtendedChartConfig(chartData), [chartData]);

  const getAverageRateString = () => {
    if (!totalDischarges) return;

    return `${averageRate}%`;
  };

  const getAllReadmissionsString = () => {
    if (!totalDischarges) return;

    return totalReadmissions.toString();
  };

  const tableData = useMemo<ReadmissionRateTableRow[]>(() => {
    const { categories, dischargeCounts, readmissionCounts, readmissionRates } = chartData;

    return categories.map((groupName, i) => ({
      groupName,
      discharges: dischargeCounts[i],
      readmissions: readmissionCounts[i],
      readmissionRate: readmissionRates[i],
    }));
  }, [chartData]);

  const columnHelper = createColumnHelper<ReadmissionRateTableRow>();
  const columns = useMemo<ColumnDef<ReadmissionRateTableRow, any>[]>(
    () => [
      columnHelper.accessor('groupName', {
        header: selectedGroupType.displayName,
        cell: (data) => <LabelBold>{data.getValue()}</LabelBold>,
        footer: () => <LabelBold>Average (All)</LabelBold>,
      }),
      columnHelper.accessor('readmissions', {
        header: 'Readmissions',
        footer: () => totalReadmissions,
      }),
      columnHelper.accessor('discharges', {
        header: 'Discharges',
        footer: () => totalDischarges,
      }),
      columnHelper.accessor('readmissionRate', {
        header: 'Readmission Rate',
        cell: (data) => `${data.getValue().toFixed(1)}%`,
        footer: () => `${averageRate.toFixed(1)}%`,
      }),
    ],
    [averageRate, selectedGroupType, totalDischarges, totalReadmissions]
  );

  const defaultSort = [
    {
      id: 'readmissionRate',
      desc: true,
    },
  ];

  return (
    <>
      <MetricDetailContainer
        onBackClick={() => history.push(RouteConstants.INSIGHTS_BASE)}
        loading={loading}
        hasData={!!chartData.categories.length}
        header={
          <>
            <MetricDetailHeader label='All Cause Readmission Rate' loading={loading} value={getAverageRateString()} />
            <Separator />
            <MetricDetailHeader label='All Cause Readmissions' loading={loading} value={getAllReadmissionsString()} />
          </>
        }>
        <ChartContainer>
          <Histogram config={chartConfig} />
        </ChartContainer>
      </MetricDetailContainer>
      <TableContainer>
        <Card.Title>Readmission Rate Overview</Card.Title>
        <DataTable columns={columns} data={tableData} defaultSortBy={defaultSort} loading={loading} />
      </TableContainer>
    </>
  );
};

export default ReadmissionRateDetail;

const Separator = styled.div`
  height: 100%;
  width: 1px;
  background-color: ${({ theme }) => theme.colors.black15};
  margin: 0 24px;
`;
