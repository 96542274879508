import React from 'react';

function InfoIcon() {
  return (
    <svg width='12px' height='12px' viewBox='0 0 12 12' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlnsXlink='http://www.w3.org/1999/xlink'>
      <g id='Metric-Component-Spec' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd' fillOpacity='0.25'>
        <g id='Metric-Component-CSS' transform='translate(-262.000000, -51.000000)' fill='#0F1226' fillRule='nonzero'>
          <g id='Group-11' transform='translate(25.000000, 25.000000)'>
            <path d='M243,26 C239.7,26 237,28.7 237,32 C237,35.3 239.7,38 243,38 C246.3,38 249,35.3 249,32 C249,28.7 246.3,26 243,26 Z M243.75,35 L242.25,35 L242.25,31.25 L243.75,31.25 L243.75,35 Z M243,30.5 C242.55,30.5 242.25,30.2 242.25,29.75 C242.25,29.3 242.55,29 243,29 C243.45,29 243.75,29.3 243.75,29.75 C243.75,30.2 243.45,30.5 243,30.5 Z' id='info-icon' />
          </g>
        </g>
      </g>
    </svg>
  );
}

export default InfoIcon;
