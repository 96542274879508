import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { H2 } from '~/components/shared/typography';

import EmptyState from './EmptyState';
import FilesTable from './FilesTable';

function Files({ filesCount }) {
  return (
    <>
      <FilesHeader>
        <H2>Files/Documents</H2>
      </FilesHeader>
      {filesCount ? <FilesTable /> : <EmptyState />}
    </>
  );
}

Files.propTypes = {
  filesCount: PropTypes.number,
};

export default Files;

const FilesHeader = styled.div`
  display: flex;
  margin-bottom: 16px;
`;
