import React from 'react';

import {
  EmptyStateContainer,
  EmptyStateContent,
  EmptyStateTitle,
  IconContainer,
} from '~/components/shared/EmptyStateContainer';
import FolerIcon from '~/components/shared/svg/FolderIcon';

const EmptyState = () => (
  <EmptyStateContainer>
    <IconContainer>
      <FolerIcon size={32} />
    </IconContainer>
    <EmptyStateTitle>No Uploaded Files</EmptyStateTitle>
    <EmptyStateContent>All files uploaded for the patient will be listed here.</EmptyStateContent>
  </EmptyStateContainer>
);

export default EmptyState;
