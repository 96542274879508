import Icon from 'react-fontawesome';
import styled from 'styled-components';

export const Table = styled.table`
  overflow-x: auto;
  width: 100%;
  display: flex;
  flex-direction: column;
  font-size: 12px;
  position: relative;
  border: 1px solid ${({ theme }) => theme.colors.black10};
  border-radius: 4px;
  max-height: 440px;

  > * + * {
    border-top: 1px solid ${({ theme }) => theme.colors.black10};
  }
`;

export const TableHeader = styled.thead`
  display: flex;
`;

export const TableBody = styled.tbody`
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  background-color: ${({ theme }) => theme.colors.white};

  > * + * {
    border-top: 1px solid ${({ theme }) => theme.colors.black10};
  }
`;

export const TableRow = styled.tr`
  display: flex;
  flex-shrink: 0;
`;

export const TableHeaderFooterRow = styled(TableRow)`
  flex-basis: 100%;
  background-color: ${({ theme }) => theme.colors.black03};
`;

export const TableFooter = styled.tfoot`
  display: flex;
`;

export const TableSortIcon = styled(Icon)`
  padding-left: 8px;
  padding-top: 1px;
`;

export const TableCell = styled.td<{ width?: number; bold?: boolean }>`
  align-items: center;
  display: flex;
  flex: ${({ width }) => (width ? width : 150)} 0 auto;
  padding: 16px 24px;
  text-overflow: ellipsis;
  white-space: initial;
  width: ${({ width }) => (width ? width : 150)}px;
`;

export const TableFooterCell = styled(TableCell)`
  font-weight: bold;
  color: ${({ theme }) => theme.colors.black};
`;

export const TableHeaderCell = styled(TableCell)<{ isSorted: boolean; isSortable: boolean }>`
  align-items: normal;
  color: ${(props) => (props.isSorted ? props.theme.colors.black : props.theme.colors.black50)};
  font-weight: bold;
  display: flex;
  align-items: center;
  cursor: ${(props) => (props.isSortable ? 'pointer' : 'default')};
`;
