import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { BodySmall } from '~/components/shared/typography';

function MatchCancel({ onCancel }) {
  return (
    <Container>
      <BodySmall>Not the correct patient?</BodySmall>
      <CancelLink as='a' onClick={ onCancel }> Click here to continue adding patient.</CancelLink>
    </Container>
  );
}

MatchCancel.propTypes = {
  onCancel: PropTypes.func.isRequired
};

export default MatchCancel;

const Container = styled.div`
  background-color: ${({ theme }) => theme.colors.black05};
  padding: 12px;
  text-align: center;
  width: 100%;
  border-radius: 5px;
`;

const CancelLink = styled(BodySmall)`
  color: ${({ theme }) => theme.colors.primaryBlue};
  cursor: pointer;
`;
