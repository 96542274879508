import {
  ADDITIONAL_FILTERS,
  COMANAGEMENT_TEAM,
  createProviderDropdown,
  EPISODE_TYPE_DROPDOWN,
  getComanagementTeamAcuteFilters,
  PLAN_TYPE_DROPDOWN,
} from '~/constants/filterDropdownConfigs';
import { GroupType } from '~/models';

export const renderableFilterDropdowns = (selectedGroupType: GroupType, actingClientType: string) => {
  return [
    {
      title: COMANAGEMENT_TEAM,
      filters: [createProviderDropdown(selectedGroupType), ...getComanagementTeamAcuteFilters(actingClientType)],
    },
    {
      title: ADDITIONAL_FILTERS,
      filters: [PLAN_TYPE_DROPDOWN, EPISODE_TYPE_DROPDOWN],
    },
  ];
};
