import * as Yup from 'yup';

import Status from '~/models/activities/ProgressUpdate/Status';

export function getValidationSchema(questionComponentConfig, activities) {
  const maxDate = new Date().setHours(23, 59, 59, 9999);
  const latestUpdate = questionComponentConfig.hasStatusQuestion
    ? activities.find((activity) => activity.isStatusUpdate)
    : null;

  // Formik does not currently support passing additional context to Yup's
  // validate function. Instead of instantiating Formik's initial values with
  // a fake context bag (__validationContext or the like) I opted to use JS
  // closures to hold on to the latestUpdate needed for the dynamic validation.
  return () => {
    let validationSchema = Yup.object();

    if (questionComponentConfig.hasAdmissionDateQuestion) {
      validationSchema = validationSchema.concat(
        Yup.object({
          actualAdmission: Yup.date()
            .nullable()
            .required('Actual admission date required')
            .typeError('Actual admission date required')
            .max(new Date(maxDate), 'Cannot select a future date'),
        })
      );
    }

    if (questionComponentConfig.hasStatusQuestion) {
      validationSchema = validationSchema.concat(
        Yup.object().shape({
          progressUpdate: Yup.object().when([questionComponentConfig.statusQuestion.id], {
            is: (selectedStatus) => new Status(selectedStatus).isWorseThan(latestUpdate?.status),
            then: (schema) =>
              schema.shape({
                text: Yup.string().required('A detailed description is required when there is a decline in status.'),
              }),
          }),
        })
      );
    }

    return validationSchema;
  };
}
