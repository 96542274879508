import * as _ from 'lodash-es';
import moment from 'moment';

import { simpleDate } from '~/lib/formatDate';
import LocationEpisode from '~/models/LocationEpisode';
import { DISCHARGED } from '~/services/rehabState/constants';

import { parseDob } from '../helpers/datePickerHelper';

import User from './User';

export const PATIENT = 'patient';

const defaults = {
  id: '',
  name: '',
  dateOfBirth: '',
  sex: '',
  owner: null,
  hospital: null,
  rehabFacility: null,
  payer: null,
  planTypeClassification: null,
  physicianGroup: null,
  physicianTeam: null,
  episodeClassification: null,
  episodeId: '',
  locationEpisodeId: '',
  admittedOn: null,
  externalId: null,
  note: null,
  needs: [],
  attachments: [],
  antHospitalDischarge: null,
  payor: null,
  medicare: null,
  currentRehabState: {},
  hasActiveServiceRefusals: false,
  latestLocationEpisode: null,
};

function momentize(value) {
  return !value || value instanceof moment ? value : moment(value);
}

export default class Patient {
  constructor(opts = {}) {
    const options = { ...defaults, ...opts };

    this.id = options.id;
    this.name = options.name;
    this.dateOfBirth = momentize(options.dateOfBirth);
    this.sex = options.sex;
    this.owner = options.owner;
    this.hospital = options.hospital;
    this.rehabFacility = options.rehabFacility;
    this.physicianTeam = options.physicianTeam;
    this.physicianGroup = options.physicianGroup;
    this.payer = options.payer;
    this.locationEpisodeId = options.locationEpisodeId;
    this.episodeId = options.episodeId;
    this.admittedOn = momentize(options.admittedOn);
    this.externalId = options.externalId;
    this.note = options.note;
    this.needs = options.needs;
    this.antHospitalDischarge = momentize(options.antHospitalDischarge);
    this.payor = options.payor;
    this.medicare = typeof options.medicare === 'boolean' ? options.medicare : defaults.medicare;
    this.episodeClassification = options.episodeClassification;
    this.planTypeClassification = options.planTypeClassification;
    this.currentRehabState = options.currentRehabState;
    this.hasActiveServiceRefusals =
      options.locationEpisodes?.some((le) => le.hasActiveServiceRefusals) || options.hasActiveServiceRefusals;
    this.type = PATIENT;
    this.caseManager = options.caseManager ? new User(options.caseManager) : null;
    this.latestLocationEpisode = options.latestLocationEpisode
      ? new LocationEpisode(options.latestLocationEpisode)
      : null;
  }

  static get type() {
    return PATIENT;
  }

  get discharged() {
    return this.currentRehabState.state === DISCHARGED;
  }

  toFormValues(isPayerUser = false) {
    return {
      ...this,
      dateOfBirth: {
        ...parseDob(this.dateOfBirth),
      },
      sex: this.sex ? { label: _.capitalize(this.sex), value: this.sex } : '',
      locationType: {
        label: this.rehabFacility?.groupType?.displayName,
        value: this.rehabFacility?.subType || this.rehabFacility?.locationType?.kind || '',
      },
      antHospitalDischarge: isPayerUser ? null : moment().add(1, 'days'),
    };
  }

  formatNeedsNote() {
    if (!this.externalId) return null; // currently only applies to patients created via Connect
    const needsString = this.needs.length ? `Patient Needs:\n${this.needs.join('\n')}\n\n` : '';
    const noteString = this.note?.text ? `Note:\n${this.note.text}\n\n` : '';
    const dischargeString = this.antHospitalDischarge
      ? `Anticipated Hospital Discharge:\n${simpleDate(this.antHospitalDischarge)}`
      : '';

    return needsString + noteString + dischargeString;
  }

  serialize() {
    const values = {
      id: this.id,
      name: this.name,
      dateOfBirth: this.dateOfBirth.toISOString(),
      sex: this.sex,
      admittedOn: this.admittedOn?.toISOString() || null,
      ownerId: this.owner?.id,
      hospitalId: this.hospital?.id,
      locationEpisodeId: this.locationEpisodeId,
      planTypeClassificationId: this.planTypeClassification?.id || null,
      physicianGroupId: this.physicianGroup?.id,
      rehabFacilityId: this.rehabFacility?.id,
      payerId: this.payer?.id,
      episodeClassificationId: this.episodeClassification?.id || null,
      externalId: this.externalId,
      antHospitalDischarge: this.antHospitalDischarge?.toISOString() || null,
      note: this.formatNeedsNote(),
      attachments: this.note?.attachments.map((attachment) => attachment.serialize()) || [],
      payor: this.payor,
      medicare: this.medicare,
      caseManagerId: this.caseManager?.id || null,
    };

    const physicianTeamId = this.physicianTeam?.id;

    if (physicianTeamId) {
      values.attrValueIds = [physicianTeamId];
    }

    return values;
  }

  static fromFormValues(formObj) {
    const {
      dateOfBirth: { day, month, year },
      sex,
    } = formObj;

    return new this({
      ...formObj,
      sex: sex.value,
      dateOfBirth: moment([year, month - 1, day]),
    });
  }

  // eslint-disable-next-line complexity
  static fromEpisodeData(episode = {}) {
    const patient = episode.patient;
    const rehabFacility = { ...episode.rehabInformation?.latestRehabFacility };

    rehabFacility.groupType = { displayName: episode.rehabInformation?.latestRehabFacilityType };

    if (!patient) return new this();

    return new this({
      ...episode,
      ...patient,
      rehabFacility: rehabFacility?.id ? rehabFacility : '',
      physicianTeam: episode.physicianTeam?.id ? episode.physicianTeam : '',
      physicianGroup: episode.physicianGroup?.id ? episode.physicianGroup : '',
      payer: episode.payer?.id ? episode.payer : '',
      caseManager: episode.latestLocationEpisode?.caseManager || null,
    });
  }

  static fromLocationEpisodeData(locationEpisode = {}) {
    const patient = this.fromEpisodeData(locationEpisode);

    patient.locationEpisodeId = locationEpisode.id;
    return patient;
  }
}
