import React from 'react';
import styled from 'styled-components';

import colors from '~/styles/theme/colors';

import { WarnIcon } from '../../../shared/svg';
import { Label } from '../../../shared/typography';

const EscalationHeader = () => {
  return (
    <Container data-cy='escalationHeader'>
      <WarnIcon />
      <Label color={colors.white}>Escalation</Label>
    </Container>
  );
};

export default EscalationHeader;

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 22px;
  background: ${({ theme }) => theme.colors.accentRed};
  color: ${({ theme }) => theme.colors.white};
  border-radius: 6px 6px 0 0;
  & svg {
    margin-right: 5px;
  }
`;
