import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { sortBy } from '~/helpers';

import ProgressBarState from './ProgressBarState';

function PatientProgressBar(props) {
  const { locationEpisodeRehabStates, rehabStates } = props;

  const currentRehabStatePosition = locationEpisodeRehabStates.find((lers) => lers.current).position;

  const rehabStateData = sortBy(rehabStates, 'position').map((rehabState) => {
    const locationEpisodeRehabState = locationEpisodeRehabStates.find((lers) => lers.id === rehabState.id) || {};

    return {
      ...rehabState,
      ...locationEpisodeRehabState,
      completed: locationEpisodeRehabState.position < currentRehabStatePosition,
    };
  });

  return (
    <Container>
      <ProgressBarContainer>
        {rehabStateData.map((rehabState) => (
          <ProgressBarState key={rehabState.id} {...rehabState} />
        ))}
      </ProgressBarContainer>
    </Container>
  );
}

PatientProgressBar.propTypes = {
  locationEpisodeRehabStates: PropTypes.instanceOf(Array),
  rehabStates: PropTypes.instanceOf(Array),
};

export default PatientProgressBar;

const Container = styled.div`
  padding-top: 12px;
  padding-bottom: 32px;
`;

const ProgressBarContainer = styled.div`
  display: flex;
  flex: 1;
  max-width: 600px;
  margin: 0 auto;
`;
