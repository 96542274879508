import { LocalStorageCache } from '@auth0/auth0-spa-js';

class Dummy {}

const LSC = LocalStorageCache ?? Dummy;

export const IFRAME_CLASS = 'iframe-olio-external';

class CrossDomainLocalStorageCache extends LSC {
  remove(key) {
    super.remove(key);
    const iframes = document.getElementsByClassName(IFRAME_CLASS);

    for (let i = 0; i < iframes.length; i++) {
      const iframe = iframes[i];

      if (!iframe.contentWindow) continue;
      const origin = new URL(iframe.src).origin;

      iframe.contentWindow?.postMessage({ key }, origin);
      console.log('Sent message to', origin);
      console.log({ key });
    }
  }
}

export default CrossDomainLocalStorageCache;
