import UserPreference, {
  defaults as userPreferenceDefaults,
  UserPreferenceOptions,
  UserPreferenceType,
} from '../UserPreference';

type PortfolioFilter = Record<string, any>;

type PortfolioFilters = {
  [key: string]: PortfolioFilter[] | string;
};

type PortfolioFilterUserPreferenceOptions = UserPreferenceOptions;

const defaults: PortfolioFilterUserPreferenceOptions = {
  ...userPreferenceDefaults,
  type: UserPreferenceType.PORTFOLIO_FILTER,
};

export default class PortfolioFilterUserPreference extends UserPreference {
  constructor(options: Partial<PortfolioFilterUserPreferenceOptions> = defaults) {
    const opts = { ...defaults, ...options };

    super(opts);
  }

  static get type() {
    return UserPreferenceType.PORTFOLIO_FILTER;
  }

  static serializeFilters(filters: PortfolioFilters) {
    return Object.fromEntries(
      Object.entries(filters).map(([filterKey, filterValues]) => {
        if (filterValues && Array.isArray(filterValues)) {
          filterValues.forEach((filterValue) => {
            if (filterValue.isProvider) {
              filterValue.type ||= 'Location::RehabFacility';
            }
          });
        }
        return [filterKey, filterValues];
      })
    );
  }

  serialize() {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { locationType, patientState, search, ...valuesToSave } = this.value;

    const serializedFilters = PortfolioFilterUserPreference.serializeFilters(valuesToSave);

    return {
      ...super.serialize(),
      value: serializedFilters,
    };
  }
}
