import React from 'react';
import styled from 'styled-components';

import { createColumnHelper } from '@tanstack/react-table';

import { BodySmall } from '~/components/shared/typography';
import { PAYOR, PHYSICIAN_GROUP } from '~/constants/clientTypes';
import { simpleDate } from '~/lib/formatDate';
import { LocationEpisode } from '~/models';
import { ProfileService } from '~/services/profile';
import { history } from '~/store';
import colors from '~/styles/theme/colors';

const columnHelper = createColumnHelper<LocationEpisode>();

const onPatientClick = (episodeId: string) => () => history.push(`/patients/${episodeId}`);

export default function allPatientsTableColumns(profileSvc: ProfileService) {
  let ownerColumn = {} as ReturnType<typeof columnHelper.accessor>;

  switch (profileSvc?.actingClient?.clientType) {
    case PHYSICIAN_GROUP:
      ownerColumn = columnHelper.accessor((row) => row.owner.name, {
        id: 'ownerName',
        header: 'Physician Group',
        size: 100,
      });
      break;
    case PAYOR:
      ownerColumn = columnHelper.accessor((row) => row.owner.name, {
        id: 'ownerName',
        header: 'Payer',
        size: 100,
      });
      break;
    default:
      ownerColumn = columnHelper.accessor((row) => row.hospital.name, {
        id: 'hospitalName',
        header: 'Hospital',
        size: 100,
      });
  }

  return [
    columnHelper.accessor((row) => row.patient.name, {
      id: 'patientName',
      header: 'Name',
      cell: (info) => (
        <Link fontWeight={700} onClick={onPatientClick(info.row.original.episodeId)} data-dd-action-name='Patient name'>
          {info.getValue()}
        </Link>
      ),
      size: 100,
    }),
    ownerColumn,
    columnHelper.accessor((row) => row.rehabInformation.latestRehabFacility.name, {
      id: 'rehabFacility',
      header: 'Post Acute',
      size: 100,
    }),
    columnHelper.accessor((row) => row.physicianTeam.name, {
      id: 'physicianTeamName',
      header: 'Physician',
      cell: (info) => info.getValue() || '—',
      size: 100,
    }),
    columnHelper.accessor((row) => row.planTypeClassification.name, {
      id: 'planTypeClassification',
      header: 'Plan Type',
      cell: (info) => info.getValue() || '—',
      size: 100,
    }),
    columnHelper.accessor((row) => row.episodeClassification.name, {
      id: 'episodeClassification',
      header: 'Episode Type',
      cell: (info) => info.getValue() || '—',
      size: 100,
    }),
    columnHelper.accessor((row) => row.admittedOn, {
      id: 'admittedOn',
      header: 'Hospital Admission',
      cell: (info) => simpleDate(info.getValue()) || '—',
      size: 100,
    }),
    columnHelper.accessor((row) => ({ archived: row.archived, currentRehabState: row.currentRehabState.state }), {
      id: 'currentRehabState',
      header: 'Stage',
      cell: (info) => (info.row.original.archived ? 'Canceled' : info.row.original.currentRehabState.state),
      size: 100,
    }),
    columnHelper.accessor((row) => row.lengthOfStay, {
      id: 'lengthOfStay',
      header: 'Days',
      cell: (info) => {
        const trackLos = info.row.original.trackLos;

        return trackLos ? info.getValue() : '—';
      },
      size: 100,
    }),
  ];
}

const Link = styled(BodySmall)`
  cursor: pointer;
  color: ${() => colors.primaryBlue};
`;
