import { createEntityAdapter, createSlice } from '@reduxjs/toolkit';

import { updateLocationEpisode } from '~/ducks/locationEpisode';
import { addToast } from '~/ducks/toasts';
import { createAsyncThunk } from '~/lib';
import { locationEpisodesApi } from '~/services/api';

import { API_STATES, createApiHasStatusSelector } from './api';
import { fetchActivityAttachments, updateAttachment } from './attachments';
import { updateEscalation } from './escalations';
import { createReview, updateReview } from './reviews';

const sliceName = 'activities';

export const fetchActivities = createAsyncThunk(`${sliceName}/fetch`, async (params) => {
  // TODO - remove hardcoded page size once we have converted to lazy loading in #186591841
  const modifiedParams = { ...params, pageSize: 1000 };
  const { data } = await locationEpisodesApi.fetchActivities.invoke(modifiedParams);

  return data;
});

export const createActivity = createAsyncThunk(`${sliceName}/create`, async (activity, { dispatch }) => {
  const { data } = await locationEpisodesApi.createActivity.invoke(activity).catch((e) => {
    dispatch(addToast({ text: 'There was an error creating the note. Please try again.' }));
    throw e;
  });

  return data;
});

export const updateActivity = createAsyncThunk(`${sliceName}/update`, async ({ id, ...activity }, { dispatch }) => {
  try {
    const { data } = await locationEpisodesApi.updateActivity.invoke(id, activity);

    return data;
  } catch (e) {
    dispatch(addToast({ text: 'There was an error updating the activity. Please try again.' }));
    throw e;
  }
});

const getInitialState = () => activitiesAdapter.getInitialState({ locationEpisodeId: null });

const activitiesAdapter = createEntityAdapter({
  sortComparer: (a, b) => new Date(b.createdAt) - new Date(a.createdAt),
});

const replaceAll = (state, { payload }) => {
  if (!state.locationEpisodeId || state.locationEpisodeId === payload.locationEpisodeId) {
    state.locationEpisodeId = payload.locationEpisodeId;
    activitiesAdapter.setAll(state, payload.items);
  }
};

const activitiesSlice = createSlice({
  name: sliceName,
  initialState: getInitialState(),
  reducers: {
    clearActivities: getInitialState,
  },
  extraReducers: {
    [fetchActivities.fulfilled]: replaceAll,
    [createActivity.fulfilled]: replaceAll,
    [updateActivity.fulfilled]: (state, { payload }) => {
      const { id, ...rest } = payload;

      activitiesAdapter.updateOne(state, { id, changes: { ...rest } });
    },
    [updateEscalation.fulfilled]: (state, { payload }) => {
      activitiesAdapter.updateOne(state, {
        id: payload.activity.id,
        changes: { escalation: payload },
      });
    },
    [createReview.fulfilled]: (state, { payload }) => {
      if (payload.activities.length) {
        activitiesAdapter.addMany(state, payload.activities);
      }
    },
    [updateReview.fulfilled]: (state, { payload }) => {
      if (payload.activities.length) {
        activitiesAdapter.addMany(state, payload.activities);
      }
    },
    [fetchActivityAttachments.fulfilled]: (state, { payload }) => {
      if (payload.data.length) {
        activitiesAdapter.updateOne(state, {
          id: payload.activityId,
          changes: {
            attachments: payload.data,
          },
        });
      }
    },
    [updateAttachment.fulfilled]: (state, { payload }) => {
      const { id, activityId, docType } = payload;

      const activity = state.entities[activityId];

      if (!activity) return;

      activitiesAdapter.updateOne(state, {
        id: activityId,
        changes: {
          attachments: activity.attachments?.map((attachment) => {
            if (id === attachment.id) return { ...attachment, docType: docType };

            return attachment;
          }),
        },
      });
    },
    [updateLocationEpisode.fulfilled]: (state, { payload }) => {
      const activities = payload.activitiesCreated;

      if (activities?.length) {
        activitiesAdapter.addMany(state, activities);
      }
    },
  },
});

export const { clearActivities } = activitiesSlice.actions;

const getActivitiesState = (state) => state[sliceName];

export const getActivityCreating = createApiHasStatusSelector(createActivity, [API_STATES.pending]);

export const getActivitiesLoaded = createApiHasStatusSelector(fetchActivities, [
  API_STATES.complete,
  API_STATES.failed,
]);

export const { selectAll: getActivities } = activitiesAdapter.getSelectors(getActivitiesState);

export default activitiesSlice.reducer;
