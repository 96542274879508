/* eslint-disable react/prop-types */
import React, { memo } from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import moment from 'moment';

import thumbsDown from '~/assets/images/thumbs-down.svg';
import thumbsUp from '~/assets/images/thumbs-up.svg';

// eslint-disable-next-line react/display-name
const PatientProgressChart = memo(({ patientDailyPerformance }) => {
  const scoreTarget = patientDailyPerformance.target || 0;
  const dailyPerformanceData = patientDailyPerformance.items || [];
  const dates = dailyPerformanceData.map((o) => moment(o.date).format('MM/DD'));
  const scores = dailyPerformanceData.map((o) => o.score);
  const visible = dailyPerformanceData.length > 0;

  const config = {
    chart: {
      type: 'line',
      height: '240',
      width: '370',
    },
    plotOptions: {
      areaspline: {
        fillOpacity: 0.4,
      },
    },
    credits: {
      enabled: false,
    },
    xAxis: {
      categories: dates,
      title: {
        text: 'Date',
      },
      tickLength: 0,
    },
    yAxis: {
      title: {
        text: 'Progress',
      },
      min: 0,
      allowDecimals: false,
      tickPixelInterval: 44,
      tickAmount: scoreTarget + 1,
      minorGridLineWidth: 0,
      gridLineDashStyle: 'dot',
      gridLineColor: '#A7B0B9',
      labels: {
        step: 2,
      },
    },
    series: [
      {
        data: scores,
        name: 'Progress',
        color: '#6733D0',
        marker: {
          radius: 4,
        },
      },
    ],
  };

  return (
    visible && (
      <div id='patient-progress-chart-card' className='patient-detail-card'>
        <div className='card-header chart-progress'>Patient Progress</div>
        <div className='chart-container'>
          <div>
            <HighchartsReact highcharts={Highcharts} options={config} />
          </div>
          <div className='indicators'>
            <img src={thumbsUp} className='indicator-top' />
            <img src={thumbsDown} className='indicator-bottom' />
          </div>
        </div>
      </div>
    )
  );
});

export default PatientProgressChart;
