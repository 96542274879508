import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import styled from 'styled-components';

import FilterBar from '~/components/shared/FilterBar';
import { PlusIcon } from '~/components/shared/svg';
import { LOCATION_TYPE, PATIENT_STATE, PatientState } from '~/constants/filterKeysConstants';
import { clearFilters, removeFilter, setFilters } from '~/ducks/portfolioFilters';
import { ALL_FLAGS } from '~/models';
import { PatientBoardRequestService } from '~/services/PatientBoardRequestService';
import { useProfileContext } from '~/services/profile';
import { colors } from '~/styles/theme';

import { createProviderDropdown } from '../../../constants/filterDropdownConfigs';
import PortfolioFilterUserPreference from '../../../models/userPreferences/PortfolioFilterUserPreference';
import { Button } from '../../shared/buttons';
import FilterMultiSelect from '../../shared/FilterMultiSelect';

import { renderableFilterDropdowns } from './renderableFilterDropdowns';

function PatientFilterBar(props) {
  const profileSvc = useProfileContext();
  const hasImportImprovements = profileSvc.hasFlag(ALL_FLAGS.importImprovements);
  const addPatient = () => props.history.push('/patients/new');

  const tabProviderTypeFilter = useMemo(() => {
    const tabProviderType = profileSvc.enabledProviderTypes.find(
      (providerType) =>
        // Don't show provider filter type on the continued tab
        providerType.apiName === props.filters[LOCATION_TYPE] && props.filters[PATIENT_STATE] !== PatientState.CONTINUED
    );

    return tabProviderType && createProviderDropdown(tabProviderType, { include: 'groupType' });
  }, [profileSvc.enabledProviderTypes, props.filters]);

  const filterBarFilterSections = useMemo(
    () =>
      renderableFilterDropdowns(
        profileSvc.actingClient.clientType,
        tabProviderTypeFilter,
        profileSvc.hasFlag(ALL_FLAGS.caseManagerAssignment)
      ),
    [profileSvc.actingClient.clientType, tabProviderTypeFilter]
  );

  const quickFilter = useMemo(() => {
    return (
      props.filters[PATIENT_STATE] !== PatientState.CONTINUED &&
      tabProviderTypeFilter && (
        <FilterMultiSelect
          {...tabProviderTypeFilter}
          onChange={(val) => applyFilters({ ...props.filters, [tabProviderTypeFilter.name]: val })}
          value={props.filters[tabProviderTypeFilter.name] || []}
        />
      )
    );
  }, [tabProviderTypeFilter, props.filters[PATIENT_STATE]]);

  const callToAction = useMemo(
    () =>
      profileSvc.canCreatePatient && (
        <Button
          data-cy='addPatient'
          text='New Patient'
          icon={<PlusIcon size={14} fill={colors.white} />}
          onClick={addPatient}
        />
      ),
    [profileSvc.canCreatePatient]
  );

  const applyFilters = (filters) => {
    const serializedFilters = PortfolioFilterUserPreference.serializeFilters(filters);

    props.setFilters(serializedFilters);
  };

  const fetchCountThunk = useMemo(
    () => new PatientBoardRequestService({ hasImportImprovements }).requestAllLanesThunk,
    []
  );

  const filterFormatter = (filters) => ({ filters: filters });

  const parseCount = (data) =>
    Object.values(data)
      .map((data) => data.meta.totalRecords)
      .reduce((acc, totalRecords) => acc + totalRecords, 0);

  return (
    <StyledFilterBar
      title='Patient Portfolio'
      filterSections={filterBarFilterSections}
      filters={props.filters}
      applyFilters={applyFilters}
      onClearFilters={props.clearFilters}
      fetchCountThunk={fetchCountThunk}
      filterFormatter={filterFormatter}
      parseCount={parseCount}
      quickFilter={quickFilter}
      callToAction={callToAction}
    />
  );
}

PatientFilterBar.propTypes = {
  clearFilters: PropTypes.func.isRequired,
  filters: PropTypes.instanceOf(Object).isRequired,
  history: PropTypes.instanceOf(Object).isRequired,
  removeFilter: PropTypes.func.isRequired,
  setFilters: PropTypes.func.isRequired,
};

const mapDispatchToProps = {
  clearFilters,
  removeFilter,
  setFilters,
};

export default connect(null, mapDispatchToProps)(PatientFilterBar);

const StyledFilterBar = styled(FilterBar)`
  border-bottom: none;
`;
