import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Button, ButtonGroup } from '~/components/shared/buttons';
import { ConfirmationList } from '~/components/shared/ConfirmationList';
import { FormHeader } from '~/components/shared/form';
import { FormPageContentContainer } from '~/components/shared/pageLayout';
import { InfoIconOutline } from '~/components/shared/svg';
import { PatientMatchFoundIcon } from '~/components/shared/svg';
import { BodySmall, Label } from '~/components/shared/typography';
import { capitalize } from '~/helpers/capitalize';
import { simpleDate } from '~/lib/formatDate';
import { ImportedPatient, Patient } from '~/models';
import { ProfileService } from '~/services/profile';

import MatchCancel from './MatchCancel';
import MatchNotice from './MatchNotice';

function MatchConfirm(props) {
  const { goToStep, matchedPatient, onComplete, patient } = props;

  const [confirmed, setConfirmed] = useState(false);

  const matchedPatientData = useMemo(
    () => [
      { label: 'Name', value: patient.name },
      { label: 'Date of Birth', value: simpleDate(patient.dateOfBirth) },
      { label: 'Gender', value: capitalize(patient.sex) },
      { label: 'Hosp. Admission Date', value: simpleDate(patient.admittedOn) },
      { label: 'Physician Group', value: patient.physicianGroup?.name },
      { label: 'Hospital', value: patient.hospital?.name },
      { label: 'Physician', value: patient.physicianTeam?.name },
      { label: 'Post Acute', value: patient.rehabFacility?.name },
      { label: 'Plan Type', value: patient.planTypeClassification?.name },
      { label: 'Episode Type', value: patient.episodeClassification?.name },
    ],
    [patient]
  );

  const handleMatchConfirm = () => {
    setConfirmed(true);
    const mergedPatient = new Patient({
      ...patient,
      locationEpisodeId: matchedPatient.locationEpisodeId,
    });

    onComplete(mergedPatient, { manualNavigation: true, setConfirmed });
  };

  const handleMatchCancel = () => onComplete(null, { manualNavigation: true });

  const handleBack = () => goToStep(1);

  return (
    <FormPageContentContainer>
      <FormHeader title='Review Patient Details' />
      <MatchNotice clientName={matchedPatient.owner?.clientName} />
      {matchedPatient.discharged && (
        <DischargedNotificationContainer>
          <InfoIconOutline />
          <BodySmall margin='0 0 0 8px'>This patient is currently discharged in Olio</BodySmall>
        </DischargedNotificationContainer>
      )}

      <NameComparisonContainer>
        <PatientMatchFoundIcon size={16} />
        <Label margin='0 0 0 8px'>Olio matched on a patient record named {matchedPatient.name}</Label>
      </NameComparisonContainer>
      <ConfirmationListContainer>
        <ConfirmationList items={matchedPatientData} />
      </ConfirmationListContainer>
      <MatchCancel onCancel={handleMatchCancel} />
      <StyledButtonGroup>
        <Button color='transparent' onClick={handleBack} text='Back' />
        <Button onClick={handleMatchConfirm} data-cy='confirmMatch' text='Confirm Match' loading={confirmed} />
      </StyledButtonGroup>
    </FormPageContentContainer>
  );
}

MatchConfirm.propTypes = {
  goToStep: PropTypes.func.isRequired,
  matchedPatient: PropTypes.instanceOf(Patient),
  onComplete: PropTypes.func.isRequired,
  patient: PropTypes.oneOfType([PropTypes.instanceOf(Patient), PropTypes.instanceOf(ImportedPatient)]),
  profileSvc: PropTypes.instanceOf(ProfileService).isRequired,
};

export default MatchConfirm;

const NameComparisonContainer = styled.div`
  display: flex;
  align-items: center;
  margin-top: 40px;
  margin-bottom: 8px;
  width: 100%;
`;

const ConfirmationListContainer = styled.div`
  width: 100%;
  margin-bottom: 24px;
`;

const StyledButtonGroup = styled(ButtonGroup)`
  margin-top: 40px;
  margin-bottom: 40px;
`;

const DischargedNotificationContainer = styled.div`
  width: 100%;
  background-color: ${({ theme }) => theme.colors.black05};
  border-radius: 5px;
  padding: 12px;
  margin-top: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
`;
