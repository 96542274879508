import {
  ACCEPTED,
  ALTCS_APPLICATION,
  AUTHORIZATION,
  PENDING,
  PROJECTED_DISCHARGE,
  SERVICE_REFUSAL,
} from '~/constants/reviews';

import { Attr, AttrValue } from './Attr';

interface IReview {
  readonly attrValues: AttrValue[];
  readonly active: boolean;
  readonly isAuthorization: boolean;
  readonly isServiceRefusal: boolean;
  readonly isAltcsApplication: boolean;
  readonly pending: boolean;
  readonly accepted: boolean;
}

interface IReviewOptions {
  id: string;
  createdAt: string | null;
  createdBy: string | null;
  data: Record<string, unknown>;
  locationEpisodeId: string;
  reviewedBy: string | null;
  status: string;
  type: string;
  attrs?: Attr[];
}

export const defaults: IReviewOptions = {
  id: '',
  createdAt: null,
  createdBy: null,
  data: {},
  locationEpisodeId: '',
  reviewedBy: null,
  status: PENDING,
  type: '',
};

export default class Review implements IReview, IReviewOptions {
  id: string;
  createdAt: string | null;
  createdBy: string | null;
  data: Record<string, unknown>;
  locationEpisodeId: string;
  reviewedBy: string | null;
  status: string;
  type: string;
  attrs?: Attr[];
  constructor(options: IReviewOptions) {
    const opts = { ...defaults, ...options };

    this.id = opts.id;
    this.createdAt = opts.createdAt;
    this.createdBy = opts.createdBy;
    this.data = opts.data;
    this.locationEpisodeId = opts.locationEpisodeId;
    this.reviewedBy = opts.reviewedBy;
    this.status = opts.status;
    this.type = opts.type;
    this.createdAt = opts.createdAt;
    this.attrs = opts.attrs?.map((attr) => new Attr(attr));
  }

  get active() {
    return this.pending;
  }

  get attrValues() {
    return this.attrs?.flatMap((attr) => attr?.attrValues || []) ?? [];
  }

  get isAuthorization() {
    return this.type === AUTHORIZATION;
  }

  get isProjectedDischarge() {
    return this.type === PROJECTED_DISCHARGE;
  }

  get isServiceRefusal() {
    return this.type === SERVICE_REFUSAL;
  }

  get isAltcsApplication() {
    return this.type === ALTCS_APPLICATION;
  }

  get pending() {
    return this.status === PENDING;
  }

  get accepted() {
    return this.status === ACCEPTED;
  }
}
