import { DISCHARGE } from '../../constants/questionTemplateTypes';
import QuestionTemplate from '../QuestionTemplate';

const defaults = {
  type: DISCHARGE
};

export default class DischargeTemplate extends QuestionTemplate {
  constructor(options =  {}) {
    super({ ...defaults, ...options });
  }
}
