import { AUTHORIZATION_UPDATES } from '~/constants/activities';

import FieldChanges from './FieldChanges';

const defaults = {
  type: AUTHORIZATION_UPDATES,
};

export default class AuthorizationUpdates extends FieldChanges {
  constructor(options = {}) {
    super({ ...defaults, ...options });
  }

  get typeLabel() {
    return 'Authorization Details';
  }
}
