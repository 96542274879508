import React from 'react';
import * as _ from 'lodash-es';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { BodySmall } from '~/components/shared/typography';
import { FieldChanges } from '~/models/activities';

import { SectionContainer } from '../shared';

const fieldLabels = {
  ['admitted_on']: 'Hospital Admission Date',
  ['affiliate_id']: 'Affiliate',
  ['case_manager_id']: 'Case Manager',
  ['episode_classification_id']: 'Episode Type',
  ['home_health_agency_id']: 'Home Health Agency',
  ['hospital_id']: 'Hospital',
  ['inpatient_rehab_facility_id']: 'Inpatient Rehab Facility',
  ['location_id']: 'Post Acute',
  ['long_term_acute_care_hospital_id']: 'Long Term Acute Care Hospital',
  ['outpatient_therapy_provider_id']: 'Outpatient Therapy Provider',
  ['payer_classification_id']: 'Plan Type',
  ['plan_type_classification_id']: 'Plan Type',
  ['payer_id']: 'Payer',
  ['physician_group_id']: 'Physician Group',
  ['physician_team_id']: 'Physician Team',
  ['rehab_facility_id']: 'Post Acute',
  ['skilled_nursing_facility_id']: 'Skilled Nursing Facility',
  ['date']: 'Projected Discharge Date',
};

function FieldChangesContent({ activity }) {
  const { changes } = new FieldChanges(activity);

  if (!Array.isArray(changes)) return null;

  const changesText = changes.map((change) => {
    return (
      `${fieldLabels[change.prop] || _.startCase(change.prop)} updated from ` +
      `${change.previous || '—'} to ${change.current || '—'}`
    );
  });

  return (
    <SectionContainer>
      {changesText.length === 1 ? (
        changesText[0]
      ) : (
        <List>
          {changesText.map((change, i) => (
            <li key={i}>
              <BodySmall>{change}</BodySmall>
            </li>
          ))}
        </List>
      )}
    </SectionContainer>
  );
}

FieldChangesContent.propTypes = {
  activity: PropTypes.instanceOf(FieldChanges).isRequired,
};

export default FieldChangesContent;

const List = styled.ul`
  list-style: inside;
  margin: 0px;
  padding-left: 0px;
`;
