import React, { Fragment, useEffect, useState } from 'react';
import styled from 'styled-components';

import { CircleCheckIcon } from '~/components/shared/svg';
import { Label } from '~/components/shared/typography';
import { colors } from '~/styles/theme';

import { ACKNOWLEDGED, NOTE_ADDED } from '../constants';
import { useTaskModalContext } from '../TaskModalContext';

const MESSAGES = {
  [ACKNOWLEDGED]: 'Acknowledged and notification sent to {locationName}',
  [NOTE_ADDED]: 'Notification sent to {locationName}'
};

let notificationTimer, visibilityTimer = null;

function Notification() {
  const [visible, setVisible] = useState(true);

  const {
    displayNotificationType: type,
    escalationType,
    selectedEscalation,
    setDisplayNotificationType
  } = useTaskModalContext();

  const patientLocationName = escalationType === 'default' ?
    selectedEscalation.episode.rehabInformation.latestRehabFacility.name :
    selectedEscalation.episode.owner.name;
  const message = MESSAGES[type]?.replace('{locationName}', patientLocationName);

  useEffect(() => clearIntervals, []);

  const clearIntervals = () => {
    clearInterval(notificationTimer);
    clearInterval(visibilityTimer);
    notificationTimer = null;
    visibilityTimer = null;
  };

  useEffect(() => {
    if (type) {
      setVisible(true);

      visibilityTimer = setTimeout(() => {
        setVisible(false);
      }, 2000);

      notificationTimer = setTimeout(() => {
        setDisplayNotificationType(null);
      }, 3000);
    }
  }, [type]);

  return (
    <Fragment>
      { type &&
        <Container className={ visible ? '' : 'hidden' }>
          <StyledCircleCheckIcon fill={ colors.accentGreen } size={ 16 } />
          <Label>{ message }</Label>
        </Container>
      }
    </Fragment>
  );
}

export default Notification;

const Container = styled.div`
  align-items: center;
  background-color: ${({ theme }) => theme.colors.accentGreen10 };
  border-radius: 3px;
  opacity: 1;
  display: flex;
  padding: 8px 16px;
  width: fit-content;
  margin-bottom: 16px;

  &.hidden {
    opacity: 0;
    transition: opacity 1s ease-out;
  }
`;

const StyledCircleCheckIcon = styled(CircleCheckIcon)`
  margin-right: 16px;
`;
