import { LOCATION_TYPE, PATIENT_STATE, PatientState } from '~/constants/filterKeysConstants';
import LocationEpisodesParamsBuilder from '~/services/LocationEpisodesParamsBuilder';
import cancellableRequestFactory from '~/sources/api/cancellableRequestFactory';
import locationEpisodesAPI from '~/sources/api/locationEpisodesAPI';

class PatientTabFiltersService {
  constructor({
    filterOptions,
    hasImportImprovements = false,
    requestFn = locationEpisodesAPI.fetchLocationEpisodes,
    isPostAcuteClient,
  }) {
    this.filterOptions = filterOptions;
    this.hasImportImprovements = hasImportImprovements;
    this.requestFns = this.setRequestFns(requestFn);
    this.isPostAcuteClient = isPostAcuteClient;
  }

  setRequestFns(baseRequestFn) {
    return this.filterOptions.map(() => {
      return cancellableRequestFactory(baseRequestFn);
    });
  }

  async requestAllCounts({ url, filters = {} } = {}) {
    const newCountResponses = await Promise.all(
      this.requestFns.map((fn, index) => {
        const { filterType, value } = this.filterOptions[index];

        const updatedFilters = { [filterType]: value };

        switch (filterType) {
          case PATIENT_STATE:
            updatedFilters[LOCATION_TYPE] = null;
            break;
          case LOCATION_TYPE:
            updatedFilters[PATIENT_STATE] = this.isPostAcuteClient ? PatientState.CURRENT : null;
            break;
        }

        const requestFilters = { ...filters, ...updatedFilters };

        const params = new LocationEpisodesParamsBuilder({
          filters: requestFilters,
          active: true,
          pageSize: 1,
          hasImportImprovements: this.hasImportImprovements,
        }).build();

        return fn(url, params);
      })
    );

    return newCountResponses.map((res) => res.data.meta.totalRecords);
  }
}

export default PatientTabFiltersService;
