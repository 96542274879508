import React, { useMemo } from 'react';
import { Form, Formik } from 'formik';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

import { Button, ButtonGroup } from '~/components/shared/buttons';
import { STATUS } from '~/constants/activities';
import { ProgressUpdate } from '~/models';

import { formHelpers, QuestionList } from '../../../shared/QuestionForm';
import { usePatientProfileContext } from '../../PatientProfileContext';

import { getValidationSchema } from './progressUpdateValidationSchema';

function ProgressUpdateForm(props) {
  const initialFormValues = { actualAdmission: null, progressUpdate: new ProgressUpdate() };

  const { activities, locationEpisode, progressTemplate } = usePatientProfileContext();

  const questionComponentConfig = useMemo(
    () => formHelpers.questionsToComponentConfig(progressTemplate.questions, locationEpisode),
    [progressTemplate, locationEpisode]
  );

  const validationSchema = useMemo(() => {
    const validationSchemaFn = getValidationSchema(questionComponentConfig, activities);

    return validationSchemaFn();
  }, [questionComponentConfig, activities]);

  const hasUnansweredQuestions = (values) => !formHelpers.allQuestionsAnswered(progressTemplate.questions, values);

  const handleSubmit = (values) => {
    const questions = formHelpers.buildAnsweredQuestionsFromFormValues(progressTemplate.questions, values);
    const admittedAt = values.actualAdmission || null;
    const statusUpdateAnswer = questions.find((answer) => answer.kind === STATUS)?.answer?.text;

    let text,
      plaintext,
      mentions = null;
    let attachments = [];

    let escalated = false;

    if (statusUpdateAnswer) {
      attachments = values.progressUpdate.attachments;
      text = values.progressUpdate.text;
      plaintext = values.progressUpdate.plaintext;
      mentions = values.progressUpdate.mentions;

      if (statusUpdateAnswer.match(/no/i) && values.progressUpdate.escalated) {
        escalated = values.progressUpdate.escalated;
      }
    }

    props.onSave({ attachments, questions, admittedAt, text, plaintext, mentions, escalated });
  };

  const isDisabled = (values, status, isValid, isSubmitting) =>
    hasUnansweredQuestions(values) || !isValid || isSubmitting || status.uploadingAttachments;

  return (
    <Formik
      initialValues={initialFormValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
      initialStatus={{ uploadingAttachments: false }}>
      {({ values, isSubmitting, isValid, status }) => (
        <StyledForm>
          <QuestionList locationEpisode={locationEpisode} questionComponentConfig={questionComponentConfig} />
          <Actions>
            <Button color='transparent' onClick={props.onCancel} text='Cancel' />
            <Button
              type='submit'
              data-cy='submitProgressUpdate'
              disabled={isDisabled(values, status, isValid, isSubmitting)}
              text='Submit'
            />
          </Actions>
        </StyledForm>
      )}
    </Formik>
  );
}

ProgressUpdateForm.propTypes = {
  onCancel: PropTypes.func,
  onSave: PropTypes.func,
};

export default ProgressUpdateForm;

const layoutColumnFull = css`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

const StyledForm = styled(Form)`
  color: #0f1226;
  font-size: 16px;
  line-height: 1.5;
  ${layoutColumnFull};
`;

const Actions = styled(ButtonGroup)`
  justify-content: flex-end;
  margin-top: 24px;
`;
