import React, { useCallback, useMemo, useState } from 'react';
import { Formik } from 'formik';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components';

import * as Sentry from '@sentry/react';

import { Button, ButtonGroup } from '~/components/shared/buttons';
import { Form, FormHeader } from '~/components/shared/form';
import { AsyncModal } from '~/components/shared/modal';
import { XIcon } from '~/components/shared/svg';
import { deleteEpisode } from '~/ducks/episode';
import { getLatestPatientsRoot } from '~/ducks/navigation';
import { addToast } from '~/ducks/toasts';
import { unwrapResult } from '~/lib';
import { ImportedPatient, Patient } from '~/models';
import { PERMISSIONS, ProfileService } from '~/services/profile';

import ConfirmPatientRemovalModal from './ConfirmPatientRemovalModal';
import EpisodeInfoSection from './EpisodeInfoSection';
import { intakeValidation } from './IntakeValidation';
import PatientInfoSection from './PatientInfoSection';
import PostAcuteInfoSection from './PostAcuteInfoSection';

const IntakeForm = (props) => {
  const {
    history,
    match: {
      params: { id },
    },
    latestPatientsRoot,
    onAbort,
    onComplete,
    patient,
    profileSvc,
  } = props;

  const [submitting, setSubmitting] = useState(false);
  const editing = !!id;
  const connecting = patient instanceof ImportedPatient;
  const title = useMemo(() => {
    if (connecting) return 'Connect';
    return editing ? 'Edit' : 'New';
  }, [patient]);

  const subtitle = profileSvc.isAcute && patient.externalId ? `ID: ${patient.externalId}` : null;
  const submitText = useMemo(() => (connecting ? 'Next' : 'Review Patient Details'), [patient]);
  const initialValues = useMemo(() => patient.toFormValues(profileSvc.isPayerUser), [patient]);
  const canRemovePatient = profileSvc.has(PERMISSIONS.patientDelete);
  const handleSubmit = useCallback((formValues) => {
    formValues.note = null;
    onComplete(patient.constructor.fromFormValues(formValues), { manualNavigation: true, setSubmitting });
  }, []);

  const handleCancel = useCallback(() => onAbort(), []);

  const handleRemovePatient = useCallback(async () => {
    const confirm = await new AsyncModal(ConfirmPatientRemovalModal, { name: patient.name }).render();

    if (confirm) {
      try {
        await props.deleteEpisode(patient.episodeId).then(unwrapResult);

        props.addToast({ text: `${patient.name} has been successfully removed.` });
        history.replace(`/${latestPatientsRoot || 'patients'}`);
      } catch (e) {
        props.addToast({ text: 'Something went wrong. Please try again.' });

        Sentry.captureException(e);
      }
    }
  });

  return (
    <React.Fragment>
      {editing && !connecting && canRemovePatient && (
        <RemovePatientButton text='Remove Patient' color='accentWhite' icon={<XIcon />} onClick={handleRemovePatient} />
      )}
      <Formik
        initialValues={initialValues}
        initialStatus={{ connecting, editing }}
        validationSchema={intakeValidation}
        onSubmit={handleSubmit}
        enableReinitialize
        validateOnMount>
        {({ isValid }) => (
          <Form>
            <FormHeader title={`${title} Patient`} subtitle={subtitle} />
            <PatientInfoSection disabled={profileSvc.isPostAcute} />
            <EpisodeInfoSection isPostAcuteUser={profileSvc.isPostAcute} />
            <PostAcuteInfoSection isAcute={profileSvc.isAcute} connecting={connecting} />
            <ButtonGroup>
              <Button color='transparent' onClick={handleCancel} text='Cancel' />
              <Button
                type='submit'
                disabled={!isValid}
                data-cy='reviewPatientDetails'
                text={submitText}
                loading={submitting}
              />
            </ButtonGroup>
          </Form>
        )}
      </Formik>
    </React.Fragment>
  );
};

IntakeForm.propTypes = {
  addToast: PropTypes.func.isRequired,
  deleteEpisode: PropTypes.func.isRequired,
  latestPatientsRoot: PropTypes.string,
  onAbort: PropTypes.func,
  onComplete: PropTypes.func,
  patient: PropTypes.instanceOf(Patient).isRequired,
  profileSvc: PropTypes.instanceOf(ProfileService).isRequired,
};

const mapStateToProps = (state) => ({
  latestPatientsRoot: getLatestPatientsRoot(state),
});

const mapDispatchToProps = {
  addToast,
  deleteEpisode,
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(IntakeForm));

const RemovePatientButton = styled(Button)`
  position: absolute;
  top: 24px;
  right: 24px;
`;
