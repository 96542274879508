import React, { useCallback } from 'react';
import { Form, Formik } from 'formik';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import styled from 'styled-components';

import ActivityInput from '~/components/shared/ActivityInput';
import { suggestionsListStyles } from '~/components/shared/form/TaggableTextarea';
import { createActivity } from '~/ducks/activities';
import { unwrapResult } from '~/lib';
import { Note } from '~/models';
import { PERMISSIONS, useProfileContext } from '~/services/profile';
import { colors } from '~/styles/theme';

import { activityValidationSchema } from '../../ActivityInput/activityValidationSchema';
import { NOTE_ADDED } from '../constants';
import { useTaskModalContext } from '../TaskModalContext';

import Notification from './Notification';

const PORTAL_HOST_ID = 'suggestionsPortalHost';

function Footer(props) {
  const profileSvc = useProfileContext();
  const { escalationType, selectedEscalation, setDisplayNotificationType, hasAcknowledgePermission, onAcknowledge } =
    useTaskModalContext();
  const placeholderText = `Adding a reply to this ${
    escalationType === 'default' ? 'escalation' : 'priority'
  } will also acknowledge it...`;
  const userCanCreateNote = profileSvc.has(PERMISSIONS.activityNoteCreate);

  const handleSubmit = useCallback(
    (values, { resetForm, setSubmitting }) => {
      const serializedActivity = new Note({
        ...values.note,
        locationEpisodeId: selectedEscalation.locationEpisode.id,
      }).serialize();

      props
        .createActivity({ ...serializedActivity, include: 'attachments.blob' })
        .then(unwrapResult)
        .then(() => {
          if (selectedEscalation.acknowledged) {
            setDisplayNotificationType(NOTE_ADDED);
          } else {
            onAcknowledge();
          }

          resetForm({ errors: { text: true } });
        })
        /* eslint-disable-next-line no-unused-vars */
        .catch((e) => {
          /* Prevent unhandled error; toast alert is done in createActivity thunk */
        })
        .finally(() => {
          setSubmitting(false);
        });
    },
    [selectedEscalation]
  );

  return (
    <Container id={PORTAL_HOST_ID}>
      <Notification />
      {userCanCreateNote && hasAcknowledgePermission && (
        <Formik
          initialValues={{ note: new Note() }}
          onSubmit={handleSubmit}
          enableReinitialize
          validationSchema={activityValidationSchema}
          validateOnMount>
          <Form>
            <ActivityInput
              portalHostId={PORTAL_HOST_ID}
              escalationType={escalationType}
              locationEpisodeId={selectedEscalation.locationEpisode.id}
              placeholder={placeholderText}
              isTaggable
              customStyles={{
                borderColor: colors.black25,
                textarea: {
                  minHeight: '50px',
                  maxHeight: '150px',
                },
                button: {
                  fontSize: '12px',
                  padding: '4px 16px',
                },
              }}
              canSubmit
            />
          </Form>
        </Formik>
      )}
    </Container>
  );
}

Footer.propTypes = {
  createActivity: PropTypes.func.isRequired,
};

const mapDispatchToProps = { createActivity };

export default connect(null, mapDispatchToProps)(Footer);

const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  margin: auto 24px 24px 24px;

  > div {
    ${suggestionsListStyles};
  }
`;
