import React from 'react';
import PropTypes from 'prop-types';

import { DatePicker, InputGroup } from '~/components/shared/form';

function AdmittedOnInputGroup({ disabled }) {
  return (
    <InputGroup
      component={DatePicker}
      label='Hospital Admission Date (optional)'
      maxDate={new Date()}
      name='admittedOn'
      data-cy='admittedOn'
      disabled={disabled}
    />
  );
}

AdmittedOnInputGroup.propTypes = {
  disabled: PropTypes.bool,
};

export default AdmittedOnInputGroup;
