import { FilterMultiSelectFilter } from '~/components/shared/FilterMultiSelect/FilterMultiSelect';
import {
  ADDITIONAL_FILTERS,
  CASE_MANAGER_DROPDOWN,
  COMANAGEMENT_TEAM,
  createProviderDropdown,
  EPISODE_TYPE_DROPDOWN,
  getComanagementTeamAcuteFilters,
  LATEST_REHAB_STATE_DROPDOWN,
  PLAN_TYPE_DROPDOWN,
} from '~/constants/filterDropdownConfigs';
import { GroupType } from '~/models';

export const renderableFilterDropdowns = (
  actingClientType: string,
  enabledProviderTypes: GroupType[],
  hasCaseManagerFlag: boolean
) => {
  const groupTypeFilters = enabledProviderTypes.map((enabledProviderType: GroupType) =>
    createProviderDropdown(enabledProviderType)
  );

  const additionalFilters: FilterMultiSelectFilter[] = [
    PLAN_TYPE_DROPDOWN,
    EPISODE_TYPE_DROPDOWN,
    LATEST_REHAB_STATE_DROPDOWN,
    ...(hasCaseManagerFlag ? [CASE_MANAGER_DROPDOWN] : []),
  ];

  return [
    {
      title: COMANAGEMENT_TEAM,
      filters: [...groupTypeFilters, ...getComanagementTeamAcuteFilters(actingClientType)],
    },
    {
      title: ADDITIONAL_FILTERS,
      filters: additionalFilters,
    },
  ];
};
