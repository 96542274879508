import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { ClipboardIcon, PayerIcon } from '~/components/shared/svg';
import { BodySmall, BodySmallBold, LabelBold } from '~/components/shared/typography';
import { LocationEpisode } from '~/models';
import colors from '~/styles/theme/colors';

function ClassificationInfo({ locationEpisode }) {
  return (
    <Container data-dd-privacy='allow'>
      <Title>PLAN TYPE + EPISODE INFORMATION</Title>
      <Row>
        <Column>
          <PayerIcon />
          <BodySmall margin='0 0 0 10px'>Plan Type</BodySmall>
        </Column>
        <Column>
          <BodySmallBold>{locationEpisode.planTypeClassification?.name || '—'}</BodySmallBold>
        </Column>
      </Row>
      <Row>
        <Column>
          <ClipboardIcon />
          <BodySmall margin='0 0 0 10px'>Episode Type</BodySmall>
        </Column>
        <Column>
          <BodySmallBold>{locationEpisode.episodeClassification?.name || '—'}</BodySmallBold>
        </Column>
      </Row>
    </Container>
  );
}

ClassificationInfo.propTypes = {
  locationEpisode: PropTypes.instanceOf(LocationEpisode).isRequired,
};

export default ClassificationInfo;

const Container = styled.div`
  border-top: 1px solid ${colors.black10};
`;

const Title = styled(LabelBold)`
  color: ${colors.black50};
  font-weight: 800;
  padding: 24px;
`;

const Row = styled.div`
  display: flex;
  align-items: baseline;
  padding: 0 24px 16px 24px;

  &:last-of-type {
    padding: 0px 24px 24px 24px;
  }
`;

const Column = styled.div`
  display: flex;
  flex-direction: row;
  flex-basis: 100%;
  flex: 1;
  align-items: center;
`;
