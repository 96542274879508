import { createSelector, createSlice } from '@reduxjs/toolkit';

import {
  ALL_OPTION,
  END_DATE,
  EPISODE_CLASSIFICATIONS,
  HOME_HEALTH_AGENCY,
  HOSPITALS,
  LOCATION_TYPE,
  PAYERS,
  PHYSICIAN_GROUP,
  PLAN_TYPE_CLASSIFICATIONS,
  SKILLED_NURSING_FACILITY,
  START_DATE,
} from '~/constants/filterKeysConstants';

export const sliceName = 'insightsV1/selectedFilters';

const now = new Date();
const thirtyDaysAgo = new Date(new Date().setDate(now.getDate() - 30));

export const initialState = {
  [EPISODE_CLASSIFICATIONS]: ALL_OPTION,
  [HOME_HEALTH_AGENCY]: ALL_OPTION,
  [HOSPITALS]: ALL_OPTION,
  [PAYERS]: ALL_OPTION,
  [PLAN_TYPE_CLASSIFICATIONS]: ALL_OPTION,
  [PHYSICIAN_GROUP]: ALL_OPTION,
  [LOCATION_TYPE]: null,
  [SKILLED_NURSING_FACILITY]: ALL_OPTION,
  [START_DATE]: thirtyDaysAgo,
  [END_DATE]: now,
};

const slice = createSlice({
  name: sliceName,
  initialState,
  reducers: {
    selectFilter: (state, { payload }) => {
      state[payload.name] = payload.value;
    },
    selectFilters: (state, { payload }) => ({ ...state, ...payload }),
  },
});

export const getSelectedFilters = (state) => state[sliceName];
export const getSelectedLocationTypeFilter = createSelector(
  getSelectedFilters,
  (selectedFilters) => selectedFilters[LOCATION_TYPE]
);
export const getSelectedPostAcuteFilter = createSelector(
  getSelectedFilters,
  getSelectedLocationTypeFilter,
  (selectedFilters, selectedLocationTypeFilter) => selectedFilters[selectedLocationTypeFilter?.filterKey] || ALL_OPTION
);

export const { selectFilter, selectFilters } = slice.actions;

export default slice.reducer;
