import React from 'react';

export default function FilterIcon({ fill = 'currentColor', ...rest }: React.SVGProps<SVGSVGElement>) {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='12' height='10' fill='none' {...rest}>
      <g fill={fill} clipPath='url(#a)'>
        <path d='M8.25 9.114h-4.5c-.414 0-.75-.321-.75-.716 0-.395.336-.716.75-.716h4.5c.414 0 .75.32.75.716 0 .395-.336.716-.75.716ZM9.75 5.295h-7.5c-.414 0-.75-.32-.75-.715 0-.396.336-.716.75-.716h7.5c.414 0 .75.32.75.716 0 .395-.336.715-.75.715ZM11.25 1.477H.75c-.414 0-.75-.32-.75-.716C0 .366.336.045.75.045h10.5c.414 0 .75.321.75.716 0 .396-.336.716-.75.716Z' />
      </g>
      <defs>
        <clipPath id='a'>
          <path fill='#fff' d='M0 0h12v10H0z' />
        </clipPath>
      </defs>
    </svg>
  );
}
