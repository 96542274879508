import React, { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import FilterBar from '~/components/shared/FilterBar';
import FilterChips from '~/components/shared/FilterChips';
import SimplePage from '~/components/shared/pageLayout/SimplePage';
import { ControlledTable } from '~/components/shared/table';
import { CASE_MANAGER, SEARCH } from '~/constants/filterKeysConstants';
import { fetchAllPatients } from '~/ducks/allPatients';
import {
  clearFilters,
  formatFilters,
  getFilters,
  getFiltersForRequest,
  getFiltersOrderedForUser,
  removeFilter,
  setFilters,
} from '~/ducks/patientFilters';
import { useThunk } from '~/lib/hooks';
import { ALL_FLAGS } from '~/models';
import { useProfileContext } from '~/services/profile';

import allPatientsTableColumns from './allPatientsTableColumns';
import { renderableFilterDropdowns } from './renderableFilterDropdowns';

function AllPatients(props) {
  const { patientFilters, patientFiltersMap } = props;

  const profileSvc = useProfileContext();

  const [pagingFilters, setPagingFilters] = useState({
    page: 1,
    pageSize: 10,
    sortBy: 'patientName asc',
  });
  const [tableProps, setTableProps] = useState({
    setSorting: () => {},
  });
  const [locationEpisodesMeta, setLocationEpisodesMeta] = useState({ totalPages: 0, totalRecords: 0 });

  const handlePagingFiltersChange = useCallback((newPagingFilters) => {
    setPagingFilters(newPagingFilters);
  }, []);

  // Order does matter here. This dictates the joins in the backend
  // query and it was slower when ordered differently.
  const INCLUDES = [
    'episode',
    'current_rehab_state',
    'hospital',
    'patient',
    'episode_classification',
    'plan_type_classification',
    'owner',
    'physician_team',
    'rehab_information',
    'progress_templates',
  ];

  const { data, loaded } = useThunk(fetchAllPatients, [patientFilters, pagingFilters], {
    onSuccess: (payload) => {
      setLocationEpisodesMeta({
        totalPages: payload?.meta?.totalPages,
        totalRecords: payload?.meta?.totalRecords,
      });
    },
    params: {
      include: INCLUDES.join(','),
      ...pagingFilters,
      ...patientFilters,
    },
  });

  const onSortChange = useCallback(
    (sortBy) => {
      const newSortBy = sortBy
        .map(({ id, desc }) => {
          let sortTerm = `${id} ${desc ? 'desc' : 'asc'}`;

          if (id === 'lengthOfStay') {
            sortTerm = 'trackLos desc,' + sortTerm;
          }

          return sortTerm;
        })
        .join(',');

      setPagingFilters({ ...pagingFilters, sortBy: newSortBy });
    },
    [pagingFilters]
  );

  const defaultSortBy = useMemo(() => [{ id: 'patientName', desc: false }], []);
  const columns = useMemo(() => allPatientsTableColumns(profileSvc), [profileSvc.actingClient]);

  const searchValue = patientFilters[SEARCH];

  useEffect(() => {
    const sortBy = searchValue ? [{ id: SEARCH, desc: true }] : defaultSortBy;

    tableProps.setSorting(sortBy);
  }, [searchValue]);

  const filterBarFilterSections = useMemo(
    () =>
      renderableFilterDropdowns(
        profileSvc.actingClient.clientType,
        profileSvc.enabledProviderTypes,
        profileSvc.hasFlag(ALL_FLAGS.caseManagerAssignment)
      ),
    [profileSvc.actingClient.clientType, profileSvc.enabledProviderTypes]
  );

  const getChipLabel = (filterType, filterValue) => {
    if (filterType === CASE_MANAGER) {
      return filterValue.fullName;
    }
  };

  return (
    <SimplePage>
      <SimplePage.Header>
        <FilterBar
          title='All Patients'
          filterSections={filterBarFilterSections}
          filters={patientFiltersMap}
          onClearFilters={props.clearFilters}
          applyFilters={props.setFilters}
          fetchCountThunk={fetchAllPatients}
          filterFormatter={formatFilters}
        />
      </SimplePage.Header>
      <SimplePage.SubHeader>
        <FilterChips
          filters={props.orderedFilters}
          onClickRemoveFilter={props.removeFilter}
          getChipLabel={getChipLabel}
        />
        <p data-cy='allPatientsTotal'>{locationEpisodesMeta.totalRecords} patients found</p>
      </SimplePage.SubHeader>
      <SimplePage.Content>
        <ControlledTable
          data={data}
          filters={patientFilters}
          defaultSortBy={searchValue ? 'search desc' : 'patientName asc'}
          columns={columns}
          loading={!loaded}
          onInitialize={setTableProps}
          onSortChange={onSortChange}
          pageCount={locationEpisodesMeta.totalPages}
          onPagingFiltersChange={handlePagingFiltersChange}
        />
      </SimplePage.Content>
    </SimplePage>
  );
}

AllPatients.propTypes = {
  clearFilters: PropTypes.func.isRequired,
  orderedFilters: PropTypes.instanceOf(Object),
  patientFilters: PropTypes.instanceOf(Object),
  patientFiltersMap: PropTypes.instanceOf(Object),
  removeFilter: PropTypes.func.isRequired,
  setFilters: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  patientFilters: getFiltersForRequest(state),
  patientFiltersMap: getFilters(state),
  orderedFilters: getFiltersOrderedForUser(state),
});

const mapDispatchToProps = {
  clearFilters,
  setFilters,
  removeFilter,
};

export default connect(mapStateToProps, mapDispatchToProps)(AllPatients);
