import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

function NeedItem({ need }) {
  return (
    <Item>{ need }</Item>
  );
}

const Item = styled.div`
  margin-bottom: .5em;
`;

NeedItem.propTypes = {
  need: PropTypes.string.isRequired
};

export default NeedItem;
