import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { EditIcon } from '~/components/shared/svg';
import { BodySmall, H2Bold } from '~/components/shared/typography';
import { simpleDate } from '~/lib/formatDate';
import { Episode, LocationEpisode } from '~/models';
import { useProfileContext } from '~/services/profile';

import { CollapsibleContent, CollapsibleTrigger, useCollapsible } from '../../../shared/Collapsible';
import ExportDownload from '../ExportDownload';

import PatientActions from './PatientActions';
import PatientInfoItem from './PatientInfoItem';
import ProgressBar from './ProgressBar';

function PatientInfoBar(props) {
  const { collapsed, toggleCollapse } = useCollapsible();

  const {
    rehabStates,
    editPatient,
    episode,
    locationEpisode,
    patientDischargedViaModal,
    showEscalationModal,
    showDischargeModal,
  } = props;

  const profileSvc = useProfileContext();
  const episodeId = episode.id;
  const name = episode.patient.name;
  const dob = episode.patient.dateOfBirth;
  const episodeDate = episode.admittedOn;
  const sex = episode.patient.sex;
  const formattedSex = sex.charAt(0).toUpperCase() + sex.slice(1);
  const currentRehabState = episode.currentRehabState.state;
  const externalId = episode.patient.externalId;
  const latestLocationEpisode = episode.locationEpisodes.find((le) => le.latest);

  return (
    <PatientInfoBarContainer>
      <FlexContainer>
        <PatientInfo>
          <FlexRow>
            <H2Bold data-dd-privacy='allow' data-dd-action-name='Patient name'>
              {name}
            </H2Bold>
            {profileSvc.isAcute && externalId && (
              <StyledBodySmall data-dd-privacy='allow'>ID: {externalId}</StyledBodySmall>
            )}
            <StyledBodySmall data-dd-action-name='Date of birth'>DOB: {simpleDate(dob)}</StyledBodySmall>
            {!episode.archived && !latestLocationEpisode?.archived && profileSvc.canEditPatient(episode) && (
              <ActionIcon data-cy='editPatient' onClick={editPatient}>
                <EditIcon />
              </ActionIcon>
            )}
            {!episode.archived && (
              <ActionIcon>
                <ExportDownload episodeId={episodeId} />
              </ActionIcon>
            )}
            <TriggerContainer>
              <CollapsibleTrigger data-gtm-id='expandPatientHeader' onClick={toggleCollapse}>
                {collapsed ? 'Expand' : 'Collapse'}
              </CollapsibleTrigger>
            </TriggerContainer>
          </FlexRow>
        </PatientInfo>

        <PatientActions
          locationEpisode={locationEpisode}
          episode={episode}
          currentRehabState={currentRehabState}
          patientDischargedViaModal={patientDischargedViaModal}
          showEscalationModal={showEscalationModal}
          showDischargeModal={showDischargeModal}
        />
      </FlexContainer>

      <CollapsibleContent collapsed={collapsed}>
        <FlexContainer>
          <CollapsiblePatientInfo>
            <PatientCollapsibleInfoItem label='Hospital Admission' value={simpleDate(episodeDate) || '—'} />
            <PatientCollapsibleInfoItem label='Gender' value={formattedSex} />
          </CollapsiblePatientInfo>
        </FlexContainer>

        <ProgressBar locationEpisodeRehabStates={episode.rehabStates} rehabStates={rehabStates} />
      </CollapsibleContent>
    </PatientInfoBarContainer>
  );
}

PatientInfoBar.propTypes = {
  editPatient: PropTypes.func.isRequired,
  episode: PropTypes.instanceOf(Episode),
  locationEpisode: PropTypes.instanceOf(LocationEpisode),
  patientDischargedViaModal: PropTypes.bool.isRequired,
  rehabStates: PropTypes.instanceOf(Array),
  showDischargeModal: PropTypes.func.isRequired,
  showEscalationModal: PropTypes.func.isRequired,
};

export default PatientInfoBar;

const PatientInfoBarContainer = styled.div`
  padding-left: 16px;
  padding-right: 16px;
  padding-bottom: 18px;
  margin-top: 24px;
  max-width: 1072px;
`;

const FlexContainer = styled.div`
  display: flex;
  align-items: center;
`;

const FlexRow = styled(FlexContainer)`
  width: 100%;
`;

const PatientInfo = styled(FlexContainer)`
  flex-wrap: wrap;
`;

const StyledBodySmall = styled(BodySmall)`
  color: ${({ theme }) => theme.colors.black75};
  margin-left: 16px;
  padding-top: 4px;
`;

const CollapsiblePatientInfo = styled(PatientInfo)`
  width: 60%;
`;

const PatientCollapsibleInfoItem = styled(PatientInfoItem)`
  margin-top: 12px;
`;

const TriggerContainer = styled.div`
  margin-left: 12px;
`;

const ActionIcon = styled.div`
  margin-left: 6px;
  cursor: pointer;
`;
