import React from 'react';

function SuccessfulMatchFolderIcon() {
  return (
    <svg width='40px' height='37px' viewBox='0 0 40 37' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlnsXlink='http://www.w3.org/1999/xlink'>
      <title>Shape</title>
      <g id='match-on-add-/-connect' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
        <g id='4---success' transform='translate(-657.000000, -156.000000)' fill='#3253EF' fillRule='nonzero'>
          <path d='M696.130435,162.956522 L676.613043,162.956522 L672.52,156.408696 C672.361097,156.154451 672.082426,156 671.782609,156 L657.869565,156 C657.389318,156 657,156.389318 657,156.869565 L657,188.173913 C657.002875,190.573959 658.94778,192.518864 661.347826,192.521739 L692.652174,192.521739 C695.05222,192.518864 696.997125,190.573959 697,188.173913 L697,163.826087 C697,163.345839 696.610682,162.956522 696.130435,162.956522 Z M682.217391,167.304348 C684.138382,167.304348 685.695652,168.861618 685.695652,170.782609 C685.695652,172.703599 684.138382,174.26087 682.217391,174.26087 C680.296401,174.26087 678.73913,172.703599 678.73913,170.782609 C678.73913,168.861618 680.296401,167.304348 682.217391,167.304348 Z M671.782609,170.782609 C673.703599,170.782609 675.26087,172.339879 675.26087,174.26087 C675.26087,176.18186 673.703599,177.73913 671.782609,177.73913 C669.861618,177.73913 668.304348,176.18186 668.304348,174.26087 C668.304348,172.339879 669.861618,170.782609 671.782609,170.782609 Z M678.73913,187.304348 L664.826087,187.304348 L664.826087,186.434783 C664.826087,182.592802 667.940628,179.478261 671.782609,179.478261 C675.62459,179.478261 678.73913,182.592802 678.73913,186.434783 L678.73913,187.304348 Z M689.173913,183.826087 L680.077391,183.826087 C679.455382,181.87112 678.164843,180.197606 676.432174,179.09913 C678.133421,176.551488 681.30101,175.416607 684.233046,176.304241 C687.165081,177.191876 689.171349,179.893073 689.173913,182.956522 L689.173913,183.826087 Z' id='Shape' />
        </g>
      </g>
    </svg>
  );
}

export default SuccessfulMatchFolderIcon;
