import React from 'react';

function PayerIcon() {
  return (
    <svg width='15px' height='12px' viewBox='0 0 15 12' version='1.1' xmlns='http://www.w3.org/2000/svg'>
      <title>58AC9A97-9667-4896-A18C-922C7EF211E0</title>
      <g id='info-sections-with-icons' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd' strokeLinecap='round' strokeLinejoin='round'>
        <g id='long-values-wrapping' transform='translate(-223.000000, -530.000000)' stroke='#3253EF'>
          <g id='money-11' transform='translate(224.000000, 531.000000)'>
            <rect id='Rectangle' x='0' y='0' width='13' height='9.53333333' rx='1' />
            <circle id='Oval' cx='6.5' cy='4.76666667' r='2.16666667' />
          </g>
        </g>
      </g>
    </svg>
  );
}

export default PayerIcon;
