import React, { Fragment } from 'react';
import { useFormikContext } from 'formik';
import PropTypes from 'prop-types';

import { InputGroup, Select } from '~/components/shared/form';
import { EPISODE, PLAN_TYPE } from '~/constants/classifications';
import { fetchClassifications } from '~/ducks/classifications';
import { getId, getName } from '~/helpers';
import { useAsyncOptions } from '~/lib/hooks';

function ClassificationSection({ disabled }) {
  const {
    values: { owner },
  } = useFormikContext();

  const episodeFieldName = 'episodeClassification';
  const planTypeFieldName = 'planTypeClassification';

  const episodesAsyncOptions = useAsyncOptions(fetchClassifications, {
    condition: Boolean(owner),
    params: {
      client: owner?.clientId,
      type: EPISODE,
    },
  });

  const planTypesAsyncOptions = useAsyncOptions(fetchClassifications, {
    condition: Boolean(owner),
    params: {
      client: owner?.clientId,
      type: PLAN_TYPE,
    },
  });

  const commonProps = {
    autoSelectSingleOption: false,
    component: Select,
    disabled: disabled || !owner,
    getOptionLabel: getName,
    getOptionValue: getId,
    isClearable: true,
  };

  return (
    <Fragment>
      <InputGroup
        {...commonProps}
        {...planTypesAsyncOptions}
        data-cy={planTypeFieldName}
        label='Plan Type (optional)'
        name={planTypeFieldName}
        placeholder='Plan Type'
      />
      <InputGroup
        {...commonProps}
        {...episodesAsyncOptions}
        data-cy={episodeFieldName}
        label='Episode Type (optional)'
        name={episodeFieldName}
        placeholder='Episode'
      />
    </Fragment>
  );
}

ClassificationSection.propTypes = {
  disabled: PropTypes.bool,
};

export default ClassificationSection;
