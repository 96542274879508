import { isCancel } from 'axios';
import moment from 'moment';

import { DATES, FLAGGED_OLIO_ATTRS } from '~/constants/filterKeysConstants';
import insightsAPI from '~/sources/api/insightsAPI';

const useNameAsValue = (filter: any) => FLAGGED_OLIO_ATTRS.includes(filter);

export default class InsightsAnalyticsRequestService {
  static serializeFilters = (filters: any) =>
    Object.entries(filters).reduce((acc, [filterKey, filterValue]) => {
      if (!filterValue) return acc;

      if (Array.isArray(filterValue)) {
        if (!filterValue.length) {
          return acc;
        }
        if (useNameAsValue(filterKey)) {
          return { ...acc, [filterKey]: filterValue.map((value) => value.name) };
        }
        if (filterKey !== DATES) {
          return { ...acc, [filterKey]: filterValue.map((value) => value.id) };
        }
      }

      return { ...acc, [filterKey]: filterValue };
    }, {});

  static requestData = async (params: any, filters: any) => {
    const requestParams = {
      ...params,
      ...this.serializeFilters(filters),
    };

    const formattedRequestParams = {
      ...requestParams,
      [DATES]: requestParams[DATES].map(({ start, end }: { start: Date; end: Date }) => ({
        start: moment(start).format('YYYY-MM-DD'),
        end: moment(end).format('YYYY-MM-DD'),
      })),
    };

    try {
      const res = await insightsAPI.fetchAnalytics(formattedRequestParams);
      const { data } = res.data;

      return data;
    } catch (e) {
      if (isCancel(e)) return null;
      throw e;
    }
  };
}
