import React from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { CircleCheckIcon } from '~/components/shared/svg';
import { Label } from '~/components/shared/typography';
import { simpleDate, simpleDateAtTime } from '~/lib/formatDate';
import Escalation from '~/models/Escalation';
import { colors } from '~/styles/theme';

function EscalationAcknowledgement({ escalation }) {
  const { acknowledgedAt, acknowledgedBy } = escalation;
  const escalationColor = escalation.isPriorityNote ? colors.black : colors.white;
  const acknowledgedText = `Acknowledged by ${acknowledgedBy} on ${simpleDateAtTime(acknowledgedAt)}`;

  return (
    <EscalationActionContainer>
      <CircleCheckIcon fill={escalationColor} size={12} />
      <AcknowledgedLabel color={escalationColor}>{acknowledgedText}</AcknowledgedLabel>
    </EscalationActionContainer>
  );
}

EscalationAcknowledgement.propTypes = {
  escalation: PropTypes.instanceOf(Escalation),
};

export default EscalationAcknowledgement;

const EscalationActionContainer = styled.div`
  display: flex;
  align-items: center;
`;

const AcknowledgedLabel = styled(Label)`
  margin-left: 6px;
`;
