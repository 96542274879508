import React from 'react';
import PropTypes from 'prop-types';

import { DischargeQuestions } from '~/models';

import QuestionsContent from './QuestionsContent';

function DischargeQuestionsContent({ activity }) {
  return <QuestionsContent activity={activity} expand />;
}

DischargeQuestionsContent.propTypes = {
  activity: PropTypes.instanceOf(DischargeQuestions),
};

export default DischargeQuestionsContent;
