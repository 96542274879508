import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import ActivityInput from '~/components/shared/ActivityInput';
import { colors } from '~/styles/theme';

import NeedsList from './NeedsList';

const NeedsAndDocuments = (props) => {
  const { onUploading } = props;

  return (
    <FormContainer>
      <NeedsContainer>
        <NeedsList name='needs' />
        <ActivityInput
          name='note'
          label='Notes (optional)'
          customStyles={{ borderColor: colors.black25 }}
          onUploading={onUploading}
          placeholder='Start typing here to add a note...'
          showEscalationToggle={false}
        />
      </NeedsContainer>
    </FormContainer>
  );
};

NeedsAndDocuments.propTypes = {
  onUploading: PropTypes.func,
};

export default NeedsAndDocuments;

const NeedsContainer = styled.div`
  display: flex;
  flex-flow: row wrap;
  margin-bottom: 8px;
  width: 100%;
`;

const FormContainer = styled.div`
  width: 100%;
  box-sizing: border-box;
  margin-bottom: 24px;
`;
