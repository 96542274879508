import axios from 'axios';

const cancellableRequestFactory = (requestFn) => {
  let controller;

  return (url, params = {}) => {
    if (controller) {
      controller.abort();
    }

    controller = new AbortController();
    return requestFn(url, { params: params, signal: controller.signal }).catch((error) => {
      if (!axios.isCancel(error)) {
        throw error;
      } else {
        return { then: () => {}, catch: () => {}, finally: () => {} };
      }
    });
  };
};

export default cancellableRequestFactory;
