import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import {
  AffiliateIcon,
  BedIcon,
  HospitalIcon,
  PayerIcon,
  PhysicianGroupIcon,
  PhysicianIcon,
  StarIcon,
} from '~/components/shared/svg';
import { BodySmall, BodySmallBold, LabelBold } from '~/components/shared/typography';
import { ACUTE_LOCATION_TYPES, AFFILIATE, HOSPITAL, PAYER, PHYSICIAN_GROUP } from '~/constants/locationTypes';
import { Episode } from '~/models';
import colors from '~/styles/theme/colors';

function PatientTeamInfo({ episode, postAcuteName, rehabFacilityAcronym }) {
  const getGroupIcon = (type) => {
    switch (type) {
      case HOSPITAL:
        return <HospitalIcon />;
      case PAYER:
        return <PayerIcon />;
      case AFFILIATE:
        return <AffiliateIcon />;
      case PHYSICIAN_GROUP:
        return <PhysicianGroupIcon />;
      default:
        return null;
    }
  };

  const locationTypeOrderPriority = useMemo(() => {
    return [...new Set([episode.owner.locationType.kind, HOSPITAL, PHYSICIAN_GROUP, PAYER, AFFILIATE])];
  }, [episode.owner]);

  const nonOwningAcuteGroups = useMemo(() => {
    return episode.episodeGroups
      .filter(
        (group) =>
          group.location.id !== episode.owner.id && ACUTE_LOCATION_TYPES.includes(group.location.locationType.kind)
      )
      .sort(
        (a, b) =>
          locationTypeOrderPriority.indexOf(a.location.locationType.kind) -
          locationTypeOrderPriority.indexOf(b.location.locationType.kind)
      );
  }, [episode.episodeGroups, episode.owner]);

  return (
    <Container data-dd-privacy='allow'>
      <Title>CO-MANAGEMENT TEAM</Title>
      <Row>
        <Column>
          <BedIcon />
          <BodySmall data-cy='postAcuteLabel' margin='0 0 0 10px'>
            {rehabFacilityAcronym}
          </BodySmall>
        </Column>
        <Column>
          <BodySmallBold data-cy='postAcuteValue'>{postAcuteName}</BodySmallBold>
        </Column>
      </Row>

      <Row>
        <Column>
          {getGroupIcon(episode.owner.locationType.kind)}
          <BodySmall margin='0 0 0 10px' data-cy='ownerLabel'>
            {episode.owner.locationType.name}
          </BodySmall>
        </Column>
        <Column>
          <BodySmallBold margin='0 8px 0 0' data-cy='ownerValue'>
            {episode.owner.name}
          </BodySmallBold>
          <div>
            <StarIcon />
          </div>
        </Column>
      </Row>

      {nonOwningAcuteGroups.map((episodeGroup) => {
        const locationType = episodeGroup.location.locationType.kind;

        return (
          <Row key={episodeGroup.id}>
            <Column>
              {getGroupIcon(locationType)}
              <BodySmall margin='0 0 0 10px' data-cy={`${locationType}Label`}>
                {episodeGroup.location.locationType.name}
              </BodySmall>
            </Column>
            <Column>
              <BodySmallBold data-cy={`${locationType}Value`}>{episodeGroup.location.name}</BodySmallBold>
            </Column>
          </Row>
        );
      })}

      <Row>
        <Column>
          <PhysicianIcon />
          <BodySmall margin='0 0 0 10px'>Physician</BodySmall>
        </Column>
        <Column>
          <BodySmallBold>{episode.physicianTeam?.name || '—'}</BodySmallBold>
        </Column>
      </Row>
    </Container>
  );
}

PatientTeamInfo.propTypes = {
  episode: PropTypes.instanceOf(Episode).isRequired,
  postAcuteName: PropTypes.string.isRequired,
  rehabFacilityAcronym: PropTypes.string.isRequired,
};

export default PatientTeamInfo;

const Container = styled.div`
  border-top: 1px solid ${colors.black10};
`;

const Title = styled(LabelBold)`
  color: ${colors.black50};
  padding: 24px;
  font-weight: 800;
`;

const Row = styled.div`
  display: flex;
  align-items: baseline;
  padding: 0 24px 16px 24px;

  &:last-of-type {
    padding: 0px 24px 24px 24px;
  }
`;

const Column = styled.div`
  display: flex;
  flex-direction: row;
  flex-basis: 100%;
  flex: 1;
  align-items: center;
`;
