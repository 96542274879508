import * as Yup from 'yup';

import { ACUTE_CLIENT_TYPES } from '~/constants/clientTypes';
import { AFFILIATE, PHYSICIAN_TEAM } from '~/constants/locationTypes';

export default () =>
  Yup.lazy(({ client, locationType }) => {
    const isAcuteClient = ACUTE_CLIENT_TYPES.includes(client?.clientType);

    return Yup.object().shape({
      name: Yup.string().required('Location name required'),
      address:
        locationType?.kind === PHYSICIAN_TEAM || locationType?.kind === AFFILIATE
          ? null
          : Yup.object().shape({
              streetAddress: Yup.string().required('Street address required'),
              streetAddressLine2: Yup.string().nullable(),
              city: Yup.string().required('City is required'),
              state: Yup.object()
                .shape({ value: Yup.string() })
                .test('state', 'State is required', ({ value }) => !!value),
              zip: Yup.string().required('Zip code is required'),
            }),
      client: Yup.object()
        .shape({
          id: Yup.string(),
          name: Yup.string(),
        })
        .test('client', 'Client is required', ({ id }) => !!id),
      locationType: Yup.object()
        .shape({
          name: Yup.string(),
          kind: Yup.string(),
        })
        .test('locationType', 'Group Type is required', ({ kind }) => !isAcuteClient || (isAcuteClient && !!kind)),
      groupType: Yup.object()
        .shape({
          id: Yup.string(),
          displayName: Yup.string(),
        })
        .test('groupType', 'Group Type is required', ({ id }) => isAcuteClient || (!isAcuteClient && !!id)),
      timezone: Yup.object()
        .shape({
          value: Yup.string(),
        })
        .test('timezone', 'Timezone is required', ({ value }) => !!value),
    });
  });
