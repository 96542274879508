import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import styled from 'styled-components';

import { Button, ButtonGroup } from '~/components/shared/buttons';
import StandardModal from '~/components/shared/modal/StandardModal';
import { Body } from '~/components/shared/typography';
import { clearEscalationUpdateError, getEscalationError } from '~/ducks/escalations';

function EscalationAcknowledgementErrorModal({ escalationError, ...props }) {
  const [showModal, setShowModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const closeModal = () => setShowModal(false);

  useEffect(() => {
    const prevAckedError = escalationError?.response?.data?.errors?.acknowledgedById;

    if (prevAckedError) {
      const [{ message: previouslyAcknowledgedEscalationErrorMessage }] = prevAckedError;

      setErrorMessage(previouslyAcknowledgedEscalationErrorMessage);
      setShowModal(true);
      props.clearEscalationUpdateError();
    }
  }, [escalationError]);

  return (
    <StandardModal onCancel={closeModal} title='Acknowledgement' show={showModal} disableBackdropClick>
      <Content>
        <Paragraph>{errorMessage}</Paragraph>
      </Content>

      <Actions>
        <Button onClick={closeModal} text='Close' />
      </Actions>
    </StandardModal>
  );
}

const mapStateToProps = (state) => ({
  escalationError: getEscalationError(state),
});

const mapDispatchToProps = {
  clearEscalationUpdateError,
};

EscalationAcknowledgementErrorModal.propTypes = {
  clearEscalationUpdateError: PropTypes.func,
  escalationError: PropTypes.string,
};

export default connect(mapStateToProps, mapDispatchToProps)(EscalationAcknowledgementErrorModal);

const Content = styled.div`
  margin: 26px 0 50px;
  text-align: center;
`;

const Paragraph = styled(Body)`
  display: block;
`;

const Actions = styled(ButtonGroup)`
  justify-content: flex-end;
`;
