import {
  ActivityAltcsApplicationStatusUpdateIcon,
  ActivityAuthorizationUpdatesIcon,
  ActivityDischargeQuestionsIcon,
  ActivityEscalationIcon,
  ActivityNoteIcon,
  ActivityPatientFieldChangeIcon,
  ActivityPatientMatchIcon,
  ActivityProgressUpdateIcon,
  ActivityProjectedDischargeUpdatesIcon,
  ActivityRefuseServiceIcon,
  ActivityStateChangeIcon,
} from '~/components/shared/svg';
import {
  ALTCS_APPLICATION_STATUS_UPDATE,
  AUTHORIZATION_UPDATES,
  DISCHARGE_QUESTIONS,
  PATIENT_FIELD_CHANGES,
  PATIENT_MATCH_COLLABORATION,
  PROGRESS_UPDATE,
  PROJECTED_DISCHARGE_UPDATES,
  REHAB_STATE_CHANGE,
  SERVICE_REFUSAL,
} from '~/constants/activities';
import { HOSPITAL, PAYER, PHYSICIAN_GROUP, PHYSICIAN_TEAM } from '~/constants/locationTypes';
import { colors } from '~/styles/theme';

import { DischargeQuestionsContent, FieldChangesContent, NoteContent, ProgressUpdateContent } from './contents';
import { PatientMatchCollaborationHeader, RehabStateChangeHeader, StandardActivityHeader } from './headers';

const componentMap = {
  [AUTHORIZATION_UPDATES]: {
    content: FieldChangesContent,
  },
  [DISCHARGE_QUESTIONS]: {
    content: DischargeQuestionsContent,
  },
  [PATIENT_FIELD_CHANGES]: {
    content: FieldChangesContent,
  },
  [PATIENT_MATCH_COLLABORATION]: {
    content: null,
    header: PatientMatchCollaborationHeader,
  },
  [PROGRESS_UPDATE]: {
    content: ProgressUpdateContent,
  },
  [PROJECTED_DISCHARGE_UPDATES]: {
    content: FieldChangesContent,
  },
  [REHAB_STATE_CHANGE]: {
    content: null,
    header: RehabStateChangeHeader,
  },
};

class ActivityCardService {
  constructor(activity) {
    this.activity = activity;
    this.components = componentMap[this.activity.type];
  }

  get headerComponent() {
    return this.components?.header || StandardActivityHeader;
  }

  get contentComponent() {
    const contentComponent = this.components?.content;

    return typeof contentComponent === 'undefined' ? NoteContent : contentComponent;
  }

  get borderColor() {
    const locationType = this.activity.createdBy?.locationType;

    if (this.activity.createdByGroupType?.isProvider) {
      return colors.accentGreen;
    }

    switch (locationType) {
      case PHYSICIAN_TEAM:
      case HOSPITAL:
      case PHYSICIAN_GROUP:
      case PAYER:
        return colors.pictonBlue;
      default:
        return colors.black10;
    }
  }

  get icon() {
    switch (this.activity.type) {
      case ALTCS_APPLICATION_STATUS_UPDATE:
        return ActivityAltcsApplicationStatusUpdateIcon;
      case AUTHORIZATION_UPDATES:
        return ActivityAuthorizationUpdatesIcon;
      case DISCHARGE_QUESTIONS:
        return ActivityDischargeQuestionsIcon;
      case PATIENT_FIELD_CHANGES:
        return ActivityPatientFieldChangeIcon;
      case PATIENT_MATCH_COLLABORATION:
        return ActivityPatientMatchIcon;
      case PROGRESS_UPDATE:
        return ActivityProgressUpdateIcon;
      case PROJECTED_DISCHARGE_UPDATES:
        return ActivityProjectedDischargeUpdatesIcon;
      case REHAB_STATE_CHANGE:
        return ActivityStateChangeIcon;
      case SERVICE_REFUSAL:
        return ActivityRefuseServiceIcon;
      default:
        if (this.activity.escalation?.isDefault) {
          return ActivityEscalationIcon;
        }
        return ActivityNoteIcon;
    }
  }

  get showBorder() {
    return !(this.activity.isStateChange || this.activity.isPatientMatchCollaboration);
  }
}

export default ActivityCardService;
