import React, { useCallback, useMemo } from 'react';
import { Form, Formik } from 'formik';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import styled from 'styled-components';

import ActivityInput from '~/components/shared/ActivityInput';
import { activityValidationSchema } from '~/components/shared/ActivityInput/activityValidationSchema';
import { AsyncModal } from '~/components/shared/modal';
import { createActivity } from '~/ducks/activities';
import { unwrapResult } from '~/lib';
import { Escalation, Note } from '~/models';
import { useProfileContext } from '~/services/profile';

import ConfirmEscalationModal from './ConfirmEscalationModal';

function NewActivity({ locationEpisodeId, locationId, mainPageScrollTop, ...props }) {
  const profileSvc = useProfileContext();
  const escalationType = useMemo(() => Escalation.typeForUser(profileSvc.profile), [profileSvc.profile]);

  const showEscalationToggle = useMemo(() => {
    return profileSvc.canEscalate(locationId) || profileSvc.canAddPriorityNote;
  }, [profileSvc.profile, locationId]);

  const handleSubmit = useCallback(
    async (values, { resetForm, setSubmitting }) => {
      const serializedActivity = new Note({
        ...values.note,
        locationEpisodeId,
      }).serialize();

      if (serializedActivity.escalated && serializedActivity.escalation.isDefault) {
        const confirmed = await new AsyncModal(ConfirmEscalationModal).render();

        if (!confirmed) {
          setSubmitting(false);
          return;
        }
      }

      try {
        await props.createActivity({ ...serializedActivity, include: 'attachments.blob' }).then(unwrapResult);

        resetForm({ errors: { text: true } });
      } finally {
        setSubmitting(false);
      }
    },
    [locationEpisodeId]
  );

  return (
    <Container>
      <Formik
        initialValues={{ note: new Note() }}
        onSubmit={handleSubmit}
        enableReinitialize
        validationSchema={activityValidationSchema}>
        <Form>
          <ActivityInput
            escalationType={escalationType}
            locationEpisodeId={locationEpisodeId}
            placeholder='Start typing here to leave a note...'
            isTaggable
            canSubmit
            showEscalationToggle={showEscalationToggle}
            parentScrollTop={mainPageScrollTop}
          />
        </Form>
      </Formik>
    </Container>
  );
}

NewActivity.propTypes = {
  createActivity: PropTypes.func.isRequired,
  locationEpisodeId: PropTypes.string.isRequired,
  locationId: PropTypes.string.isRequired,
  mainPageScrollTop: PropTypes.number,
};

const mapDispatchToProps = { createActivity };

export default connect(null, mapDispatchToProps)(NewActivity);

const Container = styled.div`
  margin-bottom: 40px;
  box-sizing: border-box;
  border-radius: 4px;
`;
