import React from 'react';
import PropTypes, { InferProps } from 'prop-types';
import OutsideClickHandler from 'react-outside-click-handler';
import styled from 'styled-components';

function LaneSortFlyoutMenuItems({ children, data, onHideFlyoutMenu }: Props) {
  return (
    <OutsideClickHandler onOutsideClick={ onHideFlyoutMenu }>
      <MenuItems onMouseLeave={ onHideFlyoutMenu }>
        {
          React.Children.map(children, (child) => child && React.cloneElement(child, {
            onClick: () => {
              child.props.onClick?.(data);
              onHideFlyoutMenu();
            }
          }))
        }
      </MenuItems>
    </OutsideClickHandler>
  );
}

LaneSortFlyoutMenuItems.propTypes = {
  children: PropTypes.arrayOf(PropTypes.element),
  data: PropTypes.instanceOf(Object),
  onHideFlyoutMenu: PropTypes.func.isRequired
};

type Props = InferProps<typeof LaneSortFlyoutMenuItems.propTypes>;

export default LaneSortFlyoutMenuItems;

const MenuItems = styled.div`
  background-color: ${({ theme }) => theme.colors.white };
  border: 1px solid ${({ theme }) => theme.colors.black10 };
  border-radius: 5px;
  box-shadow: 0px 6px 16px -8px ${({ theme }) => theme.colors.black25 };
  margin-top: 16px;
  right: 8px;
  position: absolute;
  text-align: left;
  width: max-content;
  z-index: 100;

  & > * {
    padding: 12px;
  }

  & > *:first-child {
    margin-top: 0px;
    border-radius: 5px 5px 0 0;
  }

  & > *:last-child {
    margin-bottom: 0px;
    border-radius: 0 0 5px 5px;
  }
`;
