import React, { useEffect } from 'react';

import { iframes } from '~/helpers';

function External() {
  useEffect(() => {
    const messageHandler = (message: MessageEvent) => {
      const allowed = iframes.filter((domain) => new URL(domain).origin !== window.origin);

      if (!allowed?.includes(message.origin)) return;

      const key = message.data.key;

      if (!key) return;
      console.log('Got message from', message.origin);
      console.log({ key });
      localStorage.removeItem(key);
    };

    window.addEventListener('message', messageHandler);
    return () => {
      window.removeEventListener('message', messageHandler);
    };
  }, []);
  return <></>;
}

export default External;
