import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import colors from '~/styles/theme/colors';

const TAG_COMPONENTS_REGEX = /\{\{(.+?) - (.+)\|\|(.+)\}\}/;

function TaggedUser({ tag }) {
  // Can do const [, name, location, id] in the future as needed
  const [, name] = tag.match(TAG_COMPONENTS_REGEX);

  return <UserTag>@{name}</UserTag>;
}

TaggedUser.propTypes = {
  tag: PropTypes.string.isRequired,
};

export default TaggedUser;

const UserTag = styled.span`
  background-color: ${colors.primaryBlue10};
  border-radius: 3px;
  cursor: default;
  font-weight: 700;
  padding: 2px;
  color: ${colors.primaryBlue};
`;
