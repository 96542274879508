import React from 'react';
import PropTypes, { InferProps } from 'prop-types';
import styled from 'styled-components';

import { simpleDate } from '~/lib/formatDate';
import { Episode } from '~/models';

function ArchivedWarningBanner({ episode }: Props) {
  const archivedByText = episode.archivedBy?.name && ` by ${episode.archivedBy.name}`;
  const archivedByLocationText = episode.archivedByLocation?.name && ` at ${episode.archivedByLocation.name}`;

  return (
    <Banner>
      {episode.patient.name} was archived in Olio on {simpleDate(episode.updatedAt)}
      {archivedByText}
      {archivedByLocationText}
    </Banner>
  );
}

ArchivedWarningBanner.propTypes = {
  episode: PropTypes.instanceOf(Episode).isRequired,
};

type Props = InferProps<typeof ArchivedWarningBanner.propTypes>;

export default ArchivedWarningBanner;

const Banner = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 48px;
  text-align: center;
  background-color: #fbefcb;
  border-bottom: 1px solid #f0c02f;
  font-size: 14px;
  font-weight: bold;
  color: ${({ theme }) => theme.colors.black};
`;
