import styled from 'styled-components';

import { Label } from '~/components/shared/typography';

export const FullWidthLabel = styled(Label)`
  width: 100%;
`;

export const SectionContainer = styled.div`
  width: 100%;
  padding-bottom: 16px;
  overflow: hidden;
`;
