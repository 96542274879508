/* eslint-disable @typescript-eslint/no-explicit-any */
import { createAsyncThunk } from '~/lib';
import { ImportedPatient } from '~/models';
import { importedPatientsApi } from '~/services/api';

const cancellableFetchImportedPatients = importedPatientsApi.fetch.cancellable();

const SLICE_NAME = 'importedPatients';

type FetchParams = {
  sortBy?: string;
  include?: string;
  hospital?: string;
  search?: string;
};

export const fetchImportedPatients = createAsyncThunk(
  `${SLICE_NAME}/fetch`,
  async (params: FetchParams) => {
    const res = cancellableFetchImportedPatients(params).then((arg) => arg as any);

    return (await res).data;
  },
  {
    defaultValue: [],
    modelClass: ImportedPatient,
  } as any
);

type FetchByIdParams = {
  id: string;
  include?: string;
};

export const fetchImportedPatient = createAsyncThunk(
  `${SLICE_NAME}/fetchById`,
  async ({ id, ...params }: FetchByIdParams) => {
    const res = await importedPatientsApi.fetchById.invoke(id, params);

    return res.data;
  },
  {
    modelClass: ImportedPatient,
  } as any
);

export const fetchImportedPatientsCount = createAsyncThunk(
  `${SLICE_NAME}/fetchCount`,
  async (params: FetchParams) => {
    const res = await importedPatientsApi.fetch.invoke(params);

    return res.data;
  },
  {
    modelClass: ImportedPatient,
  } as any
);
