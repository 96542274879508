import React from 'react';

function ActivityProjectedDischargeUpdatesIcon() {
  return (
    <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg">
      <g id="Projected-Discharge" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="PDD-(Activity)" transform="translate(-607.6, -458.2)" fill="#0F1226">
          <g id="Group-31" transform="translate(595.0433, 442)">
            <g id="Group-32" transform="translate(12.5567, 16.2)">
              <rect id="Rectangle-Copy" fillOpacity="0.1" x="0" y="0" width="24" height="24" rx="2.7"></rect>
              <g id="opening-times" transform="translate(6, 6)" fillOpacity="0.5" fillRule="nonzero">
                <path d="M11.5,1.5 L9,1.5 L9,0.5 C9,0.224 8.776,0 8.5,0 C8.224,0 8,0.224 8,0.5 L8,1.5 L4,1.5 L4,0.5 C4,0.224 3.776,0 3.5,0 C3.224,0 3,0.224 3,0.5 L3,1.5 L0.5,1.5 C0.224,1.5 0,1.724 0,2 L0,11 C0,11.276 0.224,11.5 0.5,11.5 L4.5,11.5 C4.776,11.5 5,11.276 5,11 L5,11 C5,10.724 4.776,10.5 4.5,10.5 L1,10.5 L1,3.5 L11,3.5 L11,5 C11,5.276 11.224,5.5 11.5,5.5 L11.5,5.5 C11.776,5.5 12,5.276 12,5 L12,2 C12,1.724 11.776,1.5 11.5,1.5 Z" id="Path"></path>
                <path d="M9,6 C7.343,6 6,7.343 6,9 C6,10.657 7.343,12 9,12 C10.657,12 12,10.657 12,9 C12,7.343 10.657,6 9,6 Z M10.5,9.5 L9,9.5 C8.724,9.5 8.5,9.2765 8.5,9 L8.5,7.5 C8.5,7.2235 8.724,7 9,7 C9.276,7 9.5,7.2235 9.5,7.5 L9.5,8.5 L10.5,8.5 C10.776,8.5 11,8.7235 11,9 C11,9.2765 10.776,9.5 10.5,9.5 Z" id="Shape"></path>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default ActivityProjectedDischargeUpdatesIcon;
