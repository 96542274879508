import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import styled from 'styled-components';

import { Button, ButtonGroup } from '~/components/shared/buttons';
import { StandardModal } from '~/components/shared/modal';
import { BodySmall, BodySmallBold } from '~/components/shared/typography';
import { ALTCS_APPLICATION } from '~/constants/reviews';
import { createReview } from '~/ducks/reviews';
import { addToast } from '~/ducks/toasts';
import { unwrapResult } from '~/lib';

interface Props extends ConnectedProps<typeof connector> {
  show: boolean;
  onCancel: () => void;
  locationEpisodeId: string;
  patientName: string;
}

function AltcsPendingModal({ locationEpisodeId, patientName, show, onCancel, createReview }: Props) {
  const [loading, setLoading] = React.useState(false);

  const createAltcsPendingReview = async () => {
    setLoading(true);

    await createReview({
      locationEpisodeId: locationEpisodeId,
      type: ALTCS_APPLICATION,
      include: 'activities.attachments,locationEpisode,episode',
    })
      .then(unwrapResult)
      .catch((error) => {
        addToast({ text: error.response?.data?.message || 'Something went wrong.' });
      })
      .then(onCancel)
      .finally(() => setLoading(false));
  };

  return (
    <StandardModal
      show={show}
      disableBackdropClick
      title='Confirm ALTCS Pending'
      onCancel={onCancel}
      data-cy='authorizationUpdateModal'>
      <Heading>Patient: {patientName}</Heading>
      <TextWrapper>
        <BodySmall>Are you sure you wish to update this patient to ALTCS Pending?</BodySmall>
        <BodySmall>
          This will discharge the current stay and transition the patient to a new ALTCS Pending custodial stay record
          in Olio.
        </BodySmall>
      </TextWrapper>
      <FormActions>
        <Button data-cy='cancelAuthorization' color='transparent' text='Cancel' onClick={onCancel} />
        <Button
          data-cy='submitAuthorization'
          type='submit'
          disabled={loading}
          loading={loading}
          onClick={createAltcsPendingReview}
          text='Confirm'
        />
      </FormActions>
    </StandardModal>
  );
}

const mapDispatchToProps = {
  addToast,
  createReview,
};

const connector = connect(null, mapDispatchToProps);

export default connector(AltcsPendingModal);

const FormActions = styled(ButtonGroup)`
  margin-top: 60px;
  justify-content: flex-end;
`;

const Heading = styled(BodySmallBold)`
  margin-bottom: 24px;
  text-align: center;
  width: 100%;
`;

const TextWrapper = styled.div`
  > * + * {
    margin-top: 24px;
  }
`;
