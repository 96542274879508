import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { connect, useSelector } from 'react-redux';
import { useRouteMatch } from 'react-router-dom';

import {
  fetch as fetchGlobalEscalations,
  fetchEscalation,
  fetchNextPage as fetchNextGlobalPage,
  getAllData as getAllGlobalData,
  getEscalationUpdating,
  updateEscalation,
} from '~/ducks/escalations';
import {
  fetch as fetchPatientEscalations,
  fetchNextPage as fetchNextPatientPage,
  getAllData as getAllPatientData,
} from '~/ducks/patientEscalations';
import { addToast } from '~/ducks/toasts';
import { unwrapResult } from '~/lib';
import { Escalation } from '~/models';
import { useProfileContext } from '~/services/profile';

import { ACKNOWLEDGED } from './constants';
import TaskModal from './TaskModal';
import { TaskModalContext } from './TaskModalContext';

const getEpisodeId = (routeMatch) => {
  const idParam = routeMatch?.params?.id;

  return idParam && idParam !== 'new' ? idParam : null;
};

function TaskModalContainer(props) {
  const {
    globalEscalationsData,
    escalationUpdating,
    history,
    location,
    onInit = () => {},
    patientEscalationsData,
  } = props;

  const patientRouteMatch = useRouteMatch('/patients/:id');
  const episodeId = useMemo(() => getEpisodeId(patientRouteMatch), [patientRouteMatch]);
  const [show, setShow] = useState(false);
  const [usePatientData, setUsePatientData] = useState(false);
  const [viewedEpisodeIds, setViewedEpisodeIds] = useState(new Set());
  const [displayNotificationType, setDisplayNotificationType] = useState(null);
  const [selectedId, setSelectedId] = useState(null);
  const [selectedEscalationLoaded, setSelectedEscalationLoaded] = useState(false);
  const profileSvc = useProfileContext();
  const profile = profileSvc.profile;
  const escalationType = profile.isAcute ? 'default' : 'priority';
  const escalationTypeLabel = escalationType === 'default' ? 'Escalations' : 'Priorities';
  const { escalationsData, fetchNextPage } = useMemo(
    () => ({
      escalationsData: usePatientData ? patientEscalationsData : globalEscalationsData,
      fetchNextPage: usePatientData ? props.fetchNextPatientPage : props.fetchNextGlobalPage,
    }),
    [usePatientData, globalEscalationsData, patientEscalationsData]
  );

  useEffect(() => {
    async function fetchData() {
      if (selectedId && show) {
        setSelectedEscalationLoaded(false);
        await props.fetchEscalation({ id: selectedId, include: 'blob' });
        setSelectedEscalationLoaded(true);
      }
    }

    fetchData();
  }, [selectedId, show]);

  const selectedEscalation = useSelector((state) => new Escalation(state.escalations.entities[selectedId]));

  useEffect(() => {
    onInit({ setShowTaskModal: setShow });
  }, []);

  // eslint-disable-next-line complexity
  useEffect(() => {
    const allDataLoaded = globalEscalationsData.loaded && (patientEscalationsData.loaded || !episodeId);

    if (!allDataLoaded) return;

    const isInitialLogin = history.location.state?.initialLogin;
    const isInitialPatientView = !!episodeId && episodeId !== 'new' && !viewedEpisodeIds.has(episodeId);
    const shouldUsePatientData = !!(patientEscalationsData.escalations.length && episodeId);
    const currentEscalations = shouldUsePatientData
      ? patientEscalationsData.escalations
      : globalEscalationsData.escalations;

    setUsePatientData(shouldUsePatientData);
    setSelectedId(currentEscalations[0]?.id);

    if (episodeId) {
      setViewedEpisodeIds(viewedEpisodeIds.add(episodeId));
    }

    if (isInitialLogin) {
      // replace history without location state so if the user visits another route
      // and navigates back via back button the modal doesn't launch again.
      history.replace(location.pathname);
    }

    if (!profile.isAdmin && (isInitialPatientView || isInitialLogin)) {
      const shouldLaunchModal = Boolean(
        (isInitialPatientView && shouldUsePatientData) || (isInitialLogin && currentEscalations.length && !episodeId)
      );

      setShow(shouldLaunchModal);
    }
  }, [globalEscalationsData.loaded, patientEscalationsData.loaded]);

  useEffect(() => {
    if (profile.id) {
      setSelectedId(null);

      const baseParams = {
        acknowledged: false,
        type: escalationType,
        patientState: profile.isAcute || profile.isAdmin ? null : 'current',
        sortBy: 'createdAt desc',
      };

      props.fetchGlobalEscalations(baseParams);

      if (episodeId) {
        props.fetchPatientEscalations({ ...baseParams, episode: episodeId });
      }
    }
  }, [profile.id, profile.actingClient?.id, escalationType, location.pathname, show]);

  const hasAcknowledgePermission = useMemo(
    () => profileSvc.canAcknowledge(selectedEscalation, selectedEscalation?.locationEpisode?.locationId),
    [selectedEscalation, profileSvc.profile]
  );

  const canAcknowledge = useMemo(() => {
    return (
      !selectedEscalation.acknowledged && hasAcknowledgePermission && !escalationUpdating && selectedEscalationLoaded
    );
  }, [selectedEscalation.acknowledged, hasAcknowledgePermission, selectedEscalationLoaded, escalationUpdating]);

  const handleClose = () => {
    setShow(false);
    setSelectedEscalationLoaded(false);
    setSelectedId(null);
  };

  const handleNavigateToPatient = () => {
    if (selectedEscalationLoaded) {
      const id = selectedEscalation.episode.id;

      setViewedEpisodeIds(viewedEpisodeIds.add(id));
      history.push(`/patients/${id}`);
      setShow(false);
    }
  };

  const handleAcknowledge = () => {
    props
      .updateEscalation({ id: selectedId, include: 'blob' })
      .then(unwrapResult)
      .then(() => {
        setDisplayNotificationType(ACKNOWLEDGED);
      })
      .catch((e) => {
        const errorMessage = e?.response?.data?.message || 'An error occurred';

        props.addToast({ text: errorMessage });
      });
  };

  const handleCardClick = (id) => {
    if (id != selectedId) {
      setSelectedEscalationLoaded(false);
      setSelectedId(id);
    }
  };

  const context = {
    canAcknowledge,
    displayNotificationType,
    escalationsData,
    escalationType,
    escalationTypeLabel,
    fetchNextPage,
    hasAcknowledgePermission,
    onAcknowledge: handleAcknowledge,
    onCardClick: handleCardClick,
    onClose: handleClose,
    onNavigateToPatient: handleNavigateToPatient,
    selectedEscalation,
    selectedEscalationLoaded,
    setDisplayNotificationType,
    setUsePatientData,
    show,
    usePatientData,
  };

  return (
    <TaskModalContext.Provider value={context}>
      <TaskModal />
    </TaskModalContext.Provider>
  );
}

const mapStateToProps = (state) => ({
  escalationUpdating: getEscalationUpdating(state),
  globalEscalationsData: getAllGlobalData(state),
  patientEscalationsData: getAllPatientData(state),
});

const mapDispatchToProps = {
  addToast,
  fetchGlobalEscalations,
  fetchEscalation,
  fetchNextGlobalPage,
  fetchNextPatientPage,
  fetchPatientEscalations,
  updateEscalation,
};

const escalationsDataPropType = PropTypes.shape({
  escalations: PropTypes.arrayOf(PropTypes.instanceOf(Escalation)),
  loaded: PropTypes.bool,
  nextUrl: PropTypes.string,
  pageLoading: PropTypes.bool,
  totalRecords: PropTypes.number,
});

TaskModalContainer.propTypes = {
  addToast: PropTypes.func.isRequired,
  escalationUpdating: PropTypes.bool,
  fetchEscalation: PropTypes.func.isRequired,
  fetchGlobalEscalations: PropTypes.func.isRequired,
  fetchNextGlobalPage: PropTypes.func.isRequired,
  fetchNextPatientPage: PropTypes.func.isRequired,
  fetchPatientEscalations: PropTypes.func.isRequired,
  globalEscalationsData: escalationsDataPropType,
  onInit: PropTypes.func,
  patientEscalationsData: escalationsDataPropType,
  show: PropTypes.bool,
  updateEscalation: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(TaskModalContainer);
