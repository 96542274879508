import moment from 'moment';

import { sortBy } from '~/helpers/sortBy';

const defaults = {
  title: '',
  yAxis: '',
  data: [],
  question: ''
};

const isRequired = (parameter) => {
  throw new TypeError(`${parameter} is required`);
};

export default class Chart {
  constructor(data = isRequired('data'), opts = {}) {
    this.data = sortBy(data, 'date') || defaults.data;
    this.question = opts.question || defaults.question;
    this.title = opts.title || defaults.title;
    this.yAxis = opts.yAxis || defaults.yAxis;
    this.min = opts.min || this.findMin();
    this.max = opts.max || this.findMax();
    this.allowDecimals = opts.allowDecimals || this.shouldAllowDecimals();
  }

  get scores() {
    return this.data.filter((item) => item.score).map((item) => Number(item.score));
  }

  get dates() {
    return this.data.filter((item) => item.score).map((item) => moment(item.date).format('MM/DD'));
  }

  dataByAxes() {
    return this.data.filter((item) => item.score).map((item) => {
      return { x: moment(item.date).format('MM/DD'), y: Number(item.score) };
    });
  }

  findMax() {
    if (this.isRangeQuestion()) return 10;
    return Math.max(...this.scores) + 10;
  }

  findMin() {
    if (this.isRangeQuestion()) return 0;
    const calculatedMin = Math.min(...this.scores) - 10;

    return calculatedMin < 0 ? 0 : calculatedMin;
  }

  shouldAllowDecimals() {
    if (this.isRangeQuestion()) return false;
    return true;
  }

  isRangeQuestion() {
    return this.question && this.question.kind === 'range';
  }
}
