import React, { createContext, useContext, useEffect, useState } from 'react';
import * as _ from 'lodash-es';
import OutsideClickHandler from 'react-outside-click-handler';
import styled from 'styled-components';

import MenuContent from '~/components/shared/menu/menu-content';
import MenuItem from '~/components/shared/menu/menu-item';
import MenuTrigger from '~/components/shared/menu/menu-trigger';

type MenuContextType = {
  open: boolean;
  toggle: () => void;
};

type MenuProps = {
  children?: React.ReactNode;
  disableOutsideClick?: boolean;
  onToggle?: (open: boolean) => void;
  open?: boolean;
};

export const MenuContext = createContext<MenuContextType>({
  open: false,
  toggle: _.noop,
});

export const useMenuContext = () => useContext(MenuContext);

function Menu({ children, disableOutsideClick = false, open = false, onToggle = () => undefined }: MenuProps) {
  const [isOpen, setIsOpen] = useState(open);

  useEffect(() => {
    setIsOpen(open);
  }, [open]);

  const toggleMenu = () => {
    const newValue = !isOpen;

    setIsOpen(newValue);
    onToggle(newValue);
  };

  return (
    <MenuContext.Provider value={{ open: isOpen, toggle: toggleMenu }}>
      <OutsideClickHandler disabled={disableOutsideClick || !isOpen} onOutsideClick={toggleMenu}>
        <MenuContainer>{children}</MenuContainer>
      </OutsideClickHandler>
    </MenuContext.Provider>
  );
}

const MenuContainer = styled.div`
  position: relative;
  display: inline-block;
`;

Menu.Item = MenuItem;
Menu.Trigger = MenuTrigger;
Menu.Content = MenuContent;
export default Menu;
