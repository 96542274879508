import React, { useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { ColumnDef, createColumnHelper } from '@tanstack/react-table';

import { MetricDetailContainer, MetricDetailHeader } from '~/components/insights';
import { useInsightsContext } from '~/components/insights/InsightsContainer';
import { Histogram } from '~/components/shared/Charts';
import DataTable from '~/components/shared/DataTable';
import { LabelBold } from '~/components/shared/typography';

import Card from '../Card';
import { DimensionConstants, MetricConstants, RouteConstants, SortOrder, SourceConstants } from '../constants';
import { DataRow, parseDimensionValues, parseMetricValues, requestData } from '../dataUtils';

import { AlosChartData, generateExtendedChartConfig } from './alosChartUtils';
import ChartContainer from './ChartContainer';
import TableContainer from './TableContainer';
import { GroupDischargesRow } from './tableUtils';

type AlosTableRow = GroupDischargesRow & {
  alos: number;
};

const AverageLengthOfStayDetail = () => {
  const history = useHistory();

  const insightsContext: any = useInsightsContext();
  const { dischargedId, selectedGroupType } = insightsContext;

  const [chartData, setChartData] = useState<AlosChartData>({
    categories: [],
    values: [],
    counts: [],
    average: 0,
  });
  const [totalDischarges, setTotalDischarges] = useState(-1);

  const request = useMemo(
    () => ({
      params: {
        source: SourceConstants.LOCATION_EPISODES,
        dimensions: [DimensionConstants.GROUP_NAME],
        metrics: [MetricConstants.AVERAGE_LENGTH_OF_STAY, MetricConstants.ID_COUNT],
        sortBy: `${MetricConstants.ID_COUNT} ${SortOrder.DESC}, ${DimensionConstants.GROUP_NAME} ${SortOrder.DESC}`,
        rehabState: dischargedId,
        rollups: true,
      },
      processData: (data: DataRow[]) => {
        const parsedData = data.reduce(
          (acc, row) => {
            const dimensionValues = parseDimensionValues(row, false) as string[];
            const metricValues = parseMetricValues(row);

            const groupName = dimensionValues[0];
            const alos = metricValues[0];
            const discharges = metricValues[1];

            if (!groupName) {
              acc.average = alos;
              acc.totalDischarges = discharges;
            } else {
              acc.categories.push(groupName);
              acc.values.push(alos);
              acc.counts.push(discharges);
            }

            return acc;
          },
          { categories: [], values: [], counts: [], average: 0, totalDischarges: 0 } as {
            categories: string[];
            values: number[];
            counts: number[];
            average: number;
            totalDischarges: number;
          }
        );

        const { totalDischarges, ...chartData } = parsedData;

        setChartData(chartData);
        setTotalDischarges(totalDischarges);
      },
    }),
    [dischargedId]
  );

  const { loading } = requestData([request], {
    condition: dischargedId !== undefined,
  });

  const chartConfig = useMemo(() => generateExtendedChartConfig(chartData), [chartData]);

  const getValueString = () => {
    if (chartData.average <= 0) return;

    return `${chartData.average.toFixed(1)} days`;
  };

  const tableData = useMemo<AlosTableRow[]>(() => {
    const { categories, values, counts } = chartData;

    return categories.map((groupName, i) => ({
      groupName,
      alos: values[i],
      discharges: counts[i],
    }));
  }, [chartData]);

  const columnHelper = createColumnHelper<AlosTableRow>();
  const columns = useMemo<ColumnDef<AlosTableRow, any>[]>(
    () => [
      columnHelper.accessor('groupName', {
        header: selectedGroupType.displayName,
        cell: (data) => <LabelBold>{data.getValue()}</LabelBold>,
        footer: () => <LabelBold>Average (All)</LabelBold>,
      }),
      columnHelper.accessor('discharges', {
        header: 'Discharges',
        footer: () => totalDischarges,
      }),
      columnHelper.accessor('alos', {
        header: 'Average Length of Stay',
        cell: (data) => `${data.getValue().toFixed(1)} days`,
        footer: () => `${chartData.average.toFixed(1)} days`,
      }),
    ],
    [chartData.average, selectedGroupType, totalDischarges]
  );

  const defaultSort = [
    {
      id: 'discharges',
      desc: true,
    },
  ];

  return (
    <>
      <MetricDetailContainer
        onBackClick={() => history.push(RouteConstants.INSIGHTS_BASE)}
        loading={loading}
        hasData={!!chartData.values.length}
        header={<MetricDetailHeader label='Average Length of Stay' loading={loading} value={getValueString()} />}>
        <ChartContainer>
          <Histogram config={chartConfig} />
        </ChartContainer>
      </MetricDetailContainer>
      <TableContainer>
        <Card.Title>Average Length of Stay Overview</Card.Title>
        <DataTable columns={columns} data={tableData} defaultSortBy={defaultSort} loading={loading} />
      </TableContainer>
    </>
  );
};

export default AverageLengthOfStayDetail;
