import React from 'react';
import PropTypes, { InferProps } from 'prop-types';
import ReactTooltip from 'react-tooltip';
import styled from 'styled-components';

import { InfoIcon, PencilIcon } from '~/components/shared/svg';
import { BodySmallBold, Label, LabelBold } from '~/components/shared/typography';
import { simpleDate } from '~/lib/formatDate';
import { useSwitch } from '~/lib/hooks';
import colors from '~/styles/theme/colors';

import ProjectedDischargeModal from './ProjectedDischargeModal';

function ProjectedDischarge({ date, id, isOverdue, onUpdateClick, showUpdateButton = false }: Props) {
  const toolTip = 'This date is provided by the risk-bearing entity';
  const modalSwitch = useSwitch();
  const handleOnUpdateClick = onUpdateClick || modalSwitch.turnOn;
  const renderModal = showUpdateButton && modalSwitch.state;

  const actionLink = (text: string) => {
    return (
      <ActionContainer onClick={handleOnUpdateClick}>
        <PencilIcon size={12} fill={colors.primaryBlue} />
        <StyledLabel color={colors.primaryBlue}>{text}</StyledLabel>
      </ActionContainer>
    );
  };

  return (
    <Card data-cy='projectedDischargeDate'>
      <Container>
        <LabelContainer>
          <Label data-cy='projectedDischargeDateLabel'>Projected Discharge</Label>
          <InfoContainer data-tip={toolTip}>
            <InfoIcon />
          </InfoContainer>
          <ReactTooltip place='right' effect='solid' multiline />
        </LabelContainer>
        {showUpdateButton && actionLink('Update')}
        {renderModal && (
          <ProjectedDischargeModal
            show={modalSwitch.state}
            id={id}
            date={date}
            onCancel={modalSwitch.turnOff}
            onSuccess={modalSwitch.turnOff}
          />
        )}
      </Container>
      <StyledDate isOverdue={isOverdue} data-cy='projectedDischargeDateValue'>
        {date ? simpleDate(date) : '—'}
      </StyledDate>
    </Card>
  );
}

ProjectedDischarge.propTypes = {
  date: PropTypes.instanceOf(Date),
  id: PropTypes.string.isRequired,
  isOverdue: PropTypes.bool.isRequired,
  onUpdateClick: PropTypes.func,
  showUpdateButton: PropTypes.bool,
};

type Props = InferProps<typeof ProjectedDischarge.propTypes>;

export default ProjectedDischarge;

const Card = styled.div`
  border: 1px solid ${colors.black10};
  border-radius: 4px;
  background-color: ${colors.white};
  margin-bottom: 24px;
`;

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 24px 24px 8px 24px;
  align-items: center;
`;

const LabelContainer = styled.div`
  display: flex;
`;

const ActionContainer = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
`;

const StyledLabel = styled(LabelBold)`
  margin-left: 8px;
  cursor: pointer;
`;

const InfoContainer = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-left: 8px;
`;

const StyledDate = styled(BodySmallBold)`
  color: ${({ isOverdue, theme }) => isOverdue && theme.colors.accentRed};
  padding: 0 24px 24px 24px;
`;
