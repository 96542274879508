import React from 'react';
import { ClearIndicatorProps, components } from 'react-select';

import { DropdownClearIcon } from '~/components/shared/svg';

const FilterClearIndicator = (props: ClearIndicatorProps) => (
  <components.ClearIndicator {...props}>
    <DropdownClearIcon />
  </components.ClearIndicator>
);

export default FilterClearIndicator;
