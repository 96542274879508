interface IChartData {
  items: Array<{date: string, score: number}>
  target: number;
}

const defaults: IChartData = {
  items: [],
  target: 0
};

export default class ChartData {
  items: Array<{date: string, score: number}>;
  target: number;

  constructor(options: IChartData) {
    const opts = { ...defaults, ...options };

    this.items = opts.items;
    this.target = opts.target;
  }
}
