import React from 'react';
import styled from 'styled-components';

import { Button } from '~/components/shared/buttons';
import { CheckIcon } from '~/components/shared/svg';
import { BodyBold, Label } from '~/components/shared/typography';

import { useTaskModalContext } from '../TaskModalContext';

function Header() {
  const {
    canAcknowledge,
    onAcknowledge,
    hasAcknowledgePermission,
    onNavigateToPatient,
    selectedEscalation: escalation
  } = useTaskModalContext();

  const { patient } = escalation;

  const latestRehabFacilityName = escalation.episode.rehabInformation.latestRehabFacility.name;
  const ownerName = escalation.episode.owner.name;
  const locationDetails = `${ownerName} — ${latestRehabFacilityName}`;

  return (
    <Container>
      <Details>
        <BodyBold>{ patient.name }</BodyBold>
        <LocationDetails>{ locationDetails }</LocationDetails>
      </Details>
      <Actions>
        <PatientStoryLink onClick={ onNavigateToPatient }>
          { 'Go to Patient Story' }
        </PatientStoryLink>
        { hasAcknowledgePermission &&
        <AcknowledgeButton disabled={ !canAcknowledge }
          onClick={ onAcknowledge } />
        }
      </Actions>
    </Container>
  );
}

export default Header;

const Container = styled.div`
  border-bottom: 1px solid ${({ theme }) => theme.colors.black25};
  display: flex;
  padding: 16px 24px;
  min-width: 0;
`;

const Details = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  margin-right: 24px;
  min-width: 0;
`;

const Actions = styled.div`
  display: flex;
  align-items: center;
  flex: none;
`;

const StyledLabel = styled(Label)`
  color: ${({ theme }) => theme.colors.black75 };
`;

const LocationDetails = styled(StyledLabel)`
  margin-top: 8px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const PatientStoryLink = styled(StyledLabel)`
  align-items: center;
  cursor: pointer;
  display: flex;
  margin-right: 16px;
`;

const AcknowledgeButton = styled(Button).attrs({
  icon: <CheckIcon size={ 12 } />,
  fontSize: '12px',
  text: 'Acknowledge'
})`
  height: 32px;
  padding: 8px 10px;
`;
