import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

import { Checkbox } from '~/components/shared/form';
import { colors } from '~/styles/theme';

function EscalationCheckbox({ isPriority, checked, onChange }) {
  const color = useMemo(() => (isPriority ? colors.black : colors.white), [isPriority]);
  const backgroundColor = useMemo(() => (isPriority ? colors.white : colors.accentRed), [isPriority]);

  return (
    <Checkbox
      checked={checked}
      onChange={onChange}
      color={color}
      labelSize='12px'
      size={12}
      style={{ checkboxContainer: { backgroundColor } }}
      label='I acknowledge that I have received and read this note'
    />
  );
}

EscalationCheckbox.propTypes = {
  checked: PropTypes.bool,
  isPriority: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
};

export default EscalationCheckbox;
