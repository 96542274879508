import React from 'react';

interface Props extends React.SVGProps<SVGSVGElement> {
  size?: number;
}

export default function ArrowUpDownIcon({ size, ...props }: Props) {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width={size} height={size} fill='none' {...props}>
      <g fill='currentColor' clipPath='url(#a)'>
        <path d='M2.205 2.622a.417.417 0 0 0 .295.711h5a.417.417 0 0 0 .295-.711l-2.5-2.5a.417.417 0 0 0-.589 0l-2.5 2.5ZM4.705 9.878a.417.417 0 0 0 .59 0l2.5-2.5a.417.417 0 0 0-.295-.711h-5a.417.417 0 0 0-.295.711l2.5 2.5Z' />
      </g>
      <defs>
        <clipPath id='a'>
          <path fill='#fff' d='M0 10V0h10v10z' />
        </clipPath>
      </defs>
    </svg>
  );
}
