import { isDeepEqual } from '~/lib';
import { useDeepEffect } from '~/lib/hooks';

export default function useDeepEffectCompare(object1, object2, effectFunc, deps = []) {
  useDeepEffect(() => {
    if (!isDeepEqual(object1, object2)) {
      effectFunc();
    }
  }, deps);
}
