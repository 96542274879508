import React, { memo } from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import PropTypes from 'prop-types';

import { Chart } from '~/models';

// eslint-disable-next-line react/display-name
const OlioLineChart = memo(({ chart }) => {
  const scores = chart.scores;
  const dates = chart.dates;

  const config = {
    chart: {
      type: 'line',
      height: '240',
      width: '370',
    },
    plotOptions: {
      areaspline: {
        fillOpacity: 0.4,
      },
    },
    credits: {
      enabled: false,
    },
    xAxis: {
      categories: dates,
      title: {
        text: 'Date',
      },
      tickLength: 0,
    },
    yAxis: {
      title: {
        text: chart.yAxis,
      },
      min: chart.min,
      ceiling: chart.max,
      allowDecimals: chart.allowDecimals,
      tickPixelInterval: 44,
      tickAmount: 11,
      minorTickInterval: (chart.max - chart.min) / 10,
      minorTickLength: 0,
      minorGridLineDashStyle: 'dot',
      minorGridLineColor: '#A7B0B9',
      gridLineWidth: 0,
      labels: {
        step: 2,
      },
    },
    series: [
      {
        data: scores,
        name: chart.title,
        color: '#3253EF',
        marker: {
          radius: 4,
        },
      },
    ],
  };

  return (
    <div>
      <div id='patient-pain-chart-card' className='patient-detail-card'>
        <div className='card-header chart-pain'>{chart.title}</div>
        <div className='chart-container'>
          <div>
            <HighchartsReact highcharts={Highcharts} options={config} />
          </div>
        </div>
      </div>
    </div>
  );
});

OlioLineChart.propTypes = {
  chart: PropTypes.instanceOf(Chart),
};

export default OlioLineChart;
