import React, { useMemo } from 'react';
import * as _ from 'lodash-es';
import PropTypes, { InferProps } from 'prop-types';
import { connect } from 'react-redux';
import styled from 'styled-components';

import { BodySmall } from '~/components/shared/typography';
import { RehabStateApiName } from '~/constants/rehabStates';
import { getSorts, removeFilter, setSort } from '~/ducks/portfolioFilters';
import { PortfolioSortOption } from '~/models';
import { verticalCenter } from '~/styles/mixins';

function LaneSortMenuItem({ attributeName, direction, onClick, text, rehabStateApiName, visible, ...props }: Props) {
  const menuItemSortOption = new PortfolioSortOption({
    attributeName,
    direction,
    rehabStateApiName,
  });
  const handleMenuItemClick = () => {
    props.setSort(menuItemSortOption);

    onClick();
  };

  const menuItemSortOptionKey = menuItemSortOption.key as keyof typeof props.sorts;
  const menuSortOptionState = props.sorts?.[menuItemSortOptionKey] || {};
  const portfolioSortOption = new PortfolioSortOption(menuSortOptionState as PortfolioSortOption);

  const isSelected = useMemo(
    () => _.isEqual(portfolioSortOption, menuItemSortOption),
    [portfolioSortOption, menuItemSortOption]
  );

  return (
    <>
      {visible && (
        <StyledMenuItem onClick={handleMenuItemClick} isSelected={isSelected} {...props}>
          {text}
        </StyledMenuItem>
      )}
    </>
  );
}

const noop = () => undefined;

LaneSortMenuItem.defaultProps = {
  onClick: noop,
  visible: true,
};

LaneSortMenuItem.propTypes = {
  attributeName: PropTypes.string.isRequired,
  direction: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  rehabStateApiName: PropTypes.string.isRequired,
  setSort: PropTypes.func.isRequired,
  text: PropTypes.string.isRequired,
  visible: PropTypes.bool,
};

interface StateProps {
  sorts: Map<RehabStateApiName, PortfolioSortOption>;
}

type DefaultProps = InferProps<typeof LaneSortMenuItem.defaultProps>;
type Props = InferProps<typeof LaneSortMenuItem.propTypes> & DefaultProps & StateProps;

const mapStateToProps = (state: any) => ({
  sorts: getSorts(state),
});

const mapDispatchToProps = {
  removeFilter,
  setSort,
};

export default connect(mapStateToProps, mapDispatchToProps)(LaneSortMenuItem);

const StyledMenuItem = styled(BodySmall).attrs(() => ({
  forwardedAs: 'div',
}))`
  color: ${({ color }) => color};
  cursor: pointer;

  &:hover {
    background-color: ${({ isSelected, theme }) => !isSelected && theme.colors.black10};
  }

  ${verticalCenter()};

  ${({ isSelected, theme }) =>
    isSelected &&
    `
     background-color: ${theme.colors.primaryBlue};
     color: ${theme.colors.white};
  `};
`;
