import { SERVICE_REFUSAL } from '~/constants/reviews';

import Review from '../Review';

const defaults = {
  data: { refusedBy: {} },
  type: SERVICE_REFUSAL,
};

class ServiceRefusal extends Review {
  constructor(options = {}) {
    const opts = { ...defaults, ...options };

    super(opts);
  }

  static get type() {
    return SERVICE_REFUSAL;
  }

  get refusedBy() {
    return this.data.refusedBy;
  }

  set refusedBy(value) {
    this.data = { ...this.data, refusedBy: value };
  }
}

export default ServiceRefusal;
