import { DISCHARGE_QUESTIONS } from '~/constants/activities';

import Activity from '../Activity';
import QuestionArray from '../QuestionArray';

const defaults = {
  data: {},
  type: DISCHARGE_QUESTIONS,
};

export default class DischargeQuestions extends Activity {
  constructor(options = {}) {
    const { questions } = options;
    const opts = {
      ...defaults,
      ...options,
      data: {
        questions: questions || [],
        ...options.data,
      },
    };

    super(opts);
  }

  get questions() {
    return QuestionArray.from(this.data.questions);
  }

  set questions(value) {
    this.data = { ...this.data, questions: value };
  }

  get typeLabel() {
    return 'Discharge details';
  }

  canEdit(user) {
    return super.canEdit(user) || this.ageInHours <= 72;
  }
}
