import React from 'react';
import PropTypes from 'prop-types';

import theme from '~/styles/theme';

function AttachFileIcon({ fill, width, height, viewBox }) {
  return (
    <svg
      width={width}
      height={height}
      viewBox={viewBox}
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'>
      <title>attachment</title>
      <g
        id='drag-and-drop'
        stroke='none'
        strokeWidth='1'
        fill='none'
        fillRule='evenodd'
        strokeLinecap='round'
        strokeLinejoin='round'>
        <g id='normal-state' transform='translate(-405.000000, -204.000000)' stroke={fill}>
          <g id='Group-6' transform='translate(20.000000, 22.000000)'>
            <g id='Group-18' transform='translate(257.000000, 183.000000)'>
              <g id='attachment' transform='translate(128.999102, 0.001689)'>
                <path
                  d='M3.73333333,4.66666667 L3.73333333,8.4 C3.73333333,9.43133333 4.56866667,10.2666667 5.6,10.2666667 L5.6,10.2666667 C6.63133333,10.2666667 7.46666667,9.43133333 7.46666667,8.4 L7.46666667,3.73333333 C7.46666667,1.6716 5.79506667,0 3.73333333,0 L3.73333333,0 C1.6716,0 0,1.6716 0,3.73333333 L0,8.86666667 C0,11.7021333 2.29786667,14 5.13333333,14 L5.13333333,14 C7.9688,14 10.2666667,11.7021333 10.2666667,8.86666667 L10.2666667,3.73333333'
                  id='Path'
                />
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}

AttachFileIcon.defaultProps = {
  fill: theme.colors.black,
  height: '18px',
  viewBox: '0 0 12 18',
  width: '12px',
};

AttachFileIcon.propTypes = {
  fill: PropTypes.string,
  height: PropTypes.string,
  viewBox: PropTypes.string,
  width: PropTypes.string,
};

export default AttachFileIcon;
