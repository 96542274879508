import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { unwrapResult } from '@reduxjs/toolkit';

import LoadingSpinner from '~/components/shared/loadingSpinner';
import { FormPage } from '~/components/shared/pageLayout';
import StepWizard from '~/components/shared/StepWizard';
import { clearEpisode, fetchEpisode, getEpisodeFetchDone } from '~/ducks/episode';
import { createPatient, updatePatient } from '~/ducks/patients';
import { addToast } from '~/ducks/toasts';
import { Patient } from '~/models';
import { patientMatchApi } from '~/services/api';
import { useProfileContext } from '~/services/profile';

import MatchConfirm from '../shared/MatchConfirm/MatchConfirm';
import MatchSuccess from '../shared/MatchSuccess';
import PatientConfirm from '../shared/PatientConfirm';

import { IntakeForm } from './IntakeForm';

function EditPatient(props) {
  const {
    episodeLoading,
    match: {
      params: { id },
    },
  } = props;

  const profileSvc = useProfileContext();

  useEffect(() => {
    if (id) {
      props
        .fetchEpisode({
          id,
          include: 'owner.client.enabled_provider_types,latest_location_episode.case_manager',
        })
        .then(unwrapResult)
        .then((payload) => {
          setPatient(Patient.fromLocationEpisodeData(payload));
        });
    }

    return () => {
      props.clearEpisode();
    };
  }, []);

  const [patient, setPatient] = useState(new Patient());
  const [matchedPatient, setMatchedPatient] = useState(null);
  const handleNavigate = useCallback(
    (episodeId) => {
      const param = episodeId || id;

      props.history.push(param ? `/patients/${param}` : '/patients');
    },
    [id]
  );

  const notifyError = () => {
    const text = `There was an error ${id ? 'updating' : 'creating'} the patient. Please try again.`;

    props.addToast({ text });
  };

  const notifySuccess = () => {
    props.addToast({ text: `Patient successfully ${id ? 'updated' : 'added'}.` });
  };

  const handlePatientSave = async (confirmedPatient) => {
    const patientRequest = confirmedPatient.id ? props.updatePatient : props.createPatient;
    const serializedPatient = confirmedPatient.serialize();

    try {
      const { episodeId } = await patientRequest(serializedPatient).then(unwrapResult);

      notifySuccess();
      handleNavigate(episodeId);
    } catch (error) {
      notifyError(error);
      handleNavigate();
    }
  };

  const handleIntakeComplete = async (patientInfo, { goToStep, setSubmitting }) => {
    setPatient(patientInfo);

    try {
      const { data: match } = await patientMatchApi.search.invoke(patientInfo.serialize());

      setMatchedPatient(Patient.fromLocationEpisodeData(match));
      goToStep(MatchConfirm);
    } catch (e) {
      const noMatchFound = e.response?.status === 404;

      if (noMatchFound) {
        goToStep(PatientConfirm);
      } else {
        props.addToast({ text: 'Something went wrong. Please try again.' });
        setSubmitting(false);
      }
    }
  };

  const handleMatchResponse = async (patientInfo, { goToStep, setConfirmed }) => {
    if (patientInfo) {
      const serializedPatient = patientInfo.serialize();

      try {
        const { data: mergedPatient } = await patientMatchApi.create.invoke(serializedPatient);

        setPatient(Patient.fromLocationEpisodeData(mergedPatient));
        goToStep(MatchSuccess);
      } catch (e) {
        props.addToast({ text: 'There was an error connecting the patient. Please try again.' });
        setConfirmed(false);
      }
    } else {
      goToStep(PatientConfirm);
    }
  };

  const resetPatient = useCallback(() => setPatient(new Patient()), []);

  const [patientIntakeSteps] = useState([
    { component: IntakeForm, onStepSuccess: handleIntakeComplete },
    { component: PatientConfirm, onStepSuccess: handlePatientSave },
    { component: MatchConfirm, onStepSuccess: handleMatchResponse },
    { component: MatchSuccess },
  ]);

  if (id && episodeLoading) {
    return <LoadingSpinner />;
  }

  return (
    <FormPage>
      <StepWizard
        {...props}
        resetPatient={resetPatient}
        matchedPatient={matchedPatient}
        patient={patient}
        profileSvc={profileSvc}
        steps={patientIntakeSteps}
        onSequenceComplete={handlePatientSave}
        onSequenceAbort={handleNavigate}
      />
    </FormPage>
  );
}

EditPatient.propTypes = {
  addToast: PropTypes.func.isRequired,
  clearEpisode: PropTypes.func.isRequired,
  createPatient: PropTypes.func.isRequired,
  episodeLoading: PropTypes.bool.isRequired,
  fetchEpisode: PropTypes.func.isRequired,
  updatePatient: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  episodeLoading: !getEpisodeFetchDone(state),
});

const mapDispatchToActions = {
  addToast,
  clearEpisode,
  createPatient,
  fetchEpisode,
  updatePatient,
};

export default connect(mapStateToProps, mapDispatchToActions)(EditPatient);
