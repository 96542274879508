import { PROGRESS_UPDATE } from '~/constants/activities';

import { STATUS } from '../../../constants/activities';
import QuestionArray from '../../QuestionArray';
import Note from '../Note';

import Status from './Status';

const defaults = {
  data: {},
  type: PROGRESS_UPDATE,
};

export default class ProgressUpdate extends Note {
  constructor(options = {}) {
    const { questions = [] } = options;
    const opts = {
      ...defaults,
      ...options,
      // The following supports forms that will pass the "data" field values
      // as top level properties in the "options" parameter
      data: {
        questions,
        ...options.data,
      },
    };

    super(opts);
  }

  get questions() {
    return QuestionArray.from(this.data.questions);
  }

  set questions(value) {
    this.data = { ...this.data, questions: value };
  }

  get typeLabel() {
    return 'Progress Update';
  }

  get status() {
    const statusQuestion = this.questions.find((q) => q.kind === STATUS);

    if (!statusQuestion) return null;

    const statusQuestionValue = statusQuestion.answer.text;

    return new Status(statusQuestionValue);
  }
}
