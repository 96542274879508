import React from 'react';
import PropTypes from 'prop-types';

import theme from '~/styles/theme';

const ClockIcon = ({ fill, size }) => {
  return (
    <svg width={size} height={size} viewBox='0 0 32 32' version='1.1' xmlns='http://www.w3.org/2000/svg'>
      <g stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
        <g transform='translate(-804.000000, -323.000000)' fill={fill}>
          <g transform='translate(225.000000, 238.000000)'>
            <g transform='translate(0.000000, 48.000000)'>
              <g transform='translate(179.000000, 37.000000)'>
                <g transform='translate(400.000000, 0.000000)'>
                  <path d='M16,0 C7.163444,0 0,7.163444 0,16 C0,24.836556 7.163444,32 16,32 C24.836556,32 32,24.836556 32,16 C31.9895304,7.1677843 24.8322157,0.0104696182 16,0 Z M26,17 L15,17 L15,6 L17,6 L17,15 L26,15 L26,17 Z' />
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

ClockIcon.defaultProps = {
  fill: theme.colors.primaryBlue,
  secondaryFill: theme.colors.primaryBlue,
  size: 24,
};

ClockIcon.propTypes = {
  fill: PropTypes.string,
  secondaryFill: PropTypes.string,
  size: PropTypes.number,
};

export default ClockIcon;
