import * as Yup from 'yup';

export const dischargeFormValidation = (inTreatmentDate) => {
  const inTreatmentDateStartOfDay = new Date(inTreatmentDate).setHours(0, 0, 0, 0);

  return (
    Yup.object().shape({
      actualDischargeDateTime:
        Yup.date()
          .nullable()
          .max(new Date(), 'Cannot select a future date/time')
          .min(new Date(inTreatmentDateStartOfDay), 'Discharge Date must be on or after the date of treatment')
          .required('Actual Discharge Date is required')
          .typeError('Actual Discharge Date is required')
    })
  );
};
