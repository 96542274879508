import React from 'react';
import PropTypes, { InferProps } from 'prop-types';
import styled from 'styled-components';

import { SortIcon } from '~/components/shared/svg';
import { ASC, DESC, LOS_ATTR_NAME, PATIENT_NAME_ATTR_NAME } from '~/constants/portfolioSortOptions';
import { useSwitch } from '~/lib/hooks';

import LaneSortFlyoutMenuItems from './LaneSortFlyoutMenuItems';
import LaneSortMenuItem from './LaneSortMenuItem';

function LaneSortFlyoutMenu({ rehabStateApiName, isQueueLane }: Props) {
  const { state: menuState, toggle, turnOff } = useSwitch(false);

  const rehabStateNotQueue = !isQueueLane;

  return (
    <>
      <Icon onClick={toggle} data-cy='laneSortIcon'>
        <SortIcon />
      </Icon>
      {menuState && (
        <LaneSortFlyoutMenuItems onHideFlyoutMenu={turnOff}>
          <LaneSortMenuItem
            attributeName={LOS_ATTR_NAME}
            data-cy='losDesc'
            direction={DESC}
            onClick={turnOff}
            text={'Length of Stay (Highest)'}
            rehabStateApiName={rehabStateApiName}
            visible={rehabStateNotQueue}
          />
          <LaneSortMenuItem
            attributeName={LOS_ATTR_NAME}
            data-cy='losAsc'
            direction={ASC}
            onClick={turnOff}
            text={'Length of Stay (Lowest)'}
            rehabStateApiName={rehabStateApiName}
            visible={rehabStateNotQueue}
          />
          <LaneSortMenuItem
            attributeName={PATIENT_NAME_ATTR_NAME}
            data-cy='patientNameAsc'
            direction={ASC}
            onClick={turnOff}
            text={'Patient Name (A-Z)'}
            rehabStateApiName={rehabStateApiName}
          />
          <LaneSortMenuItem
            attributeName={PATIENT_NAME_ATTR_NAME}
            data-cy='patientNameDesc'
            direction={DESC}
            onClick={turnOff}
            text={'Patient Name (Z-A)'}
            rehabStateApiName={rehabStateApiName}
          />
        </LaneSortFlyoutMenuItems>
      )}
    </>
  );
}

LaneSortFlyoutMenu.propTypes = {
  isQueueLane: PropTypes.bool.isRequired,
  rehabStateApiName: PropTypes.string.isRequired,
};

type Props = InferProps<typeof LaneSortFlyoutMenu.propTypes>;

export default LaneSortFlyoutMenu;

const Icon = styled.div`
  cursor: pointer;
  width: 12px;
`;
