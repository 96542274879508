import { isCancel } from 'axios';

import { ALL } from '~/constants/filterKeysConstants';
import {
  addLocationEpisodes,
  addLocationEpisodesToAllLanes,
  clearLaneData,
  clearLocationEpisodes,
  fetchNextLocationEpisodesPage,
} from '~/ducks/locationEpisodes';
import { createAsyncThunk } from '~/lib';
import { LocationEpisode } from '~/models';
import cancellableRequestFactory from '~/sources/api/cancellableRequestFactory';
import locationEpisodesAPI from '~/sources/api/locationEpisodesAPI';
import { store } from '~/store';

import { REHAB_STATE_NAMES } from './rehabState/constants';
import LocationEpisodesParamsBuilder from './LocationEpisodesParamsBuilder';

export class PatientBoardRequestService {
  constructor({
    appStore = store,
    filters = {},
    hasImportImprovements = false,
    onAllLanesRequested = () => {},
    sorts = {},
  } = {}) {
    this.requestFns = this.initRequestFns();
    this.filters = filters;
    this.hasImportImprovements = hasImportImprovements;
    this.onAllLanesRequested = onAllLanesRequested;
    this.store = appStore;
    this.sorts = sorts;
  }

  async requestAllLanes() {
    const lane = ALL;
    const url = 'location_episodes/portfolio';
    const queryParams = this.buildQueryParams({ lane, filters: this.filters });

    this.store.dispatch(clearLocationEpisodes());

    try {
      const { data } = await this.requestFns[lane](url, queryParams);

      this.store.dispatch(addLocationEpisodesToAllLanes(data));

      this.onAllLanesRequested();

      return data;
    } catch (e) {
      if (isCancel(e)) return null;
      throw e;
    }
  }

  requestAllLanesThunk = createAsyncThunk(
    'location_episodes',
    async (params) => {
      const lane = ALL;
      const url = 'location_episodes/portfolio';
      const queryParams = this.buildQueryParams({ lane, filters: params.filters });

      try {
        const { data } = await this.requestFns[lane](url, queryParams);

        return data;
      } catch (e) {
        if (isCancel(e)) return null;
        throw e;
      }
    },
    {
      modelClass: LocationEpisode,
    }
  );

  async requestFirstPageForLane({ lane }) {
    this.store.dispatch(clearLaneData(lane));
    return await this.requestData({ lane });
  }

  async requestNextPageForLane({ lane, url }) {
    this.store.dispatch(fetchNextLocationEpisodesPage(lane));
    return await this.requestData({ lane, url, buildParams: false });
  }

  async requestData({ lane, url = 'location_episodes/portfolio', filters = this.filters, buildParams = true }) {
    const params = buildParams ? this.buildQueryParams({ lane, filters }) : null;

    try {
      const { data } = await this.requestFns[lane](url, params);

      data.meta.lane = lane;
      this.store.dispatch(addLocationEpisodes(data));

      return data;
    } catch (e) {
      if (isCancel(e)) return null;
      throw e;
    }
  }

  buildQueryParams({ lane, filters }) {
    const params = {
      active: true,
      filters,
      hasImportImprovements: this.hasImportImprovements,
      sorts: this.sorts,
    };

    params.rehabState = lane;

    return new LocationEpisodesParamsBuilder(params).build();
  }

  initRequestFns() {
    return [...REHAB_STATE_NAMES, ALL].reduce((requestFnMap, name) => {
      requestFnMap[name] = cancellableRequestFactory(locationEpisodesAPI.fetchLocationEpisodes);
      return requestFnMap;
    }, {});
  }
}
