import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Button, ButtonGroup } from '~/components/shared/buttons';
import { StandardModal } from '~/components/shared/modal';
import { Body, H3 } from '~/components/shared/typography';

function ConfirmPatientRemovalModal({ show, name, onConfirm, onCancel }) {
  return (
    <StandardModal show={show} onCancel={onCancel} colorTheme='danger' title='Confirm Patient Removal'>
      <Content>
        <H3>Are you sure you want to remove {name}?</H3>
        <Paragraph>
          Once a patient is removed, they can no longer be seen on the Patient Portfolio or All Patients pages.
        </Paragraph>
      </Content>
      <StyledButtonGroup>
        <Button color='transparent' text='Cancel' onClick={onCancel} />
        <Button text='Remove Patient' color='danger' onClick={onConfirm} />
      </StyledButtonGroup>
    </StandardModal>
  );
}

ConfirmPatientRemovalModal.propTypes = {
  name: PropTypes.string.isRequired,
  onCancel: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  show: PropTypes.bool,
};

export default ConfirmPatientRemovalModal;

const StyledButtonGroup = styled(ButtonGroup)`
  justify-content: flex-end;
`;

const Content = styled.div`
  margin: 26px 0 50px;
  text-align: center;
`;

const Paragraph = styled(Body)`
  display: block;
`;
