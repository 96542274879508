import React from 'react';

const DropdownClearIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width='8' height='8' viewBox='0 0 8 8' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <g opacity='0.5'>
      <path d='M7 1L1 7' stroke='#0F1226' strokeMiterlimit='10' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M1 1L7 7' stroke='#0F1226' strokeMiterlimit='10' strokeLinecap='round' strokeLinejoin='round' />
    </g>
  </svg>
);

export default DropdownClearIcon;
