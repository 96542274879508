import { createSlice } from '@reduxjs/toolkit';

import { createReview, updateReview } from '~/ducks/reviews';
import { createAsyncThunk } from '~/lib';
import { Episode } from '~/models';
import episodesAPI from '~/sources/api/episodesAPI';

import { API_STATES, createApiHasStatusSelector } from './api';
import { updateLocationEpisodeDates } from './locationEpisode';

const SLICE_NAME = 'episode';

export const INITIAL_STATE = { data: { ...new Episode() } };

export const deleteEpisode = createAsyncThunk(`${SLICE_NAME}/delete`, async (id) => {
  const { data } = await episodesAPI.delete(id);

  return data;
});

export const fetchEpisode = createAsyncThunk(`${SLICE_NAME}/fetchById`, async ({ id, ...params }) => {
  const { data } = await episodesAPI.fetchById(id, params);

  return data;
});

const episodeSlice = createSlice({
  name: SLICE_NAME,
  initialState: INITIAL_STATE,
  reducers: {
    clearEpisode: () => INITIAL_STATE,
  },
  extraReducers: {
    [fetchEpisode.fulfilled]: (state, { payload }) => {
      state.data = payload;
    },
    [updateLocationEpisodeDates.fulfilled]: (state, { payload }) => {
      state.data = { ...state.data, rehabStates: payload.rehabStates };
    },
    [updateReview.fulfilled]: (state, { payload }) => {
      if (payload.episode) {
        state.data = payload.episode;
      }
    },
    [createReview.fulfilled]: (state, { payload }) => {
      if (payload.episode) {
        state.data = payload.episode;
      }
    },
  },
});

export const getEpisode = (state) => state[SLICE_NAME].data;

export const getEpisodeFetchDone = createApiHasStatusSelector(fetchEpisode, [API_STATES.complete, API_STATES.failed]);

export const { clearEpisode } = episodeSlice.actions;

export default episodeSlice.reducer;
