import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { clearActivities, fetchActivities, getActivities, updateActivity } from '~/ducks/activities';
import { clearAttachments, getAttachmentsCount } from '~/ducks/attachments';
import { fetchClientsForResource } from '~/ducks/clients';
import { clearEpisode, fetchEpisode, getEpisode } from '~/ducks/episode';
import { getEscalationError, updateEscalation } from '~/ducks/escalations';
import {
  clearLocationEpisode,
  fetchDailyPerformance,
  fetchLocationEpisode,
  getLocationEpisode,
} from '~/ducks/locationEpisode';
import { updateProfile } from '~/ducks/profile';
import { fetchRehabStates, getRehabStates } from '~/ducks/rehabStates';
import { addToast } from '~/ducks/toasts';
import { unwrapResult } from '~/lib';
import { useModel, usePrevious, useThunk } from '~/lib/hooks';
import { Episode, LocationEpisode } from '~/models';
import { ActivityFactory } from '~/models/activities';
import { useProfileContext } from '~/services/profile';
import { IN_TREATMENT } from '~/services/rehabState/constants';

import { getChartData, shouldShowPerformanceChart } from './helpers/chartHelpers';
import PatientProfile from './PatientProfile';
import { PatientProfileContext } from './PatientProfileContext';

function PatientProfileContainer(props) {
  const {
    escalationError,
    match: {
      params: { id: episodeId },
    },
    rehabStates,
  } = props;
  const profileSvc = useProfileContext();
  const episode = useModel(Episode, props.episode);
  const locationEpisode = useModel(LocationEpisode, props.locationEpisode);

  const [authorizationChecked, setAuthorizationChecked] = useState(false);
  const [eligibleForProgressUpdate, setEligibleForProgressUpdate] = useState(false);
  const [progressUpdateActivities, setProgressUpdateActivities] = useState([]);
  const [selectedLocationEpisodeId, setSelectedLocationEpisodeId] = useState('');
  const [showProgressUpdateModal, setShowProgressUpdateModal] = useState(false);
  const [mainPageScrollTop, setMainPageScrollTop] = useState(0);

  const handleMainPageScroll = (event) => setMainPageScrollTop(event.target.scrollTop);

  const { data: dailyPerformance } = useThunk(
    fetchDailyPerformance,
    [selectedLocationEpisodeId, progressUpdateActivities.length],
    {
      params: selectedLocationEpisodeId,
      condition: selectedLocationEpisodeId && shouldShowPerformanceChart(progressUpdateActivities),
      parseData: (payload) => payload,
    }
  );

  const progressTemplate = locationEpisode.progressTemplate;
  const { questions } = progressTemplate;
  const prevEscalationError = usePrevious(escalationError);
  const activities = useMemo(() => props.activities?.map((log) => ActivityFactory.get(log)), [props.activities]);
  const chartData = useMemo(
    () => getChartData(progressUpdateActivities, questions).filter((chart) => chart.data.length > 0),
    [progressUpdateActivities, questions]
  );
  const patientName = episode.patient.name;

  const fetchSelectedLocationEpisode = () =>
    props.fetchLocationEpisode({
      id: selectedLocationEpisodeId,
      include:
        'reviews.attrs.attr_values,last_valid_predecessor,question_templates,owner.client.group_types,case_manager.credential,owner.client.enabled_flags',
    });
  const handleAcknowledgeEscalation = (escalationId) => props.updateEscalation({ id: escalationId, include: 'blob' });
  const handleOpenProgressUpdateModal = () => setShowProgressUpdateModal(true);
  const handleCancelProgressUpdateModal = () => setShowProgressUpdateModal(false);
  const handleCloseProgressUpdateModal = () => {
    // completion of first progress update causes state change, so refresh the episode
    if (episode.currentRehabState.state !== IN_TREATMENT) {
      // completion of first progress update causes state change, so refresh the episode
      // TODO: Remove owner.client.enabled_flags include when we remove ALTCS flag
      props.fetchEpisode({ id: episodeId, include: 'episode_groups.location.group_type,owner.client.enabled_flags' });
    }

    setEligibleForProgressUpdate(false);
    fetchSelectedLocationEpisode();

    setShowProgressUpdateModal(false);
  };

  const canCreateProgressUpdate = profileSvc.canCreateProgressUpdate(locationEpisode.locationId);
  const canRefuseService =
    profileSvc.canRefuseService(
      locationEpisode.locationId,
      locationEpisode.rehabInformation?.latestRehabFacilityType
    ) &&
    locationEpisode.id &&
    locationEpisode.inQueue;

  const checkActingClientAuthorized = async () => {
    if (!profileSvc.isAdmin && profileSvc.client.leafDescendants.length === 0) {
      setAuthorizationChecked(true);
      return;
    }

    try {
      const clientsRes = await props.fetchClientsForResource(new Episode({ id: episodeId }));
      const clientIds = unwrapResult(clientsRes).data.map((client) => client.id);

      if (!clientIds.includes(profileSvc.actingClient.id)) {
        const newActingClientId = profileSvc.isAdmin
          ? clientIds[0]
          : clientIds.find((id) => profileSvc.client.leafDescendantIds.includes(id));

        if (!newActingClientId) {
          props.addToast({ text: 'Patient not found' });
          props.history.push('/');
          return;
        }

        const userRes = await props.updateProfile({
          actingClientId: newActingClientId,
          include: 'acting_client,preferences',
        });

        unwrapResult(userRes);
      }

      setAuthorizationChecked(true);
    } catch (e) {
      props.addToast({ text: e.message });
    }
  };

  const invokeFetchActivities = () => {
    props.fetchActivities({ locationEpisodeId: selectedLocationEpisodeId, include: 'attachments.blob' });
  };

  useEffect(() => {
    // User with multiple clients may be trying to view a patient that does not belong to the
    // acting client they are currently scoped to. This method checks valid clients for
    // the episode and automatically updates the acting client id if necessary.
    checkActingClientAuthorized();
  }, [episodeId]);

  useEffect(() => {
    if (authorizationChecked) {
      props.fetchRehabStates();
      // TODO: Remove owner.client.enabled_flags include when we remove ALTCS flag
      props.fetchEpisode({ id: episodeId, include: 'episode_groups.location.group_type,owner.client.enabled_flags' });
    }

    return () => {
      setSelectedLocationEpisodeId('');
      props.clearEpisode();
      props.clearLocationEpisode();
    };
  }, [episodeId, authorizationChecked]);

  useEffect(() => {
    const latestLocationEpisodeId = episode.locationEpisodes.find((le) => le.latest)?.id || '';

    setSelectedLocationEpisodeId(latestLocationEpisodeId);
  }, [episode]);

  useEffect(() => {
    if (selectedLocationEpisodeId) {
      fetchSelectedLocationEpisode();
      invokeFetchActivities();
    }

    return () => {
      props.clearAttachments();
      props.clearActivities();
    };
  }, [selectedLocationEpisodeId]);

  useEffect(() => {
    setEligibleForProgressUpdate(locationEpisode.rehabInformation.eligibleForProgressUpdate);
  }, [locationEpisode]);

  useEffect(() => {
    if (!prevEscalationError && escalationError) {
      invokeFetchActivities();
    }
  }, [escalationError]);

  useEffect(() => {
    const newProgressUpdateActivities = activities.filter((activity) => activity.isProgressUpdate);

    if (
      newProgressUpdateActivities.length &&
      newProgressUpdateActivities.every((newLog) => progressUpdateActivities.some((log) => newLog.id === log.id))
    )
      return;

    setProgressUpdateActivities(newProgressUpdateActivities);
  }, [activities]);

  const patientProfileProps = {
    activities,
    canCreateProgressUpdate,
    canRefuseService,
    chartData,
    dailyPerformance,
    eligibleForProgressUpdate,
    episode,
    locationEpisode,
    mainPageScrollTop,
    onAcknowledgeEscalation: handleAcknowledgeEscalation,
    onCancelProgressUpdateModal: handleCancelProgressUpdateModal,
    onCloseProgressUpdateModal: handleCloseProgressUpdateModal,
    onMainPageScroll: handleMainPageScroll,
    onOpenProgressUpdateModal: handleOpenProgressUpdateModal,
    onUpdateActivity: props.updateActivity,
    patientName,
    progressTemplate: progressTemplate,
    rehabStates,
    setSelectedLocationEpisodeId,
    showProgressUpdateModal,
    attachmentsCount: props.attachmentsCount,
  };

  return (
    <PatientProfileContext.Provider value={patientProfileProps}>
      <PatientProfile />
    </PatientProfileContext.Provider>
  );
}

const mapStateToProps = (state) => ({
  activities: getActivities(state),
  episode: getEpisode(state),
  escalationError: getEscalationError(state),
  locationEpisode: getLocationEpisode(state),
  rehabStates: getRehabStates(state),
  attachmentsCount: getAttachmentsCount(state),
});

const mapDispatchToProps = {
  addToast,
  clearActivities,
  clearAttachments,
  clearEpisode,
  clearLocationEpisode,
  fetchActivities,
  fetchClientsForResource,
  fetchEpisode,
  fetchLocationEpisode,
  fetchRehabStates,
  updateActivity,
  updateEscalation,
  updateProfile,
};

PatientProfileContainer.propTypes = {
  activities: PropTypes.instanceOf(Array),
  addToast: PropTypes.func,
  attachmentsCount: PropTypes.number,
  clearActivities: PropTypes.func,
  clearAttachments: PropTypes.func,
  clearEpisode: PropTypes.func.isRequired,
  clearLocationEpisode: PropTypes.func.isRequired,
  episode: PropTypes.instanceOf(Object),
  escalationError: PropTypes.string,
  fetchActivities: PropTypes.func,
  fetchClientsForResource: PropTypes.func,
  fetchEpisode: PropTypes.func,
  fetchLocationEpisode: PropTypes.func,
  fetchRehabStates: PropTypes.func,
  locationEpisode: PropTypes.instanceOf(Object),
  rehabStates: PropTypes.instanceOf(Array),
  updateActivity: PropTypes.func,
  updateEscalation: PropTypes.func,
  updateProfile: PropTypes.func,
};

export default connect(mapStateToProps, mapDispatchToProps)(PatientProfileContainer);
