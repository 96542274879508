import React from 'react';
import styled from 'styled-components';

import { useMenuContext } from '~/components/shared/menu';

export interface ItemProps extends React.HTMLAttributes<HTMLDivElement> {
  active?: boolean;
}

export default function MenuItem({ children, active = false, onClick, ...rest }: ItemProps) {
  const { toggle } = useMenuContext();

  // custom onClick handler to close the menu after calling the original onClick handler
  const handleItemClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    onClick?.(e);
    toggle();
  };

  return (
    <ItemContainer active={active} onClick={handleItemClick} {...rest}>
      {children}
    </ItemContainer>
  );
}

const ItemContainer = styled.div<{ active?: boolean }>`
  padding: 10px 12px;
  font-size: 12px;
  cursor: pointer;
  background-color: ${({ theme, active }) => (active ? theme.colors.primaryBlue : theme.colors.white)};
  color: ${({ theme, active }) => (active ? theme.colors.white : theme.colors.black)};
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  &:hover {
    background-color: ${({ theme, active }) => (active ? theme.colors.primaryBlue : theme.colors.primaryBlue15)};
  }
`;
