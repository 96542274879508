import React from 'react';
import { Formik, FormikHelpers } from 'formik';
import { useDispatch } from 'react-redux';

import { usePatientProfileContext } from '~/components/patients/patientProfile/PatientProfileContext';
import { caseManagerFormValidation } from '~/components/patients/patientProfile/PatientStory/CaseManager/caseManagerFormValidation';
import ContentHeading from '~/components/patients/shared/ContentHeading';
import { Button, ButtonGroup } from '~/components/shared/buttons';
import { Form, InputGroup, Select } from '~/components/shared/form';
import FormSection from '~/components/shared/form/FormSection';
import StandardModal from '~/components/shared/modal/StandardModal';
import { updateLocationEpisode } from '~/ducks/locationEpisode';
import { addToast } from '~/ducks/toasts';
import { fetchUsers } from '~/ducks/users';
import { getFullName, getId } from '~/helpers';
import { unwrapResult } from '~/lib';
import { useAsyncOptions } from '~/lib/hooks';
import { User } from '~/models';
import { store } from '~/store';

type CaseManagerFormValues = {
  id: string;
  caseManager: User | null;
};

type Props = {
  caseManager: User | null;
  locationEpisodeId: string;
  ownerGroupId: string;
  show: boolean;
  onCancel: () => void;
  onSuccess: () => void;
};

type Dispatch = typeof store.dispatch;

function CaseManagerModal(props: Props) {
  const { caseManager, locationEpisodeId, ownerGroupId, show, onCancel, onSuccess } = props;

  const dispatch = useDispatch<Dispatch>();
  const { patientName }: any = usePatientProfileContext();

  const caseManagerAsyncOptions = useAsyncOptions(fetchUsers, {
    condition: !!ownerGroupId,
    params: {
      group: ownerGroupId,
      include: 'credential',
      sortBy: 'name',
      active: true,
    },
  });

  const handleUpdateLocationEpisode = async (values: CaseManagerFormValues) => {
    const response = await dispatch(
      updateLocationEpisode({
        id: values.id,
        caseManagerId: values?.caseManager?.id || null,
        include: 'case_manager.credential',
      })
    );

    return unwrapResult(response);
  };

  const handleSubmit = async (
    values: CaseManagerFormValues,
    { setSubmitting }: FormikHelpers<CaseManagerFormValues>
  ) => {
    try {
      await handleUpdateLocationEpisode(values);
      onSuccess();
    } catch (e: any) {
      dispatch(addToast({ text: 'There was an error updating the case manager. Please try again.' }));
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <StandardModal show={show} disableBackdropClick title='Update Case Manager' onCancel={onCancel}>
      <Formik
        initialValues={{ id: locationEpisodeId, caseManager }}
        validationSchema={caseManagerFormValidation}
        onSubmit={handleSubmit}
        validateOnMount>
        {({ isValid, isSubmitting }) => (
          <Form>
            <FormSection>
              <ContentHeading subtitle={`Patient: ${patientName}`} />
              <InputGroup
                {...caseManagerAsyncOptions}
                label='Select a case manager to assign:'
                name='caseManager'
                isClearable
                autoSelectSingleOption={false}
                component={Select}
                getOptionLabel={getFullName}
                getOptionValue={getId}
                data-cy='caseManager'
                placeholder='Case Manager'
              />
            </FormSection>
            <ButtonGroup>
              <Button onClick={onCancel} color='transparent' text='Cancel' />
              <Button type='submit' disabled={!isValid || isSubmitting} loading={isSubmitting} text='Confirm' />
            </ButtonGroup>
          </Form>
        )}
      </Formik>
    </StandardModal>
  );
}

export default CaseManagerModal;
