import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

function ContentHeading(props) {
  return (
    <CenteredHeading>
      {props.title && <h2>{props.title}</h2>}
      {props.subtitle && <SubTitle>{props.subtitle}</SubTitle>}
    </CenteredHeading>
  );
}

ContentHeading.propTypes = {
  subtitle: PropTypes.string,
  title: PropTypes.string,
};

const CenteredHeading = styled.div`
  width: 100%;
  text-align: center;
  margin-bottom: 12px;
`;

const SubTitle = styled.p`
  color: ${({ theme }) => theme.colors.mediumGrey};
  font-weight: bold;
`;

export default ContentHeading;
