import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { BodySmall } from '~/components/shared/typography';

import TaggedUser from './TaggedUser';

const TAG_REGEX = new RegExp(/@\{\{.+?\}\}/);
const NEWLINE_REGEX = new RegExp(/\n/);
const COMBINED_REGEX = new RegExp(`(${TAG_REGEX.source}|${NEWLINE_REGEX.source})`);

function TaggedText({ text, removed }) {
  const textFragments = useMemo(() => text.split(COMBINED_REGEX).filter((t) => t), [text]);

  const renderFragment = (fragment, i) => {
    if (fragment.match(TAG_REGEX)) {
      return <TaggedUser key={i} tag={fragment} />;
    } else if (fragment.match(NEWLINE_REGEX)) {
      return <br key={i} />;
    }
    return fragment;
  };

  return <StyledBodySmall removed={removed}>{textFragments.map(renderFragment)}</StyledBodySmall>;
}

TaggedText.propTypes = {
  removed: PropTypes.bool,
  text: PropTypes.string.isRequired,
};

export default TaggedText;

const StyledBodySmall = styled(BodySmall)`
  text-decoration: ${({ removed }) => (removed ? 'line-through' : 'none')};
`;
