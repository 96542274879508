import moment from 'moment';

import { ADMISSION, DISCHARGED, IN_TREATMENT, QUEUE, RehabStateApiName } from '~/constants/rehabStates';

interface IRehabStateOptions {
  current: boolean;
  enteredAt: Date;
  id: string;
  position: number;
  state: string;
  apiName: string;
}

const defaults: IRehabStateOptions = {
  current: true,
  enteredAt: new Date(),
  id: '',
  position: 0,
  state: QUEUE,
  apiName: RehabStateApiName.QUEUE,
};

export default class RehabState {
  current: boolean;
  enteredAt: Date;
  id: string;
  position: number;
  state: string;
  apiName: string;

  constructor(options: IRehabStateOptions) {
    const opts = { ...defaults, ...options };

    this.current = opts.current;
    // Using moment prevents date strings from being converted to
    // an earlier date due to client timezone.
    this.enteredAt = moment(opts.enteredAt).toDate();
    this.id = opts.id;
    this.position = opts.position;
    this.state = opts.state;
    this.apiName = opts.apiName;
  }

  // The name getter can be used for form fields where the name property is commonly used.
  get name() {
    return this.state;
  }

  get queue() {
    return this.state === QUEUE;
  }

  get admission() {
    return this.state === ADMISSION;
  }

  get inTreatment() {
    return this.state === IN_TREATMENT;
  }

  get discharged() {
    return this.state === DISCHARGED;
  }
}
