import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { BodySmallBold } from '~/components/shared/typography';

const QuestionIndex = styled.span`
  margin-right: 8px;
`;

const StyledQuestion = styled(BodySmallBold)`
  display: flex;
`;

const QuestionContent = (props) => (
  <StyledQuestion>
    {props.index >= 0 && <QuestionIndex>{props.index}.</QuestionIndex>}
    <span>{props.text}</span>
  </StyledQuestion>
);

QuestionContent.propTypes = {
  index: PropTypes.number,
  text: PropTypes.string,
};

export default QuestionContent;
