import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import Upload from '~/lib/hooks/useDirectUpload/Upload';

function DocumentSummary({ document }) {
  return (
    <div>
      <Item>{ document.filename }</Item>
    </div>

  );
}

const Item = styled.div`
  margin-bottom: 1em;
`;

DocumentSummary.propTypes = {
  document: PropTypes.instanceOf(Upload).isRequired
};

export default DocumentSummary;
