import React, { useEffect, useMemo, useState } from 'react';
import * as _ from 'lodash-es';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import styled from 'styled-components';

import FilterChips from '~/components/shared/FilterChips';
import SimplePage from '~/components/shared/pageLayout/SimplePage';
import { CASE_MANAGER, LOCATION_TYPE, PATIENT_STATE, PatientState } from '~/constants/filterKeysConstants';
import { clearFilters, getFilters, getSorts, removeFilter, setFilters } from '~/ducks/portfolioFilters';
import { fetchRehabStates, getRehabStates, getRehabStatesLoaded } from '~/ducks/rehabStates';
import { updateUserPreference } from '~/ducks/userPreferences';
import { useDeepEffect, useDeepEffectCompare, useDeepMemo, usePrevious } from '~/lib/hooks';
import { ALL_FLAGS } from '~/models';
import { PatientBoardRequestService } from '~/services/PatientBoardRequestService';
import { useProfileContext } from '~/services/profile';

import PatientBoard from './PatientBoard';
import PatientFilterBar from './PatientFilterBar';
import PatientTabFilters from './PatientTabFilters';

function Patients(props) {
  const profileSvc = useProfileContext();
  const profile = profileSvc.profile;
  const [dataRequestCount, setDataRequestCount] = useState(0);
  const hasImportImprovements = profileSvc.hasFlag(ALL_FLAGS.importImprovements);
  const boardRequestSvc = useDeepMemo(
    () =>
      new PatientBoardRequestService({
        filters: props.filters,
        hasImportImprovements,
        sorts: props.sorts,
      }),
    [props.filters, props.sorts]
  );
  const currentFilter = profile.actingClientPortfolioFilterPreferences;
  const previousFilter = usePrevious(currentFilter);

  useEffect(() => {
    props.fetchRehabStates();
  }, [profile.actingClient.id]);

  useDeepEffect(() => {
    if (props.filters[LOCATION_TYPE] || props.filters[PATIENT_STATE]) {
      onFiltersChanged();
    }
  }, [props.filters]);

  const onFiltersChanged = async () => {
    await boardRequestSvc.requestAllLanes();
    setDataRequestCount((prevCount) => prevCount + 1);
  };

  useDeepEffectCompare(
    currentFilter,
    previousFilter,
    () => {
      const isCurrentFilterUnsaved = currentFilter && !currentFilter.id;
      const previousAndCurrentClientIdsMatch = previousFilter?.clientId === currentFilter?.clientId;

      // "previousAndCurrentClientIdsMatch" will be false when a user switches user's acting client
      if (isCurrentFilterUnsaved || previousAndCurrentClientIdsMatch) {
        props.updateUserPreference(currentFilter.serialize());
      }
    },
    [currentFilter]
  );

  const showProviderType = (filterKey) => {
    switch (props.filters[PATIENT_STATE]) {
      // CURRENT if post acute user is on provider tab.
      // NULL if acute user is on a provider tab.
      case PatientState.CURRENT:
      case null:
        return _.snakeCase(filterKey) === props.filters[LOCATION_TYPE];

      // Don't show provider type on CONTINUED or LATEST. We no longer return providers
      // that ARE NOT associated to the user's enabled care options for performance reasons.
      default:
        return false;
    }
  };

  const tabSpecificFilters = useMemo(
    () =>
      Object.fromEntries(
        Object.entries(props.filters)
          .filter(([filterKey, filterValue]) => {
            if (filterValue && Array.isArray(filterValue)) {
              // TODO: We need to get away from this hardcoded check for rehab facility and transition
              // to "provider" instead
              return filterValue[0]?.type !== 'Location::RehabFacility' || showProviderType(filterKey);
            }

            return true;
          })
          .sort(([, aValues], [, bValues]) => {
            const isProvider = (values) =>
              values && Array.isArray(values) && values[0]?.type === 'Location::RehabFacility';

            return isProvider(bValues) - isProvider(aValues); // makes provider chips appear first
          })
      ),
    [props.filters]
  );

  const getChipLabel = (filterType, filterValue) => {
    if (filterType === CASE_MANAGER) {
      return filterValue.fullName;
    }
  };

  return (
    <SimplePage>
      <SimplePage.Header id='pageHeader'>
        <PatientFilterBar filters={tabSpecificFilters} history={props.history} key={profile.actingClient.id} />
        <PatientTabFilters dataRequestCount={dataRequestCount} />
      </SimplePage.Header>
      <SimplePage.SubHeader>
        <PatientFilterChips>
          <FilterChips
            filters={tabSpecificFilters}
            onClickRemoveFilter={props.removeFilter}
            getChipLabel={getChipLabel}
          />
        </PatientFilterChips>
      </SimplePage.SubHeader>
      <SimplePage.Content>
        {props.rehabStatesLoaded && (
          <PatientBoard boardRequestSvc={boardRequestSvc} filters={props.filters} rehabStates={props.rehabStates} />
        )}
      </SimplePage.Content>
    </SimplePage>
  );
}

Patients.propTypes = {
  clearFilters: PropTypes.func.isRequired,
  fetchRehabStates: PropTypes.func.isRequired,
  filters: PropTypes.instanceOf(Object),
  rehabStates: PropTypes.instanceOf(Object),
  rehabStatesLoaded: PropTypes.bool.isRequired,
  removeFilter: PropTypes.func.isRequired,
  setFilters: PropTypes.func.isRequired,
  sorts: PropTypes.instanceOf(Object).isRequired,
  updateUserPreference: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  filters: getFilters(state),
  rehabStates: getRehabStates(state),
  rehabStatesLoaded: getRehabStatesLoaded(state),
  sorts: getSorts(state),
});

const mapDispatchToProps = {
  clearFilters,
  fetchRehabStates,
  removeFilter,
  setFilters,
  updateUserPreference,
};

export default connect(mapStateToProps, mapDispatchToProps)(Patients);

const PatientFilterChips = styled.div`
  margin-bottom: 24px;
`;
