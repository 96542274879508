import { createSelector, createSlice } from '@reduxjs/toolkit';

import {
  EPISODE_CLASSIFICATIONS,
  HOME_HEALTH_AGENCY,
  HOSPITALS,
  LOCATION_TYPE,
  PAYERS,
  PHYSICIAN_GROUP,
  PLAN_TYPE_CLASSIFICATIONS,
  SKILLED_NURSING_FACILITY,
} from '~/constants/filterKeysConstants';
import { createAsyncThunk } from '~/lib';
import { FilterOption } from '~/models';
import insightsApi from '~/services/api/insightsApi';

import { initializeFilter } from '../filterOptions';
import { updateProfile } from '../profile';

import { getSelectedFilters } from './selectedFilters';

export const sliceName = 'insightsV1/filterOptions';

const areFilterOptionsPopulated = (state) => {
  const filterOptions = getFilterOptions(state);

  return filterOptions !== initialState;
};

export const fetchFilterOptions = createAsyncThunk(
  `${sliceName}/fetch`,
  async (params) => {
    const { data } = await insightsApi.filterOptions.invoke(params);

    return data;
  },
  {
    defaultValue: [],
    modelClass: FilterOption,
    condition: (_, { getState }) => {
      const state = getState();

      return !areFilterOptionsPopulated(state);
    },
  }
);

export const fetchFilterOptionsNoCache = createAsyncThunk(
  `${sliceName}/fetch/noCache`,
  async (params) => {
    const { data } = await insightsApi.filterOptions.invoke(params);

    return data;
  },
  {
    defaultValue: [],
    modelClass: FilterOption,
  }
);

export const initialState = {
  ...initializeFilter(HOSPITALS),
  ...initializeFilter(PHYSICIAN_GROUP),
  ...initializeFilter(PAYERS),
  ...initializeFilter(HOME_HEALTH_AGENCY),
  ...initializeFilter(SKILLED_NURSING_FACILITY),
  ...initializeFilter(PLAN_TYPE_CLASSIFICATIONS),
  ...initializeFilter(EPISODE_CLASSIFICATIONS),
};

const slice = createSlice({
  name: sliceName,
  initialState,
  reducers: {},
  extraReducers: {
    [fetchFilterOptions.fulfilled]: (_state, { payload }) => payload,
    [updateProfile.fulfilled]: (state, { payload }) => {
      if (payload.actingClient) {
        return initialState;
      }

      return state;
    },
  },
});

export const getFilterOptions = (state) => state[sliceName];

export const getPostAcuteFilterOptions = createSelector(
  getFilterOptions,
  getSelectedFilters,
  (filterOptions, selectedFilters) => {
    const selectedLocationType = selectedFilters[LOCATION_TYPE];

    return filterOptions[selectedLocationType?.filterKey] || {};
  }
);

export default slice.reducer;
