
import { useState } from 'react';

function useCollapsible({ open } = {}) {
  const [collapsed, setCollapsed] = useState(!open);

  const toggleCollapse = () => {
    setCollapsed(!collapsed);
  };

  return {
    collapsed,
    toggleCollapse
  };
}

export default useCollapsible;
