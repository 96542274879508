import { PERMISSIONS } from '~/services/profile';

import { REHAB_STATE_NAMES } from './constants';

const [QUEUE, ADMISSION, IN_TREATMENT, DISCHARGED] = REHAB_STATE_NAMES;

const transitionRules = {
  [QUEUE]: {
    accepts: [QUEUE, ADMISSION],
    rejects: [
      {
        states: [IN_TREATMENT],
        message:
          "This patient must have a progress update completed in order to move to In Treatment. \nClick on the patient to complete their progress update. Once it's complete, the patient will automatically move to In Treatment.",
      },
      {
        states: [DISCHARGED],
        message:
          'Patient must be admitted or removed from the Queue in Olio. Contact support@olio.health for assistance.',
      },
    ],
    permission: {},
  },
  [ADMISSION]: {
    accepts: [ADMISSION, DISCHARGED],
    rejects: [
      {
        states: [IN_TREATMENT],
        message:
          "This patient must have a progress update completed in order to move to In Treatment. \nClick on the patient to complete their progress update. Once it's complete, the patient will automatically move to In Treatment.",
      },
      {
        states: [QUEUE],
        message:
          'Admitted patients cannot be moved back to Queue. If you need assistance, please contact support@olio.health.',
      },
    ],
    permission: {
      permission: PERMISSIONS.rehabStateAdmissionCreate,
      message:
        'You currently do not have permission to admit patients in Olio. Contact support@olio.health with questions. ',
    },
  },
  [IN_TREATMENT]: {
    accepts: [IN_TREATMENT, DISCHARGED],
    rejects: [
      {
        states: [QUEUE, ADMISSION],
        message: 'A patient cannot be moved back to Admission or Queue after completing their first treatment.',
      },
    ],
    permission: {},
  },
  [DISCHARGED]: {
    accepts: [DISCHARGED],
    rejects: [
      {
        states: [QUEUE, ADMISSION, IN_TREATMENT],
        message:
          'Discharged Patients cannot be moved back to Queue, Admission or In Treatment. If you need assistance, please contact support@olio.health',
      },
    ],
    permission: {
      permission: PERMISSIONS.rehabStateDischargeCreate,
      message:
        'You currently do not have permission to discharge patients in Olio. Contact support@olio.health with questions. ',
    },
  },
};

export class RehabStateService {
  stateName: string;
  state: string | undefined;
  constructor(stateName: string) {
    this.stateName = stateName;
    this.state = REHAB_STATE_NAMES.find((state) => {
      return state === stateName;
    });
  }

  get canDischarge() {
    return this.stateName !== DISCHARGED && this.stateName !== QUEUE && this.stateName !== '';
  }

  canChangeState(targetStateName: string | number, profileSvc: { has: (arg0: string) => any }) {
    const acceptRules = transitionRules[this.stateName].accepts;
    const rejectRules = transitionRules[this.stateName].rejects;
    const permission = transitionRules[targetStateName].permission;

    const matchRule = (rules: any[]) =>
      rules.find((rule) => {
        const states = typeof rule !== 'string' ? rule.states : [rule];

        return states.find((stateName: string | number) => stateName === targetStateName);
      });

    const getMessage = (rule: { hasOwnProperty: (arg0: string) => any; message: any }) => {
      // eslint-disable-next-line no-prototype-builtins
      if (rule && rule.hasOwnProperty('message')) {
        return rule.message;
      }

      return null;
    };

    const hasPermission = permission.permission ? profileSvc.has(permission.permission) : true;

    if (!hasPermission) {
      return {
        valid: false,
        message: permission.message,
      };
    }

    const acceptRule = matchRule(acceptRules);

    if (acceptRule) {
      return {
        valid: true,
        message: getMessage(acceptRule),
      };
    }

    const rejectRule = matchRule(rejectRules);

    return {
      valid: false,
      message: getMessage(rejectRule),
    };
  }
}
