export const formFields = {
  episodeClassificationField: { name: 'episodeClassification' },
  hospitalField: { label: 'Hospital', name: 'hospital' },
  locationTypeField: { name: 'locationType' },
  ownerField: { name: 'owner' },
  planTypeClassificationField: { name: 'planTypeClassification' },
  physicianTeamField: { label: 'Physician', name: 'physicianTeam' },
  rehabFacilityField: { label: 'Post Acute', name: 'rehabFacility' },
  caseManagerField: { label: 'Case Manager (optional)', name: 'caseManager', placeholder: 'Case Manager' },
};
