import React from 'react';
import PropTypes from 'prop-types';

import theme from '~/styles/theme';

function FolderIcon({ fill, size }) {
  return (
    <svg width={size} height={size} viewBox='0 0 32 30' version='1.1' xmlns='http://www.w3.org/2000/svg'>
      <g id='Files-Tab---Empty-State' transform='translate(-659.000000, -337.000000)' fill={fill} fillRule='nonzero'>
        <g id='Group-10' transform='translate(207.000000, 306.000000)'>
          <g id='Group-7' transform='translate(337.000000, 31.000000)'>
            <g id='doc-folder' transform='translate(115.000000, 0.000000)'>
              <path
                d='M30.6666667,9.33333333 L6.66666667,9.33333333 C5.930287,9.33333333 5.33333333,9.930287 5.33333333,10.6666667 L5.33333333,25.3333333 C5.33333333,26.069713 4.73637967,26.6666667 4,26.6666667 C3.26362033,26.6666667 2.66666667,26.069713 2.66666667,25.3333333 L2.66666667,2.66666667 L8.62,2.66666667 L10.8866667,6.07333333 C11.1348179,6.44527454 11.5528788,6.66807347 12,6.66666667 L25.3333333,6.66666667 L25.3333333,7.99999338 L28,7.99999338 L28,5.33333333 C28,4.59695367 27.4030463,3.99999338 26.6666667,3.99999338 L12.7133333,3.99999338 L10.4466667,0.593333333 C10.1985154,0.221392122 9.78045449,-0.00140680684 9.33333333,-6.62270846e-06 L1.33333333,-6.62270846e-06 C0.596953667,-6.62270846e-06 0,0.596953667 0,1.33333333 L0,25.3333333 C0.00804376793,27.5391319 1.79420143,29.3252896 4,29.3333333 L5.33333333,29.3333333 L5.33333333,29.3333333 L28,29.3333333 C30.2054964,29.3245639 31.9912305,27.5388297 32,25.3333333 L32,10.6666667 C32,9.930287 31.4030463,9.33333333 30.6666667,9.33333333 Z'
                id='Path'
              />
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}

FolderIcon.defaultProps = {
  fill: theme.colors.primaryBlue,
  size: 24,
};

FolderIcon.propTypes = {
  fill: PropTypes.string,
  size: PropTypes.number,
};

export default FolderIcon;
