import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import {
  AdmissionEmptyState,
  DischargedEmptyState,
  InTreatmentEmptyState,
  QueueEmptyState,
} from '~/components/shared/svg';
import { Label } from '~/components/shared/typography';
import { ADMISSION, DISCHARGED, IN_TREATMENT, QUEUE } from '~/services/rehabState/constants';

const LANE_ATTRS = {
  [QUEUE]: {
    component: QueueEmptyState,
    display: 'Queue',
  },
  [ADMISSION]: {
    component: AdmissionEmptyState,
    display: 'Admission',
  },
  [IN_TREATMENT]: {
    component: InTreatmentEmptyState,
    display: 'Treatment',
  },
  [DISCHARGED]: {
    component: DischargedEmptyState,
    display: 'Discharged',
  },
};

function LaneEmptyState({ rehabStateName }) {
  const attrs = LANE_ATTRS[rehabStateName];
  const Component = attrs.component;

  return (
    <Container>
      {Component && <Component />}
      <StateLabel>No patients in {attrs.display}</StateLabel>
    </Container>
  );
}

LaneEmptyState.propTypes = {
  rehabStateName: PropTypes.string.isRequired,
};

export default LaneEmptyState;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  align-items: center;
  justify-content: center;
`;

const StateLabel = styled(Label)`
  color: ${({ theme }) => theme.colors.black50};
  margin-top: 14px;
`;
