import React from 'react';
import styled from 'styled-components';

import Modal from '~/components/shared/modal/Modal';

import Body from './Body';
import Header from './Header';
import List from './List';
import { useTaskModalContext } from './TaskModalContext';

function TaskModal() {
  const { show } = useTaskModalContext();

  return (
    <Modal show={show} style={{ maxWidth: 'none', flex: '0 1 auto', overflow: 'initial' }}>
      <Container data-cy='taskModalContainer'>
        <Header />
        <ContentContainer data-cy='taskModalContentContainer'>
          <List />
          <Body />
        </ContentContainer>
      </Container>
    </Modal>
  );
}

export default TaskModal;

const Container = styled.div`
  width: 960px;
  height: 600px;
  border: 1px solid ${({ theme }) => theme.colors.black25};
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  flex: 1;
`;

const ContentContainer = styled.div`
  display: flex;
  flex: 1;
  overflow: hidden;
`;
