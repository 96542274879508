import React from 'react';
import PropTypes from 'prop-types';

import {
  FormSection,
  InlineInputGroups,
  Input,
  InputGroup,
  SectionHeader,
  Select
} from '~/components/shared/form';
import { yearLimit } from '~/helpers/datePickerHelper';

function PatientInfoSection({ disabled }) {
  const GENDERS = [
    { label: 'Male', value: 'male' },
    { label: 'Female', value: 'female' },
    { label: 'Unknown', value: 'unknown' }
  ];

  return (
    <FormSection>
      <SectionHeader>Patient Information</SectionHeader>
      <InputGroup name='name'
        label='Patient Name'
        placeholder='Patient Name'
        disabled={ disabled }
        component={ Input } />
      <InlineInputGroups>
        <InputGroup name='dateOfBirth.month'
          label='Date of Birth'
          type='number'
          placeholder='MM'
          min='1'
          max='12'
          disabled={ disabled }
          component={ Input } />
        <InputGroup name='dateOfBirth.day'
          type='number'
          placeholder='DD'
          min='1'
          max='31'
          disabled={ disabled }
          component={ Input } />
        <InputGroup name='dateOfBirth.year'
          type='number'
          placeholder='YYYY'
          min='1900'
          max={ yearLimit() }
          disabled={ disabled }
          component={ Input } />
      </InlineInputGroups>
      <InputGroup name='sex'
        label='Gender'
        data-cy='patientGender'
        placeholder='Select Gender'
        options={ GENDERS }
        disabled={ disabled }
        component={ Select } />
    </FormSection>
  );
}

PatientInfoSection.propTypes = {
  disabled: PropTypes.bool
};

export default PatientInfoSection;
