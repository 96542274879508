import React, { useMemo } from 'react';
import { Formik } from 'formik';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Button, ButtonGroup } from '~/components/shared/buttons';
import { Form, FormSection } from '~/components/shared/form';
import { DischargeTemplate } from '~/models';

import { formHelpers, QuestionList } from '../QuestionForm';

function DischargeQuestionsForm(props) {
  const { dischargeTemplate, formValues, checkIfContinuedCare, onComplete, onRevert, stepIndex, updateFormValues } =
    props;

  const questionComponentConfig = useMemo(
    () => formHelpers.questionsToComponentConfig(dischargeTemplate.questions),
    [dischargeTemplate]
  );

  const continuingCare = useMemo(() => checkIfContinuedCare(formValues), [formValues]);
  const submitButtonText = useMemo(() => (continuingCare ? 'Next' : 'Confirm'), [continuingCare]);
  const hasUnansweredQuestions = (values) => {
    return !formHelpers.allQuestionsAnswered(dischargeTemplate.questions, values);
  };

  const handleSubmit = (values) => {
    updateFormValues({ dischargeQuestions: values });
    onComplete({ ...formValues, dischargeQuestions: values }, { completeSequence: !continuingCare });
  };

  return (
    <Formik initialValues={formValues.dischargeQuestions || {}} enableReinitialize onSubmit={handleSubmit}>
      {({ isSubmitting, values }) => (
        <Form>
          <StyledFormSection>
            <QuestionList questionComponentConfig={questionComponentConfig} />
          </StyledFormSection>

          <ButtonGroup>
            <Button color='transparent' onClick={onRevert} text={stepIndex > 0 ? 'Back' : 'Cancel'} />
            <Button
              type='submit'
              data-cy='submitDischargeQuestions'
              disabled={hasUnansweredQuestions(values) || isSubmitting}
              loading={isSubmitting}
              text={submitButtonText}
            />
          </ButtonGroup>
        </Form>
      )}
    </Formik>
  );
}

DischargeQuestionsForm.propTypes = {
  checkIfContinuedCare: PropTypes.func,
  dischargeTemplate: PropTypes.instanceOf(DischargeTemplate).isRequired,
  formValues: PropTypes.instanceOf(Object),
  onComplete: PropTypes.func.isRequired,
  onRevert: PropTypes.func.isRequired,
  stepIndex: PropTypes.number,
  updateFormValues: PropTypes.func,
};

DischargeQuestionsForm.defaultProps = {
  dischargeTemplate: new DischargeTemplate(),
  updateFormValues: () => {},
  checkIfContinuedCare: () => {},
};

export default DischargeQuestionsForm;

const StyledFormSection = styled(FormSection)`
  box-sizing: border-box;
`;
