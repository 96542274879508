import React, { Fragment, useMemo } from 'react';
import { useFormikContext } from 'formik';

import { InputGroup, MultiSelect, MultiSelectLabel } from '~/components/shared/form';
import { HOSPITAL, PHYSICIAN_TEAM } from '~/constants/locationTypes';
import { useAsyncLocationOptions, useDeepEffect } from '~/lib/hooks';
import { Location } from '~/models';

function AcuteAssociatedLocations() {
  const {
    setFieldTouched,
    setFieldValue,
    status: { isEdit },
    values,
  } = useFormikContext();

  const hospitalsFieldName = 'hospitals';
  const rehabFacilitiesFieldName = 'rehabFacilities';
  const physicianTeamsFieldName = 'physicianTeams';
  const location = useMemo(() => new Location(values), [values]);

  const setField = (fieldName, value) => {
    setFieldValue(fieldName, value, false);
    setFieldTouched(fieldName, false, false);
  };

  useDeepEffect(() => {
    if (!isEdit && values?.locationType) {
      setField(hospitalsFieldName, []);
      setField(rehabFacilitiesFieldName, []);
      setField(physicianTeamsFieldName, []);
    }
  }, [values.locationType]);

  const asyncHospitalOptions = useAsyncLocationOptions({
    condition: !location.isHospital,
    params: { type: HOSPITAL },
  });

  const asyncPhysicianTeamOptions = useAsyncLocationOptions({
    params: { type: PHYSICIAN_TEAM },
  });

  const asyncRehabFacilityOptions = useAsyncLocationOptions({
    params: {
      isProvider: true,
      include: 'groupType',
    },
  });

  return (
    <Fragment>
      {!location.isHospital && (
        <InputGroup
          {...asyncHospitalOptions}
          closeMenuOnSelect={false}
          name='hospitals'
          label='Hospitals'
          data-cy='hospitals'
          placeholder='Hospitals'
          component={MultiSelect}
          labelComponent={MultiSelectLabel}
        />
      )}

      <InputGroup
        {...asyncRehabFacilityOptions}
        closeMenuOnSelect={false}
        name='rehabFacilities'
        label='Post Acute Groups'
        data-cy='rehabFacilities'
        placeholder='Post Acute Groups'
        component={MultiSelect}
        labelComponent={MultiSelectLabel}
      />

      <InputGroup
        {...asyncPhysicianTeamOptions}
        closeMenuOnSelect={false}
        name='physicianTeams'
        label='Physician Teams'
        data-cy='physicianTeams'
        placeholder='Physician Teams'
        component={MultiSelect}
        labelComponent={MultiSelectLabel}
      />
    </Fragment>
  );
}

export default AcuteAssociatedLocations;
