import React from 'react';
import { isEqual } from 'lodash';
import styled from 'styled-components';

import { createColumnHelper } from '@tanstack/react-table';

import { CopyIcon, DeactivateIcon, EditedPermissionsIcon } from '~/components/shared/svg';
import { ParentLabel } from '~/components/shared/table';
import { BodySmall } from '~/components/shared/typography';
import { PAYOR } from '~/constants/clientTypes';
import { simpleDateAtTime } from '~/lib/formatDate';
import { User } from '~/models';
import { colors } from '~/styles/theme';

import UsersFlyoutMenu, { permissions } from './UsersFlyoutMenu';

const columnHelper = createColumnHelper<User>();

type ColumnFnProps = {
  profileSvc: any;
} & React.ComponentProps<typeof UsersFlyoutMenu>;

export default function usersTableColumns({ profileSvc, onCopy, ...usersFlyoutMenuProps }: ColumnFnProps) {
  const columns = [
    columnHelper.accessor((row) => row.fullName, {
      id: 'userName',
      header: 'Name',
      cell: (info) => {
        const user = info.row.original;

        if (user.active) {
          return (
            <CopyContainer onClick={() => onCopy(user.fullName)}>
              <BodySmall fontWeight={700}>{info.getValue()}</BodySmall>
              <CopyIconContainer>
                <CopyIcon />
              </CopyIconContainer>
            </CopyContainer>
          );
        }
        return (
          <React.Fragment>
            <DeactivateIcon fill={colors.black25} size={16} />
            <CopyContainer onClick={() => onCopy(user.fullName)}>
              <BodySmall fontWeight={700} color={colors.black25} margin='0 0 0 8px'>
                {info.getValue()}
              </BodySmall>
              <CopyIconContainer>
                <CopyIcon />
              </CopyIconContainer>
            </CopyContainer>
          </React.Fragment>
        );
      },
    }),
    columnHelper.accessor((row) => row.email, {
      id: 'email',
      header: 'Email',
      cell: (info) => (
        <CopyContainer onClick={() => onCopy(info.getValue())}>
          <TruncateEllipsisCell>{info.getValue()}</TruncateEllipsisCell>
          <CopyIconContainer>
            <CopyIcon />
          </CopyIconContainer>
        </CopyContainer>
      ),
    }),
    columnHelper.accessor((row) => row.role.name, {
      id: 'roleName',
      header: 'Role',
      cell: (info) => {
        const user = info.row.original;
        const hasEditedPermissions = !isEqual(user.permissions, user.role.permissions);

        if (hasEditedPermissions) {
          return (
            <React.Fragment>
              <EditedPermissionsIcon fill={colors.black} size={16} />
              <BodySmall margin='0 0 0 8px'>{info.getValue()}</BodySmall>
            </React.Fragment>
          );
        }

        return <BodySmall>{info.getValue()}</BodySmall>;
      },
    }),
    columnHelper.accessor((row) => row.client?.name, {
      id: 'clientName',
      header: 'Client',
      cell: (info) => {
        const isParent = info.row.original.client?.isParent;

        return (
          <BodySmall>
            {info.getValue()}
            {isParent && <ParentLabel />}
          </BodySmall>
        );
      },
    }),
    columnHelper.accessor((row) => row.client?.clientType, {
      id: 'clientType',
      header: 'Client Type',
      cell: (info) => (info.getValue() === PAYOR ? 'Payer' : info.getValue()),
    }),
    columnHelper.accessor((row) => row.enabledProviderTypeNames, {
      id: 'enabledProviderTypeNames',
      header: 'Enabled Care Options',
      enableSorting: false,
      cell: (info) => (info.getValue() ? info.getValue() : <>&mdash;</>),
    }),
    columnHelper.accessor((row) => row.locationNames, {
      id: 'locationNames',
      header: 'Groups',
      enableSorting: false,
    }),
    columnHelper.accessor((row) => row.lastLogin, {
      id: 'lastLogin',
      header: 'Last Login',
      cell: (info) => <div>{info.getValue() ? simpleDateAtTime(info.getValue()) : ''}</div>,
    }),
  ];

  if (profileSvc.hasAny(permissions)) {
    columns.push(
      columnHelper.display({
        id: 'menu',
        cell: (info) => <UsersFlyoutMenu row={info.row} {...usersFlyoutMenuProps} />,
        size: 75,
        meta: { style: { justifyContent: 'flex-end' } },
      })
    );
  }

  return columns;
}

const TruncateEllipsisCell = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const CopyIconContainer = styled.div`
  display: none;
  margin-left: 5px;
`;

const CopyContainer = styled.div`
  display: flex;
  cursor: pointer;
  &:hover ${CopyIconContainer} {
    display: initial;
  }
`;
