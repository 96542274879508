import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { BodySmall, BodySmallBold } from '~/components/shared/typography';

function UserTagSuggestion({ name, locationName }, _search, _highlightedDisplay, _index, focused) {
  return (
    <Container focused={ focused }>
      <BodySmallBold>{ name }</BodySmallBold>
      <BodySmall>&nbsp;- { locationName }</BodySmall>
    </Container>
  );
}

UserTagSuggestion.propTypes = {
  locationName: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired
};

export default UserTagSuggestion;

const Container = styled.div`
  padding: 6px 7px;
  background-color: ${({ focused, theme }) => focused ? theme.colors.primaryBlue10 : theme.colors.white};
`;
