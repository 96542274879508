import client from '~/sources/api/client';

const BASE_URL = '/insights';

export default {
  fetchAnalytics(data) {
    return client.post(`${BASE_URL}/analytics`, data); // technically a POST, semantically a GET
  },
  // remove `fetchInsights` in Insights 2.0 cleanup #187229995
  fetchInsights(url, config = {}) {
    return client.get(`${BASE_URL}/${url}`, config);
  },
};
