import * as Yup from 'yup';

export const intakeValidation = Yup.object().shape({
  name: Yup.string()
    .required('Patient Name is required'),
  dateOfBirth: Yup.object().shape({
    day: Yup.number().typeError('Day Required').required('Day is required'),
    month: Yup.number().typeError('Month Required').required('Month is required'),
    year: Yup.number().typeError('Year Required').required('Year is required')
  }),
  sex: Yup.object().shape({
    label: Yup.string(),
    value: Yup.string()
  }).test('sex', 'Patient Gender is required', ({ value }) => !!value),
  physicianTeam: Yup.object().shape({
    id: Yup.string(),
    name: Yup.string()
  }).nullable().test('physicianTeam', 'Physician is required', (obj) => Boolean(obj && obj.id)),
  hospital: Yup.object().shape({
    id: Yup.string(),
    name: Yup.string()
  }).nullable().test('hospital', 'Hospital is required', (obj) => Boolean(obj && obj.id)),
  rehabFacility: Yup.object().shape({
    id: Yup.string(),
    name: Yup.string()
  }).nullable().test('rehabFacility', 'Post Acute is required', (obj) => Boolean(obj && obj.id))
});
