import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { PatientMatchFoundIcon } from '~/components/shared/svg';
import { BodySmall, H3 } from '~/components/shared/typography';

function MatchNotice({ clientName }) {
  return (
    <Container>
      <PatientMatchFoundIcon />
      <TextContainer>
        <H3 fontSize='20px' margin='0 0 12px 0'>
          Olio found a matching record!
        </H3>
        <BodySmall>You will now be collaborating with {clientName}.</BodySmall>
      </TextContainer>
    </Container>
  );
}

MatchNotice.propTypes = {
  clientName: PropTypes.string.isRequired,
};

export default MatchNotice;

const Container = styled.div`
  background-color: ${({ theme }) => theme.colors.accentGreen10};
  border: 1px solid;
  border-radius: 5px;
  border-color: ${({ theme }) => theme.colors.accentGreen};
  width: 100%;
  padding: 20px 50px;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-wrap: wrap;
`;

const TextContainer = styled.div`
  margin-top: 15px;
  flex-basis: 100%;
  text-align: center;
`;
