import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { CircleCheckIcon, PriorityFlag, WarnIcon } from '~/components/shared/svg';
import { Label, LabelBold } from '~/components/shared/typography';
import { Escalation } from '~/models';
import { colors } from '~/styles/theme';

function Card(props) {
  const {
    active,
    escalation: { acknowledged, id: escalationId, isDefault, patient, summary },
    onClick,
  } = props;

  const icon = useMemo(() => {
    if (acknowledged) return CircleCheckIcon;

    return isDefault ? WarnIcon : PriorityFlag;
  }, [acknowledged, isDefault]);

  const StyledIcon = useMemo(() => {
    return styled(icon).attrs({
      fill: acknowledged ? colors.accentGreen : colors.accentRed,
      size: 12,
    })`
      display: flex;
      margin-left: auto;
    `;
  }, [icon]);

  const handleOnClick = () => onClick(escalationId);

  return (
    <Container onClick={handleOnClick} active={active}>
      <Header>
        <Title>{patient.name}</Title>
        <StyledIcon />
      </Header>
      <Label>{summary}</Label>
    </Container>
  );
}

Card.propTypes = {
  active: PropTypes.bool,
  escalation: PropTypes.instanceOf(Escalation),
  icon: PropTypes.instanceOf(Object),
  onClick: PropTypes.func,
};

export default Card;

const Container = styled.div`
  background-color: ${({ theme }) => theme.colors.white};
  border: 1px solid ${({ active, theme }) => (active ? theme.colors.primaryBlue : theme.colors.black25)};
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  max-width: 280px;
  min-height: 48px;
  min-width: 232px;
  padding: 16px;
`;

const Header = styled.div`
  display: flex;
  flex-direction: row;
`;

const Title = styled(LabelBold)`
  display: flex !important;
  margin-bottom: 8px !important;
`;
