import { useMemo, useRef, useState } from 'react';

import { authService } from '~/services/auth';

import Upload from './Upload';

function useDirectUpload() {
  const accessToken = useRef('');
  const [uploadsMap, setUploadsMap] = useState({});
  const [uploading, setUploading] = useState(false);

  const uploadFiles = async (files) => {
    if (uploading) return null;

    await fetchAccessToken();

    const newUploads = buildDirectUploads(files);

    setUploadsMap((prevUploadsMap) => ({
      ...prevUploadsMap,
      ...newUploads,
    }));

    return await beginUploads(newUploads);
  };

  const beginUploads = async (newUploads) => {
    setUploading(true);

    const results = await Promise.all(Object.values(newUploads).map((upload) => upload.start()));

    setUploading(false);
    return results;
  };

  const handleUploadChange = (upload) => {
    setUploadsMap((prevUploadsMap) => ({
      ...prevUploadsMap,
      [upload.id]: new Upload(upload),
    }));
  };

  const buildDirectUploads = (files) => {
    return files.reduce((acc, file) => {
      const upload = new Upload({
        file,
        onUploadChange: handleUploadChange,
        accessToken: accessToken.current,
      });

      acc[upload.id] = upload;
      return acc;
    }, {});
  };

  const fetchAccessToken = async () => {
    const { accessToken: token } = await authService.renewSession();

    accessToken.current = `Bearer ${token}`;
  };

  const removeUpload = (id) => {
    setUploadsMap((prevUploadsMap) => {
      delete prevUploadsMap[id];

      return { ...prevUploadsMap };
    });
  };

  const clearUploads = () => {
    setUploadsMap({});
  };

  const updateUpload = (id, updatedUpload) => {
    setUploadsMap((prevUploadsMap) => {
      const upload = prevUploadsMap[id];

      prevUploadsMap[id] = new Upload({ ...upload, ...updatedUpload });

      return { ...prevUploadsMap };
    });
  };

  const setInitialUploads = (initUploads) => {
    const map = initUploads.reduce((obj, item) => ({ ...obj, [item.id]: item }), {});

    setUploadsMap(map);
  };

  const uploads = useMemo(() => Object.values(uploadsMap), [uploadsMap]);
  const signedIds = useMemo(() => uploads.map((upload) => upload.signedId).filter((id) => id), [uploads]);

  return {
    clearUploads,
    removeUpload,
    setInitialUploads,
    signedIds,
    updateUpload,
    uploadFiles,
    uploading,
    uploads,
  };
}

export default useDirectUpload;
