import { createEntityAdapter, createSelector, createSlice } from '@reduxjs/toolkit';

import { rootPathnameChanged } from '~/ducks/navigation';
import { titleCase } from '~/helpers';

import { REHAB_STATE_NAMES } from '../services/rehabState/constants';

import { createLocationEpisodeRehabState, fetchLocationEpisode } from './locationEpisode';
import PaginationState from './PaginationState';

const SLICE_NAME = 'locationEpisodes';

const locationEpisodesAdapter = createEntityAdapter();

const initialLaneData = () => ({
  loading: true,
  loadingNextPage: false,
  nextPageURL: '',
  totalCount: null,
});

const buildInitialState = () =>
  locationEpisodesAdapter.getInitialState({
    loading: false,
    laneData: REHAB_STATE_NAMES.reduce((acc, name) => {
      acc[name] = initialLaneData();
      return acc;
    }, {}),
    pagination: new PaginationState(),
  });

const handleUpdateLocationEpisode = (state, { payload }) => {
  const { id, ...attrs } = payload;

  const currentPatient = state.entities[id];

  if (!currentPatient) return;

  const currentPatientState = currentPatient.currentRehabState.state;
  const updatedPatientState = payload.currentRehabState.state;

  if (currentPatientState !== updatedPatientState) {
    state.laneData[currentPatientState].totalCount--;
    state.laneData[updatedPatientState].totalCount++;
  }

  locationEpisodesAdapter.updateOne(state, { id, changes: { ...attrs } });
};

const locationEpisodesSlice = createSlice({
  name: SLICE_NAME,
  initialState: buildInitialState(),
  reducers: {
    addLocationEpisodesToAllLanes: (state, { payload }) => {
      Object.entries(payload).map(([lane, laneData]) => {
        const laneName = titleCase(lane);

        locationEpisodesAdapter.upsertMany(state, laneData.data);
        state.laneData[laneName] = {
          loading: false,
          loadingNextPage: false,
          nextPageURL: laneData.links.next,
          totalCount: laneData.meta.totalRecords,
        };
      });
    },
    addLocationEpisodes: (state, { payload }) => {
      // Portfolio
      if (payload.meta.lane) {
        locationEpisodesAdapter.upsertMany(state, payload.data);
        state.laneData[payload.meta.lane] = {
          loading: false,
          loadingNextPage: false,
          nextPageURL: payload.links.next,
          totalCount: payload.meta.totalRecords,
        };
        // All Patients
      } else {
        locationEpisodesAdapter.setAll(state, payload.data);
        state.pagination = {
          links: payload.links,
          meta: payload.meta,
        };
        state.loading = false;
      }
    },
    clearLaneData: (state, { payload }) => {
      const { selectAll } = locationEpisodesAdapter.getSelectors((state) => state);
      const locationEpisodesToDelete = selectAll(state).filter(
        (entity) => entity?.currentRehabState?.state === payload
      );

      locationEpisodesAdapter.removeMany(
        state,
        locationEpisodesToDelete.map((le) => le.id)
      );

      state.laneData[payload] = initialLaneData();
    },
    clearLocationEpisodes: () => buildInitialState(),
    fetchNextLocationEpisodesPage: (state, { payload }) => {
      if (payload) {
        state.laneData[payload].loadingNextPage = true;
      } else {
        state.loading = true;
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(createLocationEpisodeRehabState.fulfilled, handleUpdateLocationEpisode)
      .addCase(fetchLocationEpisode.fulfilled, handleUpdateLocationEpisode)
      .addCase(rootPathnameChanged, () => buildInitialState());
  },
});

export const {
  addLocationEpisodesToAllLanes,
  addLocationEpisodes,
  clearLaneData,
  clearLocationEpisodes,
  fetchNextLocationEpisodesPage,
} = locationEpisodesSlice.actions;

const getLocationEpisodesState = (state) => state[SLICE_NAME];

export const { selectAll: getLocationEpisodes } = locationEpisodesAdapter.getSelectors(getLocationEpisodesState);

const getAllLaneData = (state) => getLocationEpisodesState(state).laneData;

export const getLocationEpisodesLoading = (state) => getLocationEpisodesState(state).loading;
export const getLocationEpisodesTotalPages = (state) => getLocationEpisodesState(state).pagination.meta.totalPages;
export const getLocationEpisodesTotalRecords = (state) => getLocationEpisodesState(state).pagination.meta.totalRecords;

export const makeGetLaneData = () =>
  createSelector(
    getAllLaneData,
    (_, lane) => lane,
    (allLaneData, lane) => allLaneData[lane]
  );

export const makeGetLocationEpisodesByRehabState = () =>
  createSelector(
    getLocationEpisodes,
    (_, rehabState) => rehabState,
    (locationEpisodes, rehabState) => {
      return locationEpisodes.filter((le) => le.currentRehabState.state === rehabState);
    }
  );

export default locationEpisodesSlice.reducer;
