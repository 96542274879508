import {
  ALTCS_APPLICATION_STATUS_UPDATE,
  AUTHORIZATION_UPDATES,
  DISCHARGE_QUESTIONS,
  NOTE,
  PATIENT_FIELD_CHANGES,
  PATIENT_MATCH_COLLABORATION,
  PROGRESS_UPDATE,
  PROJECTED_DISCHARGE_UPDATES,
  REHAB_STATE_CHANGE,
  SERVICE_REFUSAL,
} from '~/constants/activities';
import ProjectedDischargeUpdates from '~/models/activities/ProjectedDischargeUpdates';

import ModelFactory from '../ModelFactory';

import AltcsApplicationStatusUpdate from './AltcsApplicationStatusUpdate';
import AuthorizationUpdates from './AuthorizationUpdates';
import DischargeQuestions from './DischargeQuestions';
import Note from './Note';
import PatientFieldChanges from './PatientFieldChanges';
import PatientMatchCollaboration from './PatientMatchCollaboration';
import ProgressUpdate from './ProgressUpdate';
import RehabStateChange from './RehabStateChange';
import ServiceRefusalActivity from './ServiceRefusalActivity';

export default class ActivityFactory extends ModelFactory {
  static classMap = {
    [AUTHORIZATION_UPDATES]: AuthorizationUpdates,
    [ALTCS_APPLICATION_STATUS_UPDATE]: AltcsApplicationStatusUpdate,
    [DISCHARGE_QUESTIONS]: DischargeQuestions,
    [NOTE]: Note,
    [PATIENT_FIELD_CHANGES]: PatientFieldChanges,
    [PATIENT_MATCH_COLLABORATION]: PatientMatchCollaboration,
    [PROGRESS_UPDATE]: ProgressUpdate,
    [PROJECTED_DISCHARGE_UPDATES]: ProjectedDischargeUpdates,
    [REHAB_STATE_CHANGE]: RehabStateChange,
    [SERVICE_REFUSAL]: ServiceRefusalActivity,
  };

  static defaultClass = Note;
}
