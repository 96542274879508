import React, { useMemo } from 'react';
import { Form, Formik } from 'formik';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Button, ButtonGroup } from '~/components/shared/buttons';
import { DatePicker, InputGroup } from '~/components/shared/form';
import { simpleDate } from '~/lib/formatDate';
import { REHAB_STATE_NAMES } from '~/services/rehabState/constants';

const StatusDatesForm = (props) => {
  const { onCancel, onSave, rehabStates } = props;

  const validate = (values) => {
    const errors = REHAB_STATE_NAMES.reduce((errors, name, i) => {
      if (!values[name]) {
        return errors;
      }

      const thisDate = new Date(values[name]);
      const previousStates = REHAB_STATE_NAMES.slice(0, i);

      for (let previousState of previousStates) {
        const prevDate = new Date(values[previousState] || null);

        if (prevDate && thisDate && thisDate < prevDate) {
          errors[name] = `Date must be the same date or a later date than ${previousState} (${simpleDate(prevDate)})`;
          break;
        }
      }

      return errors;
    }, {});

    return errors;
  };

  const initialValues = useMemo(
    () =>
      rehabStates.reduce((acc, rehabState) => {
        acc[rehabState.state] = rehabState.enteredAt;
        return acc;
      }, {}),
    [rehabStates]
  );

  const fieldsToSetTouched = useMemo(() => {
    return REHAB_STATE_NAMES.reduce((acc, name) => {
      acc[name] = true;
      return acc;
    }, {});
  });

  return (
    <div>
      <Formik
        initialValues={initialValues}
        onSubmit={onSave}
        validate={validate}
        initialErrors={validate(initialValues)}
        validateOnChange>
        {({ isSubmitting, isValid, dirty, validateForm, setTouched }) => (
          <Form>
            {REHAB_STATE_NAMES.map((name) => {
              const rehabState = rehabStates.find((rs) => rs.state === name);

              return (
                <InputGroup
                  key={name}
                  name={name}
                  label={name}
                  dateFormat='MM/dd/yyyy'
                  maxDate={new Date()}
                  component={DatePicker}
                  disabled={!rehabState}
                  onChange={() => {
                    // validate entire form when a date is changed
                    // calling setTouched on each field will trigger
                    // the errors to show if the date is invalid
                    validateForm().then(() => {
                      setTouched(fieldsToSetTouched);
                    });
                  }}
                />
              );
            })}
            <Actions>
              <Button color='transparent' onClick={onCancel} text='Cancel' />
              <Button type='submit' disabled={isSubmitting || !isValid || !dirty} text='Submit' />
            </Actions>
          </Form>
        )}
      </Formik>
    </div>
  );
};

StatusDatesForm.propTypes = {
  handleChange: PropTypes.func,
  isSubmitting: PropTypes.func,
  onCancel: PropTypes.func,
  onSave: PropTypes.func,
  onSubmit: PropTypes.func,
  rehabStates: PropTypes.instanceOf(Object),
};

export default StatusDatesForm;

const Actions = styled(ButtonGroup)`
  justify-content: flex-end;
`;
