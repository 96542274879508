import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Label, LabelBold } from '~/components/shared/typography';
import { simpleDate } from '~/lib/formatDate';

function DischargeOverdue({ projectedDischarge }) {
  return (
    <Container>
      <Label>
        Patient's projected discharge date of&nbsp;
        <LabelBold as='span'>{ simpleDate(projectedDischarge) }</LabelBold>&nbsp;
        has passed.  Please provide details regarding any barriers to discharge.
      </Label>
    </Container>
  );
}

DischargeOverdue.propTypes = {
  projectedDischarge: PropTypes.instanceOf(Date).isRequired
};

export default DischargeOverdue;

const Container = styled.div`
  padding: 12px 15px;
  background-color: ${({ theme }) => theme.colors.secondaryOrange25};
  border-radius: 3px;
  margin-bottom: 8px;
`;
