import React from 'react';
import PropTypes from 'prop-types';

import theme from '~/styles/theme';

const InsightsIcon = ({ fill, size }) => {
  return (
    <svg width={size} height={size} viewBox='0 0 17 16' version='1.1' xmlns='http://www.w3.org/2000/svg'>
      <g id='Template' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
        <g transform='translate(-21.000000, -202.000000)' fill={fill} fillRule='nonzero' id='Group-27'>
          <g transform='translate(0.000000, 60.000000)'>
            <g id='Group-26' transform='translate(20.000000, 23.000000)'>
              <g id='Group-10'>
                <g id='Group-11-Copy-3' transform='translate(1.469375, 119.000000)'>
                  <g id='dashboard'>
                    <path
                      d='M8,0 C3.58866667,0 0,3.58866667 0,8 C0,12.4113333 3.58866667,16 8,16 C12.4113333,16 16,12.4113333 16,8 C16,3.58866667 12.4113333,0 8,0 Z M3.33333333,8 C3.33333333,8.368 3.03533333,8.66666667 2.66666667,8.66666667 C2.298,8.66666667 2,8.368 2,8 C2,7.07533333 2.21666667,6.20266667 2.59133333,5.41933333 L3.60866667,6.43666667 C3.43333333,6.926 3.33333333,7.45133333 3.33333333,8 Z M8,10 C6.89733333,10 6,9.10266667 6,8 C6,7.692 6.07533333,7.404 6.2,7.14333333 L3.39066667,4.33333333 L4.33333333,3.39066667 L7.14266667,6.20066667 C7.404,6.076 7.692,6 8,6 C9.10266667,6 10,6.89733333 10,8 C10,9.10266667 9.10266667,10 8,10 Z M13.3333333,8.66666667 C12.9646667,8.66666667 12.6666667,8.368 12.6666667,8 C12.6666667,5.42666667 10.5726667,3.33333333 8,3.33333333 C7.45133333,3.33333333 6.92666667,3.43333333 6.43666667,3.60866667 L5.42,2.59133333 C6.20266667,2.216 7.07533333,2 8,2 C11.3086667,2 14,4.692 14,8 C14,8.368 13.702,8.66666667 13.3333333,8.66666667 Z'
                      id='Shape'
                    />
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

InsightsIcon.defaultProps = {
  fill: theme.colors.white,
  size: 24,
};

InsightsIcon.propTypes = {
  fill: PropTypes.string,
  size: PropTypes.number,
};

export default InsightsIcon;
