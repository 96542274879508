import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useFormikContext } from 'formik';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import styled, { css } from 'styled-components';

import { Attachment } from '~/components/shared/Attachment';
import FieldLabel from '~/components/shared/form/FieldLabel';
import { H3 } from '~/components/shared/typography';
import UserTaggableTextarea from '~/components/shared/UserTaggableTextarea';
import { addToast } from '~/ducks/toasts';
import { useDirectUpload } from '~/lib/hooks';

import FileDropZone from '../FileDropZone';

import ActivityActions from './ActivityActions';

function ActivityInput(props) {
  const {
    canSubmit = false,
    customStyles,
    escalationType,
    isTaggable,
    label,
    locationEpisodeId,
    name = 'note',
    onUploading,
    parentScrollTop,
    placeholder = 'Start typing here to leave a note…',
    showEscalationToggle,
    ...rest
  } = props;

  const { handleSubmit, initialValues, isSubmitting, isValid, values, setFieldValue } = useFormikContext();
  const { clearUploads, removeUpload, setInitialUploads, updateUpload, uploads, uploadFiles, uploading } =
    useDirectUpload();
  const tagInputRef = useRef(null);
  const [textAreaScrollTop, setTextAreaScrollTop] = useState(0);

  const handleScroll = (event) => {
    setTextAreaScrollTop(event.target.scrollTop);
  };

  useEffect(() => {
    onUploading?.(uploading);
  }, [uploading]);

  useEffect(() => {
    if (initialValues[name]?.attachments?.length) {
      setInitialUploads(initialValues[name].attachments);
    }
  }, []);

  useEffect(() => {
    if (!initialValues[name]?.attachments?.length && !values[name].attachments.length) {
      clearUploads();
    }
  }, [values[name].attachments.length]);

  useEffect(() => {
    setFieldValue(`${name}.attachments`, uploads);
  }, [uploads]);

  const handleTagClick = useCallback(() => {
    tagInputRef.current.focus();

    const currentValue = values[name].text;
    const tagStart = currentValue.match(/\s$/) ? '@' : ' @';

    setFieldValue(`${name}.text`, values[name].text + tagStart);
  }, [tagInputRef.current, values[name].text]);

  const handleTextAreaClick = () => tagInputRef.current.focus();
  const handleFormSubmit = (e) => {
    if (uploads.some((upload) => upload.hasError)) {
      props.addToast({ text: 'Please remove and re-upload the file(s) that failed' });
      return;
    }

    handleSubmit(e);
  };

  const handleDocumentTypeChange = (attachmentId, docType) => {
    updateUpload(attachmentId, { docType });
  };

  const handleDeleteDocumentType = (attachmentId) => {
    updateUpload(attachmentId, { docType: null });
  };

  return (
    <ActivityInputWrapper>
      {label && <FieldLabel>{label}</FieldLabel>}
      <FileDropZone onFilesSelected={uploadFiles} currentFileCount={uploads.length} noClick>
        {({ getRootProps, getInputProps, isDragAccept, isDragActive, isDragReject, open: openFileSelector }) => (
          <Container {...getRootProps({ isDragAccept, isDragActive, isDragReject })} customStyles={customStyles}>
            <input {...getInputProps()} />
            <Overlay isDragActive={isDragActive}>
              <OverlayTitle>{isDragAccept ? 'Drag your files here to attach' : 'Invalid files'}</OverlayTitle>
            </Overlay>

            <TextAreaContainer customStyles={customStyles} onClick={handleTextAreaClick} onScroll={handleScroll}>
              <UserTaggableTextarea
                name={`${name}.text,${name}.plaintext,${name}.mentions`}
                locationEpisodeId={locationEpisodeId}
                inputStyles={{ border: 'none', padding: '0px' }}
                inputRef={tagInputRef}
                placeholder={placeholder}
                {...rest}
              />
              <FilesContainer>
                {uploads.map((upload) => (
                  <Attachment
                    error={upload.hasError}
                    key={upload.id}
                    uploadProgress={upload.progress}
                    attachment={upload}
                    onDeleteAttachment={removeUpload}
                    onDeleteDocumentType={handleDeleteDocumentType}
                    onDocumentTypeChange={handleDocumentTypeChange}
                    // If we have a scenario where this component is on a page that can scroll
                    // and this component is scrollable, then we'll have to change this logic.

                    // An example would be if we set a max height for this
                    // on patient story, then this will be scrollable and patient story will be scrollable.
                    // The current implementation favors the parent so in that example if the text area scrolled,
                    // it wouldn't cause the doc type menu to close.
                    parentScrollTop={parentScrollTop || textAreaScrollTop}
                    usePortal={true}
                  />
                ))}
              </FilesContainer>
            </TextAreaContainer>

            <ActivityActions
              canSubmit={canSubmit}
              customStyles={customStyles}
              disableAttach={uploads.length >= 10}
              handleSubmit={handleFormSubmit}
              isTaggable={isTaggable}
              isSubmitting={isSubmitting}
              isValid={isValid}
              isUploading={uploading}
              onAttachClick={openFileSelector}
              showEscalationToggle={showEscalationToggle}
              escalationType={escalationType}
              onTagClick={handleTagClick}
            />
          </Container>
        )}
      </FileDropZone>
    </ActivityInputWrapper>
  );
}

ActivityInput.propTypes = {
  addToast: PropTypes.func.isRequired,
  canSubmit: PropTypes.bool,
  customStyles: PropTypes.instanceOf(Object),
  escalationType: PropTypes.string,
  isTaggable: PropTypes.bool,
  label: PropTypes.string,
  locationEpisodeId: PropTypes.string,
  name: PropTypes.string,
  onSubmit: PropTypes.func,
  onUploading: PropTypes.func,
  parentScrollTop: PropTypes.number,
  placeholder: PropTypes.string,
  showEscalationToggle: PropTypes.bool,
  updateAttachment: PropTypes.func,
};

const mapDispatchToProps = { addToast };

export default connect(null, mapDispatchToProps)(ActivityInput);

const getBorderColor = ({ isDragAccept, isDragReject, theme, customStyles }) => {
  if (isDragAccept) return theme.colors.primaryBlue;
  if (isDragReject) return theme.colors.accentRed;
  return customStyles?.borderColor || theme.colors.black10;
};

const getBorderStyle = ({ isDragActive }) => {
  return isDragActive ? 'dotted' : 'solid';
};

const ActivityInputWrapper = styled.div`
  width: 100%;
`;

const Container = styled.div`
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: 4px;
  border-width: 1px;
  border-style: ${getBorderStyle};
  border-color: ${getBorderColor};
  position: relative;
  box-sizing: border-box;

  &:focus-within {
    border-color: ${({ theme }) => theme.colors.primaryBlue};
  }

  &:focus-visible {
    outline: none;
  }
`;

const Overlay = styled.div`
  position: absolute;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background-color: ${({ theme }) => theme.colors.black50};
  border-radius: 4px;
  display: ${({ isDragActive }) => (isDragActive ? 'flex' : 'none')};
`;

const OverlayTitle = styled(H3)`
  color: ${({ theme }) => theme.colors.white};
`;

const TextAreaContainer = styled.div`
  ${({ customStyles }) => {
    if (customStyles?.textarea?.maxHeight) {
      return css`
        max-height: ${customStyles.textarea.maxHeight};
        overflow-y: auto;
      `;
    }
    return null;
  }};
  display: flex;
  flex-wrap: wrap;
  flex: 1;
  padding: 16px;
  box-sizing: border-box;
  min-height: ${({ customStyles }) => customStyles?.textarea?.minHeight || '140px'};
`;

const FilesContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
  margin-top: 1rem;

  > * + * {
    margin-top: 0.5rem;
  }
`;
