import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import ConfirmationListItem from './ConfirmationListItem';

function ConfirmationList({ items, ...rest }) {
  return (
    <ListContainer>
      {
        items.map((item) => (
          item.value && (
            <ConfirmationListItem key={ item.key || item.label }
              label={ item.label }
              value={ item.value }
              showBottomSeparator={ item.showBottomSeparator }
              { ...rest } />)
        ))
      }
    </ListContainer>
  );
}

const ListContainer = styled.div`
  width: 100%;
`;

ConfirmationList.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.array,
      PropTypes.instanceOf(Object)
    ])
  }))
};

export default ConfirmationList;
