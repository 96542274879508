import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { RefuseServiceIcon } from '~/components/shared/svg';
import { BodySmall, Label } from '~/components/shared/typography';
import { simpleDateAtTime } from '~/lib/formatDate';
import { LocationEpisode, ServiceRefusal } from '~/models';
import { PERMISSIONS, useProfileContext } from '~/services/profile';

import ActionsView from './ActionsView';

function ServiceRefusalView({ locationEpisode, serviceRefusal, showActions }) {
  const profileSvc = useProfileContext();

  if (!serviceRefusal) return null;

  const { createdAt, refusedBy } = serviceRefusal;
  const shouldShowActions = showActions && profileSvc.has(PERMISSIONS.reviewServiceRefusalEdit);

  return (
    <Container data-gtm-id='serviceRefusalView'>
      <Header>
        <StyledRefuseServiceIcon />
        <StyledBodySmall>{`${refusedBy.name} is refusing services at this time`}</StyledBodySmall>
      </Header>
      <Body>
        {shouldShowActions ? (
          <ActionsView locationEpisode={locationEpisode} serviceRefusal={serviceRefusal} />
        ) : (
          <Label>{`All collaborators were notified of refused services on ${simpleDateAtTime(createdAt)}.`}</Label>
        )}
      </Body>
    </Container>
  );
}

ServiceRefusalView.propTypes = {
  locationEpisode: PropTypes.instanceOf(LocationEpisode),
  serviceRefusal: PropTypes.instanceOf(ServiceRefusal),
  showActions: PropTypes.bool,
};

ServiceRefusalView.defaultProps = {
  showActions: false,
};

export default ServiceRefusalView;

const Container = styled.div`
  background-color: ${({ theme }) => theme.colors.white};
  border: 1px solid ${({ theme }) => theme.colors.black10};
  border-radius: 3px;
  margin-bottom: 21px;
  overflow: hidden;
`;

const Header = styled.div`
  align-items: center;
  background-color: ${({ theme }) => theme.colors.black75};
  display: flex;
  justify-content: center;
  padding: 11px 0px;
`;

const Body = styled.div`
  display: flex;
  justify-content: center;
  padding: 16px 64px;
`;

const StyledRefuseServiceIcon = styled(RefuseServiceIcon).attrs({
  size: 18,
})`
  margin-right: 16px;
`;

const StyledBodySmall = styled(BodySmall)`
  color: ${({ theme }) => theme.colors.white};
`;
