import React from 'react';
import styled from 'styled-components';

import CircleSpinner from '~/components/shared/CircleSpinner';

function LoadingState() {
  return (
    <Container>
      <CircleSpinner />
    </Container>
  );
}

export default LoadingState;

const Container = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
`;
