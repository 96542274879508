import client from './client';

export const BASE_URL = 'location_episodes';

export default {
  fetchDailyPerformance(locationEpisodeId) {
    return client.get(`${BASE_URL}/${locationEpisodeId}/progress_chart`);
  },

  fetchLocationEpisode(id) {
    return client.get(`${BASE_URL}/${id}`);
  },

  fetchLocationEpisodes(locationEpisodesURL = BASE_URL, config = {}) {
    return client.get(locationEpisodesURL, config);
  },

  updateRehabStateDates(params) {
    return client.post(`${BASE_URL}/${params.locationEpisodeId}/date_changes`, {
      states: params.states,
    });
  },
};
