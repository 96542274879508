import { ALTCS_APPLICATION } from '~/constants/reviews';

import Review, { defaults as reviewDefaults } from '../Review';

const defaults = {
  ...reviewDefaults,
  type: ALTCS_APPLICATION,
};

class AltcsApplication extends Review {
  constructor(options = {}) {
    const opts = { ...defaults, ...options };

    super(opts);
  }

  static get type() {
    return ALTCS_APPLICATION;
  }
}

export default AltcsApplication;
