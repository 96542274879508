import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import styled from 'styled-components';

import { BodySmall } from '~/components/shared/typography';
import { AFFILIATE, CLIENT_TYPES } from '~/constants/clientTypes';
import { fetchClients } from '~/ducks/admin/clients';
import {
  allOption,
  clearFilters,
  FILTER_KEYS,
  getFilters,
  getSearch,
  setFilter,
  setSearch,
} from '~/ducks/admin/locationsFilters';
import { useDebounce, useThunk } from '~/lib/hooks';

import { InputGroup, Select } from '../shared/form';
import Search from '../shared/Search';

function LocationsFilterBar(props) {
  const { filters, locationTypes } = props;
  const [searchValue, setSearchValue] = useState(props.search);
  const debouncedSearch = useDebounce(searchValue);

  const { data: clients, loaded: clientsLoaded } = useThunk(fetchClients, [], {
    params: {
      clientType: CLIENT_TYPES.filter((type) => type !== AFFILIATE),
    },
  });

  useEffect(() => {
    props.setSearch(debouncedSearch);
  }, [debouncedSearch]);

  const loaded = clientsLoaded && locationTypes.length > 0;
  const canClear = filters[FILTER_KEYS.LOCATION_TYPE] !== 'all' || filters[FILTER_KEYS.CLIENT] !== 'all';
  const clientOptions = clients.map((client) => ({ label: client.name, value: client.id }));
  const locationTypeOptions = locationTypes.map((type) => ({ label: type.name, value: type.kind }));
  const clientFilterOptions = [allOption, ...clientOptions];
  const locationTypeFilterOptions = [allOption, ...locationTypeOptions];
  const selectedClient = clientFilterOptions.find((opt) => opt.value === filters[FILTER_KEYS.CLIENT]);
  const selectedLocationType = locationTypeFilterOptions.find(
    (opt) => opt.value === filters[FILTER_KEYS.LOCATION_TYPE]
  );

  const setFilterKey = (key, value) => loaded && props.setFilter({ key, value });
  const handleClientChange = (option) => setFilterKey(FILTER_KEYS.CLIENT, option.value);
  const handleLocationTypeChange = (option) => setFilterKey(FILTER_KEYS.LOCATION_TYPE, option.value);
  const handleSearchChange = (e) => setSearchValue(e.target.value);
  const handleSearchClear = () => setSearchValue('');

  return (
    <StyledFilterBar>
      <StyledSearch
        placeholder={'Search'}
        value={searchValue}
        onChange={handleSearchChange}
        onClear={handleSearchClear}
      />
      <StyledInputGroup
        label={'Client'}
        name='client'
        component={Select}
        onChange={handleClientChange}
        options={clientFilterOptions}
        value={selectedClient}
      />
      <StyledInputGroup
        label={'Group Type'}
        name='locationType'
        component={Select}
        onChange={handleLocationTypeChange}
        options={locationTypeFilterOptions}
        value={selectedLocationType}
      />
      {canClear && <Link onClick={props.clearFilters}>Clear Filters</Link>}
    </StyledFilterBar>
  );
}

LocationsFilterBar.propTypes = {
  clearFilters: PropTypes.func.isRequired,
  filters: PropTypes.instanceOf(Object),
  locationTypes: PropTypes.instanceOf(Array),
  search: PropTypes.string,
  setFilter: PropTypes.func.isRequired,
  setSearch: PropTypes.func.isRequired,
};

LocationsFilterBar.defaultProps = {
  selectedFilters: {},
};

const mapStateToProps = (state) => ({
  filters: getFilters(state),
  search: getSearch(state),
});

const mapDispatchToProps = {
  clearFilters,
  setFilter,
  setSearch,
};

export default connect(mapStateToProps, mapDispatchToProps)(LocationsFilterBar);

const StyledSearch = styled(Search)`
  margin-right: 24px;
`;

const StyledInputGroup = styled(InputGroup)`
  max-width: 250px;
  min-width: 150px;
  margin-right: 8px;
`;

const StyledFilterBar = styled.div`
  display: flex;
  align-items: flex-end;
  margin-bottom: 24px;
`;

const Link = styled(BodySmall)`
  color: ${({ theme }) => theme.colors.primaryBlue};
  cursor: pointer;
  position: relative;
  margin-bottom: 10px;
`;
