import React from 'react';
import { components, DropdownIndicatorProps } from 'react-select';

import { DropdownCaretIcon } from '~/components/shared/svg';

const FilterDropdownIndicator = (props: DropdownIndicatorProps) => (
  <components.DropdownIndicator {...props}>
    <DropdownCaretIcon />
  </components.DropdownIndicator>
);

export default FilterDropdownIndicator;
