import React, { useEffect, useMemo, useState } from 'react';
import { camelCase } from 'lodash-es';
import styled from 'styled-components';

import { LevelOfCare, useInsightsContext } from '~/components/insights';
import FilterBar, { Filters } from '~/components/shared/FilterBar';
import FilterMultiSelect from '~/components/shared/FilterMultiSelect';
import { FilterSection } from '~/components/shared/FilterSlideout/FilterSlideout';
import { DatePicker, InputGroup } from '~/components/shared/form';
import { InputContainer } from '~/components/shared/form/DatePicker/DatePickerInput';
import { createProviderDropdown } from '~/constants/filterDropdownConfigs';
import { END_DATE, START_DATE } from '~/constants/filterKeysConstants';
import { GroupType } from '~/models';

type InsightsFilterBarProps = {
  filters: Filters;
  filterSections: FilterSection[];
};

const InsightsFilterBar = ({ filters, filterSections }: InsightsFilterBarProps) => {
  const insightsContext: any = useInsightsContext();
  const { profileSvc, selectedGroupType, setSelectedGroupType, setSelectedFilters } = insightsContext;

  const providerTypeQuickFilterConfig = createProviderDropdown(selectedGroupType);

  const shouldShowQuickFilter = (screenWidth: number) => screenWidth >= 1200;
  const [showQuickFilter, setShowQuickFilter] = useState(shouldShowQuickFilter(window.innerWidth));

  useEffect(() => {
    const handleResize = () => {
      setShowQuickFilter(shouldShowQuickFilter(window.innerWidth));
    };

    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const startDate = filters[START_DATE] as Date;
  const endDate = filters[END_DATE] as Date;
  const maxDate = useMemo(() => new Date(), []);
  const minDate = useMemo(() => new Date('05/01/2020'), []);

  const handleClearFilters = () => {
    Object.entries(filters).forEach(([key, value]) => {
      if (Array.isArray(value)) {
        setSelectedFilters({ [key]: [] });
      }
    });
  };

  return (
    <FilterBar
      showSearchInput={false}
      applyFilters={(filters) => {
        setSelectedFilters(filters);
      }}
      filters={filters}
      showResults={false}
      filterSections={filterSections}
      onClearFilters={handleClearFilters}
      quickFilter={
        showQuickFilter && (
          <FilterMultiSelect
            {...providerTypeQuickFilterConfig}
            key={selectedGroupType.id}
            filterLabel={selectedGroupType.displayName}
            name='insightsQuickFilter'
            onChange={(val) => {
              setSelectedFilters({ [camelCase(selectedGroupType.apiName)]: val });
            }}
            value={filters[camelCase(selectedGroupType.apiName)] || []}
          />
        )
      }
      title={
        <LevelOfCare
          enabledProviderTypes={profileSvc.enabledProviderTypes}
          key={selectedGroupType.id}
          initialSelectedProviderType={selectedGroupType}
          onChange={(groupType: GroupType) => {
            setSelectedGroupType(groupType);
          }}
        />
      }
      callToAction={
        <DatePickerContainer>
          <InputGroup
            name={START_DATE}
            data-gtm-id='insightsStartDateFilter'
            onChange={(val) => setSelectedFilters({ [START_DATE]: val })}
            value={startDate}
            maxDate={endDate}
            minDate={minDate}
            component={DatePicker}
            clearable={false}
          />
          <Emdash>&mdash;</Emdash>
          <InputGroup
            name={END_DATE}
            data-gtm-id='insightsEndDateFilter'
            onChange={(val) => setSelectedFilters({ [END_DATE]: val })}
            value={endDate}
            maxDate={maxDate}
            minDate={startDate}
            component={DatePicker}
            clearable={false}
          />
        </DatePickerContainer>
      }
    />
  );
};

export default InsightsFilterBar;

const DatePickerContainer = styled.div`
  display: flex;
  align-items: center;

  ${InputContainer} {
    max-width: 140px !important;
  }
`;

const Emdash = styled.div`
  margin: 0 8px;
`;
