import React, { Fragment, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';

import { InlineInputGroups, InputGroup, Select } from '~/components/shared/form';
import Search from '~/components/shared/Search';
import { ControlledTable } from '~/components/shared/table';
import { DOC_TYPE } from '~/constants/filterKeysConstants';
import {
  fetchAttachments,
  getAttachments,
  getAttachmentsFetchLoaded,
  getTotalPages,
  updateAttachment,
} from '~/ducks/attachments';
import PaginationState from '~/ducks/PaginationState';
import { useDebounce } from '~/lib/hooks';
import Attachment, { DOCUMENT_TYPES } from '~/models/Attachment';
import { store } from '~/store';

import { PatientProfileContext } from '../PatientProfileContext';

import filesTableColumns from './filesTableColumns';

type Option = {
  name: string;
  value: string;
};

type Filters = {
  [DOC_TYPE]: Option;
  search: string;
};

type Dispatch = typeof store.dispatch;

const ALL_OPTION = { name: 'All', value: '' };
const NONE_OPTION = { name: 'None', value: 'null' };

const FilesTable = () => {
  const [filters, setFilters] = useState<Filters>({ [DOC_TYPE]: ALL_OPTION, search: '' });
  const [pageFilters, setPageFilters] = useState<PaginationState | null>(null);
  const [search, setSearch] = useState('');
  const { locationEpisode }: any | null = useContext(PatientProfileContext);

  const dispatch = useDispatch<Dispatch>();

  useEffect(() => {
    if (pageFilters && locationEpisode.id) {
      const filtersForRequest = Object.entries(filters).map(([key, value]) => {
        if (typeof value === 'object' && key !== 'search') {
          return [key, value.value];
        } else {
          return [key, value];
        }
      });

      dispatch(
        fetchAttachments({
          ...Object.fromEntries(filtersForRequest),
          ...pageFilters,
          include: 'created_by_user,blob',
          locationEpisodeId: locationEpisode.id,
        })
      );
    }
  }, [filters, pageFilters, locationEpisode.id]);

  const files = useSelector(getAttachments);
  const pageCount = useSelector(getTotalPages);
  const attachmentsLoaded = useSelector(getAttachmentsFetchLoaded);

  const debouncedSearch = useDebounce(search);
  const handleSearchChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => setSearch(e.currentTarget.value),
    []
  );
  const handleSearchClear = useCallback(() => setSearch(''), []);

  useEffect(() => {
    setFilters({ ...filters, search: debouncedSearch || '' });
  }, [debouncedSearch]);

  const handleOptionChange = useCallback(
    (option: Option, filterType: string) => setFilters({ ...filters, [filterType]: option }),
    []
  );

  const handlePageFiltersChange = useCallback((newFilters: PaginationState) => setPageFilters(newFilters), []);

  const handleDocumentTypeChange = useCallback((attachmentId: string | null, docType: string | null = null) => {
    dispatch(updateAttachment({ id: attachmentId, docType }));
  }, []);

  const options = useMemo(() => {
    const documentTypeOptions = DOCUMENT_TYPES.map((type) => ({ name: type, value: type }));

    return [ALL_OPTION, ...documentTypeOptions, NONE_OPTION];
  }, []);

  const columns = useMemo(
    () =>
      filesTableColumns({
        handleDocumentTypeChange,
      }),
    []
  );

  return (
    <Fragment>
      <FiltersContainer>
        <StyledSearch value={search} placeholder='Search' onChange={handleSearchChange} onClear={handleSearchClear} />
        <InputGroup
          label='Document Type'
          name={DOC_TYPE}
          options={options}
          value={filters[DOC_TYPE]}
          getOptionLabel={({ name }: Option) => name}
          getOptionValue={({ value }: Option) => value}
          onChange={handleOptionChange}
          component={Select}
        />
      </FiltersContainer>
      <ControlledTable<Attachment>
        data={files}
        defaultSortBy={'createdAt desc'}
        loading={!attachmentsLoaded}
        columns={columns}
        filters={filters}
        onPagingFiltersChange={handlePageFiltersChange}
        pageCount={pageCount}
        pageSize={25}
      />
    </Fragment>
  );
};

export default FilesTable;

const FiltersContainer = styled(InlineInputGroups)`
  & > * {
    max-width: 240px;
  }

  margin-bottom: 32px;
`;

const StyledSearch = styled(Search)`
  margin-right: 24px;
`;
