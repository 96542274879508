import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import {
  EditIcon,
  PatientAuthApprovedIcon,
  PatientAuthDaysExpiredIcon,
  PatientAuthDaysRemainingIcon,
  PatientAuthNotAuthorizedIcon,
} from '~/components/shared/svg';
import { BodySmall, H3, Label } from '~/components/shared/typography';
import { simpleDate } from '~/lib/formatDate';
import { useSwitch } from '~/lib/hooks';
import { Authorization, LocationEpisode, RehabState } from '~/models';
import { PERMISSIONS, useProfileContext } from '~/services/profile';
import { colors, textStyles } from '~/styles/theme';

import { AuthorizationUpdateModal } from './reviews/AuthorizationUpdateModal';

function AuthorizationCard({ authorization, rehabState, locationEpisode, showUpdateButton = true }: Props) {
  const profileSvc: any = useProfileContext();
  const modalSwitch = useSwitch();

  const authorizationNumberDisplay = () => {
    return authorization.authorizationNumber ? authorization.authorizationNumber : <span>&#8212;</span>;
  };

  const daysExpired = () => {
    return !rehabState.queue && (authorization?.daysRemaining ?? 0) <= 0 && authorization.authorizationNumber;
  };

  const daysRemaining = () => {
    return (
      !rehabState.queue &&
      authorization.approvedThroughDate &&
      authorization.authorizationNumber &&
      (authorization?.daysRemaining ?? 0) > 0
    );
  };

  const notAuthorized = () => {
    return !authorization.authorizationNumber && authorization.pending;
  };

  const pendingAdmission = () => {
    return (
      authorization.authorizationNumber &&
      rehabState.queue &&
      authorization.active &&
      !authorization.approvedThroughDate
    );
  };

  const admitted = () => {
    return (
      authorization.authorizationNumber &&
      !rehabState.queue &&
      authorization.accepted &&
      !authorization.approvedThroughDate
    );
  };

  const labelHeaderText = () => {
    if (pendingAdmission() || admitted()) return 'Approved';
    if (daysExpired()) return '0 Days Remaining';
    if (daysRemaining())
      return `${authorization.daysRemaining} Day${authorization.daysRemaining === 1 ? '' : 's'} Remaining`;
    return 'Not Yet Authorized';
  };

  const labelStateText = () => {
    if (pendingAdmission()) return 'Pending Admission';
    if (admitted()) {
      if (profileSvc.canEditAuthorization(locationEpisode.owner.clientId)) {
        return (
          <LabelSelectDateContainer>
            Approved Through:<ActionText onClick={modalSwitch.turnOn}>Select Date</ActionText>
          </LabelSelectDateContainer>
        );
      }

      return 'Initial Approval on ' + simpleDate(authorization.statusUpdatedDate);
    }
    if (daysExpired() || daysRemaining()) return 'Approved Through: ' + simpleDate(authorization.approvedThroughDate);

    return 'Pending Approval';
  };

  const getIcon = () => {
    if (notAuthorized()) return <PatientAuthNotAuthorizedIcon />;
    if (pendingAdmission() || admitted()) return <PatientAuthApprovedIcon />;
    if (daysExpired()) return <PatientAuthDaysExpiredIcon />;
    if (daysRemaining()) return <PatientAuthDaysRemainingIcon />;
    return <PatientAuthNotAuthorizedIcon />;
  };

  const actionLink = (text: string) => {
    return (
      <ActionIcon onClick={modalSwitch.turnOn} data-cy='authorizationCardUpdateAction'>
        <EditIcon size={12} fill={colors.primaryBlue} />
        <ActionText>{text}</ActionText>
      </ActionIcon>
    );
  };

  return (
    <Fragment>
      <Card data-cy='authorizationCard'>
        <AuthContainer>
          <H3>Authorization</H3>
          {showUpdateButton &&
            profileSvc.isAcute &&
            profileSvc.has(PERMISSIONS.reviewAuthorizationEdit) &&
            actionLink('Update')}
        </AuthContainer>
        <NumberContainer>
          <BodySmall>Number: {authorizationNumberDisplay()}</BodySmall>
        </NumberContainer>
        <Fragment>
          <StatusContainer>
            <AuthStateIcon>{getIcon()}</AuthStateIcon>
            <AuthStateLabelContainer>
              <H3>{labelHeaderText()}</H3>
              <Label>{labelStateText()}</Label>
            </AuthStateLabelContainer>
          </StatusContainer>
        </Fragment>
      </Card>
      <AuthorizationUpdateModal
        show={modalSwitch.state}
        onCancel={modalSwitch.turnOff}
        onSuccess={modalSwitch.turnOff}
        locationEpisode={locationEpisode}
        authorization={authorization}
      />
    </Fragment>
  );
}

AuthorizationCard.propTypes = {
  authorization: PropTypes.instanceOf(Authorization).isRequired,
  locationEpisode: PropTypes.instanceOf(LocationEpisode).isRequired,
  rehabState: PropTypes.instanceOf(RehabState).isRequired,
  showUpdateButton: PropTypes.bool,
};

type Props = PropTypes.InferProps<typeof AuthorizationCard.propTypes>;

export default AuthorizationCard;

const Card = styled.div`
  border: 1px solid ${colors.black10};
  border-radius: 4px;
  background-color: ${colors.white};
  margin-bottom: 24px;
`;

const AuthContainer = styled.div`
  display: flex;
  padding: 24px 24px 8px 24px;
  align-items: center;
  justify-content: space-between;
`;

const NumberContainer = styled.div`
  padding: 0 24px 24px 24px;
`;

const ActionIcon = styled.div`
  display: flex;
  justify-content: space-between;
  cursor: pointer;
  color: ${colors.primaryBlue};
`;

const ActionText = styled.div`
  cursor: pointer;
  color: ${colors.primaryBlue};
  font-size: 12px;
  font-weight: bold;
  font-family: ${textStyles.base.fontFamily};
  margin-left: 4px;
`;

const StatusContainer = styled.div`
  border-top: 1px solid ${colors.black10};
  padding: 24px;
  display: flex;
  justify-content: space-between;
`;

const AuthStateIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const AuthStateLabelContainer = styled.div`
  display: flex;
  flex: 1 1 auto;
  align-self: auto;
  padding-left: 16px;
  align-items: flex-start;
  flex-direction: column;
  & H3 {
    padding-bottom: 4px;
  }
`;

const LabelSelectDateContainer = styled.div`
  display: flex;
`;
