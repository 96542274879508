/* eslint-disable @typescript-eslint/no-explicit-any */
import { createAsyncThunk } from '~/lib';
import { DischargeOption } from '~/models';
import { dischargeOptionsApi } from '~/services/api';

const cancellableFetchDischargeOptions = dischargeOptionsApi.fetch.cancellable();

const SLICE_NAME = 'dischargeOptions';

type FetchParams = {
  episodeId?: string;
}

export const fetchDischargeOptions = createAsyncThunk(
  `${SLICE_NAME}/fetch`,
  async (params: FetchParams) => {
    const res = cancellableFetchDischargeOptions(params).then((arg)=> arg as any);

    return (await res).data;
  },
  {
    defaultValue: [],
    modelClass: DischargeOption
  } as any
);
