import React from 'react';

import AltcsPendingModal from '~/components/patients/patientProfile/PatientStory/reviews/AltcsPendingModal';
import { Button } from '~/components/shared/buttons';
import { useSwitch } from '~/lib/hooks';

import { Container, Text } from './AltcsInfo';

type AltcsInfoProps = {
  locationEpisodeId: string;
  patientName: string;
};

const AltcsAvailableButton = ({
  locationEpisodeId,
  patientName,
}: Pick<AltcsInfoProps, 'locationEpisodeId' | 'patientName'>) => {
  const { state, toggle } = useSwitch();

  return (
    <>
      <Container data-cy='altcsAvailableContainer'>
        <Text>Click below to start ALTCS Pending custodial stay after MDR Approval (UMRN Only)</Text>
        <Button
          disabled={false}
          data-cy='altcsPendingButton'
          fontSize='12px'
          fontWeight={500}
          borderWidth='1px'
          color='primaryWhite'
          text={'Update to ALTCS Pending'}
          onClick={toggle}
        />
      </Container>
      <AltcsPendingModal
        locationEpisodeId={locationEpisodeId}
        patientName={patientName}
        show={state}
        onCancel={toggle}
      />
    </>
  );
};

export default AltcsAvailableButton;
