import React from 'react';
import PropTypes from 'prop-types';

import { Collapsible, CollapsibleContent, CollapsibleTrigger } from '~/components/shared/Collapsible';
import { DischargeQuestions, ProgressUpdate } from '~/models';

import { SectionContainer } from '../../shared';

import Questions from './Questions';

function QuestionsContent({ activity, expand }) {
  return (
    <Collapsible open={expand}>
      {({ collapsed, toggleCollapse }) => (
        <React.Fragment>
          <CollapsibleContent collapsed={collapsed}>
            <SectionContainer>
              <Questions activity={activity} />
            </SectionContainer>
          </CollapsibleContent>

          <SectionContainer>
            <CollapsibleTrigger onClick={toggleCollapse} fontSize='14px'>
              {collapsed ? `Expand ${activity.typeLabel}` : `Collapse ${activity.typeLabel}`}
            </CollapsibleTrigger>
          </SectionContainer>
        </React.Fragment>
      )}
    </Collapsible>
  );
}

QuestionsContent.propTypes = {
  activity: PropTypes.oneOfType([PropTypes.instanceOf(DischargeQuestions), PropTypes.instanceOf(ProgressUpdate)]),
  expand: PropTypes.bool,
};

export default QuestionsContent;
