import React, { useCallback, useMemo } from 'react';
import { setIn, useFormikContext } from 'formik';
import PropTypes from 'prop-types';
import { Mention, MentionsInput } from 'react-mentions';
import styled, { css } from 'styled-components';

import useField from '~/lib/hooks/useField';

const noop = () => {};
const dummyUseField = () => [{}, {}, { setValue: noop, setTouched: noop }];

function TaggableTextarea(props) {
  const {
    data,
    disabled,
    displayTransform,
    hasError,
    inputRef,
    inputStyles,
    markup,
    name,
    onBlur,
    onChange,
    placeholder,
    portalHostId,
    renderSuggestion,
  } = props;

  const { values, setValues, touched, setTouched } = useFormikContext();
  const [textName, plaintextName, mentionsName] = useMemo(() => name.split(','), [name]);

  const optionalUseField = (fieldName) => (fieldName ? useField : dummyUseField);
  const plaintextUseField = useMemo(() => optionalUseField(plaintextName), [plaintextName]);
  const mentionsUseField = useMemo(() => optionalUseField(mentionsName), [mentionsName]);

  const [textField, textMeta, textHelpers] = useField(textName);
  const [plaintextField, , plaintextHelpers] = plaintextUseField(plaintextName);
  const [mentionsField, , mentionsHelpers] = mentionsUseField(mentionsName);

  const handleChange = useCallback(
    (e, text, plaintext, mentions) => {
      if (disabled) return;

      // We have to mutate the formik values object so everything gets set
      // correctly. This isn't an issue since calling setValues handles actually
      // mutating the state properly. When the values object is a class (Note)
      // setting the text property, for example, doesn't invoke any setters within
      // the class. In one instance the values is a Note and the Note has a text
      // property that internally sets the data property's text value as well.
      // Using the spread operator to perform this task was stripping all this away
      // and turning the values into a POJO. The bug surfaces itself when attempting
      // to add a Note from the Patient Story.
      let updatedValues = setIn(values, textName, text);

      updatedValues = setIn(updatedValues, plaintextName, plaintext);
      updatedValues = setIn(updatedValues, mentionsName, mentions);

      // all these values should be set in one transaction otherwise it causes
      // multiple component re-renders and with the new dynamic validator for
      // stoplight status the required validator was not working correctly.
      setValues(updatedValues);

      onChange(e, { text, plaintext, mentions });
    },
    [name, textHelpers, plaintextHelpers, mentionsHelpers, onChange]
  );

  const handleBlur = useCallback(() => {
    // all these values should be set in one transaction otherwise it causes
    // multiple component re-renders and with the new dynamic validator for
    // stoplight status the required validator was not working correctly.
    setTouched({
      ...touched,
      [textName]: true,
      [plaintextName]: true,
      [mentionsName]: true,
    });

    onBlur({
      text: textField.value,
      plaintext: plaintextField.value,
      mentions: mentionsField.value,
    });
  }, [textHelpers, plaintextHelpers, mentionsHelpers, onBlur]);

  const showError = hasError || Boolean(textMeta.error && typeof textMeta.error === 'string' && textMeta.touched);
  const suggestionsPortalHost = portalHostId && document.getElementById(portalHostId);

  return (
    <MentionsWrapper inputStyles={inputStyles} disabled={disabled} hasError={showError}>
      <StyledMentionsInput
        disabled={disabled}
        data-cy='mentionsInput'
        allowSpaceInQuery
        name={textField.name}
        onBlur={handleBlur}
        onChange={handleChange}
        placeholder={placeholder}
        value={textField.value}
        inputRef={inputRef}
        suggestionsPortalHost={suggestionsPortalHost}>
        <Mention
          appendSpaceOnAdd
          data={data}
          displayTransform={displayTransform}
          markup={markup}
          renderSuggestion={renderSuggestion}
        />
      </StyledMentionsInput>
    </MentionsWrapper>
  );
}

TaggableTextarea.propTypes = {
  data: PropTypes.oneOfType([PropTypes.array, PropTypes.func]),
  defaultMentionStyle: PropTypes.instanceOf(Object),
  defaultStyle: PropTypes.instanceOf(Object),
  disabled: PropTypes.bool,
  displayTransform: PropTypes.func,
  hasError: PropTypes.bool,
  inputRef: PropTypes.instanceOf(Object),
  inputStyles: PropTypes.instanceOf(Object),
  markup: PropTypes.string,
  name: PropTypes.string,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  portalHostId: PropTypes.string,
  renderSuggestion: PropTypes.func,
  suggestionsPortalHost: PropTypes.instanceOf(Object),
};

TaggableTextarea.defaultProps = {
  defaultMentionStyle: {},
  defaultStyle: {},
  inputStyles: {},
  markup: '@{{__display__||__id__}}',
  onBlur: noop,
  onChange: noop,
  placeholder: 'Type...',
};

export default TaggableTextarea;

const getBorderColor = ({ theme, hasError, disabled }) => {
  let borderColor = theme.colors.black25;

  if (hasError) {
    borderColor = theme.colors.accentRed;
  }

  if (disabled) {
    borderColor = 'transparent';
  }

  return borderColor;
};

const StyledMentionsInput = styled(MentionsInput)`
  width: 100%;
  flex: 1;
`;

export const suggestionsListStyles = css`
  box-shadow: 0 8px 16px 0 ${({ theme }) => theme.colors.boxShadow};
  border: 1px solid ${({ theme }) => theme.colors.black05};
  border-radius: 3px;
  padding: 8px 5px !important;
  max-height: 168px;
  overflow-y: auto;
`;

const MentionsWrapper = styled.div`
  width: 100%;
  align-self: stretch;
  font-family: Lato;

  ${StyledMentionsInput} {
    & textarea {
      border: ${({ inputStyles, ...rest }) => inputStyles.border || `1px solid ${getBorderColor(rest)}`};
      border-radius: 3px;
      color: transparent;
      caret-color: ${({ theme }) => theme.colors.black};
      min-height: ${({ inputStyles }) => inputStyles.minHeight || '0px'};
      padding: ${({ inputStyles }) => inputStyles.padding || '8px'};
      font-size: ${({ inputStyles, theme }) => inputStyles.fontSize || theme.textStyles.bodySmall.fontSize} !important;
      line-height: ${({ inputStyles, theme }) => inputStyles.lineHeight || theme.textStyles.base.lineHeight};
      cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'inherit')};
      outline: none;

      &::placeholder {
        color: ${({ theme }) => theme.colors.black25};
      }

      &:focus {
        border-color: ${({ theme, hasError }) => (hasError ? theme.colors.accentRed : theme.colors.primaryBlue)};
      }
    }

    &__highlighter {
      border: ${({ inputStyles }) => inputStyles.border || '1px solid transparent'} !important;
      font-size: ${({ inputStyles, theme }) => inputStyles.fontSize || theme.textStyles.bodySmall.fontSize} !important;
      line-height: ${({ inputStyles, theme }) => inputStyles.lineHeight || theme.textStyles.base.lineHeight};
      padding: ${({ inputStyles }) => inputStyles.padding || '8px'};
      min-height: ${({ inputStyles }) => inputStyles.minHeight || '0px'};
    }

    &__highlighter strong {
      color: ${({ theme }) => theme.colors.primaryBlue};
      background-color: ${({ theme }) => theme.colors.primaryBlue10};
      border-radius: 3px;
      font-weight: 700 !important;
      letter-spacing: -0.01em;
    }

    &__highlighter__substring {
      visibility: inherit !important;
      color: ${({ theme }) => theme.colors.black};
    }

    &__suggestions {
      ${suggestionsListStyles};
    }
  }
`;
