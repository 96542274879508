import React, { useState } from 'react';
import styled from 'styled-components';

import { Filters } from '~/components/shared/FilterBar';
import Slideout from '~/components/shared/Slideout';
import { useDeepMemo, useThunk } from '~/lib/hooks';
import colors from '~/styles/theme/colors';

import FilterMultiSelect from '../FilterMultiSelect';
import { FilterMultiSelectFilter } from '../FilterMultiSelect/FilterMultiSelect';

import FilterSlideoutButtons from './FilterSlideoutButtons';

const parseCountDefault = (data: { meta: { totalRecords: number } }) => data.meta.totalRecords;

export type FilterSection = {
  title: string;
  filters: FilterMultiSelectFilter[];
};

type FilterSlideoutProps = {
  handleClose: () => void;
  filters: Filters;
  filterSections: FilterSection[];
  showResults?: boolean;
  resultsText?: string;
  handleApply: (filters: Filters) => void;
  fetchCountThunk?: () => void;
  filterFormatter?: (filters: Filters) => object;
  parseCount?: (data: any) => number;
};

const FilterSlideout = ({
  handleClose,
  filters,
  filterSections,
  showResults = false,
  resultsText,
  handleApply,
  fetchCountThunk,
  filterFormatter,
  parseCount = parseCountDefault,
}: FilterSlideoutProps) => {
  const [localFilters, setLocalFilters] = useState(filters);
  const [resultsCount, setResultsCount] = useState(0);

  const localFilterCount = useDeepMemo(
    () => Object.entries(localFilters).reduce((acc, [, value]) => (Array.isArray(value) ? acc + value.length : acc), 0),
    [localFilters]
  );

  const handleCancel = () => {
    setLocalFilters(filters);
  };

  const handleClear = () => {
    const resetFilters = Object.entries(localFilters).reduce((acc, [key, value]) => {
      if (Array.isArray(value)) {
        return { ...acc, [key]: [] };
      }
      return { ...acc, [key]: value };
    }, {});

    setLocalFilters(resetFilters as Filters);
  };

  fetchCountThunk &&
    filterFormatter &&
    useThunk(fetchCountThunk, [localFilters], {
      onSuccess: (data: any) => setResultsCount(parseCount(data)),
      params: { pageSize: 1, page: 1, ...filterFormatter(localFilters) },
    });

  return (
    <Slideout handleClose={handleClose}>
      <Slideout.Header>
        <Title>Filters</Title>
      </Slideout.Header>
      <Slideout.Content>
        {filterSections.map((section: FilterSection, i) => (
          <Section key={i}>
            <SectionTitle> {section.title} </SectionTitle>
            {section.filters.map((filter: FilterMultiSelectFilter, index) => (
              <DropdownContainer key={index}>
                <FilterMultiSelect
                  {...filter}
                  onChange={(val) => setLocalFilters({ ...localFilters, [filter.name]: val } as Filters)}
                  value={localFilters[filter.name] || []}
                />
              </DropdownContainer>
            ))}
          </Section>
        ))}
      </Slideout.Content>
      <Slideout.Footer>
        {showResults && (
          <Results>
            <P data-cy='filterSlideoutTotal'>{`${resultsCount} ${resultsText || 'results'}`} found</P>
          </Results>
        )}
        <Footer>
          <Link disabled={!localFilterCount} onClick={handleClear}>
            Clear Filters
          </Link>
          <FilterSlideoutButtons handleApply={() => handleApply(localFilters)} handleCancel={handleCancel} />
        </Footer>
      </Slideout.Footer>
    </Slideout>
  );
};

const Title = styled.h2`
  font-size: 16px;
  font-weight: bold;
  color: ${colors.black};
  margin: 0;
`;

const Section = styled.div`
  margin-bottom: 16px;
`;

const SectionTitle = styled.h3`
  font-size: 12px;
  font-weight: bold;
  color: ${colors.black};
  margin: 0;
  padding-bottom: 24px;
`;

const DropdownContainer = styled.div`
  padding-bottom: 24px;
`;

const Results = styled.div`
  height: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: 1px solid ${colors.black10};
`;

const Footer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  padding: 16px 24px;
`;

const Link = styled.a`
  font-size: 14px;
  color: ${({ disabled }: { disabled: boolean }) => (disabled ? colors.accentRed25 : colors.accentRed)};
  text-decoration: underline;
  &:hover {
    cursor: ${({ disabled }: { disabled: boolean }) => (disabled ? 'not-allowed' : 'pointer')};
  }
`;

const P = styled.p`
  font-size: 12px;
  font-weight: bold;
  color: ${colors.black};
  margin: 0;
`;

export default FilterSlideout;
