import React from 'react';
import styled from 'styled-components';

import { BodySmall } from '~/components/shared/typography';
import { ReviewStatuses } from '~/constants/reviews';
import AltcsApplication from '~/models/reviews/AltcsApplication';
import colors from '~/styles/theme/colors';

import AltcsAvailableButton from './AltcsAvailableButton';
import AltcsPendingButtons from './AltcsPendingButtons';

type AltcsInfoProps = {
  altcsApplicationReview: AltcsApplication | null;
  locationEpisodeId: string;
  patientName: string;
};

const AltcsInfo = (props: AltcsInfoProps) => {
  const { altcsApplicationReview, locationEpisodeId, patientName } = props;

  const reviewStatus = altcsApplicationReview?.status;

  if (!altcsApplicationReview) {
    return <AltcsAvailableButton locationEpisodeId={locationEpisodeId} patientName={patientName} />;
  } else if (reviewStatus === ReviewStatuses.PENDING) {
    return <AltcsPendingButtons locationEpisodeId={locationEpisodeId} />;
  }
};

export default AltcsInfo;

export const Container = styled.div`
  border-top: 1px solid ${colors.black10};
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1.5rem;

  button {
    height: 32px;
    width: 100%;
  }

  div:first-of-type {
    width: 100%;
  }
`;

export const Text = styled(BodySmall)`
  margin-bottom: 1rem;
`;
