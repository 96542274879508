import { createSelector, createSlice } from '@reduxjs/toolkit';

import * as clientTypes from '~/constants/clientTypes';
import {
  EPISODE_CLASSIFICATIONS,
  HOSPITALS,
  PAYERS,
  PHYSICIAN_GROUP,
  PHYSICIAN_TEAM,
  PLAN_TYPE_CLASSIFICATIONS,
} from '~/constants/filterKeysConstants';
import { createAsyncThunk } from '~/lib';
import { FilterOption } from '~/models';
import { filterOptionsApi } from '~/services/api';

import PaginationState from './PaginationState';
import { getProfile, updateProfile } from './profile';

export const SLICE_NAME = 'filterOptions';

export const initializeFilter = (filterKey) => ({ [filterKey]: { data: [], ...new PaginationState() } });

const headFilterKeys = [HOSPITALS, PHYSICIAN_GROUP, PAYERS];
const tailFilterKeys = [PLAN_TYPE_CLASSIFICATIONS, EPISODE_CLASSIFICATIONS, PHYSICIAN_TEAM];
const staticFilterKeys = new Set([...headFilterKeys, ...tailFilterKeys]);

export const initialState = {};

const filterOptionsForUser = (filterOptions, user) => {
  const dynamicFilterKeys = Object.keys(filterOptions).filter((key) => !staticFilterKeys.has(key));
  let userFilterKeys = [];

  switch (user.actingClient?.clientType) {
    case clientTypes.PAYOR:
      userFilterKeys = [PAYERS, HOSPITALS, PHYSICIAN_GROUP, ...dynamicFilterKeys, ...tailFilterKeys];
      break;
    case clientTypes.PHYSICIAN_GROUP:
      userFilterKeys = [PHYSICIAN_GROUP, HOSPITALS, PAYERS, ...dynamicFilterKeys, ...tailFilterKeys];
      break;
    default:
      userFilterKeys = [...headFilterKeys, ...dynamicFilterKeys, ...tailFilterKeys];
  }

  return userFilterKeys.reduce((acc, key) => {
    if (filterOptions[key]?.meta?.totalRecords) {
      acc[key] = filterOptions[key];
    }

    return acc;
  }, {});
};

export const makeFilterOptionsSlice = (sliceName) => {
  const areFilterOptionsPopulated = (state) => {
    const filterOptions = getOptions(state);

    return filterOptions !== initialState;
  };

  const fetch = createAsyncThunk(
    `${sliceName}/fetch`,
    async (params) => {
      const { data } = await filterOptionsApi.fetch.invoke(params);

      return data;
    },
    {
      defaultValue: [],
      modelClass: FilterOption,
      condition: (_, { getState }) => {
        const state = getState();

        return !areFilterOptionsPopulated(state);
      },
    }
  );

  const fetchNoCache = createAsyncThunk(
    `${sliceName}/fetch/noCache`,
    async (params) => {
      const { data } = await filterOptionsApi.fetch.invoke(params);

      return data;
    },
    {
      defaultValue: [],
      modelClass: FilterOption,
    }
  );

  const filterOptionSlice = createSlice({
    name: sliceName,
    initialState,
    reducers: {},
    extraReducers: {
      [fetch.fulfilled]: (state, { payload }) => {
        Object.entries(payload).forEach(([key, value]) => (state[key] = value));
      },
      [updateProfile.fulfilled]: (state, { payload }) => {
        if (payload.actingClient) {
          return initialState;
        }

        return state;
      },
    },
  });

  const getOptions = (state) => state[sliceName];

  const getOrderedOptions = createSelector(getOptions, getProfile, (filterOptions, profile) =>
    filterOptionsForUser(filterOptions, profile)
  );

  return {
    slice: filterOptionSlice,
    fetch,
    fetchNoCache,
    getOptions,
    getOrderedOptions,
  };
};

const { slice, ...rest } = makeFilterOptionsSlice(SLICE_NAME);

export const {
  fetch: fetchFilterOptions,
  fetchNoCache: fetchFilterOptionsNoCache,
  getOptions: getFilterOptions,
  getOrderedOptions: getOrderedFilterOptions,
} = rest;

export default slice;
