import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

import placeholder from '~/assets/images/image-placeholder.svg';
import CircleSpinner from '~/components/shared/CircleSpinner';

function LazyImage(props) {
  const { alt,
    className,
    onClick,
    onLoaded,
    placeholderSrc,
    src,
    useSpinner
  } = props;

  const placeholderSource = placeholderSrc || placeholder;
  const [loading, setLoading] = useState(true);
  const [source, setSource] = useState(placeholderSource || src);

  const onImageError = () => {
    setSource(placeholder);
    setLoading(false);
  };

  const onImageLoad = () => {
    setSource(src);
    setLoading(false);
    onLoaded(true);
  };

  useEffect(() => {
    if (src && src !== placeholderSource) {
      const img = new Image();

      img.src = src;

      img.onerror = onImageError;
      img.onload = onImageLoad;
    }
  }, [src]);

  return (
    loading && useSpinner ? <CircleSpinner /> :
      <StyledImage { ...props }
        alt={ alt }
        className={ className }
        loading={ loading }
        onClick={ onClick }
        src={ source } />
  );
}

LazyImage.defaultProps = {
  alt: '',
  onClick: () => {},
  onLoaded: () => {},
  src: placeholder,
  useSpinner: false
};

LazyImage.propTypes = {
  alt: PropTypes.string,
  onClick: PropTypes.func,
  onLoaded: PropTypes.func,
  placeholderSrc: PropTypes.instanceOf(Object),
  src: PropTypes.string,
  useSpinner: PropTypes.bool
};

export default LazyImage;

// Destructuring "customStyles" & "loading" props to prevent browser warning
// about unknown properties in the DOM
// eslint-disable-next-line no-unused-vars, react/display-name
const StyledImage = styled(({ customStyles, loading, onLoaded, useSpinner, ...rest }) => <img { ...rest } />)`
  max-height: ${({ customStyles }) => customStyles?.maxHeight || 'initial' };
  max-width: ${({ customStyles }) => customStyles?.maxWidth || 'initial' };

  ${ ({ loading }) => loading && css`
    filter: blur(10px);
  `};

  ${ ({ loading }) => !loading && css`
    filter: blur(0px);
    transition: filter 0.5s linear;
  `};
`;
