import { createAsyncThunk } from '~/lib';
import { AttrValue } from '~/models';
import { attrValuesApi } from '~/services/api';

const cancellableFetchAttrValues = attrValuesApi.fetch.cancellable();

const SLICE_NAME = 'admin/attr_values';

export const fetchAttrValues = createAsyncThunk(
  `${SLICE_NAME}/fetch`,
  async (params: any) => {
    const res = cancellableFetchAttrValues(params).then((arg)=> arg as any);

    return (await res).data;
  },
  {
    defaultValue: [],
    modelClass: AttrValue
  } as any
);

export const fetchAttrValue = createAsyncThunk(
  `${SLICE_NAME}/fetch`,
  async (params: any) => {
    const res = cancellableFetchAttrValues(params).then((arg)=> arg as any);

    return (await res).data;
  },
  {
    defaultValue: [],
    modelClass: AttrValue
  } as any
);
