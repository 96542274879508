import React, { useMemo } from 'react';
import { Formik } from 'formik';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import styled from 'styled-components';

import ActivityInput from '~/components/shared/ActivityInput';
import { Button, ButtonGroup } from '~/components/shared/buttons';
import { Form, FormHeader, FormSection, InputGroup, Select } from '~/components/shared/form';
import StandardModal from '~/components/shared/modal/StandardModal';
import { HOME_HEALTH_AGENCY } from '~/constants/locationTypes';
import { CANCELED, REJECTED } from '~/constants/reviews';
import { fetchLocations } from '~/ducks/locations';
import { updateReview } from '~/ducks/reviews';
import { addToast } from '~/ducks/toasts';
import { getId, getName } from '~/helpers';
import { unwrapResult } from '~/lib';
import { useAsyncOptions } from '~/lib/hooks';
import { LocationEpisode, Note, ServiceRefusal } from '~/models';
import { colors } from '~/styles/theme';

import { validation } from './serviceRefusalReassignValidation';

function ServiceRefusalReassignModal(props) {
  const { locationEpisode, onCancel, onSuccess, serviceRefusal, show } = props;

  const locationAsyncOptions = useAsyncOptions(fetchLocations, {
    condition: Boolean(locationEpisode?.owner.id) && show,
    params: {
      ownerId: locationEpisode?.owner.id,
      type: HOME_HEALTH_AGENCY,
      viewOnly: true,
    },
  });

  const handleSubmit = async (values, { setSubmitting }) => {
    try {
      const isReassignedToSameLocation = values.reassignedTo.id === locationEpisode.locationId;
      const note = new Note({
        ...values.note,
        locationEpisodeId: locationEpisode.id,
      });

      const payload = {
        ...values,
        id: serviceRefusal.id,
        include: 'activities.attachments,episode',
        status: isReassignedToSameLocation ? CANCELED : REJECTED,
        ...(note.hasContent && { note: note.serialize() }),
      };

      await props.updateReview(payload).then(unwrapResult);

      if (!isReassignedToSameLocation) {
        props.addToast({
          text: `${locationEpisode.patient.name} has been successfully assigned to services at ${values.reassignedTo.name}.`,
        });
      }

      onSuccess();
    } catch (e) {
      let msg = 'Something went wrong. Please try again.';

      if (e.response?.status === 422) {
        msg = e.response.data.message;
      }

      props.addToast({ text: msg });
    } finally {
      setSubmitting(false);
    }
  };

  const initialValues = useMemo(
    () => ({
      note: new Note({
        locationEpisodeId: locationEpisode.id,
      }),
      reassignedTo: locationEpisode.rehabInformation.latestRehabFacility,
    }),
    [locationEpisode]
  );

  return (
    <StandardModal show={show} title='Reassign Services' onCancel={onCancel} disableBackdropClick>
      <Formik
        initialValues={initialValues}
        validationSchema={validation}
        onSubmit={handleSubmit}
        enableReinitialize
        initialStatus={{ uploadingAttachments: false }}
        validateOnMount>
        {({ isValid, isSubmitting, status, setStatus }) => (
          <Form>
            <FormHeader subtitle={`Patient: ${locationEpisode.patient.name}`} />
            <FormSection>
              <InputGroup
                {...locationAsyncOptions}
                name='reassignedTo'
                data-cy='reassignedTo'
                label='Where will the patient be receiving services?'
                component={Select}
                getOptionLabel={getName}
                getOptionValue={getId}
              />
            </FormSection>
            <FormSection>
              <ActivityInput
                isTaggable
                label='Notes (optional)'
                locationEpisodeId={locationEpisode.id}
                onUploading={(uploadingAttachments) => setStatus({ uploadingAttachments })}
                customStyles={{ borderColor: colors.black25 }}
              />
            </FormSection>
            <FormActions>
              <Button color='transparent' text='Cancel' onClick={onCancel} />
              <Button
                type='submit'
                data-cy='serviceRefusalReassignSubmit'
                disabled={status.uploadingAttachments || !isValid || isSubmitting}
                loading={isSubmitting}
                text='Confirm'
              />
            </FormActions>
          </Form>
        )}
      </Formik>
    </StandardModal>
  );
}

ServiceRefusalReassignModal.propTypes = {
  addToast: PropTypes.func.isRequired,
  locationEpisode: PropTypes.instanceOf(LocationEpisode),
  onCancel: PropTypes.func,
  onSuccess: PropTypes.func,
  serviceRefusal: PropTypes.instanceOf(ServiceRefusal),
  show: PropTypes.bool,
  updateReview: PropTypes.func.isRequired,
};

const noop = () => {};

ServiceRefusalReassignModal.defaultProps = {
  onCancel: noop,
  onSuccess: noop,
};

const mapDispatchToProps = {
  addToast,
  updateReview,
};

export default connect(null, mapDispatchToProps)(ServiceRefusalReassignModal);

const FormActions = styled(ButtonGroup)`
  margin-top: 60px;
`;
