/* eslint-disable no-shadow */
import { createEntityAdapter, createSelector, createSlice } from '@reduxjs/toolkit';

import { createAsyncThunk } from '~/lib';
import { Escalation } from '~/models';
import { escalationsApi } from '~/services/api';

import { API_STATES, createApiHasStatusSelector } from './api';
import { createApiErrorSelector, createApiResetStatus } from './api';
import { updateAttachment } from './attachments';
import PaginationState from './PaginationState';

const SLICE_NAME = 'escalations';

export const updateEscalation = createAsyncThunk(`${SLICE_NAME}/update`, async ({ id, ...params }) => {
  const res = await escalationsApi.update.invoke(id, params);

  return res.data;
});

export const fetchEscalation = createAsyncThunk(
  `${SLICE_NAME}/fetchById`,
  async ({ id, ...params }) => {
    const res = await escalationsApi.fetchById.invoke(id, params);

    return res.data;
  },
  {
    modelClass: Escalation,
  }
);

export const makeEscalationsSlice = (sliceName) => {
  const cancellableFetchEscalations = escalationsApi.fetch.cancellable();

  const fetch = createAsyncThunk(
    `${sliceName}/fetch`,
    async (params) => {
      const res = await cancellableFetchEscalations(params);

      return res.data;
    },
    {
      defaultValue: [],
      modelClass: Escalation,
    }
  );

  const fetchNextPage = createAsyncThunk(
    `${sliceName}/fetchNextPage`,
    async (_, { getState }) => {
      const url = getPageLinks(getState()).next;
      const res = await cancellableFetchEscalations({ url });

      return res.data;
    },
    {
      condition: (_, { getState }) => getPageLinks(getState()).next,
    }
  );

  const adapter = createEntityAdapter();

  const handleSingleResponse = (state, { payload: escalation }) => {
    const existingEscalation = state.entities[escalation.id];

    if (!existingEscalation?.acknowledged && state.pagination && escalation.acknowledged) {
      state.pagination.meta.totalRecords--;
    }

    if (!existingEscalation) {
      state.pagination.meta.totalRecords++;
    }

    adapter.upsertOne(state, escalation);
  };

  const slice = createSlice({
    name: sliceName,
    initialState: adapter.getInitialState({ pagination: new PaginationState() }),
    reducers: {},
    extraReducers: {
      [fetch.fulfilled]: (state, { payload: { links, meta, data } }) => {
        state.pagination = { links, meta };
        adapter.setAll(state, data);
      },
      [fetchNextPage.fulfilled]: (state, { payload: { links, meta, data } }) => {
        state.pagination = { links, meta };
        adapter.upsertMany(state, data);
      },
      [fetchEscalation.fulfilled]: handleSingleResponse,
      [updateEscalation.fulfilled]: handleSingleResponse,
      [updateAttachment.fulfilled]: (state, { payload }) => {
        const { docType, id } = payload;
        const escalation = payload.activity?.escalation;

        if (!escalation) return;

        const selectedEscalation = state.entities[escalation.id];
        const attachments = selectedEscalation.activity.attachments;

        adapter.updateOne(state, {
          id: selectedEscalation.id,
          changes: {
            activity: {
              ...selectedEscalation.activity,
              attachments: attachments?.map((attachment) => {
                if (id === attachment.id) return { ...attachment, docType: docType };

                return attachment;
              }),
            },
          },
        });
      },
    },
  });

  const getSlice = (state) => state[sliceName];

  const { selectAll: getAll } = adapter.getSelectors(getSlice);
  const getTotalRecords = (state) => getSlice(state).pagination?.meta?.totalRecords;
  const getPageLinks = (state) => getSlice(state).pagination?.links || {};

  const getRecordsLoaded = createApiHasStatusSelector(fetch, [API_STATES.complete, API_STATES.failed]);

  const getPageLoading = createApiHasStatusSelector(fetchNextPage, API_STATES.pending);

  const getAllData = createSelector(
    getAll,
    getRecordsLoaded,
    getPageLoading,
    getPageLinks,
    getTotalRecords,
    (escalations, loaded, pageLoading, links, totalRecords) => ({
      escalations: escalations.map((escObj) => new Escalation(escObj)),
      loaded,
      nextUrl: links?.next,
      pageLoading,
      totalRecords,
    })
  );

  return {
    reducer: slice.reducer,
    fetch,
    fetchNextPage,
    getAll,
    getTotalRecords,
    getPageLinks,
    getRecordsLoaded,
    getPageLoading,
    getAllData,
  };
};

export const clearEscalationUpdateError = createApiResetStatus(updateEscalation);

export const getEscalationError = (state) => createApiErrorSelector(updateEscalation)(state);

export const getEscalationUpdating = createApiHasStatusSelector(updateEscalation, [API_STATES.pending]);

const { reducer, ...rest } = makeEscalationsSlice(SLICE_NAME);

export const {
  fetch,
  fetchNextPage,
  getAll,
  getTotalRecords,
  getPageLinks,
  getRecordsLoaded,
  getPageLoading,
  getAllData,
} = rest;

export default reducer;
