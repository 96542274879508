import React, { createContext, useContext, useEffect, useMemo, useState } from 'react';
import * as _ from 'lodash-es';
import { flushSync } from 'react-dom';
import styled, { css, FlattenSimpleInterpolation, keyframes } from 'styled-components';

import colors from '~/styles/theme/colors';

import SlideoutHeader from './SlideoutHeader';

type SlideoutContextType = {
  handleExit: () => void;
};

export const SlideoutContext = createContext<SlideoutContextType>({
  handleExit: _.noop,
});

export const useSlideoutContext = () => useContext(SlideoutContext);

type SlideoutProps = {
  children: React.ReactNode;
  handleClose: () => void;
};

enum SlideoutState {
  entering = 'entering',
  entered = 'entered',
  exiting = 'exiting',
  exited = 'exited',
}

const Slideout = (props: SlideoutProps) => {
  const { children, handleClose } = props;

  const [slideoutState, setSlideoutState] = useState<SlideoutState>(SlideoutState.exited);

  const backgroundPage = useMemo(() => {
    const mainScrollContainer = document.getElementById('mainScrollContainer');

    return {
      isScrollable: mainScrollContainer && mainScrollContainer.scrollHeight > mainScrollContainer.clientHeight,
      style: mainScrollContainer?.style,
    };
  }, []);

  const handleExitStyleOverrides = () => {
    if (backgroundPage.isScrollable) {
      backgroundPage.style?.setProperty('overflow-y', 'auto');
      backgroundPage.style?.removeProperty('scrollbar-gutter');
    }
  };

  useEffect(() => {
    setSlideoutState(SlideoutState.entering);
  }, []);

  // Remove style overrides when component unmounts
  useEffect(() => {
    return () => {
      handleExitStyleOverrides();
    };
  }, []);

  const handleExit = () => {
    // this restores main page scrolling as slideout closes
    handleExitStyleOverrides();
    setSlideoutState(SlideoutState.exiting);
  };

  const handleAnimationEnd = () => {
    if (slideoutState === SlideoutState.entering) {
      // this prevents main page from scrolling after slideout opens
      if (backgroundPage.isScrollable) {
        backgroundPage.style?.setProperty('overflow-y', 'hidden');
        backgroundPage.style?.setProperty('scrollbar-gutter', 'stable');
      }

      setSlideoutState(SlideoutState.entered);
    }

    if (slideoutState === SlideoutState.exiting) {
      flushSync(() => {
        setSlideoutState(SlideoutState.exited);
        handleClose();
      });
    }
  };

  return (
    <SlideoutContext.Provider value={{ handleExit }}>
      {slideoutState !== SlideoutState.exited && (
        <SlideoutWrapper onAnimationEnd={handleAnimationEnd} slideoutState={slideoutState}>
          <SlideoutModal slideoutState={slideoutState}>{children}</SlideoutModal>
        </SlideoutWrapper>
      )}
    </SlideoutContext.Provider>
  );
};

const Content = styled.div`
  height: 100%;
  padding: 24px;
  overflow-y: auto;
`;

const Footer = styled.div`
  margin-top: auto;
  border-top: 1px solid ${colors.black10};
`;

const slideInAnimation = keyframes`
  from {
    right: calc(-100%)
  }

  to {
    right: right(0);
  }
`;

const slideOutAnimation = keyframes`
  from {
    right: right(0);
  }

  to {
    right: calc(-100%);
  }
`;

const dimInAnimation = keyframes`
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
`;

const dimOutAnimation = keyframes`
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
`;

type SlideoutStyleProps = {
  slideoutState: SlideoutState;
};

type SlideoutAnimationMap = { [key: string]: FlattenSimpleInterpolation };

const wrapperAnimations: SlideoutAnimationMap = {
  entering: css`
    ${dimInAnimation} 305ms ease-in-out
  `,
  exiting: css`
    ${dimOutAnimation} 305ms ease-in-out
  `,
};

const SlideoutWrapper = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: ${colors.black50};
  z-index: 998;
  animation: ${({ slideoutState }: SlideoutStyleProps) => wrapperAnimations[slideoutState]};
`;

const slideAnimations: SlideoutAnimationMap = {
  entering: css`
    ${slideInAnimation} 300ms ease-in-out
  `,
  exiting: css`
    ${slideOutAnimation} 300ms ease-in-out
  `,
};

const SlideoutModal = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  width: 360px;
  z-index: 999;
  background-color: ${colors.white};
  height: 100%;
  animation: ${({ slideoutState }: SlideoutStyleProps) => slideAnimations[slideoutState]};
  display: flex;
  flex-direction: column;
`;

Slideout.Content = Content;
Slideout.Footer = Footer;
Slideout.Header = SlideoutHeader;

export default Slideout;
