import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components';

import { fetchActivities } from '~/ducks/activities';
import { fetchEpisode } from '~/ducks/episode';
import { Episode, LocationEpisode } from '~/models';

import DischargeModal from '../shared/DischargeModal';

import NewEscalationModal from './NewEscalationModal';
import PatientInfoBar from './PatientInfoBar';

function PatientHeader(props) {
  const [showModal, setShowModal] = useState(null);
  const [patientDischargedViaModal, setPatientDischargedViaModal] = useState(false);

  const { episode, locationEpisode, history, rehabStates } = props;

  const editPatient = () => {
    history.push(`/patients/${episode.id}/edit`);
  };

  const name = episode.patient.name;

  const showEscalationModal = () => setShowModal('escalation');
  const showDischargeModal = () => setShowModal('discharge');
  const clearModal = () => setShowModal(null);

  const handleDischargeSuccess = () => {
    props.fetchActivities({ locationEpisodeId: locationEpisode.id, include: 'attachments' });
    // TODO: Remove owner.client.enabled_flags include when we remove ALTCS flag
    props.fetchEpisode({ id: episode.id, include: 'episode_groups.location.group_type,owner.client.enabled_flags' });

    setPatientDischargedViaModal(true);
    clearModal();
  };

  return (
    <PatientHeaderContainer>
      <PatientInfoBar
        editPatient={editPatient}
        episode={episode}
        locationEpisode={locationEpisode}
        patientDischargedViaModal={patientDischargedViaModal}
        rehabStates={rehabStates}
        showEscalationModal={showEscalationModal}
        showDischargeModal={showDischargeModal}
      />

      <DischargeModal
        show={showModal === 'discharge'}
        currentRehabStateEnteredAt={episode.currentRehabState.enteredAt}
        episodeId={episode.id}
        locationEpisodeId={locationEpisode.id}
        onSuccess={handleDischargeSuccess}
        onCancel={clearModal}
        ownerId={locationEpisode.owner.id}
        patientName={name}
        rehabFacilityType={locationEpisode.rehabFacilityType}
      />

      <NewEscalationModal
        showEscalationModal={showModal === 'escalation'}
        onClose={clearModal}
        locationEpisodeId={locationEpisode.id}
        patientName={name}
      />
    </PatientHeaderContainer>
  );
}

PatientHeader.propTypes = {
  episode: PropTypes.instanceOf(Episode),
  fetchActivities: PropTypes.func,
  fetchEpisode: PropTypes.func.isRequired,
  locationEpisode: PropTypes.instanceOf(LocationEpisode).isRequired,
  rehabStates: PropTypes.instanceOf(Array),
};

const mapDispatchToProps = {
  fetchActivities,
  fetchEpisode,
};

export default withRouter(connect(null, mapDispatchToProps)(PatientHeader));

const PatientHeaderContainer = styled.div`
  padding-left: 16px;
  padding-right: 16px;
  margin-top: 24px;
  max-width: 1104px;
`;
