import React, { useMemo } from 'react';
import { withFormik } from 'formik';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import CircleSpinner from '~/components/shared/CircleSpinner';
import { TIMEZONES } from '~/constants/timezones';
import { createLocation, fetchLocation, updateLocation } from '~/ducks/admin/locations';
import { addToast } from '~/ducks/toasts';
import { capitalize } from '~/helpers';
import { unwrapResult } from '~/lib';
import { useThunk } from '~/lib/hooks';
import { Location } from '~/models';
import { history } from '~/store';

import { FormPage } from '../shared/pageLayout';

import LocationForm from './LocationForm';
import validationSchema from './validationSchema';

function EditLocation(props) {
  const { match } = props;
  const locationId = match.params.id;

  const { data: location, loaded: locationLoaded } = useThunk(fetchLocation, [locationId], {
    condition: Boolean(locationId),
    params: {
      id: locationId,
      include: 'groups,group_type,owners',
    },
  });

  const handleCancel = () => props.history.push('/groups');
  const filterByGroupOrOwnerType = location.client.isOwningClientType ? location.groups : location.owners;

  const initialValues = {
    ...location.toFormValues(),
    timezone: TIMEZONES.find((tz) => tz.value === location.timezone),
    hospitals: filterByGroupOrOwnerType.filter((loc) => loc.isHospital),
    payers: location.owners.filter((loc) => loc.isPayer),
    physicianGroups: location.owners.filter((loc) => loc.isPhysicianGroup),
    physicianTeams: location.groups.filter((loc) => loc.isPhysicianTeam),
    rehabFacilities: location.groups.filter((loc) => loc.isProvider),
  };

  const goToLocations = () => history.push('/groups');
  const handleSubmit = ({ shouldNavigate = true, ...values }, { setErrors, setSubmitting, resetForm }) => {
    const loc = Location.fromFormValues(values);
    const locationRequest = loc.id ? props.updateLocation : props.createLocation;

    const tryNotifySuccess = () => !shouldNavigate && props.addToast({ text: `${values.name} successfully added!` });

    locationRequest(loc.serialize())
      .then(unwrapResult)
      .then(() => shouldNavigate && goToLocations())
      .then(tryNotifySuccess)
      .then(() => {
        resetForm({
          values: {
            ...values,
            name: '',
            address: {
              streetAddress: '',
              streetAddressLine2: '',
              city: '',
              state: '',
              zip: '',
            },
          },
        });
      })
      .catch((e) => {
        const error = e?.response?.data?.errors;

        if (error) {
          const errorMessage = Object.keys(error).reduce((obj, key) => {
            obj[key] = error[key].map((err) => `${capitalize(key)} ${err}`).join(', ');
            return obj;
          }, {});

          setErrors(errorMessage);
        } else {
          props.addToast({ text: 'There was an error creating the location. Please try again.' });
        }
      })
      .finally(() => setSubmitting(false));
  };

  const formikOptions = useMemo(
    () => ({
      enableReinitialize: true,
      handleSubmit,
      mapPropsToStatus: () => ({ isEdit: Boolean(locationId) }),
      mapPropsToValues: () => ({ ...initialValues }),
      validationSchema,
    }),
    [initialValues]
  );

  const FormikLocationForm = useMemo(() => withFormik(formikOptions)(LocationForm), [formikOptions]);

  if (Boolean(locationId) && !locationLoaded) return <CircleSpinner centered />;

  return (
    <FormPage>
      <FormikLocationForm onCancel={handleCancel} />
    </FormPage>
  );
}

EditLocation.propTypes = {
  addToast: PropTypes.func.isRequired,
  createLocation: PropTypes.func.isRequired,
  updateLocation: PropTypes.func.isRequired,
};

const mapDispatchToProps = {
  addToast,
  createLocation,
  updateLocation,
};

export default connect(null, mapDispatchToProps)(EditLocation);
