import React, { Fragment, useMemo, useState } from 'react';
import styled from 'styled-components';

import ArchivedWarningBanner from '~/components/patients/patientProfile/ArchivedWarningBanner';
import { MainPage } from '~/components/shared/pageLayout';
import TabFilters from '~/components/shared/TabFilters';

import { EscalationAcknowledgementErrorModal } from './escalationAcknowledgementError';
import { Files } from './Files';
import PatientHeader from './PatientHeader';
import { usePatientProfileContext } from './PatientProfileContext';
import { PatientStory } from './PatientStory';
import ProgressUpdateModal from './ProgressUpdateModal';

function PatientProfile() {
  const {
    episode,
    locationEpisode,
    onCancelProgressUpdateModal,
    onCloseProgressUpdateModal,
    onMainPageScroll,
    progressTemplate,
    rehabStates,
    showProgressUpdateModal,
    attachmentsCount,
  } = usePatientProfileContext();

  const patientStoryTab = { label: 'Patient Story', value: 'patient-story' };
  const filesTab = {
    label: `Files (${attachmentsCount})`,
    value: 'files',
    'data-gtm-id': 'patientFiles',
  };
  const tabs = useMemo(() => [patientStoryTab, filesTab]);
  const [selectedTab, setSelectedTab] = useState(patientStoryTab);
  const handleTabSelect = (value) => setSelectedTab(tabs.find((tab) => tab.value === value));
  const patientStoryTabSelected = selectedTab.value === patientStoryTab.value;

  return (
    <MainPage
      onScroll={onMainPageScroll}
      headerWhite
      headerSticky
      headerContent={
        <Fragment>
          <PatientHeader locationEpisode={locationEpisode} episode={episode} rehabStates={rehabStates} />
          <StyledTabFilters tabs={tabs} onTabClick={handleTabSelect} selectedTab={selectedTab} />
        </Fragment>
      }
      banner={<Fragment>{episode.archived && <ArchivedWarningBanner episode={episode} />}</Fragment>}>
      <PatientProfileContent>
        {patientStoryTabSelected ? <PatientStory /> : <Files filesCount={attachmentsCount} />}
      </PatientProfileContent>

      <ProgressUpdateModal
        locationEpisode={locationEpisode}
        progressTemplate={progressTemplate}
        showModal={showProgressUpdateModal}
        onCancel={onCancelProgressUpdateModal}
        onClose={onCloseProgressUpdateModal}
      />
      <EscalationAcknowledgementErrorModal />
    </MainPage>
  );
}

export default PatientProfile;

export const PatientProfileContent = styled.div`
  max-width: 1072px;
  padding-left: 32px;
  padding-right: 32px;
`;

const StyledTabFilters = styled(TabFilters)`
  width: calc(100% - 8px);
  padding-left: 56px;
`;
