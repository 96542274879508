import React from 'react';
import { components, PlaceholderProps } from 'react-select';
import styled from 'styled-components';

import colors from '~/styles/theme/colors';

type FilterMultiSelectPlaceholderProps = PlaceholderProps & { selectProps: { filterLabel: string; disabled: boolean } };

const FilterMultiSelectPlaceholder = (props: FilterMultiSelectPlaceholderProps) => {
  const {
    selectProps: { filterLabel, disabled },
  } = props;

  const filterValue = props.getValue().length || 'All';

  return (
    <components.Placeholder {...props}>
      <StyledLabel hasSelected={!!props.getValue().length} disabled={disabled}>
        {filterLabel}:{' '}
      </StyledLabel>
      <StyledValue hasSelected={!!props.getValue().length} disabled={disabled}>
        {filterValue}
      </StyledValue>
    </components.Placeholder>
  );
};

export default FilterMultiSelectPlaceholder;

type StyledSpanProps = {
  hasSelected: boolean;
  disabled?: boolean;
};

const StyledLabel = styled.span`
  color: ${({ hasSelected, disabled }: StyledSpanProps) =>
    disabled ? colors.black25 : hasSelected ? colors.primaryBlue : colors.black50};
`;

const StyledValue = styled.span`
  color: ${({ hasSelected, disabled }: StyledSpanProps) =>
    disabled ? colors.black25 : hasSelected ? colors.primaryBlue : colors.black};
`;
