import React from 'react';
import styled from 'styled-components';

import { PriorityFlag } from '~/components/shared/svg';
import { LabelBold } from '~/components/shared/typography';
import { colors } from '~/styles/theme';

function PriorityNoteLabel() {
  return (
    <Container>
      <PriorityFlag size={14} />
      <LabelBold color={colors.accentRed}>This note is marked as priority</LabelBold>
    </Container>
  );
}

export default PriorityNoteLabel;

const Container = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 10px;

  & > svg {
    margin-right: 6px;
  }
`;
