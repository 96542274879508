import { NO, UNSURE, YES } from '~/constants/statusConstants';

const statusMap = {
  [NO]: 0,
  [UNSURE]: 10,
  [YES]: 20,
};

export default class Status {
  constructor(value = YES) {
    this.value = this._validateValue(value);
  }

  isWorseThan(other) {
    if ((other ?? null) === null) return this._statusValue < statusMap[YES];
    return this._statusValue < statusMap[this._validateValue(other)];
  }

  isBetterThan(other) {
    if ((other ?? null) === null) return this._statusValue === statusMap[YES];
    return this._statusValue > statusMap[this._validateValue(other)];
  }

  get _statusValue() {
    return statusMap[this.value];
  }

  _validateValue(value) {
    const val = value instanceof Status ? value.value : value;
    const isValid = Object.keys(statusMap).includes(val);

    if (isValid) return val;

    throw new TypeError(`Status value must be one of: ${Object.keys(statusMap).join(', ')}`);
  }
}
