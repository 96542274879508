import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import LoadingPulse from '~/components/shared/LoadingPulse';
import { LabelBold, LabelSmallBold } from '~/components/shared/typography';

const LengthOfStayBadge = ({ days, loading, patientOnTrack }) => {
  return loading ? (
    <EmptyBadge>
      <LoadingPulse />
    </EmptyBadge>
  ) : (
    <Badge data-cy='losBadge' patientOnTrack={patientOnTrack}>
      <Number data-cy='losLabel' patientOnTrack={patientOnTrack}>
        {days}
      </Number>
    </Badge>
  );
};

LengthOfStayBadge.propTypes = {
  days: PropTypes.number,
  loading: PropTypes.bool,
  patientOnTrack: PropTypes.string,
};

export default LengthOfStayBadge;

const getLabel = (props) => (props.children >= 1000 ? LabelSmallBold(props) : LabelBold(props));

const getBackgroundColor = ({ patientOnTrack, theme }) => {
  const COLOR_MAP = {
    Yes: theme.colors.accentGreen,
    Unsure: theme.colors.accentYellow,
    No: theme.colors.accentRed,
  };

  return COLOR_MAP[patientOnTrack] || theme.colors.black05;
};

const getTextColor = ({ patientOnTrack, theme }) => {
  return ['No', 'Yes'].includes(patientOnTrack) ? theme.colors.white : theme.colors.black;
};

const Badge = styled.div`
  border: 2px solid transparent;
  border-radius: 50%;
  height: 24px;
  width: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${getBackgroundColor};
`;

const Number = styled(getLabel)`
  color: ${getTextColor};
`;

const EmptyBadge = styled(Badge)`
  overflow: hidden;
  background: transparent;
`;
