import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { BodySmall } from '~/components/shared/typography';
import { DischargeQuestions, ProgressUpdate } from '~/models';
import colors from '~/styles/theme/colors';

const getAnswerColor = (answer) => {
  switch (answer.weight) {
    case 'positive':
      return colors.accentGreen;
    case 'negative':
      return colors.accentRed;
    default:
      return colors.black;
  }
};

function Questions({ activity }) {
  return (
    <QuestionsContainer>
      {activity.questions.sort().map((question) => (
        <div key={activity.id + question.id}>
          <div>
            <BodySmall color={colors.black75}>{question.text}</BodySmall>
          </div>
          <div>
            <BodySmall color={getAnswerColor(question.answer)}>
              {question.answer.value || question.answer.text}
            </BodySmall>
          </div>
        </div>
      ))}
    </QuestionsContainer>
  );
}

Questions.propTypes = {
  activity: PropTypes.oneOfType([PropTypes.instanceOf(DischargeQuestions), PropTypes.instanceOf(ProgressUpdate)]),
};

export default Questions;

const QuestionsContainer = styled.div`
  & > div {
    margin-top: 24px;

    &:first-child {
      margin-top: 0;
    }
  }
`;
