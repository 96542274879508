import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import Chip from '~/components/shared/Chip';
import { ThumbsDownIcon, ThumbsUpIcon, UnsureIcon } from '~/components/shared/svg';
import { BodySmallBold, Label } from '~/components/shared/typography';
import { EXPIRED, NO, UNSURE, YES } from '~/constants/statusConstants';
import { dateAtTime } from '~/lib/formatDate';
import colors from '~/styles/theme/colors';

function PatientStatus({ locationEpisode }) {
  const { onTrack, onTrackUpdatedAt, statusOutOfDate } = locationEpisode;

  const STATUS_MAP = {
    [YES]: { icon: ThumbsUpIcon, label: 'On track', color: colors.accentGreen, textColor: colors.white },
    [NO]: { icon: ThumbsDownIcon, label: 'Off track', color: colors.accentRed, textColor: colors.white },
    [UNSURE]: { icon: UnsureIcon, label: 'Unsure', color: colors.accentYellow, textColor: colors.black75 },
    [EXPIRED]: { label: 'Needs update', color: colors.black50, textColor: colors.white },
  };

  const status = statusOutOfDate ? STATUS_MAP[EXPIRED] : STATUS_MAP[onTrack];
  const Icon = status.icon;
  const chipColor = onTrack === UNSURE ? 'black' : 'white';

  return (
    <React.Fragment>
      <Status>
        <StyledLabel>Patient Progress Status</StyledLabel>
        <Chip
          backgroundColor={status.color}
          textColor={status.textColor}
          label={status.label}
          icon={statusOutOfDate ? null : <Icon fill={chipColor} size='12px' />}
        />
      </Status>
      <LastUpdated>Updated: {dateAtTime(onTrackUpdatedAt)}</LastUpdated>
    </React.Fragment>
  );
}

PatientStatus.propTypes = {
  locationEpisode: PropTypes.instanceOf(Object).isRequired,
};

export default PatientStatus;

const Status = styled.div`
  display: flex;
`;

const StyledLabel = styled(BodySmallBold)`
  margin: auto 8px auto auto;
`;

const LastUpdated = styled(Label)`
  font-style: italic;
  margin-top: 4px;
`;
