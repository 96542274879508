import React from 'react';
import PropTypes from 'prop-types';
import DatePicker from 'react-datepicker';
import styled from 'styled-components';

import { CalendarInput } from '~/components/shared/inputs';

import QuestionContent from './QuestionContent';

import 'react-datepicker/dist/react-datepicker.css';

export function dateComponentConfig(question) {
  return {
    QuestionComponent: DateQuestion,
    name: question.name,
    text: question.text,
    maxDate: question.maxDate,
  };
}

const DateQuestion = ({ value, helpers, minDate, maxDate, ...props }) => {
  const handleChange = (selectedDate) => {
    helpers.setValue(selectedDate);
  };

  return (
    <div>
      <QuestionContent {...props} />
      <AnswerContainer>
        <StyledDatePicker
          dateFormat='MM/dd/yyyy'
          customInput={<CalendarInput />}
          selected={value}
          onChange={handleChange}
          minDate={minDate}
          maxDate={maxDate}
        />
      </AnswerContainer>
    </div>
  );
};

DateQuestion.propTypes = {
  helpers: PropTypes.instanceOf(Object),
  maxDate: PropTypes.instanceOf(Object),
  minDate: PropTypes.instanceOf(Object),
  value: PropTypes.instanceOf(Object),
};

export default DateQuestion;

const AnswerContainer = styled.div`
  display: flex;
  justify-content: center;
  margin: 16px 0 0 0;
`;

const StyledDatePicker = styled(({ className, ...props }) => <DatePicker {...props} wrapperClassName={className} />)`
  width: auto !important;
`;
