import React, { useMemo } from 'react';
import { FormikHelpers, withFormik } from 'formik';
import { connect, ConnectedProps } from 'react-redux';

import { usePatientProfileContext } from '~/components/patients/patientProfile/PatientProfileContext';
import { StandardModal } from '~/components/shared/modal';
import { ACCEPTED } from '~/constants/reviews';
import { updateReview } from '~/ducks/reviews';
import { addToast } from '~/ducks/toasts';
import { unwrapResult } from '~/lib';
import { Location, LocationEpisode, Note } from '~/models';

import AltcsApprovalConfirmationForm, { AltcsApprovalConfirmationFormProps } from './AltcsApprovalConfirmationForm';
import { altcsApprovalFormValidation } from './altcsApprovalFormValidation';

interface Props extends ConnectedProps<typeof connector> {
  show: boolean;
  onCancel: () => void;
  onSuccess: ({ shouldFetchActivities }: { shouldFetchActivities: boolean }) => void;
}

interface providerOption {
  id: string;
  name: string;
}

interface AltcsApprovalConfirmationModalFormValues {
  ltcGroup: Location;
  note: Note;
  providerOption: providerOption | null;
}

function AltcsApprovalConfirmationModal(props: Props) {
  const { onCancel, onSuccess, show, updateReview } = props;
  const { locationEpisode, episode }: any = usePatientProfileContext();

  const altcsApplicationReview = locationEpisode?.altcsApplicationReview;
  const locationEpisodeId = locationEpisode?.id;

  const initialFormValues = {
    ltcGroup: new Location(),
    note: new Note(),
    providerOption: null,
  };

  const handleSubmit = async (
    values: AltcsApprovalConfirmationModalFormValues,
    { setSubmitting }: FormikHelpers<AltcsApprovalConfirmationModalFormValues>
  ) => {
    try {
      const note = new Note({
        ...values.note,
        locationEpisodeId,
      });

      const payload = {
        id: altcsApplicationReview.id,
        include: 'activities.attachments,locationEpisode,episode.episode_groups.location.group_type',
        ltcGroupId: values.ltcGroup.id,
        providerId: values.providerOption?.id,
        status: ACCEPTED,
        ...(note.hasContent && { note: note.serialize() }),
      };

      const res = await updateReview(payload).then(unwrapResult);
      const locEp = res.episode.locationEpisodes.find((locEp: LocationEpisode) => locEp.id === locationEpisodeId);

      onSuccess({ shouldFetchActivities: locEp.latest });
    } catch (e: any) {
      let msg = 'Something went wrong. Please try again.';

      if (e.response?.status === 422) {
        msg = e.response.data.message;
      }

      props.addToast({ text: msg });
    } finally {
      setSubmitting(false);
    }
  };

  const formikOptions = useMemo(
    () => ({
      enableReinitialize: true,
      handleSubmit,
      mapPropsToStatus: () => ({ uploadingAttachments: false }),
      mapPropsToValues: () => ({ ...initialFormValues }),
      validationSchema: altcsApprovalFormValidation,
    }),
    []
  );

  const FormikAltcsApprovalConfirmationForm = useMemo(
    () =>
      withFormik<AltcsApprovalConfirmationFormProps, AltcsApprovalConfirmationModalFormValues>(formikOptions)(
        AltcsApprovalConfirmationForm
      ),
    [formikOptions]
  );

  return (
    <StandardModal
      show={show}
      disableBackdropClick
      title='Confirm ALTCS Approval'
      onCancel={onCancel}
      data-cy='altcsApprovalConfirmationModal'>
      <FormikAltcsApprovalConfirmationForm
        onCancel={onCancel}
        altcsApplicationReview={altcsApplicationReview}
        episode={episode}
        locationEpisodeId={locationEpisodeId}
      />
    </StandardModal>
  );
}

const mapDispatchToProps = {
  addToast,
  updateReview,
};

const connector = connect(null, mapDispatchToProps);

export default connector(AltcsApprovalConfirmationModal);
