import {
  ASSISTED_LIVING,
  HHA,
  HOSPICE,
  HOSPITAL,
  IRF,
  LONG_TERM_CARE,
  LTACH,
  OTP,
  SNF,
} from '~/constants/dischargeTypes';
import { HOSPITAL as HOSPITAL_LOCATION_TYPE } from '~/constants/locationTypes';

export const dischargedLocationFieldName = 'dischargedLocation';
export const dischargedLocationFieldDefault = null;
export const dischargedLocationOtherFieldName = 'dischargedLocationOther';
export const otherLocation = { id: '', name: 'Other' };

export const reasonTypeLookup = {
  [HOSPICE]: 'hospice',
  [HOSPITAL]: HOSPITAL_LOCATION_TYPE,
};

export const reasonLabelOptionLookup = {
  [HHA]: 'Home Health Agency',
  [SNF]: 'SNF',
  [LTACH]: 'LTACH',
  [IRF]: 'IRF',
  [OTP]: 'OTP',
  [HOSPITAL]: 'Hospital',
  [HOSPICE]: 'Hospice',
  [ASSISTED_LIVING]: 'Assisted Living',
  [LONG_TERM_CARE]: 'Long Term Care',
};
