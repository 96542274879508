import { PATIENT_FIELD_CHANGES } from '~/constants/activities';

import FieldChanges from './FieldChanges';

const defaults = {
  type: PATIENT_FIELD_CHANGES,
};

export default class PatientFieldChanges extends FieldChanges {
  constructor(options = {}) {
    super({ ...defaults, ...options });
  }

  get typeLabel() {
    return 'Patient Details';
  }
}
