import React from 'react';
import PropTypes from 'prop-types';

import { colors } from '~/styles/theme';

function SortIcon({ fill, size }) {
  return (
    <svg width={size} height={size} viewBox='0 0 12 12' version='1.1' xmlns='http://www.w3.org/2000/svg'>
      <g id='SortPortfolio' stroke='none' strokeWidth='1' fill={fill} fillRule='evenodd' opacity='0.5'>
        <g
          id='PortfolioSorting_QueueOptions'
          transform='translate(-422.000000, -286.000000)'
          fill='#000000'
          fillRule='nonzero'>
          <g id='Group-10' transform='translate(422.000000, 286.000000)'>
            <g id='alpha-order' transform='translate(6.350006, 0.000000)'>
              <path
                d='M5.61917585,7.81878572 C5.56248179,7.69478253 5.4375774,7.61513978 5.2999704,7.61525083 L3.52474009,7.61525083 L3.52474009,0.69229553 C3.52474009,0.309951266 3.21133407,0 2.82472814,0 C2.43812222,0 2.12471619,0.309951266 2.12471619,0.69229553 L2.12471619,7.61525083 L0.349485886,7.61525083 C0.211951936,7.61545524 0.0872865139,7.69530062 0.0308410998,7.8193356 C-0.0256043144,7.94337057 -0.00336631645,8.08860252 0.0876814167,8.19054842 L2.56222366,10.9597305 C2.62866636,11.034239 2.72427622,11.0769231 2.82472814,11.0769231 C2.92518006,11.0769231 3.02078993,11.034239 3.08723262,10.9597305 L5.56177487,8.19054842 C5.65324276,8.08860912 5.67571334,7.94307633 5.61917585,7.81878572 Z'
                id='Path'></path>
            </g>
            <g
              id='alpha-order-copy'
              transform='translate(2.824997, 5.538462) scale(1, -1) translate(-2.824997, -5.538462) '>
              <path
                d='M5.61917585,7.81878572 C5.56248179,7.69478253 5.4375774,7.61513978 5.2999704,7.61525083 L3.52474009,7.61525083 L3.52474009,0.69229553 C3.52474009,0.309951266 3.21133407,0 2.82472814,0 C2.43812222,0 2.12471619,0.309951266 2.12471619,0.69229553 L2.12471619,7.61525083 L0.349485886,7.61525083 C0.211951936,7.61545524 0.0872865139,7.69530062 0.0308410998,7.8193356 C-0.0256043144,7.94337057 -0.00336631645,8.08860252 0.0876814167,8.19054842 L2.56222366,10.9597305 C2.62866636,11.034239 2.72427622,11.0769231 2.82472814,11.0769231 C2.92518006,11.0769231 3.02078993,11.034239 3.08723262,10.9597305 L5.56177487,8.19054842 C5.65324276,8.08860912 5.67571334,7.94307633 5.61917585,7.81878572 Z'
                id='Path'></path>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}

SortIcon.defaultProps = {
  fill: colors.black50,
  size: 12,
};

SortIcon.propTypes = {
  fill: PropTypes.string,
  size: PropTypes.number,
};

export default SortIcon;
