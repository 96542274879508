import React, { useEffect, useMemo, useState } from 'react';
import { Form } from 'formik';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import styled from 'styled-components';

import ActivityInput from '~/components/shared/ActivityInput';
import { Button, ButtonGroup } from '~/components/shared/buttons';
import { DatePicker, FormSection, Input, InputGroup, Select } from '~/components/shared/form';
import { HOSPITAL as HOSPITAL_LOCATION_TYPE } from '~/constants/locationTypes';
import { fetchDischargeOptions } from '~/ducks/dischargeOptions';
import { getLocationEpisodeUpdating } from '~/ducks/locationEpisode';
import { fetchLocations } from '~/ducks/locations';
import { getId, getName } from '~/helpers';
import { useAsyncOptions } from '~/lib/hooks';
import colors from '~/styles/theme/colors';

import ContentHeading from './ContentHeading';
import {
  dischargedLocationFieldName,
  dischargedLocationOtherFieldName,
  otherLocation,
  reasonLabelOptionLookup,
  reasonTypeLookup,
} from './dischargeFormConstants';

function DischargeForm(props) {
  const {
    checkIfContinuedCare = noop,
    currentRehabStateEnteredAt = null,
    episodeId,
    hasDischargeQuestions = false,
    latestRehabFacilityId,
    loading,
    locationEpisodeId,
    isSubmitting,
    isValid,
    onAbort,
    onComplete,
    ownerId,
    patientName,
    showActualDischargeDate = true,
    submitForm,
    updating,
    values,
    formValues,
    setFieldValue,
  } = props;

  const { dischargedLocationOther: locationOther } = values;
  const dischargeReason = values.dischargedReason?.value;
  const displayName = values.dischargedReason?.displayName;
  const dischargeLocationName = values.dischargedLocation?.name;
  const reasonText = `Where is ${patientName} being discharged to?`;
  const isOtherLocation = useMemo(() => dischargeLocationName === 'Other', [dischargeLocationName]);
  const groupTypeId = useMemo(() => values.dischargedReason?.groupTypeId, [values.dischargedReason]);
  const locationType = useMemo(() => reasonTypeLookup[dischargeReason], [dischargeReason]);
  const label = useMemo(() => reasonLabelOptionLookup[dischargeReason] || displayName || '', [dischargeReason]);
  const hasMoreSteps = hasDischargeQuestions || checkIfContinuedCare(values);
  const [uploading, setUploading] = useState(false);

  const isDisabled = () => {
    if (dischargeReason && /^(home|deceased|payor)$/.test(dischargeReason)) {
      return false;
    }

    if (dischargeReason && dischargeLocationName && (!isOtherLocation || (isOtherLocation && locationOther))) {
      return false;
    }

    return true;
  };

  const handleSubmit = () => {
    submitForm();

    onComplete(values, { completeSequence: !hasMoreSteps, manualNavigation: true });
  };

  const submitButtonText = () => {
    if (loading) return '';
    if (hasMoreSteps) return 'Next';

    return updating ? 'Discharging Patient' : 'Confirm';
  };

  const asyncDischargeOptions = useAsyncOptions(fetchDischargeOptions, {
    params: { episodeId },
  });

  const asyncOptionsParams = useMemo(
    () => ({
      ownerId: locationType !== HOSPITAL_LOCATION_TYPE ? ownerId : null,
      type: locationType,
      groupType: groupTypeId,
      'id.not': latestRehabFacilityId,
      viewOnly: true,
    }),
    [locationType, groupTypeId, latestRehabFacilityId]
  );

  const asyncLocationOptionsCondition = Boolean(groupTypeId) || Boolean(locationType);
  const asyncLocationOptions = useAsyncOptions(fetchLocations, {
    condition: asyncLocationOptionsCondition,
    optionsToAppend: [otherLocation],
    params: asyncOptionsParams,
  });

  useEffect(() => {
    if (formValues?.dischargedReason?.value !== dischargeReason) {
      setFieldValue(dischargedLocationFieldName, null);
    }
  }, [dischargeReason]);

  return (
    <FormContainer>
      <ContentHeading title='' subtitle={`Patient: ${patientName}`} />
      <Form>
        <FormSection>
          {showActualDischargeDate && (
            <InputGroup
              name='actualDischargeDateTime'
              label='Actual Discharge Date'
              dateFormat='MM/dd/yyyy'
              placeholder='MM/DD/YYYY'
              mode='date'
              minDate={new Date(currentRehabStateEnteredAt)}
              maxDate={new Date()}
              component={DatePicker}
            />
          )}

          <InputGroup
            {...asyncDischargeOptions}
            name='dischargedReason'
            label={reasonText}
            data-cy='dischargeReason'
            placeholder='Select a discharge reason'
            component={Select}
            /* Set the portal so the Select menu can render outside the modal */
            menuPortalTarget={document.body}
            styles={{ menuPortal: { zIndex: 9999 } }}
          />

          <InputGroup
            {...asyncLocationOptions}
            name={dischargedLocationFieldName}
            label={label}
            data-cy='dischargeLocation'
            getOptionLabel={getName}
            getOptionValue={getId}
            placeholder={'Select a location'}
            component={Select}
            /* Set the portal so the Select menu can render outside the modal */
            menuPortalTarget={document.body}
            styles={{ menuPortal: { zIndex: 9999 } }}
            visible={asyncLocationOptionsCondition}
          />

          <InputGroup
            name={dischargedLocationOtherFieldName}
            label='Other'
            placeholder=''
            component={Input}
            visible={isOtherLocation}
          />

          <ActivityInputWrapper>
            <ActivityInput
              isTaggable
              label='Notes (optional)'
              locationEpisodeId={locationEpisodeId}
              onUploading={setUploading}
              customStyles={{ borderColor: colors.black25 }}
            />
          </ActivityInputWrapper>
        </FormSection>
        <Actions>
          <Button text='Cancel' color='transparent' onClick={onAbort} />
          <Button
            data-cy='dischargeFormSubmit'
            disabled={isDisabled() || !isValid || isSubmitting || uploading || loading}
            loading={updating || loading}
            text={submitButtonText()}
            onClick={handleSubmit}
          />
        </Actions>
      </Form>
    </FormContainer>
  );
}

DischargeForm.propTypes = {
  checkIfContinuedCare: PropTypes.func,
  currentRehabStateEnteredAt: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.string]),
  dirty: PropTypes.bool.isRequired,
  episodeId: PropTypes.string,
  formValues: PropTypes.instanceOf(Object),
  hasDischargeQuestions: PropTypes.bool,
  isSubmitting: PropTypes.bool.isRequired,
  isValid: PropTypes.bool.isRequired,
  latestRehabFacilityId: PropTypes.string,
  loading: PropTypes.bool,
  locationEpisodeId: PropTypes.string,
  onAbort: PropTypes.func,
  onComplete: PropTypes.func,
  ownerId: PropTypes.string.isRequired,
  patientName: PropTypes.string.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  showActualDischargeDate: PropTypes.bool,
  submitForm: PropTypes.func.isRequired,
  updating: PropTypes.bool.isRequired,
  values: PropTypes.shape({
    dischargedLocation: PropTypes.shape({
      name: PropTypes.string,
    }),
    dischargedLocationOther: PropTypes.string,
    dischargedReason: PropTypes.shape({
      displayName: PropTypes.string,
      groupTypeId: PropTypes.string,
      checkIfContinuedCare: PropTypes.bool,
      value: PropTypes.string,
    }),
  }).isRequired,
};

const noop = () => {};

DischargeForm.defaultProps = {
  onAbort: noop,
  onComplete: noop,
};

const mapStateToProps = (state) => ({
  updating: getLocationEpisodeUpdating(state),
});

export default connect(mapStateToProps)(DischargeForm);

const Actions = styled(ButtonGroup)`
  justify-content: flex-end;
`;

export const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const ActivityInputWrapper = styled.div`
  width: 100%;
  flex-grow: 1;
  margin-bottom: 20px;
`;
