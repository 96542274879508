import React, { useEffect, useMemo, useState } from 'react';
import OutsideClickHandler from 'react-outside-click-handler';
import styled from 'styled-components';

import DocumentLink from '~/components/shared/DocumentLink';
import ProgressBar from '~/components/shared/ProgressBar';
import Tag from '~/components/shared/Tag';
import { BodySmallBold } from '~/components/shared/typography';
import { IAttachment } from '~/models/interfaces';

import DocTypeMenu from './DocTypeMenu';

type Props = {
  attachment: IAttachment;
  disabled: boolean;
  error?: boolean;
  onDeleteAttachment?: (id: string) => void;
  onDeleteDocumentType?: (id: string) => void;
  onDocumentTypeChange?: (id: string, docType: string) => void;
  parentScrollTop?: number;
  uploadProgress?: number;
  usePortal?: boolean;
};

export default function Attachment({
  attachment,
  disabled,
  error = false,
  onDeleteAttachment,
  onDeleteDocumentType,
  onDocumentTypeChange,
  parentScrollTop,
  uploadProgress,
  usePortal = false,
}: Props) {
  const showDocTypeIcon = !error && !uploadProgress;
  const showUploadProgress = !!uploadProgress && !error;
  const toolTipText = useMemo(() => {
    if (disabled) return '';

    return !attachment.docType ? 'Add Document Type' : 'Edit Document Type';
  }, [attachment.docType, disabled]);
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  useEffect(() => {
    setIsMenuOpen(false);
  }, [parentScrollTop]);

  const handleTagClear = () => {
    onDeleteDocumentType?.(attachment.id);
  };

  const handleAttachmentClick = (e: React.MouseEvent<HTMLDivElement>) => {
    // Prevent the click event from bubbling up to the parent. Specifically when this
    // is used in ActivityInput, it prevents the TextArea click handler from firing, which
    // causes it to get focused and scroll the attachments and close the menu.
    e.stopPropagation();
  };

  return (
    <OutsideClickHandler disabled={!usePortal || !isMenuOpen} onOutsideClick={() => setIsMenuOpen(false)}>
      <AttachmentContainer onClick={handleAttachmentClick} error={error} showUploadProgress={showUploadProgress}>
        <AttachmentInformation>
          <DocumentLink entry={attachment} disabled={disabled}>
            <Filename>{attachment.filename}</Filename>
          </DocumentLink>

          {error && <ErrorText>(Upload Error)</ErrorText>}

          {showDocTypeIcon && attachment.docType && (
            <TagContainer>
              <Tag disabled={disabled} label={attachment.docType} onClear={handleTagClear} />
            </TagContainer>
          )}

          <DocTypeMenu
            attachment={attachment}
            disabled={disabled}
            isMenuOpen={isMenuOpen}
            onDeleteAttachment={onDeleteAttachment}
            onDocumentTypeChange={onDocumentTypeChange}
            setIsMenuOpen={setIsMenuOpen}
            toolTipText={toolTipText}
            usePortal={usePortal}
            showDeleteIcon={!showUploadProgress}
            showDocTypeIcon={showDocTypeIcon}
          />
        </AttachmentInformation>
        {showUploadProgress && (
          <ProgressContainer>
            <ProgressBar value={uploadProgress} showLabel={false} />
          </ProgressContainer>
        )}
      </AttachmentContainer>
    </OutsideClickHandler>
  );
}

const AttachmentContainer = styled.div<{ error: boolean; showUploadProgress: boolean }>`
  box-sizing: border-box;
  background-color: ${({ theme }) => theme.colors.black02};
  border: 0.5px solid ${({ theme, error }) => (error ? theme.colors.accentRed : theme.colors.black15)};
  border-radius: 4px;
  padding: ${({ showUploadProgress }) => (showUploadProgress ? '8px 16px' : '4px 16px')};
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 32px;
`;

const AttachmentInformation = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  /* Add left margin to elements that are not the first child of the parent */
  > * + * {
    margin-left: 16px;
  }
`;

const Filename = styled(BodySmallBold)`
  display: block;
  font-size: 12px;
  color: ${({ theme }) => theme.colors.primaryBlue};
  font-weight: bold;
  margin: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const ErrorText = styled.span`
  font-style: italic;
  color: ${({ theme }) => theme.colors.accentRed};
  font-size: 12px;
  flex: 1 0 auto;
  margin-right: 8px;
`;

const TagContainer = styled.div`
  flex: 1 0 auto;
  display: flex;
  justify-content: flex-end;
`;

const ProgressContainer = styled.div`
  margin-top: 4px;
`;
