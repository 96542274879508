import React, { useCallback } from 'react';
import * as _ from 'lodash-es';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { TaggableTextarea } from '~/components/shared/form';
import { fetchTaggableUsers } from '~/ducks/taggableUsers';
import { unwrapResult } from '~/lib';

import UserTagSuggestion from './UserTagSuggestion';

function UserTaggableTextarea({ locationEpisodeId, ...props }) {
  const formatUsers = useCallback((users) => {
    return users.map((user) => ({
      ...user,
      display: `${user.name} - ${user.locationName}`,
    }));
  }, []);

  const fetchUsers = useCallback(
    (search, callback) => {
      const params = {
        search,
        pageSize: 10,
      };

      props.fetchTaggableUsers({ locationEpisodeId, params }).then(unwrapResult).then(formatUsers).then(callback);
    },
    [locationEpisodeId]
  );

  const displayTransform = useCallback((_, display) => {
    const userName = display.split(' - ')[0];

    return `@${userName}`;
  }, []);

  const debouncedFetchUsers = useCallback(_.debounce(fetchUsers, 300), [fetchUsers]);

  return (
    <TaggableTextarea
      {...props}
      data={debouncedFetchUsers}
      displayTransform={displayTransform}
      renderSuggestion={UserTagSuggestion}
    />
  );
}

UserTaggableTextarea.propTypes = {
  fetchTaggableUsers: PropTypes.func.isRequired,
  locationEpisodeId: PropTypes.string,
};

const mapDispatchToProps = { fetchTaggableUsers };

export default connect(null, mapDispatchToProps)(UserTaggableTextarea);
