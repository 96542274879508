import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { FlyoutMenu, FlyoutMenuIcon, FlyoutMenuItem, HORIZONTAL } from '~/components/shared/FlyoutMenu';
import { CircleXIcon, EditIcon, RestoreIcon } from '~/components/shared/svg';
import { useSwitch } from '~/lib/hooks';
import { Activity, DischargeQuestions } from '~/models';
import { colors } from '~/styles/theme';

import { usePatientProfileContext } from '../../PatientProfileContext';
import EditDischargeDetailsModal from '../EditDischargeDetailsModal';

function ActivityCardMenu({ activity }) {
  const { onUpdateActivity } = usePatientProfileContext();

  const editDischargeDetailsSwitch = useSwitch(false);

  const handleRestoreOrRemoveNote = () => {
    onUpdateActivity({
      ...activity,
      textRemoved: !activity.textRemoved,
      include: 'attachments.blob',
    });
  };

  const handleEditDischargeDetailsComplete = ({ questions }) => {
    onUpdateActivity(new DischargeQuestions({ ...activity, data: { questions } }).serialize());
    editDischargeDetailsSwitch.turnOff();
  };

  return (
    <Container>
      <FlyoutMenu iconOrientation={HORIZONTAL}>
        {(activity.isNote || activity.isProgressUpdate) && (
          <FlyoutMenuItem onClick={handleRestoreOrRemoveNote} width={120}>
            <FlyoutMenuIcon>
              {activity.textRemoved ? <RestoreIcon /> : <CircleXIcon fill={colors.accentRed} size={18} />}
            </FlyoutMenuIcon>
            {activity.textRemoved ? 'Restore Note' : 'Remove Note'}
            {!!activity.attachments.length && ' & Attachments'}
          </FlyoutMenuItem>
        )}
        {activity.isDischargeQuestions && (
          <FlyoutMenuItem onClick={editDischargeDetailsSwitch.turnOn} width={175}>
            <FlyoutMenuIcon>
              <EditIcon />
            </FlyoutMenuIcon>
            {'Edit Discharge Details'}
          </FlyoutMenuItem>
        )}
      </FlyoutMenu>
      {activity.isDischargeQuestions && (
        <EditDischargeDetailsModal
          activity={activity}
          show={editDischargeDetailsSwitch.state}
          onCancel={editDischargeDetailsSwitch.turnOff}
          onComplete={handleEditDischargeDetailsComplete}
        />
      )}
    </Container>
  );
}

ActivityCardMenu.propTypes = {
  activity: PropTypes.instanceOf(Activity),
};

export default ActivityCardMenu;

const Container = styled.div`
  margin-left: auto;
  width: 20px;
  position: relative;
`;
