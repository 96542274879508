import React, { useMemo } from 'react';
import { uniqueId } from 'lodash';
import styled from 'styled-components';

import { InfoIconOutline } from '~/components/shared/svg';
import Tooltip from '~/components/shared/Tooltip';
import { colors } from '~/styles/theme';

type Props = {
  label: string;
  tooltipText?: string;
  loading?: boolean;
  value?: string;
};

const MetricDetailHeader = ({ label, tooltipText = '', loading = false, value }: Props) => {
  const tooltipId = useMemo(() => `metric-tooltip-${uniqueId()}`, []);

  return (
    <MetricContainer>
      <MetricLabel>
        {label}{' '}
        {!!tooltipText && (
          <>
            <InfoIconOutline
              size={12}
              fill={colors.black50}
              style={{ marginLeft: 8 }}
              data-for={tooltipId}
              data-tip={tooltipText}
            />
            <Tooltip id={tooltipId} />
          </>
        )}
      </MetricLabel>
      <MetricValue>{!loading && value ? value : <>&mdash;</>}</MetricValue>
    </MetricContainer>
  );
};

export default MetricDetailHeader;

const MetricContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const MetricLabel = styled.div`
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.colors.black};
  font-size: 12px;
  font-weight: normal;
`;

const MetricValue = styled.div`
  color: ${({ theme }) => theme.colors.black};
  font-size: 24px;
  font-weight: 900;
  margin-top: 8px;
`;
