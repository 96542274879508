import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { PriorityFlag, WarnIcon } from '~/components/shared/svg';
import { Label } from '~/components/shared/typography';
import { colors } from '~/styles/theme';

function EscalationLabel({ isPriority }) {
  return (
    <EscalationLabelContainer>
      {isPriority ? (
        <React.Fragment>
          <PriorityFlag />
          <Label>Marked as priority </Label>
        </React.Fragment>
      ) : (
        <React.Fragment>
          <WarnIcon size={12} />
          <Label color={colors.white}>Escalation</Label>
        </React.Fragment>
      )}
    </EscalationLabelContainer>
  );
}

EscalationLabel.propTypes = {
  isPriority: PropTypes.bool,
};

export default EscalationLabel;

const EscalationLabelContainer = styled.div`
  display: flex;
  align-items: center;
  margin-right: 12px;

  & > svg {
    margin-right: 6px;
  }
`;
