import * as Yup from 'yup';

export const altcsApprovalFormValidation = Yup.object().shape({
  providerOption: Yup.object()
    .shape({
      id: Yup.string(),
      name: Yup.string(),
    })
    .nullable()
    .required('ALTCS Provider is required.'),
  ltcGroup: Yup.object()
    .shape({
      id: Yup.string(),
      name: Yup.string(),
    })
    .test('ltcGroup', 'LTC Group is required.', (obj) => Boolean(obj && !!obj.id)),
});
