import React, { useState } from 'react';
import { Formik } from 'formik';
import moment from 'moment';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import styled from 'styled-components';
import * as Yup from 'yup';

import { Button, ButtonGroup } from '~/components/shared/buttons';
import { DatePicker, Form, InputGroup } from '~/components/shared/form';
import StandardModal from '~/components/shared/modal/StandardModal';
import { createLocationEpisodeRehabState } from '~/ducks/locationEpisode';

import FormSection from '../../shared/form/FormSection';
import ContentHeading from '../shared/ContentHeading';
function AdmittedAtModal({ locationEpisodeId, isOpen, onClose, patientName, rehabStateId, ...props }) {
  const currentDate = new Date();
  const initialValues = { admittedAt: null };

  const [saving, setSaving] = useState(false);

  const handleClose = ({ admitted = false }) => {
    setSaving(false);
    onClose({ admitted });
  };

  const handleSubmit = (formValues) => {
    if (saving) {
      return;
    }

    setSaving(true);

    props.createLocationEpisodeRehabState({
      locationEpisodeId,
      rehabStateId,
      // date objects are automatically converted to UTC
      // when stringified for api requests, so we need to
      // send just the date portion of the date object
      enteredAt: moment(formValues.admittedAt).format('YYYY-MM-DD'),
    });

    handleClose({ admitted: true });
  };

  const validation = Yup.object().shape({
    admittedAt: Yup.date()
      .nullable()
      .max(new Date(), 'Cannot select a future date')
      .required('This field is required')
      .typeError('This field is required'),
  });

  return (
    <StandardModal
      show={isOpen}
      title='Confirm Admission Date'
      onCancel={handleClose}
      data-cy='authorizationUpdateModal'>
      <Formik initialValues={initialValues} validationSchema={validation} onSubmit={handleSubmit} validateOnMount>
        {({ isValid }) => (
          <Form>
            <FormSection>
              <ContentHeading title='' subtitle={`Patient: ${patientName}`} />
              <DatePickerWrapper>
                <InputGroup
                  name='admittedAt'
                  label='Actual Admission Date'
                  dateFormat='MM/dd/yyyy'
                  maxDate={currentDate}
                  component={DatePicker}
                />
              </DatePickerWrapper>
            </FormSection>
            <ButtonGroup>
              <Button onClick={handleClose} color='transparent' text='Cancel' />
              <Button type='submit' disabled={!isValid} text='Confirm' />
            </ButtonGroup>
          </Form>
        )}
      </Formik>
    </StandardModal>
  );
}

AdmittedAtModal.propTypes = {
  createLocationEpisodeRehabState: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  locationEpisodeId: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  patientName: PropTypes.string,
  rehabStateId: PropTypes.string,
};

export default connect(null, { createLocationEpisodeRehabState })(AdmittedAtModal);

const DatePickerWrapper = styled.div`
  margin: 0 100px;
`;
