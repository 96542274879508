import client from './client';

export default {
  create(data) {
    return client.post('/patients', data);
  },

  update(patient) {
    return client.put(`/patients/${ patient.id }`, patient);
  }
};
