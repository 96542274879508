import React, { useMemo, useRef } from 'react';
import styled from 'styled-components';

import InfiniteScroll from '~/components/shared/InfiniteScroll';
import { BodySmallBold } from '~/components/shared/typography';
import { colors } from '~/styles/theme';

import Card from './Card';
import { useTaskModalContext } from './TaskModalContext';

function List() {
  const {
    escalationsData: { escalations, pageLoading, nextUrl },
    escalationTypeLabel,
    fetchNextPage,
    onCardClick,
    selectedEscalation: { id: selectedId } = {},
  } = useTaskModalContext();
  const taskList = useRef(null);

  const cards = useMemo(
    () =>
      escalations.map((escalation) => (
        <Card key={escalation.id} active={escalation.id === selectedId} escalation={escalation} onClick={onCardClick} />
      )),
    [escalations, selectedId]
  );

  return (
    <Container ref={taskList}>
      {cards.length ? (
        cards
      ) : (
        <EmptyState>
          <BodySmallBold textAlign='center' color={colors.black50}>
            Any {escalationTypeLabel.toLowerCase()} that require your attention will be listed here.
          </BodySmallBold>
        </EmptyState>
      )}
      <InfiniteScroll
        element={taskList.current}
        hasMore={Boolean(nextUrl)}
        loading={pageLoading}
        onEndReached={fetchNextPage}
      />
    </Container>
  );
}

export default List;

const Container = styled.div`
  background-color: ${({ theme }) => theme.colors.black05};
  border-right: 1px solid ${({ theme }) => theme.colors.black25};
  overflow-y: scroll;
  padding: 16px 4px 16px 24px;

  &::-webkit-scrollbar {
    height: 6px;
    width: 6px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: ${({ theme }) => theme.colors.scrollbarGray};
    border-radius: 5pt;
  }

  & > * {
    margin: 8px 0;
  }

  & > *:first-child {
    margin-top: 0px;
  }

  & > *:last-child {
    margin-bottom: 0px;
  }
`;

const EmptyState = styled.div`
  height: 100%;
  padding: 0 16px;
  display: flex;
  align-items: center;
`;
