import { CreateRequest } from './core';

function buildURL(locationEpisodeId: string) {
  return `/location_episodes/${locationEpisodeId}/location_episode_rehab_states`;
}

export default {
  create: new CreateRequest({
    formatArgsForConfig: (data) => ({
      url: buildURL(data.locationEpisodeId),
      data,
    }),
  }),
};
