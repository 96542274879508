import Client from '~/models/Client';

import Classification from './Classification';
import Location from './Location';

export const baseAttrMatchRules = {
  '*': ['%{value}'],
};

interface IAttrOptions {
  id: string;
  clientId: string;
  createdAt: string | null;
  createdBy: string | null;
  name: string;
  displayName: string | null;
  olioAttr: string | null;
  visible: boolean;
  matchRules: Record<string, unknown> | null;
  client: Client | null;
  active: boolean;
  attrValues?: AttrValue[];
}

const attrDefaults: IAttrOptions = {
  id: '',
  clientId: '',
  createdAt: null,
  createdBy: null,
  name: '',
  displayName: '',
  olioAttr: '',
  visible: false,
  matchRules: baseAttrMatchRules,
  client: null,
  active: true,
};

export class Attr implements IAttrOptions {
  id: string;
  clientId: string;
  createdAt: string | null;
  createdBy: string | null;
  name: string;
  displayName: string | null;
  olioAttr: string | null;
  visible: boolean;
  matchRules: Record<string, unknown> | null;
  client: Client | null;
  active: boolean;
  attrValues?: AttrValue[];

  constructor(options: IAttrOptions) {
    const opts = { ...attrDefaults, ...options };

    this.id = opts.id;
    this.clientId = opts.clientId;
    this.client = opts.client ? new Client(opts.client) : opts.client;
    this.createdAt = opts.createdAt;
    this.createdBy = opts.createdBy;
    this.name = opts.name;
    this.displayName = opts.displayName;
    this.olioAttr = opts.olioAttr;
    this.visible = opts.visible;
    this.matchRules = opts.matchRules;
    this.active = opts.active;
    this.attrValues = opts.attrValues?.map((attrValue) => new AttrValue(attrValue));
  }

  get olioAttrObjectType(): string | null {
    if (!this.olioAttr) return null;

    return this.olioAttr.match(/classification/i) ? 'Classification' : 'Group';
  }

  get isGroupType(): boolean {
    return this.olioAttrObjectType === 'Group';
  }

  get isClassificationType(): boolean {
    return this.olioAttrObjectType === 'Classification';
  }
}

// AttrValues class definition
interface IAttrValueOptions {
  id: string;
  createdAt: string | null;
  createdBy: string | null;
  name: string;
  displayName: string | null;
  visible: boolean;
  client: Client | null;
  attr: IAttrOptions | null;
  active: boolean;
  associatedClassifications: Classification[];
  associatedGroups: Location[];
}

const attrValueDefaults: IAttrValueOptions = {
  id: '',
  createdAt: null,
  createdBy: null,
  name: '',
  displayName: '',
  visible: false,
  client: null,
  attr: null,
  active: true,
  associatedClassifications: [],
  associatedGroups: [],
};

export class AttrValue implements IAttrValueOptions {
  id: string;
  createdAt: string | null;
  createdBy: string | null;
  name: string;
  displayName: string | null;
  visible: boolean;
  client: Client | null;
  attr: IAttrOptions | null;
  active: boolean;
  associatedClassifications: Classification[];
  associatedGroups: Location[];

  constructor(options: IAttrValueOptions) {
    const opts = { ...attrValueDefaults, ...options };

    this.id = opts.id;
    this.client = opts.client ? new Client(opts.client) : opts.client;
    this.attr = opts.attr ? new Attr(opts.attr) : opts.attr;
    this.createdAt = opts.createdAt;
    this.createdBy = opts.createdBy;
    this.name = opts.name;
    this.displayName = opts.displayName;
    this.visible = opts.visible;
    this.active = opts.active;
    this.associatedClassifications = opts.associatedClassifications;
    this.associatedGroups = opts.associatedGroups;
  }
}
