import React from 'react';

import { InputGroup, MultiSelect, MultiSelectLabel } from '~/components/shared/form';
import { getId, getName } from '~/helpers';

function ParentGroups() {
  return (
    <InputGroup name='owners'
      label='Parent Groups'
      placeholder='Parent Groups'
      options={ [] }
      getOptionLabel={ getName }
      getOptionValue={ getId }
      disabled
      component={ MultiSelect }
      labelComponent={ MultiSelectLabel } />
  );
}

export default ParentGroups;
