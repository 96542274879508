import React from 'react';

function PhysicianGroupIcon() {
  return (
    <svg width='14px' height='14px' viewBox='0 0 14 14' version='1.1' xmlns='http://www.w3.org/2000/svg'>
      <title>9799CFE8-0042-4457-9126-65244259E559</title>
      <g id='info-sections-with-icons' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd' strokeLinecap='round' strokeLinejoin='round'>
        <g id='long-values-wrapping' transform='translate(-224.000000, -392.000000)' stroke='#3253EF'>
          <g id='multiple-11' transform='translate(225.000000, 393.000000)'>
            <rect id='Rectangle' x='0.8' y='1.6' width='2.4' height='2.4' rx='1.2' />
            <path d='M2.4,11.2 L0.8,11.2 L0.8,8.8 L0,8.8 L0,6.4 C0,5.9581722 0.3581722,5.6 0.8,5.6 L1.6,5.6' id='Path' />
            <rect id='Rectangle' transform='translate(10.000000, 2.800000) rotate(-180.000000) translate(-10.000000, -2.800000) ' x='8.8' y='1.6' width='2.4' height='2.4' rx='1.2' />
            <path d='M9.6,11.2 L11.2,11.2 L11.2,8.8 L12,8.8 L12,6.4 C12,5.9581722 11.6418278,5.6 11.2,5.6 L10.4,5.6' id='Path' />
            <rect id='Rectangle' x='4.4' y='0' width='3.2' height='3.2' rx='1.6' />
            <path d='M8,12 L4,12 L4,8.8 L3.2,8.8 L3.2,6.4 C3.2,5.5163444 3.9163444,4.8 4.8,4.8 L7.2,4.8 C8.0836556,4.8 8.8,5.5163444 8.8,6.4 L8.8,8.8 L8,8.8 L8,12 Z' id='Path' />
          </g>
        </g>
      </g>
    </svg>
  );
}

export default PhysicianGroupIcon;
