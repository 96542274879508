import { Api, FetchRequest } from './core';

export const url = 'attachments';
const locationEpisodesUrl = 'location_episodes';

const { fetchById, update } = new Api({ url });

export default {
  fetch: new FetchRequest({
    formatArgsForConfig: ({ locationEpisodeId, ...params }) => ({
      url: `${locationEpisodesUrl}/${locationEpisodeId}/${url}`,
      params,
    }),
  }),
  fetchById,
  update,
};
