import React, { useMemo } from 'react';
import { useFormikContext } from 'formik';
import PropTypes from 'prop-types';

import { FormSection, SectionHeader } from '~/components/shared/form';
import { PAYOR, PHYSICIAN_GROUP } from '~/constants/clientTypes';
import { ALL_FLAGS } from '~/models';
import { PERMISSIONS, useProfileContext } from '~/services/profile';

import ClassificationSection from './ClassificationSection';
import { formFields } from './constants';
import { HospitalEpisodeSection, PayerEpisodeSection, PhysicianGroupEpisodeSection } from './episodeSections';
import RehabFacilityInputGroup from './RehabFacilityInputGroup';

const COMPONENT_MAP = {
  [PAYOR]: PayerEpisodeSection,
  [PHYSICIAN_GROUP]: PhysicianGroupEpisodeSection,
};

function EpisodeInfoSection({ isPostAcuteUser }) {
  const { values } = useFormikContext();
  const profileSvc = useProfileContext();
  const clientType = profileSvc.clientType;

  const canAssignCaseManager =
    profileSvc.hasFlag(ALL_FLAGS.caseManagerAssignment) && profileSvc.has(PERMISSIONS.locationEpisodeUserEdit);

  const EpisodeSectionComponent = useMemo(() => {
    return COMPONENT_MAP[clientType] || HospitalEpisodeSection;
  }, [clientType]);

  return (
    <FormSection>
      <SectionHeader>Episode Information</SectionHeader>
      <EpisodeSectionComponent disabled={isPostAcuteUser} canAssignCaseManager={canAssignCaseManager} />
      <RehabFacilityInputGroup owner={values[formFields.ownerField.name]} isPostAcuteUser={isPostAcuteUser} />
      <ClassificationSection disabled={isPostAcuteUser} />
    </FormSection>
  );
}

EpisodeInfoSection.propTypes = {
  isPostAcuteUser: PropTypes.bool,
};

export default EpisodeInfoSection;
