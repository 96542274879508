import React from 'react';
import styled from 'styled-components';

import CircleSpinner from '~/components/shared/CircleSpinner';
import ChartIconLarge from '~/components/shared/svg/ChartIconLarge';
import ChevronLeft from '~/components/shared/svg/ChevronLeft';

type Props = {
  children: React.ReactNode;
  hasData?: boolean;
  loading?: boolean;
  onBackClick: () => void;
  header: JSX.Element;
};

export default function MetricDetailContainer(props: Props) {
  const { children, hasData = true, loading = false, onBackClick, header, ...rest } = props;

  return (
    <Container {...rest}>
      <FlexContainer>
        {onBackClick && (
          <BackButtonContainer onClick={onBackClick}>
            <Chevron />
          </BackButtonContainer>
        )}
        {header}
      </FlexContainer>

      {loading ? (
        <SpinnerContainer>
          <CircleSpinner />
        </SpinnerContainer>
      ) : (
        <MetricContent>
          {hasData ? (
            children
          ) : (
            <EmptyState>
              <ChartIconLarge color='#3253EF' />
              <EmptyText>No data to show for the selected date range</EmptyText>
            </EmptyState>
          )}
        </MetricContent>
      )}
    </Container>
  );
}

const BackButtonContainer = styled.div`
  height: 12px;
  display: flex;
  align-items: center;
  cursor: pointer;
  align-self: flex-start;
`;

const Container = styled.div`
  height: 360px;
  max-width: 1280px;
  background-color: ${({ theme }) => theme.colors.white};
  border: 1px solid ${({ theme }) => theme.colors.black10};
  border-radius: 8px;
  box-sizing: border-box;
  padding: 24px 24px 8px 24px;
  margin: 16px auto 0 auto;
  display: flex;
  flex-direction: column;
`;

const Chevron = styled(ChevronLeft)`
  margin-right: 24px;
  color: ${({ theme }) => theme.colors.black};
`;

const EmptyText = styled.p`
  color: ${({ theme }) => theme.colors.black50};
  font-size: 16px;
  font-weight: normal;
  text-align: center;
`;

const EmptyState = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100%;
`;

const FlexContainer = styled.div`
  display: flex;
`;

const MetricContent = styled.div`
  min-height: 200px;
  margin-top: 24px;
  width: 100%;
  flex: 1;
  justify-content: center;
  display: flex;
  flex-direction: column;
`;

const SpinnerContainer = styled.div`
  flex: 1;
  display: flex;
`;
