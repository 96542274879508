import React from 'react';
import FontAwesome from 'react-fontawesome';
import { connect, ConnectedProps } from 'react-redux';

import { createEpisodeExport } from '~/ducks/episodes/exports';
import useExportDownload from '~/lib/hooks/useExportDownload';
import colors from '~/styles/theme/colors';

import ExportIcon from '../../shared/svg/ExportIcon';

interface Props extends ConnectedProps<typeof connector> {
  episodeId: string;
}

function ExportDownload({ episodeId, createEpisodeExport }: Props) {
  const exportFn = () => createEpisodeExport({ id: episodeId });
  const { loading, downloadExport } = useExportDownload({ exportFn });

  return loading ? (
    <FontAwesome spin name='spinner' size='2x' style={{ color: colors.black }} />
  ) : (
    <a href='#' data-gtm-id='patientExport' onClick={downloadExport}>
      <ExportIcon />
    </a>
  );
}

const mapDispatchToProps = {
  createEpisodeExport,
};
const connector = connect(null, mapDispatchToProps);

export default connector(ExportDownload);
