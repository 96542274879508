import React, { useMemo, useRef } from 'react';
import styled from 'styled-components';

import { useDimensions } from '~/lib/hooks';

import AverageLengthOfStayTile from './AverageLengthOfStayTile';
import MetricsSection from './MetricsSection';
import OlioEngagementScoreTile from './OlioEngagementScoreTile';
import ReadmissionRateTile from './ReadmissionRateTile';
import { useInsightsContext } from '.';

const MetricsDashboard = () => {
  const insightsContext: any = useInsightsContext();

  const wrapperRef = useRef<HTMLDivElement>(null);
  const { width: wrapperWidth } = useDimensions(wrapperRef);

  const networkPerformanceEngagementTiles = useMemo(
    () => (
      <>
        <OlioEngagementScoreTile />
        <ReadmissionRateTile />
        <AverageLengthOfStayTile />
      </>
    ),
    []
  );

  return (
    <MetricsDashboardWrapper ref={wrapperRef}>
      {insightsContext.profileSvc.isAcute ? (
        // can add more acute <MetricsSection> components here as we develop more metrics
        <MetricsSection title='Network Performance & Engagement' wrapperWidth={wrapperWidth}>
          {networkPerformanceEngagementTiles}
        </MetricsSection>
      ) : (
        <MetricsSection.Grid columnMaxWidth={412} gridWidth={wrapperWidth}>
          {networkPerformanceEngagementTiles}
        </MetricsSection.Grid>
      )}
    </MetricsDashboardWrapper>
  );
};

const MetricsDashboardWrapper = styled.div`
  display: flex;
  margin-top: 16px;

  > * + * {
    margin-top: 24px;
  }

  > * {
    margin-left: auto;
    margin-right: auto;
    max-width: 1280px;
  }
`;

export default MetricsDashboard;
