import React from 'react';

export default function ChevronRight(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='5' height='9' fill='none' {...props}>
      <path
        fill='currentColor'
        d='M.422 1.62c0 .203.08.397.224.54l2.512 2.512L.646 7.185a.763.763 0 0 0 1.079 1.078l3.052-3.051a.763.763 0 0 0 0-1.08L1.725 1.082a.763.763 0 0 0-1.303.54Z'
      />
    </svg>
  );
}
