import { createAsyncThunk } from '~/lib';
import cancellableRequestFactory from '~/sources/api/cancellableRequestFactory';
import { BASE_URL as LOCATION_EPISODES_BASE_URL } from '~/sources/api/locationEpisodesAPI';
import taggableUsersAPI from '~/sources/api/taggableUsersAPI';
import { BASE_URL as TAGGABLE_USERS_BASE_URL } from '~/sources/api/taggableUsersAPI';

const cancellableFetchTaggableUsers = cancellableRequestFactory(taggableUsersAPI.fetchTaggableUsers);

export const fetchTaggableUsers = createAsyncThunk('taggableUsers/fetch', async ({ locationEpisodeId, params }) => {
  const url = `${LOCATION_EPISODES_BASE_URL}/${locationEpisodeId}/${TAGGABLE_USERS_BASE_URL}`;
  const res = await cancellableFetchTaggableUsers(url, params);

  return res.data.data;
});
