import Client from './Client';

const defaults = {
  clients: [],
  locationIds: [],
  name: '',
  title: '',
};

export const ALL_FLAGS = {
  dischargeQuestions: 'discharge_questions',
  importImprovements: 'import_improvements',
  altcsPending: 'altcs_pending',
  caseManagerAssignment: 'case_manager_assignment',
};
export default class Flag {
  constructor(options = {}) {
    const opts = { ...defaults, ...options };

    this.locationIds = opts.locationIds;
    this.name = opts.name;
    this.title = opts.title;
    this.clients = opts.clients ? opts.clients.map((client) => new Client(client)) : [];
  }
}
