import React, { useMemo } from 'react';
import styled from 'styled-components';

import {
  AllPatientsIcon,
  ConnectPatientsIcon,
  GearIcon,
  InsightsIcon,
  PatientPortfolioIcon,
} from '~/components/shared/svg';
import { PERMISSIONS, useProfileContext } from '~/services/profile';

import SideNavLink from './SideNavLink';

const SideNavbar = () => {
  const profileSvc = useProfileContext();
  const {
    adminAttrView,
    adminClassificationView,
    adminClientView,
    adminFeatureFlagView,
    adminGroupView,
    adminGroupTypeView,
    adminImportedPatientView,
    adminQuestionTemplateView,
    adminUserView,
  } = PERMISSIONS;

  const handleQuestionTemplatesIsActive = (match, location) => {
    return match || location.pathname.includes('/question-templates/');
  };

  const adminViewPermissionRegex = /^admin\w.*View$/;
  const adminViewPermissions = Object.values(PERMISSIONS).filter((value) => adminViewPermissionRegex.test(value));
  const showConnectPatients = useMemo(() => {
    return (
      profileSvc.isAcute && (profileSvc.isAdmin || (!profileSvc.isPhysicianTeamUser && !profileSvc.isAffiliateUser))
    );
  }, [profileSvc]);

  return (
    <NavContainer>
      <NavContent>
        <SideNavLink to='/patients' title='Patient Portfolio' icon={<PatientPortfolioIcon size={18} />} />
        <SideNavLink to='/all-patients' title='All Patients' icon={<AllPatientsIcon size={18} />} />
        <SideNavLink
          to='/connect-patients'
          title='Connect Patients'
          icon={<ConnectPatientsIcon size={18} />}
          visible={showConnectPatients}
        />
        <SideNavLink
          to='/insights'
          title='Olio Insights'
          icon={<InsightsIcon size={18} />}
          visible={!profileSvc.isAffiliateUser}
        />
        <SideNavLink title='Admin' icon={<GearIcon size={18} />} visible={profileSvc.hasAny(adminViewPermissions)}>
          <SideNavLink title='Clients' to='/clients' visible={profileSvc.has(adminClientView)} />
          <SideNavLink title='Users' to='/users' visible={profileSvc.has(adminUserView)} />
          <SideNavLink title='Groups' to='/groups' visible={profileSvc.has(adminGroupView)} />
          <SideNavLink title='Group Types' to='/group-types' visible={profileSvc.has(adminGroupTypeView)} />
          <SideNavLink title='Attributes' to='/attributes' visible={profileSvc.has(adminAttrView)} />
          <SideNavLink
            isActive={handleQuestionTemplatesIsActive}
            title='Question Templates'
            to='/question-templates/progress'
            visible={profileSvc.has(adminQuestionTemplateView)}
          />
          <SideNavLink
            title='Plan Types'
            to='/classifications/plan-type'
            visible={profileSvc.has(adminClassificationView)}
          />
          <SideNavLink
            title='Episode Types'
            to='/classifications/episode'
            visible={profileSvc.has(adminClassificationView)}
          />
          <SideNavLink title='Feature Flags' to='/feature-flags' visible={profileSvc.has(adminFeatureFlagView)} />
          <SideNavLink title='Import History' to='/import-history' visible={profileSvc.has(adminImportedPatientView)} />
        </SideNavLink>
      </NavContent>
    </NavContainer>
  );
};

export default SideNavbar;

const NavContainer = styled.div`
  min-height: calc(100vh - 2 * ${({ theme }) => theme.dimensions.mainHeaderHeight});
  min-width: ${({ theme }) => theme.dimensions.sideNavWidth};
  background-color: ${({ theme }) => theme.colors.black};
  display: flex;
  flex: 0;
`;

const NavContent = styled.nav`
  display: flex;
  flex: 1;
  flex-direction: column;
`;
