import React, { useMemo, useState } from 'react';
import { useFormikContext } from 'formik';

import {
  FormSection,
  InlineInputGroups,
  Input,
  InputGroup,
  SectionHeader,
  Select
} from '~/components/shared/form';
import states from '~/components/shared/states';
import { AFFILIATE, PHYSICIAN_TEAM } from '~/constants/locationTypes';
import { TIMEZONES } from '~/constants/timezones';
import { fetchClients } from '~/ducks/admin/clients';
import { getId, getName } from '~/helpers';
import { useAsyncOptions, useDeepEffect } from '~/lib/hooks';
import { Location } from '~/models';

function ContactInfoSection() {
  const { status: { isEdit }, values } = useFormikContext();

  const [clientParams, setClientParams] = useState(null);
  const location = useMemo(() => new Location(values), [values]);

  useDeepEffect(() => {
    if (isEdit && location) {
      setClientParams({ clientType: location.client.clientType });
    }
  }, [isEdit, location]);

  const asyncClientOptions = useAsyncOptions(fetchClients, {
    condition: !isEdit || (isEdit && clientParams),
    params: clientParams
  });

  const disableClient = isEdit && !location.isProvider;
  const kind = values.locationType?.kind;
  const showAddress = kind !== PHYSICIAN_TEAM && kind !== AFFILIATE;

  return (
    <FormSection>
      <SectionHeader>Contact Information</SectionHeader>
      <InputGroup { ...asyncClientOptions }
        name='client'
        label='Client'
        data-cy='client'
        placeholder='Client'
        disabled={ disableClient }
        getOptionLabel={ getName }
        getOptionValue={ getId }
        component={ Select } />
      <InputGroup name='name'
        label='Location Name'
        placeholder='Location Name'
        component={ Input } />
      { showAddress &&
        <React.Fragment>
          <InlineInputGroups>
            <InputGroup name='address.streetAddress'
              label='Street 1'
              placeholder='Street 1'
              component={ Input } />
            <InputGroup name='address.streetAddressLine2'
              label='Street 2 (optional)'
              placeholder='Street 2'
              component={ Input } />
          </InlineInputGroups>
          <InlineInputGroups>
            <InputGroup name='address.city'
              label='City'
              placeholder='City'
              component={ Input } />
            <InlineInputGroups>
              <InputGroup name='address.state'
                label='State'
                data-cy='state'
                placeholder='State'
                options={ states }
                component={ Select } />
              <InputGroup name='address.zip'
                label='Zip'
                placeholder='Zip'
                component={ Input } />
            </InlineInputGroups>
          </InlineInputGroups>
        </React.Fragment>
      }

      <InputGroup name='timezone'
        label='Timezone'
        data-cy='timezone'
        placeholder='Timezone'
        options={ TIMEZONES }
        component={ Select } />
    </FormSection>
  );
}

export default ContactInfoSection;
