import React from 'react';

function ClipboardIcon() {
  return (
    <svg width='13px' height='14px' viewBox='0 0 13 14' version='1.1' xmlns='http://www.w3.org/2000/svg'>
      <title>E4E37AB7-5E8D-4446-86AA-5FDC4B94C844</title>
      <g id='info-sections-with-icons' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd' strokeLinecap='round' strokeLinejoin='round'>
        <g id='long-values-wrapping' transform='translate(-224.000000, -560.000000)' stroke='#3253EF'>
          <g id='medical-clipboard' transform='translate(225.000000, 561.000000)'>
            <path d='M9.6,0.8 L10.4,0.8 C10.8418278,0.8 11.2,1.1581722 11.2,1.6 L11.2,11.2 C11.2,11.6418278 10.8418278,12 10.4,12 L0.8,12 C0.3581722,12 0,11.6418278 0,11.2 L0,1.6 C0,1.1581722 0.3581722,0.8 0.8,0.8 L1.6,0.8' id='Path' />
            <rect id='Rectangle' x='3.2' y='0' width='4.8' height='1.6' />
            <line x1='5.6' y1='4.8' x2='5.6' y2='9.6' id='Path' />
            <line x1='3.2' y1='7.2' x2='8' y2='7.2' id='Path' />
          </g>
        </g>
      </g>
    </svg>
  );
}

export default ClipboardIcon;
