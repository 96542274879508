import React from 'react';
import ReactSelect from 'react-select';

import { AsyncThunk } from '@reduxjs/toolkit';

import { useAsyncOptions, useField } from '~/lib/hooks';

import { CheckboxMultiSelect } from '../form';

import FilterClearIndicator from './FilterClearIndicator';
import FilterDropdownIndicator from './FilterDropdownIndicator';
import FilterMultiSelectPlaceholder from './FilterMultiSelectPlaceholder';
import getStyles from './filterMultiSelectStyles';

export type Option = { id: string; name: string; [key: string]: string | object | number };

export type FilterMultiSelectFilter = {
  filterLabel: string;
  name: string;
  hasError?: boolean;
  isDisabled?: boolean;
  getOptionLabel: (option: any) => string;
  getOptionValue: (option: any) => string;
  getOptions?: {
    thunk: AsyncThunk<object, object, any>;
    params?: object;
    optionsToPrepend?: Option[];
  };
};

export type FilterMultiSelectProps = FilterMultiSelectFilter & React.ComponentProps<ReactSelect>;

const FilterMultiSelect = (props: FilterMultiSelectProps) => {
  const [field] = useField(props);
  const [fetchOptions, setFetchOptions] = React.useState(false);

  const hasSelectedValues = !!field.value?.length;

  const components = {
    DropdownIndicator: FilterDropdownIndicator,
    ClearIndicator: FilterClearIndicator,
    Placeholder: FilterMultiSelectPlaceholder,
  };

  const asyncOptions = useAsyncOptions(props.getOptions?.thunk, {
    condition: fetchOptions,
    params: props.getOptions?.params,
    optionsToPrepend: props.getOptions?.optionsToPrepend || [],
  });

  return (
    <div data-cy={`${field.dataCy || field.name}Filter`} onClick={() => setFetchOptions(true)}>
      <CheckboxMultiSelect components={components} styles={getStyles(hasSelectedValues)} {...asyncOptions} {...props} />
    </div>
  );
};

export default FilterMultiSelect;
