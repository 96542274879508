import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import QuestionContent from './QuestionContent';

export function yesNoComponentConfig(question) {
  return {
    QuestionComponent: YesNoQuestion,
    name: question.id,
    text: question.text,
    index: question.index,
    positive: question.config.positive,
  };
}

const YesNoQuestion = ({ value, helpers, ...props }) => {
  const handleChange = (event) => {
    helpers.setValue(event.target.value);
  };

  return (
    <QuestionContainer>
      <QuestionContent {...props} />
      <AnswerContainer>
        <Answer value='No' className={value === 'No' && 'selected'} onClick={handleChange}>
          No
        </Answer>
        <Answer value='Yes' className={value === 'Yes' && 'selected'} onClick={handleChange}>
          Yes
        </Answer>
      </AnswerContainer>
    </QuestionContainer>
  );
};

YesNoQuestion.propTypes = {
  helpers: PropTypes.shape({
    setValue: PropTypes.func,
  }),
  value: PropTypes.string,
};

export default YesNoQuestion;

const QuestionContainer = styled.div`
  display: flex;

  & > *:first-child {
    flex: 1;
  }
`;

const AnswerContainer = styled.div`
  display: flex;
  align-items: center;

  & > * {
    &:first-child {
      margin: 0 8px 0 14px;
    }
  }
`;

const Answer = styled.button.attrs({
  type: 'button',
})`
  background-color: ${({ theme }) => theme.colors.black05};
  border-radius: 3px;
  font-size: 12px;
  color: ${({ theme }) => theme.colors.black};
  height: 24px;
  width: 80px;

  &.selected {
    background-color: ${({ theme }) => theme.colors.primaryBlue10};
    border: 1px solid ${({ theme }) => theme.colors.primaryBlue};
    color: ${({ theme }) => theme.colors.primaryBlue};
    border-radius: 3px;
  }
`;
