import * as _ from 'lodash-es';

import * as clientTypes from '~/constants/clientTypes';
import {
  CASE_MANAGER,
  EPISODE_CLASSIFICATIONS,
  HOSPITALS,
  LATEST_REHAB_STATE,
  NONE_OPTION,
  PAYERS,
  PHYSICIAN_GROUP,
  PLAN_TYPE_CLASSIFICATIONS,
} from '~/constants/filterKeysConstants';
import {
  HOSPITAL,
  PAYER,
  PHYSICIAN_GROUP as PHYSICIAN_GROUP_LOCATION_TYPE,
  PHYSICIAN_TEAM,
} from '~/constants/locationTypes';
import { fetchAttrValues } from '~/ducks/attrValues';
import { fetchClassifications } from '~/ducks/classifications';
import { fetchLocations } from '~/ducks/locations';
import { fetchRehabStatesNoCache } from '~/ducks/rehabStates';
import { fetchUsers } from '~/ducks/users';
import { getId, getName } from '~/helpers';
import { GroupType, RehabState, User } from '~/models';

// Slideout Section Title constants
export const COMANAGEMENT_TEAM = 'Co-Management Team';
export const ADDITIONAL_FILTERS = 'Additional Filters';

const defaultFilterDropdownConfig = {
  getOptionLabel: getName,
  getOptionValue: getId,
};

// Static Dropdowns
export const PHYSICIAN_GROUP_DROPDOWN = {
  ...defaultFilterDropdownConfig,
  filterLabel: 'Physician Group',
  name: PHYSICIAN_GROUP,
  getOptions: {
    thunk: fetchLocations,
    params: { type: PHYSICIAN_GROUP_LOCATION_TYPE },
  },
};

export const HOSPITAL_DROPDOWN = {
  ...defaultFilterDropdownConfig,
  filterLabel: 'Hospital',
  name: HOSPITALS,
  getOptions: {
    thunk: fetchLocations,
    params: { type: HOSPITAL },
  },
};

export const PHYSICIAN_TEAM_DROPDOWN = {
  ...defaultFilterDropdownConfig,
  filterLabel: 'Physician',
  name: 'physicianTeam',
  getOptionValue: getName,
  getOptions: {
    thunk: fetchAttrValues,
    params: { distinctDisplayName: true, type: PHYSICIAN_TEAM, active: true },
  },
};

export const PAYER_DROPDOWN = {
  ...defaultFilterDropdownConfig,
  filterLabel: 'Payer',
  name: PAYERS,
  getOptions: {
    thunk: fetchLocations,
    params: { type: PAYER },
  },
};

export const PLAN_TYPE_DROPDOWN = {
  ...defaultFilterDropdownConfig,
  filterLabel: 'Plan Type',
  name: PLAN_TYPE_CLASSIFICATIONS,
  getOptions: {
    thunk: fetchClassifications,
    params: { type: 'plan_type_classification' },
    optionsToPrepend: [NONE_OPTION],
  },
};

export const EPISODE_TYPE_DROPDOWN = {
  ...defaultFilterDropdownConfig,
  filterLabel: 'Episode Type',
  name: EPISODE_CLASSIFICATIONS,
  getOptions: {
    thunk: fetchClassifications,
    params: { type: 'episode_classification' },
    optionsToPrepend: [NONE_OPTION],
  },
};

export const LATEST_REHAB_STATE_DROPDOWN = {
  ...defaultFilterDropdownConfig,
  filterLabel: 'Stage',
  name: LATEST_REHAB_STATE,
  dataCy: 'stage',
  getOptionLabel: (rehabState: RehabState) => rehabState.state,
  getOptions: {
    thunk: fetchRehabStatesNoCache,
  },
};

export const CASE_MANAGER_DROPDOWN = {
  ...defaultFilterDropdownConfig,
  filterLabel: 'Case Manager',
  name: CASE_MANAGER,
  getOptionLabel: (user: User) => user.fullName,
  getOptions: {
    thunk: fetchUsers,
    params: {
      include: 'credential',
      sortBy: 'name asc',
      activeOrCaseManager: true,
    },
  },
};

// Dynamic Dropdowns. Use for creating a dropdown config for a provider.
export const createProviderDropdown = (providerType: GroupType, additionalThunkParams?: object) => ({
  ...defaultFilterDropdownConfig,
  filterLabel: providerType.displayName,
  name: _.camelCase(providerType.apiName),
  getOptions: {
    thunk: fetchLocations,
    params: {
      groupType: providerType.id,
      ...additionalThunkParams,
    },
  },
});

// Maintains the previously decided order of the filters based on clientType.
export const getComanagementTeamAcuteFilters = (actingClientType: string) => {
  switch (actingClientType) {
    case clientTypes.HEALTH_SYSTEM:
      return [HOSPITAL_DROPDOWN, PHYSICIAN_TEAM_DROPDOWN];
    case clientTypes.PHYSICIAN_GROUP:
      return [PHYSICIAN_GROUP_DROPDOWN, HOSPITAL_DROPDOWN, PHYSICIAN_TEAM_DROPDOWN];
    case clientTypes.PAYOR:
      return [PAYER_DROPDOWN, HOSPITAL_DROPDOWN, PHYSICIAN_TEAM_DROPDOWN];
    default:
      return [HOSPITAL_DROPDOWN, PHYSICIAN_GROUP_DROPDOWN, PAYER_DROPDOWN];
  }
};
