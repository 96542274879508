import * as Yup from 'yup';

export const refusalCreateValidation = Yup.object().shape({
  data: Yup.object().shape({
    refusedBy: Yup.object().shape({
      id: Yup.string(),
      type: Yup.string()
    }).nullable().test('refusedBy', 'You must select who is refusing service', (obj) => !!obj?.id)
  })
});
