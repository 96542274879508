import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Button } from '~/components/shared/buttons';
import { DischargedIcon, WarnIcon } from '~/components/shared/svg';
import { Episode, LocationEpisode } from '~/models';
import { ProfileContext } from '~/services/profile';
import { RehabStateService } from '~/services/rehabState';
import colors from '~/styles/theme/colors';

function PatientActions(props) {
  const {
    currentRehabState,
    episode,
    locationEpisode,
    patientDischargedViaModal,
    showDischargeModal,
    showEscalationModal,
  } = props;

  const profileSvc = useContext(ProfileContext);
  const userCanDischargePatient =
    profileSvc.canDischarge(locationEpisode.locationId) && !locationEpisode.currentRehabState.queue;
  const canDischargeState = new RehabStateService(currentRehabState).canDischarge;
  const userCanEscalate = profileSvc.canEscalate(locationEpisode.locationId);
  const visible = locationEpisode.latest && !locationEpisode.archived && !episode.archived;

  return (
    visible && (
      <Container>
        {userCanDischargePatient && (
          <ProfileButton
            onClick={showDischargeModal}
            icon={<DischargedIcon size={18} />}
            color='primaryWhite'
            data-cy='dischargeButton'
            text='Discharge'
            disabled={!canDischargeState || patientDischargedViaModal}
          />
        )}
        {userCanEscalate && (
          <ProfileButton
            onClick={showEscalationModal}
            icon={<WarnIcon size={18} fill={colors.accentRed} />}
            color='accentWhite'
            data-cy='escalateButton'
            text='Escalate'
          />
        )}
      </Container>
    )
  );
}

PatientActions.propTypes = {
  currentRehabState: PropTypes.string,
  episode: PropTypes.instanceOf(Episode).isRequired,
  locationEpisode: PropTypes.instanceOf(LocationEpisode),
  patientDischargedViaModal: PropTypes.bool,
  showDischargeModal: PropTypes.func.isRequired,
  showEscalationModal: PropTypes.func.isRequired,
};

export default PatientActions;

const Container = styled.div`
  display: flex;
  margin-left: auto;
  & > button {
    margin-left: 10px;
  }
`;

const ProfileButton = styled(Button)`
  font-size: 14px;
  margin-left: 24px;
  min-width: 144px;
  width: 144px;
`;
