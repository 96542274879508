import React from 'react';

import { formFields } from '~/components/patients/intake/IntakeForm/constants';
import { InputGroup, Select } from '~/components/shared/form';
import { fetchUsers } from '~/ducks/users';
import { getFullName, getId } from '~/helpers';
import { useAsyncOptions } from '~/lib/hooks';

type Props = {
  groupId?: string;
};

export default function CaseManagerSection({ groupId }: Props) {
  const { caseManagerField } = formFields;

  const caseManagerAsyncOptions = useAsyncOptions(fetchUsers, {
    condition: !!groupId,
    params: {
      group: groupId,
      include: 'credential',
      sortBy: 'name',
      active: true,
    },
  });

  return (
    <InputGroup
      {...caseManagerAsyncOptions}
      {...caseManagerField}
      isClearable
      autoSelectSingleOption={false}
      component={Select}
      getOptionLabel={getFullName}
      getOptionValue={getId}
      data-cy={caseManagerField.name}
      placeholder={caseManagerField.placeholder}
      disabled={!groupId}
    />
  );
}
