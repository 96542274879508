import Highcharts from 'highcharts';

import { colors } from '~/styles/theme';

export type ChartData = {
  categories: string[];
  dischargeCounts: number[];
  readmissionCounts: number[];
  readmissionRates: number[];
};

// Calculates the scrollable width of the chart based on the number of data points
const calculateWidth = (pointWidth: number, dataLength: number) => {
  return (pointWidth + pointWidth * 1.5) * dataLength;
};

const getDischargesData = (dischargeCounts: number[], readmissionRates: number[]) => {
  return dischargeCounts.map((value, i) => {
    return {
      y: value,
      dataLabels: {
        enabled: true,
        format: `<span style="font-size:10px;font-weight:normal;">{${readmissionRates[i]}:.1f}%</span>`,
      },
    };
  });
};

export const generateExtendedChartConfig = ({
  categories,
  dischargeCounts,
  readmissionCounts,
  readmissionRates,
}: ChartData): Highcharts.Options => ({
  chart: {
    scrollablePlotArea: {
      minWidth: calculateWidth(40, categories.length),
      scrollPositionX: 0,
    },
  },
  legend: {
    enabled: true,
    padding: 0,
    itemMarginBottom: 10,
    itemMarginTop: 16,
    align: 'center',
    symbolRadius: 2,
    x: 0,
    verticalAlign: 'bottom',
    y: 0,
    itemStyle: {
      fontSize: '12px',
    },
  },
  xAxis: {
    categories: categories,
    lineWidth: 1,
    lineColor: colors.black25,
    labels: {
      style: {
        fontSize: '10px',
        color: colors.black,
      },
    },
  },
  scrollbar: {
    enabled: true,
  },
  yAxis: {
    gridLineWidth: 1,
    gridLineColor: colors.black10,
  },
  plotOptions: {
    column: {
      maxPointWidth: 40,
      events: { legendItemClick: () => false, mouseOver: () => false },
    },
  },
  tooltip: {
    enabled: false,
  },
  series: [
    {
      name: 'Discharges',
      color: colors.black15,
      type: 'column',
      yAxis: 0,
      data: getDischargesData(dischargeCounts, readmissionRates),
      grouping: false,
    },
    {
      name: 'Readmissions',
      color: '#DC2853',
      type: 'column',
      yAxis: 0,
      data: readmissionCounts,
      grouping: false,
    },
  ],
});
