import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { usePatientProfileContext } from '~/components/patients/patientProfile/PatientProfileContext';
import { capitalize } from '~/helpers';
import { dateAtTime } from '~/lib/formatDate';
import {
  AltcsApplicationStatusUpdate,
  AuthorizationUpdates,
  DischargeQuestions,
  Note,
  PatientFieldChanges,
  PatientMatchCollaboration,
  ProgressUpdate,
  ProjectedDischargeUpdates,
  RehabStateChange,
  ServiceRefusalActivity,
} from '~/models';
import { useProfileContext } from '~/services/profile';
import { colors } from '~/styles/theme';

import ActivityCardMenu from './ActivityCardMenu';
import ActivityCardService from './ActivityCardService';
import EscalationFooter from './EscalationFooter';
import { FullWidthLabel, SectionContainer } from './shared';

function ActivityCard({ activity, expand, showConnector }) {
  const activitySvc = useMemo(() => new ActivityCardService(activity), [activity]);
  const profileSvc = useProfileContext();
  const { episode } = usePatientProfileContext();

  const showMenu = activity.canEditOrRemove(profileSvc.profile) && !episode.archived;

  const ContentComponent = useMemo(() => activitySvc.contentComponent, []);
  const HeaderComponent = useMemo(() => activitySvc.headerComponent, []);
  const Icon = activitySvc.icon;

  return (
    <React.Fragment>
      {showConnector && <Connector />}
      <CardContainer data-dd-action-name={`${capitalize(activity.type)} activity: ${activity.id}`}>
        <HeaderContainer border={activitySvc.showBorder} color={activitySvc.borderColor}>
          <IconContainer>
            <Icon />
          </IconContainer>
          <div>
            <HeaderComponent activity={activity} />
            <FullWidthLabel color={colors.black50}>Added {dateAtTime(activity.createdAt)}</FullWidthLabel>
          </div>
          {showMenu && <ActivityCardMenu activity={activity} />}
        </HeaderContainer>
        {ContentComponent && (
          <ActivityContainer>
            <ContentComponent activity={activity} expand={expand} />

            {activity.escalation && (!episode.archived || activity.escalation.acknowledged) && (
              <SectionContainer>
                <EscalationFooter escalation={activity.escalation} />
              </SectionContainer>
            )}
          </ActivityContainer>
        )}
      </CardContainer>
    </React.Fragment>
  );
}

ActivityCard.propTypes = {
  activity: PropTypes.oneOfType([
    PropTypes.instanceOf(AltcsApplicationStatusUpdate),
    PropTypes.instanceOf(AuthorizationUpdates),
    PropTypes.instanceOf(DischargeQuestions),
    PropTypes.instanceOf(Note),
    PropTypes.instanceOf(PatientFieldChanges),
    PropTypes.instanceOf(PatientMatchCollaboration),
    PropTypes.instanceOf(ProgressUpdate),
    PropTypes.instanceOf(ProjectedDischargeUpdates),
    PropTypes.instanceOf(RehabStateChange),
    PropTypes.instanceOf(ServiceRefusalActivity),
  ]),
  expand: PropTypes.bool,
  showConnector: PropTypes.bool,
};

export default ActivityCard;

const Connector = styled.div`
  height: 24px;
  margin-left: 26px;
  border-left: 2px dotted ${({ theme }) => theme.colors.black25};
`;

const CardContainer = styled.div`
  background: ${({ theme }) => theme.colors.white};
  border-radius: 4px;
`;

const HeaderContainer = styled.div`
  padding: 16px;
  border-top: ${({ color, border }) => (border ? `5px solid ${color}` : 'none')};
  border-radius: 4px;
  display: flex;
`;

export const IconContainer = styled.div`
  margin-right: 16px;
`;

const ActivityContainer = styled.div`
  border-top: 1px solid ${({ theme }) => theme.colors.black05};
  padding: 16px 16px 0 16px;
`;
