import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { ThumbsDownIcon, ThumbsUpIcon, UnsureIcon } from '~/components/shared/svg';
import { LabelBold } from '~/components/shared/typography';
import { NO, UNSURE, YES } from '~/constants/statusConstants';
import colors from '~/styles/theme/colors';

import { SectionContainer } from '../shared';

import NoteContent from './NoteContent';

function Status({ activity }) {
  const status = activity.questions[0].answer.text;

  const STATUS_MAP = {
    [YES]: { icon: ThumbsUpIcon, message: 'on track', color: colors.accentGreen },
    [NO]: { icon: ThumbsDownIcon, message: 'off track', color: colors.accentRed },
    [UNSURE]: { icon: UnsureIcon, message: 'unsure', color: colors.black },
  };

  const Icon = STATUS_MAP[status].icon;
  const color = STATUS_MAP[status].color;

  return (
    <Fragment>
      <Icon size='12px' />
      <LabelBold color={color} margin='10px 6px'>
        {`Patient progress has been updated to ${STATUS_MAP[status].message}.`}
      </LabelBold>
      <StyledSectionContainer>
        <NoteContent activity={activity} />
      </StyledSectionContainer>
    </Fragment>
  );
}

Status.propTypes = {
  activity: PropTypes.instanceOf(Object),
};

export default Status;

const StyledSectionContainer = styled(SectionContainer)`
  overflow: initial;
`;
