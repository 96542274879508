import React from 'react';
import PropTypes from 'prop-types';

import { LabelBold } from '~/components/shared/typography';
import { PatientMatchCollaboration } from '~/models';

import { FullWidthLabel } from '../shared';

function PatientMatchCollaborationHeader({ activity }) {
  const { collaboratorName, collaboratorType } = activity;

  return (
    <FullWidthLabel>
      { `${collaboratorName} has been added as a ${collaboratorType} by ` }
      <LabelBold>{ activity.createdBy.fullName }</LabelBold>
      { activity.createdByLocation?.name && ` at ${activity.createdByLocation.name}` }
    </FullWidthLabel>
  );
}

PatientMatchCollaborationHeader.propTypes = {
  activity: PropTypes.instanceOf(PatientMatchCollaboration).isRequired
};

export default PatientMatchCollaborationHeader;
