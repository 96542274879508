import React from 'react';
import PropTypes from 'prop-types';

import theme from '~/styles/theme';

function PatientPortfolioIcon({ fill, size }) {
  return (
    <svg width={size} height={size} viewBox='0 0 24 24' version='1.1' xmlns='http://www.w3.org/2000/svg'>
      <title>patient-portfolio-icon-white</title>
      <desc>Created with Sketch.</desc>
      <g id='patient-portfolio-icon-white' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
        <g transform='translate(0.000000, 3.000000)' fill={fill} fillRule='nonzero'>
          <path
            d='M23.1035973,12.6031304 L19.4044119,11.1202929 C19.0297734,10.970622 18.7836714,10.608316 18.7826087,10.2048879 L18.7826087,9.33693364 C20.0028985,8.63664023 20.7566518,7.33830021 20.7597254,5.93135011 L20.7597254,4.12426545 C20.8066261,1.89346999 19.0362853,0.047004483 16.805492,0 C14.5746987,0.047004483 12.8043579,1.89346999 12.8512586,4.12426545 L12.8512586,5.93135011 C12.8543322,7.33830021 13.6080855,8.63664023 14.8283753,9.33693364 L14.8283753,10.2048879 C14.8281195,10.6090374 14.5818787,10.9723306 14.2065721,11.12227 L12.053492,11.9842929 L13.9525126,12.7435057 C15.0808796,13.1912126 15.820441,14.2836931 15.8169336,15.4976293 L15.8169336,17.7940503 C15.8136571,18.1315834 15.7521215,18.466016 15.6350389,18.7826087 L22.7368421,18.7826087 C23.2828078,18.7826087 23.7254005,18.340016 23.7254005,17.7940503 L23.7254005,13.5205126 C23.7251447,13.116363 23.4789039,12.7530699 23.1035973,12.6031304 Z'
            id='Shape'
          />
          <path
            d='M13.2180137,14.5802471 L9.51882838,13.0974096 C9.1441899,12.9477387 8.89808791,12.5854327 8.89702517,12.1820046 L8.89702517,11.3140503 C10.117315,10.6137569 10.8710682,9.31541691 10.8741419,7.90846682 L10.8741419,6.10138215 C10.9210426,3.87058669 9.15070174,2.02412119 6.91990847,1.9771167 C4.68911519,2.02412119 2.91877434,3.87058669 2.96567506,6.10138215 L2.96567506,7.90846682 C2.96874871,9.31541691 3.72250198,10.6137569 4.94279176,11.3140503 L4.94279176,12.1820046 C4.94253596,12.5861541 4.69629521,12.9494473 4.32098856,13.0993867 L0.621803204,14.5822243 C0.247164724,14.7318952 0.00106273515,15.0942012 -5.93148719e-15,15.4976293 L-5.93148719e-15,17.7940503 C-5.93148719e-15,18.340016 0.44259265,18.7826087 0.988558352,18.7826087 L12.8512586,18.7826087 C13.3972243,18.7826087 13.8398169,18.340016 13.8398169,17.7940503 L13.8398169,15.4976293 C13.8395611,15.0934798 13.5933204,14.7301866 13.2180137,14.5802471 Z'
            id='Path'
          />
        </g>
      </g>
    </svg>
  );
}

PatientPortfolioIcon.defaultProps = {
  fill: theme.colors.white,
  size: 24,
};

PatientPortfolioIcon.propTypes = {
  fill: PropTypes.string,
  secondaryFill: PropTypes.string,
  size: PropTypes.number,
};

export default PatientPortfolioIcon;
