import React, { useMemo } from 'react';
import { FormikHelpers, withFormik } from 'formik';
import { connect, ConnectedProps } from 'react-redux';

import { usePatientProfileContext } from '~/components/patients/patientProfile/PatientProfileContext';
import DischargeForm from '~/components/patients/shared/DischargeForm';
import { dischargedLocationFieldDefault } from '~/components/patients/shared/dischargeFormConstants';
import { StandardModal } from '~/components/shared/modal';
import { REJECTED } from '~/constants/reviews';
import { updateReview } from '~/ducks/reviews';
import { addToast } from '~/ducks/toasts';
import { unwrapResult } from '~/lib';
import { DischargeOption, Location, LocationEpisode, Note } from '~/models';

interface Props extends ConnectedProps<typeof connector> {
  show: boolean;
  onCancel: () => void;
  onSuccess: ({ shouldFetchActivities }: { shouldFetchActivities: boolean }) => void;
}

export type AltcsDenialConfirmationFormProps = {
  episodeId: string;
  latestRehabFacilityId: string;
  locationEpisodeId: string;
  onAbort: () => void;
  ownerId: string;
  patientName: string;
  showActualDischargeDate: boolean;
};

export interface AltcsDenialConfirmationModalFormValues {
  dischargedLocation: Location | typeof dischargedLocationFieldDefault;
  dischargedLocationOther: string;
  dischargedReason: DischargeOption | null;
  note: Note;
}

function AltcsDenialConfirmationModal(props: Props) {
  const { onCancel, onSuccess, show, updateReview } = props;
  const { locationEpisode, episode }: any = usePatientProfileContext();

  const episodeId = episode?.id;
  const patientName = episode?.patient?.name;
  const ownerId = episode?.owner?.id;

  const altcsApplicationReview = locationEpisode?.altcsApplicationReview;
  const latestRehabFacilityId = locationEpisode.rehabInformation?.latestRehabFacility?.id;
  const locationEpisodeId = locationEpisode?.id;

  const initialFormValues = {
    dischargedLocation: dischargedLocationFieldDefault,
    dischargedLocationOther: '',
    dischargedReason: null,
    note: new Note(),
  };

  const handleSubmit = async (
    values: AltcsDenialConfirmationModalFormValues,
    { setSubmitting }: FormikHelpers<AltcsDenialConfirmationModalFormValues>
  ) => {
    try {
      const { dischargedLocation, dischargedLocationOther, dischargedReason } = values;

      const note = new Note({
        ...values.note,
        locationEpisodeId: locationEpisode.id,
      });

      const payload = {
        id: altcsApplicationReview.id,
        include: 'activities.attachments,locationEpisode,episode',
        dischargedGroupTypeId: dischargedReason?.groupTypeId,
        dischargedLocationId: dischargedLocation?.id,
        dischargedLocationOther: dischargedLocationOther || null,
        dischargedReason: dischargedReason?.label,
        status: REJECTED,
        ...(note.hasContent && { note: note.serialize() }),
      };

      const res = await updateReview(payload).then(unwrapResult);
      const locEp = res.episode.locationEpisodes.find((locEp: LocationEpisode) => locEp.id === locationEpisodeId);

      onSuccess({ shouldFetchActivities: locEp.latest });
    } catch (e: any) {
      let msg = 'Something went wrong. Please try again.';

      if (e.response?.status === 422) {
        msg = e.response.data.message;
      }

      props.addToast({ text: msg });
    } finally {
      setSubmitting(false);
    }
  };

  const formikOptions = useMemo(
    () => ({
      enableReinitialize: true,
      handleSubmit,
      mapPropsToValues: () => ({ ...initialFormValues }),
    }),
    []
  );

  const FormikAltcsDenialConfirmationForm = useMemo(
    () =>
      withFormik<AltcsDenialConfirmationFormProps, AltcsDenialConfirmationModalFormValues>(formikOptions)(
        DischargeForm
      ),
    [formikOptions]
  );

  return (
    <StandardModal
      show={show}
      disableBackdropClick
      title='Confirm ALTCS Denial'
      onCancel={onCancel}
      data-cy='altcsDenialConfirmationModal'>
      <FormikAltcsDenialConfirmationForm
        episodeId={episodeId}
        latestRehabFacilityId={latestRehabFacilityId}
        locationEpisodeId={locationEpisodeId}
        onAbort={onCancel}
        ownerId={ownerId}
        patientName={patientName}
        showActualDischargeDate={false}
      />
    </StandardModal>
  );
}

const mapDispatchToProps = {
  addToast,
  updateReview,
};

const connector = connect(null, mapDispatchToProps);

export default connector(AltcsDenialConfirmationModal);
