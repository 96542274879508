import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Label } from '~/components/shared/typography';
import { useSwitch } from '~/lib/hooks';
import { LocationEpisode, ServiceRefusal } from '~/models';

import ServiceRefusalConfirmModal from '../ServiceRefusalConfirmModal';
import ServiceRefusalReassignModal from '../ServiceRefusalReassignModal';

import ActionsButtonGroup from './ActionsButtonGroup';

function ActionsView({ locationEpisode, serviceRefusal }) {
  const serviceConfirmModalSwitch = useSwitch(false);
  const serviceReassignModalSwitch = useSwitch(false);

  const commonProps = { locationEpisode, serviceRefusal };

  return (
    <Fragment>
      <ActionsContainer>
        <StyledLabel>
          {"Please reassign the patient's service or confirm that services will not be used at this time."}
        </StyledLabel>
        <ActionsButtonGroup
          onConfirmClick={serviceConfirmModalSwitch.turnOn}
          onReassignClick={serviceReassignModalSwitch.turnOn}
        />
      </ActionsContainer>
      <ServiceRefusalConfirmModal
        {...commonProps}
        show={serviceConfirmModalSwitch.state}
        onCancel={serviceConfirmModalSwitch.turnOff}
        onSuccess={serviceConfirmModalSwitch.turnOff}
      />
      <ServiceRefusalReassignModal
        {...commonProps}
        show={serviceReassignModalSwitch.state}
        onCancel={serviceReassignModalSwitch.turnOff}
        onSuccess={serviceReassignModalSwitch.turnOff}
      />
    </Fragment>
  );
}

ActionsView.propTypes = {
  locationEpisode: PropTypes.instanceOf(LocationEpisode),
  serviceRefusal: PropTypes.instanceOf(ServiceRefusal),
  showActions: PropTypes.bool,
};

export default ActionsView;

const ActionsContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const StyledLabel = styled(Label)`
  margin-bottom: 16px;
`;
