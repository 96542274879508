import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import QuestionContent from './QuestionContent';

export function rangeComponentConfig(question) {
  return {
    QuestionComponent: RangeQuestion,
    name: question.id,
    text: question.text,
    index: question.index,
    max: 10,
  };
}

const AnswerContainer = styled.div`
  display: flex;
  margin: 16px 0px 0px 24px;

  & > * {
    margin-right: 8px;
  }
`;

const Answer = styled.button`
  background-color: white;
  border: 2px solid rgba(15, 18, 38, 0.5);
  border-radius: 3px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  width: 40px;

  &.selected {
    background-color: rgb(50, 83, 239);
    border-color: rgb(50, 83, 239);
    color: white;
  }
`;

const RangeQuestion = ({ helpers, max, min, step, value, ...props }) => {
  const handleChange = (event) => {
    helpers.setValue(event.target.value);
  };

  return (
    <div>
      <QuestionContent {...props} />
      <AnswerContainer>
        {[...Array(max).keys()].map((item, index) => {
          const rangeValue = (item + min) * step;

          return (
            <Answer
              type='button'
              key={index}
              value={rangeValue}
              className={value === String(rangeValue) && 'selected'}
              onClick={handleChange}>
              {rangeValue}
            </Answer>
          );
        })}
      </AnswerContainer>
    </div>
  );
};

RangeQuestion.defaultProps = {
  min: 1,
  step: 1,
};

RangeQuestion.propTypes = {
  helpers: PropTypes.shape({
    setValue: PropTypes.func,
  }),
  max: PropTypes.number,
  min: PropTypes.number,
  step: PropTypes.number,
  value: PropTypes.string,
};

export default RangeQuestion;
