import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import styled from 'styled-components';

import { Button, ButtonGroup } from '~/components/shared/buttons';
import { ConfirmationList, DocumentSummary, NeedItem } from '~/components/shared/ConfirmationList';
import { getLocationEpisodeUpdating } from '~/ducks/locationEpisode';
import { addToast } from '~/ducks/toasts';
import { simpleDate } from '~/lib/formatDate';

import ContentHeading from './ContentHeading';
import { FormContainer } from './DischargeForm';

function DischargeConfirm(props) {
  const {
    formValues: { actualDischargeDateTime, dischargedLocation, dischargedReason, needs, note },
    onComplete,
    onRevert,
    patientName,
    updating,
  } = props;

  const patientDataItems = [
    { label: 'Discharge Date', value: simpleDate(actualDischargeDateTime) },
    { label: 'Discharge Reason', value: dischargedReason.label },
    { label: 'Discharge Location', value: dischargedLocation.name },
  ];

  if (needs.length) {
    patientDataItems.push({
      label: 'Patient Needs',
      value: needs.map((need) => <NeedItem key={need} need={need} />),
    });
  }

  if (note.plaintext) {
    patientDataItems.push({ label: 'Note', value: note.plaintext });
  }

  if (note.attachments?.length) {
    note.attachments.map((attachment) => {
      patientDataItems.push({
        label: 'Attachment',
        key: attachment.key,
        value: <DocumentSummary document={attachment} />,
      });
    });
  }

  const handleConfirm = () => onComplete(props.formValues);

  return (
    <FormContainer>
      <ContentHeading title='' subtitle={`Patient: ${patientName}`} />
      <ConfirmationListContainer>
        <ConfirmationList items={patientDataItems} />
      </ConfirmationListContainer>
      <Actions>
        <Button text='Back' color='transparent' onClick={onRevert} />
        <Button
          data-cy='dischargeConfirm'
          onClick={handleConfirm}
          disabled={updating}
          loading={updating}
          text={updating ? 'Discharging Patient' : 'Confirm'}
        />
      </Actions>
    </FormContainer>
  );
}

DischargeConfirm.propTypes = {
  addToast: PropTypes.func.isRequired,
  formValues: PropTypes.shape({
    actualDischargeDateTime: PropTypes.instanceOf(Date),
    dischargedLocation: PropTypes.shape({ name: PropTypes.string }),
    dischargedReason: PropTypes.shape({ label: PropTypes.string }),
    documents: PropTypes.instanceOf(Array),
    needs: PropTypes.instanceOf(Array),
    note: PropTypes.shape({
      text: PropTypes.string,
      plaintext: PropTypes.string,
      mentions: PropTypes.array,
      attachments: PropTypes.array,
    }),
  }),
  onComplete: PropTypes.func,
  onRevert: PropTypes.func,
  patientName: PropTypes.string,
  updating: PropTypes.bool,
};

const mapStateToProps = (state) => ({
  updating: getLocationEpisodeUpdating(state),
});

export default connect(mapStateToProps, { addToast })(DischargeConfirm);

export const Actions = styled(ButtonGroup)`
  justify-content: flex-end;
`;

const ConfirmationListContainer = styled.div`
  padding: 0 48px;
  margin-bottom: 20px;
`;
