import { createSelector, createSlice } from '@reduxjs/toolkit';

import { createAsyncThunk } from '~/lib';
import { ALL_FLAGS } from '~/models';
import { flagsApi } from '~/services/api';

const sliceName = 'flags';

export const fetchEpisodeFlagEnabled = createAsyncThunk(
  'flag/fetch/episodeFlagEnabled',
  async ({ name, episodeId }) => {
    return await flagsApi.fetchEpisodeFlagEnabled(name, episodeId)
      .then(() => true)
      .catch((e) => {
        if (e.response.status !== 404) throw e;
        return false;
      });
  },
  {
    condition: ({ name, episodeId }, { getState }) => {
      const state = getState();

      return typeof getFlags(state)[name]?.[episodeId] === 'undefined';
    }
  }
);

const slice = createSlice({
  name: sliceName,
  initialState: {},
  reducers: {},
  extraReducers: {
    [fetchEpisodeFlagEnabled.fulfilled]: (state, action) => {
      const { name, episodeId } = action.meta.arg;
      const enabled = action.payload;

      if (!state[name]) {
        state[name] = {};
      }

      state[name][episodeId] = enabled;
    }
  }
});

export default slice.reducer;

const createGetFlagSelector = (flagName) => {
  return createSelector(
    getFlags,
    (_state, value) => value,
    (flags, value) => flags[flagName]?.[value]
  );
};

export const getFlags = (state) => state[sliceName];
export const getDischargeQuestionsEnabled = createGetFlagSelector(ALL_FLAGS.dischargeQuestions);
