import colors from '~/styles/theme/colors';

const getBackgroundColor = (hasSelectedValues: boolean, isDisabled: boolean, defaultValue: string) => {
  if (isDisabled) {
    return colors.black10;
  }

  if (hasSelectedValues) {
    return colors.primaryBlue05;
  }

  return defaultValue;
};

const getBorderColor = (hasSelectedValues: boolean, isDisabled: boolean, isErrored: boolean) => {
  if (isDisabled) {
    return 'transparent';
  }

  if (isErrored) {
    return colors.accentRed;
  }

  if (hasSelectedValues) {
    return colors.primaryBlue;
  }

  return 'inherit';
};

const getStyles = (hasSelectedValues: boolean) => ({
  menu: {
    minWidth: '140px',
    zIndex: 3,
  },
  placeholder: {
    fontSize: '14px',
  },
  control: (provided: any, state: any) => ({
    width: '100%',
    minWidth: '140px',
    backgroundColor: getBackgroundColor(hasSelectedValues, state.isDisabled, provided.backgroundColor),
    border: `1px solid ${getBorderColor(hasSelectedValues, state.isDisabled, state.selectProps.hasError)}`,
    borderRadius: '4px',
    paddingLeft: '4px',
    '&:hover': { cursor: 'pointer' },
  }),
  dropdownIndicator: (_: any, state: any) => ({
    marginRight: '4px',
    paddingLeft: '2px',
    paddingTop: hasSelectedValues ? '9px' : '8px',
    color: state.isDisabled ? colors.black25 : colors.black75,
    ':hover': state.isDisabled ? colors.black25 : colors.black75,
  }),
  indicatorsContainer: {
    display: 'flex',
  },
});

export default getStyles;
