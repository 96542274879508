import { PROJECTED_DISCHARGE_UPDATES } from '~/constants/activities';

import FieldChanges from './FieldChanges';

const defaults = {
  type: PROJECTED_DISCHARGE_UPDATES,
};

export default class ProjectedDischargeUpdates extends FieldChanges {
  constructor(options = {}) {
    super({ ...defaults, ...options });
  }

  get typeLabel() {
    return 'Projected Discharge';
  }
}
