import React, { createContext, useEffect, useReducer, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { REHAB_STATE_NAMES } from '~/services/rehabState';

import DischargeModal from '../shared/DischargeModal';

import AdmittedAtModal from './AdmittedAtModal';
import { BoardContext } from './context';
import { Lane } from './lane';

const [, ADMISSION, , DISCHARGED] = REHAB_STATE_NAMES;
const initialState = {
  item: {
    id: '',
    currentRehabStateEnteredAt: null,
    ownerId: '',
    patientName: '',
    rehabStateId: '',
  },
  showAdmittedAtModal: false,
  showDischargeModal: false,
};

function PatientBoard(props) {
  const { boardRequestSvc, filters, rehabStates } = props;

  const [offsetHeight, setOffsetHeight] = useState(0);

  const calculateOffset = () => {
    const header = document.getElementById('pageHeader');
    const headerHeight = header.getBoundingClientRect().height;
    const navHeight = 48;

    setOffsetHeight(headerHeight + navHeight);
  };

  const onInit = () => calculateOffset();

  useEffect(onInit, []);

  const handleDrop = (item, targetState) => ({
    item: {
      ...item,
      rehabStateId: targetState.id,
    },
    showAdmittedAtModal: targetState.state === ADMISSION,
    showDischargeModal: targetState.state === DISCHARGED,
  });

  const reducer = (state, { type, payload }) => {
    switch (type) {
      case 'drop':
        return handleDrop(payload.item, payload.targetState);
      case 'closeModal':
        return { ...initialState };
      default:
        return state;
    }
  };

  const [boardState, dispatch] = useReducer(reducer, initialState);

  const handleCloseModal = () => {
    dispatch({ type: 'closeModal' });
  };

  return (
    <BoardContext.Provider value={dispatch}>
      <Board offsetHeight={offsetHeight}>
        {rehabStates.map((rehabState) => (
          <Lane key={rehabState.id} rehabState={rehabState} filters={filters} boardRequestSvc={boardRequestSvc} />
        ))}
      </Board>

      <DischargeModal
        show={boardState.showDischargeModal}
        currentRehabStateEnteredAt={boardState.item.currentRehabStateEnteredAt}
        episodeId={boardState.item.episodeId}
        locationEpisodeId={boardState.item.id}
        onCancel={handleCloseModal}
        onSuccess={handleCloseModal}
        ownerId={boardState.item.ownerId}
        patientName={boardState.item.patientName}
        rehabFacilityType={boardState.item.rehabFacilityType}
      />

      <AdmittedAtModal
        isOpen={boardState.showAdmittedAtModal}
        locationEpisodeId={boardState.item.id}
        onClose={handleCloseModal}
        patientName={boardState.item.patientName}
        rehabStateId={boardState.item.rehabStateId}
      />
    </BoardContext.Provider>
  );
}

PatientBoard.propTypes = {
  boardRequestSvc: PropTypes.instanceOf(Object),
  filters: PropTypes.instanceOf(Object),
  rehabStates: PropTypes.instanceOf(Object),
};

export default PatientBoard;

const Board = styled.div`
  margin-top: -9px;
  overflow-y: hidden;
  color: #393939;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  height: calc(100vh - ${({ offsetHeight }) => offsetHeight + 39 + 'px'});
`;
