import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Checkbox } from '~/components/shared/form';
import Escalation, { PRIORITY_NOTE_TYPE } from '~/models/Escalation';
import { colors } from '~/styles/theme';

function EscalationToggle({ escalationType, customStyles, ...rest }) {
  const isPriorityNote = escalationType === PRIORITY_NOTE_TYPE;
  const labelText = isPriorityNote ? 'Mark as priority' : 'Escalate';

  const checkboxOptions = {
    checkedColor: colors.accentRed,
    label: labelText,
  };

  const handleChange = useCallback(
    (escalated, _name, setValue) => {
      let newValue = null;

      if (escalated) {
        newValue = new Escalation({ type: escalationType });
      }

      setValue(newValue);
    },
    [escalationType]
  );

  return (
    <EscalateContainer customStyles={customStyles}>
      <Checkbox
        {...rest}
        overrideSetValue
        onChange={handleChange}
        labelSize='12px'
        size={14}
        data-cy='escalateCheckbox'
        {...checkboxOptions}
      />
    </EscalateContainer>
  );
}

EscalationToggle.propTypes = {
  customStyles: PropTypes.instanceOf(Object),
  escalationType: PropTypes.string,
};

export default EscalationToggle;

const EscalateContainer = styled.div`
  display: flex;
`;
