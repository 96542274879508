import React from 'react';

import { useMenuContext } from '~/components/shared/menu';
import Slot from '~/components/shared/Slot';

export type MenuTriggerProps = React.HTMLAttributes<HTMLButtonElement>;

export default function MenuTrigger(props: MenuTriggerProps) {
  const { toggle } = useMenuContext();

  return <Slot onClick={toggle} {...props} />;
}
