export enum RouteConstants {
  INSIGHTS_BASE = '/insights',
  OLIO_ENGAGEMENT_SCORE = INSIGHTS_BASE + '/olio-engagement-score',
  AVERAGE_LENGTH_OF_STAY = INSIGHTS_BASE + '/average-length-of-stay',
  READMISSION_RATE = INSIGHTS_BASE + '/readmission-rate',
}

export enum MetricConstants {
  OLIO_ENGAGEMENT_SCORE = 'olio_engagement_score',
  AVERAGE_LENGTH_OF_STAY = 'length_of_stay:average',
  ID_COUNT = 'id:count',
  READMISSIONS_COUNT = 'readmissions:count',
  READMISSION_RATE = 'readmission_rate',
}

export enum DimensionConstants {
  GROUP_NAME = 'group_name',
  LENGTH_OF_STAY = 'length_of_stay',
}

export enum SourceConstants {
  LOCATION_EPISODE_DAYS = 'location_episode_days',
  LOCATION_EPISODES = 'location_episodes',
}

export enum SortOrder {
  ASC = 'asc',
  DESC = 'desc',
}
