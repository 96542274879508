import { ACTUAL_ADMISSION_DATE_QUESTION, NUMERIC, RANGE, STATUS, YES_NO } from '~/constants/activities';
import { QUEUE } from '~/services/rehabState/constants';

import { dateComponentConfig } from './DateQuestion';
import { numericComponentConfig } from './NumericQuestion';
import { rangeComponentConfig } from './RangeQuestion';
import { statusComponentConfig } from './StatusQuestion';
import { yesNoComponentConfig } from './YesNoQuestion';

const QUESTION_MAP = {
  [RANGE]: rangeComponentConfig,
  [YES_NO]: yesNoComponentConfig,
  [NUMERIC]: numericComponentConfig,
  [STATUS]: statusComponentConfig,
};

function questionsToComponentConfig(questions, locationEpisode) {
  const admissionDateComponentConfig = dateComponentConfig({
    name: 'actualAdmission',
    text: ACTUAL_ADMISSION_DATE_QUESTION,
    maxDate: new Date(),
  });
  const hasAdmissionDateQuestion = locationEpisode?.currentRehabState.state === QUEUE;

  let statusQuestion = null;

  const questionConfigs = questions.sort().map((question, i) => {
    if (question.kind === STATUS) {
      statusQuestion = question;
    }

    return QUESTION_MAP[question.kind]({ ...question, index: i + 1 });
  });

  return {
    questions: questionConfigs,
    admissionDateQuestion: hasAdmissionDateQuestion ? admissionDateComponentConfig : null,
    statusQuestion,
    hasAdmissionDateQuestion,
    hasStatusQuestion: !!statusQuestion,
  };
}

function allQuestionsAnswered(questions = [], answers = {}) {
  return questions.every((q) => Boolean(answers[q.id]));
}

function buildAnsweredQuestionsFromFormValues(questions, answers) {
  return questions.map((question) => {
    const answer = answers[question.id];
    const answerWeight = findAnswerWeight(question, answer);

    return {
      ...question,
      answer: {
        text: answer,
        weight: answerWeight,
      },
    };
  });
}

function findAnswerWeight(question = {}, answer = {}) {
  if (question.kind === YES_NO) {
    return question.config.positive.toLowerCase() === answer.toLowerCase() ? 'positive' : 'negative';
  }

  return 'n/a';
}

export default {
  allQuestionsAnswered,
  buildAnsweredQuestionsFromFormValues,
  questionsToComponentConfig,
};
