export const SERVICE_REFUSAL = 'service_refusal';
export const AUTHORIZATION = 'authorization';
export const PROJECTED_DISCHARGE = 'projected_discharge';
export const ALTCS_APPLICATION = 'altcs_application';
export const PENDING = 'pending';
export const ACCEPTED = 'accepted';
export const REJECTED = 'rejected';
export const CANCELED = 'canceled';

export enum ReviewStatuses {
  PENDING = 'pending',
  ACCEPTED = 'accepted',
  REJECTED = 'rejected',
  CANCELED = 'canceled',
}
