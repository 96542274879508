import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import EscalationToggle from '~/components/shared/ActivityInput/EscalationToggle';
import { Button } from '~/components/shared/buttons';
import { AtIcon, AttachFileIcon } from '~/components/shared/svg';
import { FONT_WEIGHT } from '~/styles/theme/textStyles';

function ActivityActions(props) {
  const {
    canSubmit,
    customStyles = {},
    disableAttach,
    escalationType,
    handleSubmit,
    isSubmitting,
    isTaggable,
    isUploading,
    isValid,
    onAttachClick,
    onTagClick,
    showEscalationToggle,
  } = props;

  return (
    <Container customStyles={customStyles}>
      <ActionsWrapper>
        {isTaggable && (
          <Button
            color='white'
            fontSize='12px'
            fontWeight={FONT_WEIGHT}
            icon={<AtIcon />}
            padding='0px'
            text='Tag'
            data-cy='tagButton'
            onClick={onTagClick}
          />
        )}

        <Button
          color='white'
          fontSize='12px'
          fontWeight={FONT_WEIGHT}
          padding='0px'
          data-gtm-id='attachFiles'
          disabled={disableAttach}
          icon={<AttachFileIcon />}
          text='Attach'
          onClick={onAttachClick}
        />

        {showEscalationToggle && (
          <EscalationWrapper>
            <EscalationToggle
              name={`${'note'}.escalation`}
              escalationType={escalationType}
              customStyles={customStyles}
            />
          </EscalationWrapper>
        )}
      </ActionsWrapper>

      {canSubmit && (
        <Button
          onClick={handleSubmit}
          fontSize='10px'
          padding='3px 18px'
          data-cy='textSubmit'
          loading={isSubmitting}
          disabled={!isValid || isSubmitting || isUploading}
          text='Post'
        />
      )}
    </Container>
  );
}

ActivityActions.propTypes = {
  canSubmit: PropTypes.bool,
  customStyles: PropTypes.instanceOf(Object),
  disableAttach: PropTypes.bool,
  escalationType: PropTypes.string,
  handleSubmit: PropTypes.func.isRequired,
  isSubmitting: PropTypes.bool,
  isTaggable: PropTypes.bool,
  isUploading: PropTypes.bool,
  isValid: PropTypes.bool,
  onAttachClick: PropTypes.func,
  onTagClick: PropTypes.func.isRequired,
  showEscalationToggle: PropTypes.bool,
  uploads: PropTypes.arrayOf(PropTypes.instanceOf(Object)),
};

export default ActivityActions;

const Container = styled.div`
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-top: 1px solid ${({ theme, customStyles }) => customStyles?.borderColor || theme.colors.black10};
  box-sizing: border-box;
  padding: 0 16px;
`;

const ActionsWrapper = styled.div`
  display: flex;
  align-items: center;

  /* Target all direct children of the parent but the first */
  > * + * {
    margin-left: 16px;
  }
`;

const EscalationWrapper = styled.div`
  border-left: 1px solid ${({ theme }) => theme.colors.black10};
  padding: 4px 0 4px 16px;
`;
