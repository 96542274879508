import React from 'react';
import ReactTooltip from 'react-tooltip';

import Menu from '~/components/shared/menu';
import { DOCUMENT_TYPES } from '~/models/Attachment';
import { colors } from '~/styles/theme';

import { TagIcon } from './svg';

type Props = {
  attachmentId: string | null;
  documentType?: string | null;
  onDocumentTypeChange?: (attachmentId: string | null, docType?: string | null) => void;
  size?: number;
};

export default function DocumentTypeTagButton({ attachmentId, documentType, onDocumentTypeChange, size = 12 }: Props) {
  return (
    <>
      <Menu>
        <Menu.Trigger>
          <TagIcon
            size={size}
            fill={colors.black}
            data-tip={!documentType ? 'Add Document Type' : 'Edit Document Type'}
            data-for='document-type'
            style={{ cursor: 'pointer' }}
          />
        </Menu.Trigger>
        <Menu.Content>
          {DOCUMENT_TYPES.map((docType) => (
            <Menu.Item
              key={docType}
              active={documentType === docType}
              onClick={() => onDocumentTypeChange?.(attachmentId, docType)}>
              {docType}
            </Menu.Item>
          ))}
        </Menu.Content>
      </Menu>
      <ReactTooltip effect='solid' id='document-type' padding='10px 16px' />
    </>
  );
}
