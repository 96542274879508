import React, { useEffect, useMemo, useState } from 'react';
import { withFormik } from 'formik';
import moment from 'moment';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { StandardModal } from '~/components/shared/modal';
import StepWizard from '~/components/shared/StepWizard';
import { OTHER } from '~/constants/dischargeTypes';
import { fetchEpisodeFlagEnabled, getDischargeQuestionsEnabled } from '~/ducks/flags';
import {
  createLocationEpisodeRehabState,
  fetchLocationEpisode,
  getLocationEpisode,
  getLocationEpisodeUpdating,
} from '~/ducks/locationEpisode';
import { getRehabStates } from '~/ducks/rehabStates';
import { useModel, usePrevious } from '~/lib/hooks';
import { ALL_FLAGS, LocationEpisode, Note } from '~/models';
import { DISCHARGED } from '~/services/rehabState/constants';

import DischargeConfirm from './DischargeConfirm';
import DischargeForm from './DischargeForm';
import { dischargedLocationFieldDefault } from './dischargeFormConstants';
import { dischargeFormValidation } from './dischargeFormValidation';
import DischargeNeeds from './DischargeNeeds';
import DischargeQuestionsForm from './DischargeQuestionsForm';
import { formHelpers } from './QuestionForm';

function DischargeModal(props) {
  const {
    currentRehabStateEnteredAt,
    dischargeQuestionsEnabled,
    episodeId,
    locationEpisodeId,
    onCancel,
    onSuccess,
    ownerId,
    patientName,
    rehabStates = [],
    show,
    updating,
  } = props;

  const initialFormValues = {
    actualDischargeDateTime: new Date(),
    dischargedLocation: dischargedLocationFieldDefault,
    dischargedLocationOther: '',
    dischargeQuestions: null,
    dischargedReason: null,
    needs: [],
    note: new Note(),
  };

  const locationEpisode = useModel(LocationEpisode, props.locationEpisode);
  const [formValues, setFormValues] = useState(initialFormValues);
  const [loaded, setLoaded] = useState(false);
  const previousUpdating = usePrevious(updating);
  const dischargeState = rehabStates.find((rs) => rs.state === DISCHARGED);
  const enabledGroupTypes = locationEpisode.owner?.client?.groupTypeIds || [];
  const hasDischargeQuestions = dischargeQuestionsEnabled && Boolean(locationEpisode.dischargeTemplate);

  const checkIfContinuedCare = (values = formValues) => {
    return (
      values.dischargedReason?.canContinueCare &&
      enabledGroupTypes.includes(values.dischargedReason.groupTypeId) &&
      values.dischargedLocation?.name !== OTHER
    );
  };

  const fetchData = async () => {
    const dataRequests = [];

    if (locationEpisode.id !== locationEpisodeId) {
      dataRequests.push(
        props.fetchLocationEpisode({
          id: locationEpisodeId,
          include: 'question_templates,owner.client.group_types',
        })
      );
    }

    dataRequests.push(props.fetchEpisodeFlagEnabled({ name: ALL_FLAGS.dischargeQuestions, episodeId }));
    await Promise.allSettled(dataRequests);

    setLoaded(true);
  };

  useEffect(() => {
    if (show) {
      fetchData();
    } else {
      setLoaded(false);
      setFormValues(initialFormValues);
    }
  }, [show]);

  useEffect(() => {
    setFormValues((prev) => ({ ...prev, name: patientName }));
  }, [patientName]);

  useEffect(() => {
    if (show && previousUpdating && !updating) {
      onSuccess();
    }
  }, [updating]);

  const handleOnCancel = () => {
    setFormValues(initialFormValues);
    onCancel();
  };

  const handleFormComplete = (values, { goToStep }) => {
    if (hasDischargeQuestions) {
      goToStep(DischargeQuestionsForm);
    } else if (checkIfContinuedCare(values)) {
      goToStep(DischargeNeeds);
    }
  };

  const buildDischargeQuestions = (values) => {
    if (!values) return null;

    return formHelpers.buildAnsweredQuestionsFromFormValues(locationEpisode.dischargeTemplate.questions, values);
  };

  const dischargePatient = (values) => {
    const {
      actualDischargeDateTime,
      dischargedReason,
      dischargedLocation,
      dischargedLocationOther,
      dischargeQuestions,
      needs,
    } = values;

    // eslint-disable-next-line no-warning-comments
    // TODO: Simplify after 183913823
    const note = new Note({ ...values.note });

    const obj = {
      locationEpisodeId: locationEpisode.id,
      rehabStateId: dischargeState.id,
      dischargedGroupTypeId: dischargedReason?.groupTypeId,
      dischargedReason: dischargedReason.label || null,
      dischargedLocationId: dischargedLocation?.id,
      dischargedLocationOther: dischargedLocationOther || null,
      dischargeQuestions: buildDischargeQuestions(dischargeQuestions),
      // date objects are automatically converted to UTC
      // when stringified for api requests, so we need to
      // send just the date portion of the date object
      enteredAt: moment(actualDischargeDateTime).format('YYYY-MM-DD'),
      note: note.serialize(),
      needs,
    };

    props.createLocationEpisodeRehabState(obj);
  };

  const handleSave = (data) => {
    dischargePatient(data ? data : formValues);
  };

  const updateFormValues = (values) => {
    setFormValues((prev) => ({ ...prev, ...values }));
  };

  const handleFormSubmit = (values) => {
    updateFormValues(values);
  };

  const validationSchema = dischargeFormValidation(currentRehabStateEnteredAt || new Date());

  const formikOptions = useMemo(
    () => ({
      handleSubmit: handleFormSubmit,
      mapPropsToValues: () => formValues,
      validationSchema,
    }),
    [validationSchema]
  );

  const FormikDischargeForm = useMemo(() => withFormik(formikOptions)(DischargeForm), [formikOptions]);

  const STEPS = [
    { component: FormikDischargeForm, onStepSuccess: handleFormComplete },
    { component: DischargeQuestionsForm },
    { component: DischargeNeeds },
    { component: DischargeConfirm },
  ];

  return (
    <StandardModal show={show} title={'Discharge Patient'} onCancel={handleOnCancel} disableBackdropClick>
      <StepWizard
        currentRehabStateEnteredAt={currentRehabStateEnteredAt || new Date()}
        dischargeTemplate={locationEpisode.dischargeTemplate}
        formValues={formValues}
        checkIfContinuedCare={checkIfContinuedCare}
        hasDischargeQuestions={hasDischargeQuestions}
        loading={!loaded}
        onSequenceAbort={handleOnCancel}
        onSequenceComplete={handleSave}
        ownerId={ownerId}
        latestRehabFacilityId={locationEpisode.rehabInformation?.latestRehabFacility?.id}
        locationEpisodeId={locationEpisode.id}
        episodeId={locationEpisode.episodeId}
        patientName={patientName}
        data-cy='snf'
        steps={STEPS}
        updateFormValues={updateFormValues}
      />
    </StandardModal>
  );
}

const mapStateToProps = (state, { episodeId }) => ({
  dischargeQuestionsEnabled: getDischargeQuestionsEnabled(state, episodeId),
  locationEpisode: getLocationEpisode(state),
  rehabStates: getRehabStates(state),
  updating: getLocationEpisodeUpdating(state),
});

const mapDispatchToProps = {
  fetchEpisodeFlagEnabled,
  fetchLocationEpisode,
  createLocationEpisodeRehabState,
};

DischargeModal.propTypes = {
  createLocationEpisodeRehabState: PropTypes.func,
  currentRehabStateEnteredAt: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.string]),
  dischargeQuestionsEnabled: PropTypes.bool,
  episodeId: PropTypes.string,
  fetchEpisodeFlagEnabled: PropTypes.func,
  fetchLocationEpisode: PropTypes.func,
  locationEpisode: PropTypes.instanceOf(Object),
  locationEpisodeId: PropTypes.string,
  onCancel: PropTypes.func,
  onSuccess: PropTypes.func,
  ownerId: PropTypes.string.isRequired,
  patientName: PropTypes.string,
  rehabFacilityType: PropTypes.string,
  rehabStates: PropTypes.instanceOf(Array),
  show: PropTypes.bool,
  updating: PropTypes.bool,
};

const noop = () => {};

DischargeModal.defaultProps = {
  onCancel: noop,
  onSuccess: noop,
  createLocationEpisodeRehabState: noop,
};

export default connect(mapStateToProps, mapDispatchToProps)(DischargeModal);
