import React, { useCallback, useContext, useEffect, useMemo, useRef } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';

import { Attachment as AttachmentComponent } from '~/components/shared/Attachment';
import { TaggedText } from '~/components/shared/TaggedText';
import { fetchActivityAttachments, updateAttachment } from '~/ducks/attachments';
import useIntersectionObserver from '~/lib/hooks/useIntersectionObserver';
import { Note, ServiceRefusalActivity } from '~/models';

import { PatientProfileContext } from '../../../PatientProfileContext';
import { SectionContainer } from '../shared';

import AttachedImage from './AttachedImage';
import PriorityNoteLabel from './PriorityNoteLabel';
function NoteContent({ activity }) {
  const { textRemoved, attachments } = activity;
  const [observer, isIntersecting] = useIntersectionObserver();
  const containerRef = useRef(null);
  const imageAttachments = useMemo(() => attachments.filter((attachment) => attachment.isImage), [attachments]);
  const fileAttachments = useMemo(() => attachments.filter((attachment) => !attachment.isImage), [attachments]);

  const dispatch = useDispatch();
  const { locationEpisode, mainPageScrollTop } = useContext(PatientProfileContext);

  const setContainerRef = useCallback(
    (newRef) => {
      if (!textRemoved) {
        if (containerRef.current) {
          observer.unobserve(containerRef.current);
        }

        containerRef.current = newRef;

        if (containerRef.current) {
          observer.observe(newRef);
        }
      }
    },
    [textRemoved]
  );

  useEffect(() => {
    if (isIntersecting && locationEpisode.id) {
      dispatch(
        fetchActivityAttachments({
          locationEpisodeId: locationEpisode.id,
          activityId: activity.id,
          include: 'thumbnailUrl,blob',
        })
      );
    }
  }, [isIntersecting, locationEpisode]);

  const handleDocumentTypeChange = (attachmentId, docType = null) => {
    dispatch(updateAttachment({ id: attachmentId, docType }));
  };

  return (
    <React.Fragment>
      {activity.escalation?.isPriorityNote && <PriorityNoteLabel />}

      {!!activity.text?.length && (
        <SectionContainer>
          <TaggedText text={activity.text} removed={activity.textRemoved} />
        </SectionContainer>
      )}

      {!!attachments.length && (
        <AttachmentsContainer ref={setContainerRef}>
          {!!imageAttachments.length && (
            <ImagesWrapper>
              {imageAttachments.map((item) => (
                <AttachedImage key={item.id} attachment={item} removed={activity.textRemoved} />
              ))}
            </ImagesWrapper>
          )}

          {!!fileAttachments.length && (
            <AttachmentWrapper>
              {fileAttachments.map((item) => (
                <AttachmentComponent
                  disabled={activity.textRemoved}
                  key={item.id}
                  attachment={item}
                  onDeleteDocumentType={() => handleDocumentTypeChange(item.id)}
                  onDocumentTypeChange={handleDocumentTypeChange}
                  parentScrollTop={mainPageScrollTop}
                />
              ))}
            </AttachmentWrapper>
          )}
        </AttachmentsContainer>
      )}
    </React.Fragment>
  );
}

NoteContent.propTypes = {
  activity: PropTypes.oneOfType([PropTypes.instanceOf(Note), PropTypes.instanceOf(ServiceRefusalActivity)]).isRequired,
};

export default NoteContent;

const ImagesWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: flex-end;

  & > * {
    margin-top: 0.5rem;
    margin-right: 0.5rem;
  }
`;

const AttachmentWrapper = styled.div`
  width: 100%;

  & > * {
    margin-top: 0.5rem;
  }
`;

const AttachmentsContainer = styled(SectionContainer)`
  overflow: initial;
`;
