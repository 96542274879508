import React, { useContext, useMemo, useRef } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { useDrop } from 'react-dnd';
import { connect } from 'react-redux';
import styled from 'styled-components';

import InfiniteScroll from '~/components/shared/InfiniteScroll';
import { addLocationEpisodes, makeGetLaneData, makeGetLocationEpisodesByRehabState } from '~/ducks/locationEpisodes';
import { getSorts } from '~/ducks/portfolioFilters';
import { addToast } from '~/ducks/toasts';
import { DragType } from '~/helpers/DragType';
import { useDeepEffect, useModel } from '~/lib/hooks';
import { LocationEpisode, RehabState } from '~/models';
import { ProfileContext } from '~/services/profile';
import { RehabStateService } from '~/services/rehabState';
import colors from '~/styles/theme/colors';

import { BoardContext } from '../context';
import PatientCard from '../PatientCard';

import LaneEmptyState from './LaneEmptyState';
import LaneSortFlyoutMenu from './LaneSortFlyoutMenu';

function Lane(props) {
  const { boardRequestSvc, laneData } = props;

  const scrollLane = useRef(null);
  const profileSvc = useContext(ProfileContext);
  const locationEpisodes = useModel(LocationEpisode, props.locationEpisodes);
  const rehabState = useModel(RehabState, props.rehabState);
  const rehabStateSort = props.sorts?.[rehabState.apiName];

  const cardList = useMemo(() => {
    if (laneData.loading) {
      return ['1', '2', '3'].map((id) => new LocationEpisode({ id, episodeId: id }));
    }

    return locationEpisodes;
  }, [locationEpisodes, laneData.loading]);

  useDeepEffect(
    () => {
      boardRequestSvc.requestFirstPageForLane({ lane: rehabState.state });
    },
    [rehabStateSort],
    true
  );

  const handleFetchNextPage = () => {
    const url = laneData.nextPageURL;

    if (url && !laneData.loadingNextPage) {
      boardRequestSvc.requestNextPageForLane({ lane: rehabState.state, url });
    }
  };

  const validateDrop = (item) => {
    const rehabStateService = new RehabStateService(item.rehabState.state);

    return rehabStateService.canChangeState(rehabState.state, profileSvc);
  };

  const dispatch = useContext(BoardContext);

  const [{ dragItem, dragItemType, isOver }, dropZone] = useDrop({
    accept: DragType.CARD,
    drop: (item) => {
      if (item.rehabState === rehabState) {
        return item;
      }

      const result = validateDrop(item);

      if (result.valid) {
        dispatch({
          type: 'drop',
          payload: { item, targetState: rehabState },
        });
      } else {
        props.addToast({ text: result.message });
      }

      return item;
    },
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      dragItem: monitor.getItem(),
      dragItemType: monitor.getItemType(),
    }),
  });

  const tryApplyDragClasses = (item, type) => {
    const valid = type === DragType.CARD && validateDrop(item).valid;

    return {
      'valid-drop-zone': valid,
      'dragging-over': valid && isOver,
    };
  };

  return (
    <Section
      key={rehabState.id}
      className={classNames(props.className, tryApplyDragClasses(dragItem, dragItemType))}
      data-cy={`lane${rehabState.state.replace(/\s/g, '')}`}>
      <Header>
        <Title>{rehabState.state}</Title>
        <SpanContainer>
          <LaneSortFlyoutMenu rehabStateApiName={rehabState.apiName} isQueueLane={rehabState.queue} />
          <LaneTotal data-cy='laneCount'>{laneData.totalCount}</LaneTotal>
        </SpanContainer>
      </Header>
      <DropZone ref={dropZone}>
        <DraggableList ref={scrollLane}>
          {cardList.length ? (
            cardList.map((card) => <PatientCard key={card.id} rehabState={rehabState} locationEpisode={card} />)
          ) : (
            <LaneEmptyState rehabStateName={rehabState.state} />
          )}
          <InfiniteScroll
            element={scrollLane.current}
            hasMore={Boolean(laneData.nextPageURL)}
            loading={laneData.loadingNextPage}
            onEndReached={handleFetchNextPage}
          />
        </DraggableList>
      </DropZone>
    </Section>
  );
}

Lane.propTypes = {
  addLocationEpisodes: PropTypes.func.isRequired,
  addToast: PropTypes.func.isRequired,
  boardRequestSvc: PropTypes.instanceOf(Object),
  laneData: PropTypes.instanceOf(Object),
  locationEpisodes: PropTypes.instanceOf(Array),
  rehabState: PropTypes.instanceOf(Object),
  sorts: PropTypes.instanceOf(Object),
};

const makeMapStateToProps = () => {
  const getLocationEpisodesByRehabState = makeGetLocationEpisodesByRehabState();
  const getLaneData = makeGetLaneData();

  const mapStateToProps = (state, props) => {
    const lane = props.rehabState.state;

    return {
      locationEpisodes: getLocationEpisodesByRehabState(state, lane),
      laneData: getLaneData(state, lane),
      sorts: getSorts(state),
    };
  };

  return mapStateToProps;
};

const mapDispatchToProps = {
  addLocationEpisodes,
  addToast,
};

export default React.memo(connect(makeMapStateToProps, mapDispatchToProps)(Lane));

const Section = styled.section`
  box-sizing: border-box;
  max-width: 275px;
  min-width: 175px;
  width: 100%;
  border-radius: 5px;
  border: 2px solid transparent;
  margin: 5px 0 5px 10px;
  padding: 10px 0px 10px 10px;
  position: relative;
  height: 100%;
  max-height: 1400px;
  background-color: ${({ theme }) => theme.colors.gray};

  &:first-child {
    margin-left: 0;
  }

  &.valid-drop-zone {
    border: 2px dotted ${({ theme }) => theme.colors.primaryBlue};
  }

  &.dragging-over {
    background-color: rgba(50, 83, 239, 0.1);
  }
`;

const Header = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
`;

const Title = styled.span`
  color: ${colors.black};
  font-size: 16px;
  margin: 4px 0 24px 10px;
`;

const SpanContainer = styled.span`
  display: flex;
  align-items: center;
  margin: 4px 10px 0 0;
`;

const LaneTotal = styled.span`
  width: 30%;
  text-align: right;
  font-size: 16px;
  color: ${colors.black};
  margin-left: 8px;
`;

const DropZone = styled.div`
  height: calc(100% - 30px);
`;

const DraggableList = styled.div`
  height: 100%;
  overflow-y: scroll;

  &::-webkit-scrollbar {
    height: 0.5em;
    width: 0.5em;
  }

  &::-webkit-scrollbar-thumb {
    background-color: ${({ theme }) => theme.colors.scrollbarGray};
    border-radius: 5pt;
  }
`;
