import React from 'react';
import PropTypes from 'prop-types';

const CheckIcon = ({ size, className }) => {
  return (
    <svg className={ className } width={ size } height={ size } viewBox='0 0 12 9' version='1.1' xmlns='http://www.w3.org/2000/svg'>
      <g id='patient-cards-+-body' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
        <g id='body-structure-with-attachment' transform='translate(-870.000000, -125.000000)' fill='#FFFFFF' fillRule='nonzero'>
          <g id='Group-25' transform='translate(40.000000, 40.000000)'>
            <g id='Group-8' transform='translate(820.000000, 74.000000)'>
              <g id='Group-7' transform='translate(10.000000, 8.000000)'>
                <g id='check' transform='translate(0.000000, 3.000000)'>
                  <path d='M10.280712,0.280712021 L3.98771202,6.57371202 L1.69471202,4.28071202 C1.30233313,3.90173981 0.678627244,3.90715965 0.292893445,4.29289345 C-0.0928403531,4.67862724 -0.0982601888,5.30233313 0.280712021,5.69471202 L3.28071202,8.69471202 C3.67121187,9.08509396 4.30421217,9.08509396 4.69471202,8.69471202 L11.694712,1.69471202 C12.0736842,1.30233313 12.0682644,0.678627244 11.6825306,0.292893445 C11.2967968,-0.0928403531 10.6730909,-0.0982601888 10.280712,0.280712021 Z' id='Path' />
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

CheckIcon.defaultProps = {
  size: 24
};

CheckIcon.propTypes = {
  className: PropTypes.string,
  size: PropTypes.number
};

export default CheckIcon;
