import React, { createContext, useContext, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { InsightsPage } from '~/components/insights';
import { Filter } from '~/components/shared/FilterBar';
import {
  getFilters,
  getSelectedGroupType,
  resetFilters,
  setSelectedFilters,
  setSelectedGroupType,
} from '~/ducks/insights/filters';
import { fetchRehabStates, getRehabStates } from '~/ducks/rehabStates';
import { GroupType, RehabState } from '~/models';
import { useProfileContext } from '~/services/profile';
import { store } from '~/store';

type Dispatch = typeof store.dispatch;

const InsightsContext = createContext({});

export const useInsightsContext = () => useContext(InsightsContext);

const InsightsContainer = () => {
  const profileSvc: any = useProfileContext();
  const insightsFilters = useSelector((state: any) => getFilters(state));
  const rehabStates = useSelector((state: any) => getRehabStates(state)).map((rs: any) => new RehabState(rs));
  const selectedGroupType = useSelector((state: any) => getSelectedGroupType(state));
  const dispatch = useDispatch<Dispatch>();

  const insightsContext = {
    dischargedId: rehabStates?.find((rs: RehabState) => rs.discharged)?.id,
    profileSvc,
    selectedGroupType,
    filters: insightsFilters,
    setSelectedGroupType: (groupType: GroupType) => dispatch(setSelectedGroupType(groupType)),
    setSelectedFilters: (filters: Filter[]) => dispatch(setSelectedFilters(filters)),
  };

  useEffect(() => {
    dispatch(fetchRehabStates(null));
  }, []);

  useEffect(() => {
    // Sets initial selectedGroupType to the first enabled groupType
    if (profileSvc.enabledProviderTypes.length > 0) {
      dispatch(setSelectedGroupType(profileSvc.enabledProviderTypes[0]));
    }
  }, [profileSvc.enabledProviderTypes]);

  useEffect(() => {
    dispatch(resetFilters());
  }, [profileSvc.actingClient.id]);

  return (
    <InsightsContext.Provider value={insightsContext}>
      {!!selectedGroupType && <InsightsPage />}
    </InsightsContext.Provider>
  );
};

export default InsightsContainer;
