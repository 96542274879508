import { useEffect, useMemo, useRef, useState } from 'react';
import { camelCase } from 'lodash-es';

import { DATES, END_DATE, START_DATE } from '~/constants/filterKeysConstants';
import { isDeepEqual } from '~/lib';
import { usePrevious } from '~/lib/hooks';
import useIntersectionObserver from '~/lib/hooks/useIntersectionObserver';
import InsightsAnalyticsRequestService from '~/services/InsightsAnalyticsRequestService';

import { useInsightsContext } from '.';

type DataValues = { value: number | string }[];

export type DataRow = {
  dimensionValues: DataValues;
  metricValues: DataValues;
};

const isRollup = ({ dimensionValues }: DataRow) =>
  !dimensionValues.length || dimensionValues.every(({ value }) => !value);

export const parseDimensionValues = ({ dimensionValues }: DataRow, asNumber = true) =>
  dimensionValues.map(({ value }) => (asNumber ? Number(value ?? -1) : value));

export const parseMetricValues = ({ metricValues }: DataRow) => metricValues.map(({ value }) => Number(value ?? -1));

export const getRollup = (data: DataRow[], { dataParser = parseMetricValues } = {}) => {
  const rollup = data.find(isRollup);

  return rollup ? dataParser(rollup) : [];
};

export const getNonRollups = (data: DataRow[]) => data.filter((row: DataRow) => !isRollup(row));

export const getAverageOes = (data: DataRow[]) => {
  if (!data.length || getRollup(data)[0] === -1) return -1;

  const scores = getNonRollups(data);

  const totalScore = scores.reduce((sum: number, { metricValues }: DataRow) => sum + Number(metricValues[0].value), 0);

  return Math.round(totalScore / scores.length);
};

type Request = {
  params: any;
  processData: (data: DataRow[]) => void;
};

type RequestDataConfig = {
  condition?: boolean;
  onIntersecting?: boolean;
};

export const requestData = (requests: Request[], config: RequestDataConfig = {}) => {
  const { condition = true, onIntersecting = false } = config;

  const insightsContext: any = useInsightsContext();
  const { filters, selectedGroupType } = insightsContext;
  const previousFilters = usePrevious(filters);
  const previousSelectedGroupType = usePrevious(selectedGroupType);

  const [loading, setLoading] = useState(true);
  const [hasFetched, setHasFetched] = useState(false);

  const [observer, isIntersecting, setIsIntersecting] = useIntersectionObserver({
    initialState: !onIntersecting,
    threshold: 0.2,
  });
  const containerRef = useRef(null);

  const setContainerRef = useMemo(
    () => (newRef: any) => {
      containerRef.current = newRef;
      if (containerRef.current) {
        observer.observe(newRef);
      }
    },
    [insightsContext]
  );

  const analyticsFilters = useMemo(() => {
    const rehabFacilitiesFilterKey = camelCase(selectedGroupType.apiName);

    const { [START_DATE]: start, [END_DATE]: end, [rehabFacilitiesFilterKey]: group, ...rest } = filters;

    return { ...rest, [DATES]: [{ start, end }], groupType: selectedGroupType.id, group };
  }, [filters, selectedGroupType]);

  const fetchData = async () => {
    Promise.all(
      requests.map(async ({ params, processData }) => {
        const data: DataRow[] = await InsightsAnalyticsRequestService.requestData(params, analyticsFilters);

        if (!data) return;

        processData(data);
      })
    ).then(() => {
      setLoading(false);
    });
  };

  useEffect(() => {
    if (!isDeepEqual(previousFilters, filters) || !isDeepEqual(previousSelectedGroupType, selectedGroupType)) {
      // Reset the intersection observer when filters or selected group type change
      // so that tiles can be re-fetched once in view
      setHasFetched(false);

      if (onIntersecting) {
        setIsIntersecting(false);
      }
    }
  }, [filters, selectedGroupType]);

  useEffect(() => {
    if (!hasFetched && isIntersecting) {
      setLoading(true);

      if (condition) {
        fetchData();
        setHasFetched(true);
      }
    }
  }, [condition, hasFetched, isIntersecting]);

  return { loading, isIntersecting, setContainerRef };
};
