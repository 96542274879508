import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { simpleDate } from '~/lib/formatDate';

function ProgressBarState(props) {
  const { enteredAt, state, ...rest } = props;

  const formattedEnteredAt = enteredAt && simpleDate(enteredAt);

  return (
    <State { ...rest }>
      { rest.position > 0 && <Line { ...rest } /> }
      <Dot { ...rest }>
        <Title { ...rest }>{ state }</Title>
        <Date { ...rest }>{ formattedEnteredAt }</Date>
      </Dot>
    </State>
  );
}

ProgressBarState.propTypes = {
  enteredAt: PropTypes.string,
  state: PropTypes.string.isRequired
};

export default ProgressBarState;

const State = styled.div`
  display: flex;
  justify-content: flex-start;
  margin: auto 0;
  width: 30%;

  ${({ position }) => position === 0 && `
    width: 25px;
  `};
`;

const Line = styled.div`
  height: 3px;
  flex: 1;
  background: ${({ theme }) => theme.colors.black10 };
  margin: auto 0;

  ${({ completed, current, theme }) => (completed || current) && `
    background: ${ theme.colors.accentGreen };
  `};
`;

const Dot = styled.div`
  background: ${({ theme }) => theme.colors.white };
  background-clip: padding-box;
  border-radius: 50%;
  position: relative;
  border: ${({ theme }) => `8px solid ${theme.colors.black10}` };
  width: 9px;
  height: 9px;

  ${({ completed, theme }) => (completed) && `
    border-color: ${ theme.colors.accentGreen };
  `};

  ${({ current, theme }) => (current) && `
    border-color: ${ theme.colors.primaryBlue };
  `};
`;

const Title = styled.div`
  width: 71px;
  text-align: center;
  font-size: 12px;
  color: ${({ theme }) => theme.colors.black25 };
  position: absolute;
  right: -29px;
  top: 22px;
  
  ${({ current, theme }) => current && `
    color: ${ theme.colors.primaryBlue };
    font-weight: 700;
  `};

  ${({ completed, theme }) => completed && `
    color: ${ theme.colors.black75 };
    font-weight: 700;
  `};
`;

const Date = styled(Title)`
  font-size: 10px;
  top: 40px;

  ${({ current, theme }) => current && `
    color: ${ theme.colors.black };
  `};
`;
