import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import QuestionContent from './QuestionContent';

export function numericComponentConfig(question) {
  return {
    QuestionComponent: NumericQuestion,
    name: question.id,
    text: question.text,
    index: question.index,
  };
}

const AnswerContainer = styled.div`
  display: flex;
  margin: 16px 0px 0px 24px;

  & > * {
    margin-right: 8px;
  }
`;

const Answer = styled.input`
  background-color: white;
  border: 2px solid rgba(15, 18, 38, 0.5);
  border-radius: 3px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 26px;
  width: 100px;

  &:focus {
    border: 2px solid;
    border-radius: 3px;
  }
`;

const NumericQuestion = ({ name, value, helpers, ...props }) => {
  const handleChange = (event) => {
    // IE / Edge apparently don't prevent letters in numeric fields so strip them out.
    const sanitizedValue = event.target.value.replace(/[^0-9.]/g, '');

    helpers.setValue(sanitizedValue);
  };

  return (
    <div>
      <QuestionContent {...props} />
      <AnswerContainer>
        <Answer name={name} type='number' step='any' min='0' onChange={handleChange} value={value} />
      </AnswerContainer>
    </div>
  );
};

NumericQuestion.propTypes = {
  helpers: PropTypes.shape({
    setValue: PropTypes.func,
  }),
  name: PropTypes.string,
  value: PropTypes.string,
};

export default NumericQuestion;
