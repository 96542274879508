import * as Sentry from '@sentry/react';

import { createAsyncThunk } from '~/lib';
import patientsAPI from '~/sources/api/patientsAPI';

import { API_STATES, createApiErrorSelector, createApiHasStatusSelector, createApiResetStatus } from './api';

const sliceName = 'patients';

export const createPatient = createAsyncThunk(`${sliceName}/create`, async (payload) => {
  try {
    const { data } = await patientsAPI.create(payload);

    return data;
  } catch (error) {
    Sentry.captureException(error);
    throw error;
  }
});

export const updatePatient = createAsyncThunk(`${sliceName}/update`, async (payload) => {
  try {
    const { data } = await patientsAPI.update(payload);

    return data;
  } catch (error) {
    Sentry.captureException(error);
    throw error;
  }
});

export const resetPatientCreateStatus = createApiResetStatus(createPatient);
export const resetPatientUpdateStatus = createApiResetStatus(updatePatient);

export const getPatientCreateDone = createApiHasStatusSelector(createPatient, [API_STATES.complete, API_STATES.failed]);

export const getPatientCreateError = (state) => createApiErrorSelector(createPatient)(state);

export const getPatientUpdateDone = createApiHasStatusSelector(updatePatient, [API_STATES.complete, API_STATES.failed]);

export const getPatientUpdateError = (state) => createApiErrorSelector(updatePatient)(state);
