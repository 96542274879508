import React from 'react';

import { iframes as iframes_ } from '~/helpers/iframes';
import { IFRAME_CLASS } from '~/lib/authCache';

function IFrameContainer() {
  const iframes = iframes_.filter((domain) => new URL(domain).origin !== window.origin);

  return (
    <>
      {iframes.map((iframe, index) => {
        return (
          <iframe
            key={index}
            src={`${iframe}/external`}
            className={IFRAME_CLASS}
            style={{ width: 0, height: 0, border: 'none', position: 'absolute' }}></iframe>
        );
      })}
    </>
  );
}

export default IFrameContainer;
