import React, { Fragment, useContext, useMemo, useState } from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import Chip from '~/components/shared/Chip';
import { EditIcon } from '~/components/shared/svg';
import { H3, Label } from '~/components/shared/typography';
import { SNF } from '~/constants/locationTypes';
import { simpleDate } from '~/lib/formatDate';
import { ALL_FLAGS, Episode, LocationEpisode } from '~/models';
import { PERMISSIONS, ProfileContext } from '~/services/profile';
import { ADMISSION, DISCHARGED, IN_TREATMENT, QUEUE } from '~/services/rehabState/constants';
import colors from '~/styles/theme/colors';

import AltcsInfo from './AltcsInfo';
import ClassificationInfo from './ClassificationInfo';
import PatientTeamInfo from './PatientTeamInfo';
import StatusDatesModal from './StatusDatesModal';

function StayInfo({ episode, locationEpisode }) {
  const { startDate, endDate, rehabInformation, latest, lengthOfStay } = locationEpisode;
  const [showModal, setShowModal] = useState(false);

  const endDateText = useMemo(() => {
    const now = moment();
    const end = moment(endDate);
    const difference = end.diff(now, 'days');
    const endDateIsToday = difference === 0;

    if (latest && endDateIsToday) return 'present';
    return simpleDate(endDate);
  }, [latest, endDate]);

  const losText = useMemo(() => {
    const suffix = lengthOfStay === 1 ? '' : 's';

    return `${lengthOfStay} day${suffix}`;
  }, [lengthOfStay]);

  const handleCloseModal = () => setShowModal(false);
  const handleShowModal = () => setShowModal(true);

  const profileSvc = useContext(ProfileContext);
  const currentRehabState = episode.currentRehabState.state;
  const userCanEditStatusDates = profileSvc.has(PERMISSIONS.rehabStateEdit);
  const postAcuteName = rehabInformation.latestRehabFacility.name;
  const rehabFacilityAcronym = rehabInformation.latestRehabFacilityType;
  const rehabStateNotInQueue = currentRehabState !== QUEUE;
  const showLosChip = episode.trackLos && (locationEpisode.stayDescriptor === 'Past' || rehabStateNotInQueue);
  const altcsApplicationReview = locationEpisode.altcsApplicationReview;

  const showAltcsInfo =
    profileSvc.has(PERMISSIONS.reviewAltcsApplicationEdit) &&
    episode.owner.client.enabledFlags.includes(ALL_FLAGS.altcsPending) &&
    locationEpisode.rehabInformation.latestRehabFacilityType === SNF &&
    (locationEpisode.currentRehabState.inTreatment || locationEpisode.currentRehabState.admission);

  const headerText = () => {
    if (locationEpisode.stayDescriptor !== 'Current') {
      return `${locationEpisode.stayDescriptor} Stay`;
    }

    const stateLabels = {
      [QUEUE]: 'In Queue',
      [ADMISSION]: 'Admitted',
      [IN_TREATMENT]: 'In Treatment',
      [DISCHARGED]: 'Discharged',
    };

    return stateLabels[currentRehabState] || 'Current Stay';
  };

  if (!locationEpisode.id) return null;

  return (
    <Card>
      <StayContainer>
        <div>
          <HeaderContainer>
            <H3>{headerText()}</H3>
            {!episode.archived && userCanEditStatusDates && rehabStateNotInQueue && (
              <ActionIcon onClick={handleShowModal}>
                <EditIcon size={18} />
              </ActionIcon>
            )}
          </HeaderContainer>
          {locationEpisode.currentRehabState.queue ? (
            <StyledLabel>Added on {simpleDate(locationEpisode.currentRehabState.enteredAt)}</StyledLabel>
          ) : (
            <StyledLabel>
              {simpleDate(startDate)} - {endDateText}
            </StyledLabel>
          )}
        </div>
        {showLosChip && (
          <div>
            <Chip
              fontSize='16px'
              padding='6px 11px'
              bold
              label={losText}
              backgroundColor={latest ? colors.secondaryOrange : colors.black50}
            />
          </div>
        )}
        <StatusDatesModal
          showStatusDatesModal={showModal}
          onClose={handleCloseModal}
          locationEpisode={locationEpisode}
        />
      </StayContainer>
      <Fragment>
        <PatientTeamInfo episode={episode} postAcuteName={postAcuteName} rehabFacilityAcronym={rehabFacilityAcronym} />
        <ClassificationInfo locationEpisode={locationEpisode} />
      </Fragment>
      {showAltcsInfo && (
        <AltcsInfo
          altcsApplicationReview={altcsApplicationReview}
          locationEpisodeId={locationEpisode.id}
          patientName={locationEpisode.patient?.name}
        />
      )}
    </Card>
  );
}

StayInfo.propTypes = {
  episode: PropTypes.instanceOf(Episode).isRequired,
  locationEpisode: PropTypes.instanceOf(LocationEpisode).isRequired,
};

export default StayInfo;

const Card = styled.div`
  border: 1px solid ${colors.black10};
  border-radius: 4px;
  background-color: ${colors.white};
  margin-bottom: 24px;
`;

const StayContainer = styled.div`
  display: flex;
  padding: 24px;
  justify-content: space-between;
  align-items: center;
`;

const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
`;

const StyledLabel = styled(Label)`
  display: block;
  color: ${colors.black50};
  margin-top: 6px;
`;

const ActionIcon = styled.div`
  margin-left: 6px;
  cursor: pointer;
`;
