import React from 'react';
import PropTypes from 'prop-types';

const HHAIcon = ({ fill, size }) => {
  return (
    <svg width={size} height={size} viewBox='0 0 24 24' version='1.1' xmlns='http://www.w3.org/2000/svg'>
      <g id='hha-icon-black100' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
        <path
          d='M12,0.640585812 L7,5.13457208 L7,2.96567506 L4,2.96567506 L4,7.83037071 L0.327,11.131167 L1.673,12.5942334 L3,11.402032 L3,21.7482838 C3,22.2949565 3.448,22.7368421 4,22.7368421 L10,22.7368421 L10,16.805492 L14,16.805492 L14,22.7368421 L20,22.7368421 C20.552,22.7368421 21,22.2949565 21,21.7482838 L21,11.402032 L22.327,12.595222 L23.673,11.1321556 L12,0.640585812 Z M14,12.8512586 L10,12.8512586 L10,8.89702517 L14,8.89702517 L14,12.8512586 Z'
          fill={fill}
          fillRule='nonzero'
        />
      </g>
    </svg>
  );
};

HHAIcon.defaultProps = {
  fill: '#0F1226',
  size: 14,
};

HHAIcon.propTypes = {
  fill: PropTypes.string,
  size: PropTypes.number,
};

export default HHAIcon;
