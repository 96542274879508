import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

import { Activity } from '~/models';

import ActivityCard from './ActivityCard/ActivityCard';

function Activities(props) {
  const activitiesProps = useMemo(() => {
    let expand = true;

    return props.activities.map((activity, i) => {
      const activityProps = {
        activity,
        expand,
        showConnector: i !== 0,
      };

      if (expand && activity.isProgressUpdate) {
        // only start the first progress update expanded
        expand = false;
      }

      return activityProps;
    });
  }, [props.activities]);

  return activitiesProps.map((activityProp) => <ActivityCard key={activityProp.activity.id} {...activityProp} />);
}

Activities.propTypes = {
  activities: PropTypes.arrayOf(PropTypes.instanceOf(Activity)),
};

Activities.defaultProps = {
  activities: [],
};

export default Activities;
