import React, { useMemo } from 'react';
import styled from 'styled-components';

import { PriorityFlag, WarnIcon } from '~/components/shared/svg';
import { H3, Label } from '~/components/shared/typography';
import { colors } from '~/styles/theme';

import { Button } from '../buttons';

import { useTaskModalContext } from './TaskModalContext';

function Header() {
  const {
    escalationType,
    escalationTypeLabel,
    escalationsData: { escalations, totalRecords },
    onClose,
    setUsePatientData,
    usePatientData,
  } = useTaskModalContext();

  const Icon = useMemo(() => (escalationType === 'default' ? WarnIcon : PriorityFlag), [escalationType]);
  const patientNameText = usePatientData ? `${escalations[0]?.patient?.name} — ` : '';
  const handleToggleVisibility = () => setUsePatientData(false);

  return (
    <Container>
      <TitleContainer>
        <Icon size={16} fill={colors.accentRed} />
        <H3 margin='0 0 0 8px'>{patientNameText + escalationTypeLabel}</H3>
        {!!totalRecords && <H3>&nbsp;({totalRecords})</H3>}
        {usePatientData && (
          <VisibilityFilter onClick={handleToggleVisibility}>
            View all {escalationTypeLabel.toLowerCase()}
          </VisibilityFilter>
        )}
      </TitleContainer>
      <ActionContainer>
        <CloseButton padding='8px' color='transparent' onClick={onClose} text='&times;' />
      </ActionContainer>
    </Container>
  );
}

export default Header;

const Container = styled.div`
  display: flex;
  align-items: center;
  padding: 14px 24px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.black25};
`;

const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  flex: 1;
`;

const ActionContainer = styled.div`
  display: flex;
  flex: none;
`;

const VisibilityFilter = styled(Label)`
  color: ${({ theme }) => theme.colors.primaryBlue};
  margin-left: 16px;
  cursor: pointer;
`;

const CloseButton = styled(Button)`
  font-size: 24px;
  font-weight: 300;
  height: 24px;
  margin-left: auto;

  &:focus {
    border-color: ${({ theme }) => theme.colors.black75};
    color: ${({ theme }) => theme.colors.black75};
  }
`;
