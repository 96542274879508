import { Blob, DirectUpload } from 'activestorage';

import * as Sentry from '@sentry/react';

import { IAttachment } from '~/models/interfaces';

export const STATES = {
  pending: 'pending',
  uploading: 'uploading',
  complete: 'complete',
  error: 'error',
};

type UploadDefaults = Partial<Upload> & {
  file: File;
  uploadsUrl: string;
};

const defaults = {
  accessToken: '',
  docType: null,
  blob: null,
  error: null,
  file: null,
  onUploadChange: () => undefined,
  progress: null,
  status: STATES.pending,
  upload: null,
  uploadsUrl: import.meta.env.API_URL + '/uploads',
};

class Upload implements IAttachment {
  accessToken: string;
  docType: string | null;
  blob: Blob | null;
  error: Error | null;
  onUploadChange: (upload: Upload) => void;
  progress: number | null;
  status: string;
  upload: DirectUpload | null;

  constructor(options: Partial<UploadDefaults> = {}) {
    const opts = { ...defaults, ...options };

    this.accessToken = opts.accessToken;
    this.docType = opts.docType;
    this.blob = opts.blob;
    this.error = opts.error;
    this.onUploadChange = opts.onUploadChange;
    this.progress = opts.progress;
    this.status = opts.status;
    this.upload = opts.upload || new DirectUpload(opts.file as File, opts.uploadsUrl, this);
  }

  get file() {
    return this.upload?.file;
  }

  get filename() {
    return this.upload?.file.name;
  }

  get id() {
    return this.upload?.id;
  }

  get isComplete() {
    return this.status === STATES.complete;
  }

  get hasError() {
    return this.status === STATES.error;
  }

  get signedId() {
    return this.blob?.signed_id || null;
  }

  start() {
    const promise = new Promise<Blob>((resolve, reject) => {
      this.upload?.create((err: Error, blob: Blob) => {
        if (err) {
          reject(err);
        } else {
          resolve(blob);
        }
      });
    });

    return promise.then(this.handleSuccess).catch(this.handleError);
  }

  handleProgress = ({ loaded, total }: { loaded: number; total: number }) => {
    this.status = STATES.uploading;
    this.progress = (loaded / total) * 100;

    this.onUploadChange(this);
  };

  handleSuccess = (blob: Blob) => {
    this.status = STATES.complete;
    this.blob = blob;
    this.progress = null;

    this.onUploadChange(this);
    return this;
  };

  handleError = (err: Error) => {
    this.status = STATES.error;
    this.error = err;
    this.progress = null;

    Sentry.captureException(err);
    this.onUploadChange(this);
    return this;
  };

  directUploadWillCreateBlobWithXHR(xhr: XMLHttpRequest) {
    xhr.setRequestHeader('Authorization', this.accessToken);
  }

  directUploadWillStoreFileWithXHR(xhr: XMLHttpRequest) {
    xhr.upload.addEventListener('progress', this.handleProgress);
  }
}

export default Upload;
