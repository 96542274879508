import React, { Fragment, useState } from 'react';
import { useFormikContext } from 'formik';
import PropTypes from 'prop-types';

import CaseManagerSection from '~/components/patients/intake/IntakeForm/episodeSections/CaseManagerSection';
import { InputGroup, Select } from '~/components/shared/form';
import { PHYSICIAN_GROUP } from '~/constants/locationTypes';
import { fetchLocations } from '~/ducks/locations';
import { getId, getName } from '~/helpers';
import { useAsyncOptions, useDeepEffectCompare } from '~/lib/hooks';

import { formFields } from '../constants';

import { AdmittedOnInputGroup, HospitalInputGroup, PhysicianTeamInputGroup } from './shared';

function PhysicianGroupEpisodeSection({ disabled, canAssignCaseManager }) {
  const {
    initialValues,
    setValues,
    status: { connecting },
    values,
  } = useFormikContext();
  const {
    caseManagerField,
    episodeClassificationField,
    hospitalField,
    ownerField,
    planTypeClassificationField,
    physicianTeamField,
    rehabFacilityField,
  } = formFields;

  const physicianGroupFieldLabel = 'Physician Group';
  const physicianGroupFieldName = 'physicianGroup';

  const payerAsyncOptions = useAsyncOptions(fetchLocations, {
    params: { type: PHYSICIAN_GROUP },
  });

  const {
    [physicianTeamField.name]: initialPhysicianTeamValue,
    [planTypeClassificationField.name]: initialPlanTypeClassValue,
    [episodeClassificationField.name]: initialEpisodeClassValue,
    [rehabFacilityField.name]: initialRehabFacilityValue,
    [caseManagerField.name]: initialCaseManagerValue,
  } = initialValues;

  const {
    physicianGroup,
    [physicianTeamField.name]: physicianTeamValue,
    [planTypeClassificationField.name]: planTypeClassValue,
    [episodeClassificationField.name]: episodeClassValue,
    [rehabFacilityField.name]: rehabFacilityValue,
    [caseManagerField.name]: caseManagerValue,
  } = values;

  const initialDepFieldValues = {
    [physicianTeamField.name]: initialPhysicianTeamValue,
    [planTypeClassificationField.name]: initialPlanTypeClassValue,
    [episodeClassificationField.name]: initialEpisodeClassValue,
    [rehabFacilityField.name]: initialRehabFacilityValue,
    [caseManagerField.name]: initialCaseManagerValue,
  };

  const depFieldValues = {
    [physicianTeamField.name]: physicianTeamValue,
    [planTypeClassificationField.name]: planTypeClassValue,
    [episodeClassificationField.name]: episodeClassValue,
    [rehabFacilityField.name]: rehabFacilityValue,
    [caseManagerField.name]: caseManagerValue,
  };

  const [clearDepFields, setClearDepFields] = useState(!connecting);

  useDeepEffectCompare(
    initialDepFieldValues,
    depFieldValues,
    () => {
      setClearDepFields(true);
    },
    [depFieldValues]
  );

  const handlePhysicianGroupChange = (newValue) => {
    let fieldValues = {
      ...values,
      [physicianGroupFieldName]: newValue,
      [ownerField.name]: newValue,
    };

    if (clearDepFields) {
      fieldValues = {
        ...fieldValues,
        [hospitalField.name]: null,
        [episodeClassificationField.name]: null,
        [planTypeClassificationField.name]: null,
        [physicianTeamField.name]: null,
        [rehabFacilityField.name]: null,
        [caseManagerField.name]: null,
      };
    }

    setValues(fieldValues, true);
  };

  return (
    <Fragment>
      <AdmittedOnInputGroup disabled={disabled} />
      <InputGroup
        {...payerAsyncOptions}
        component={Select}
        data-cy={physicianGroupFieldName}
        getOptionLabel={getName}
        getOptionValue={getId}
        label={physicianGroupFieldLabel}
        name={physicianGroupFieldName}
        placeholder={physicianGroupFieldLabel}
        onChange={handlePhysicianGroupChange}
        disabled={disabled}
      />
      {canAssignCaseManager && <CaseManagerSection groupId={physicianGroup?.id} />}
      <HospitalInputGroup ownerId={physicianGroup?.id} disabled={disabled} />
      <PhysicianTeamInputGroup ownerId={physicianGroup?.id} disabled={disabled} />
    </Fragment>
  );
}

PhysicianGroupEpisodeSection.propTypes = {
  canAssignCaseManager: PropTypes.bool.isRequired,
  disabled: PropTypes.bool,
};

export default PhysicianGroupEpisodeSection;
