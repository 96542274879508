export const ACKNOWLEDGEMENT = 'acknowledgement';
export const ACTUAL_ADMISSION_DATE_QUESTION = 'Confirm patient\'s actual admission date:';
export const ALTCS_APPLICATION_STATUS_UPDATE = 'altcs_application_status_update';
export const AUTHORIZATION_UPDATES = 'authorization_updates';
export const DISCHARGE_QUESTIONS = 'discharge_questions';
export const DOCUMENT = 'document';
export const IMAGE = 'image';
export const NOTE = 'note';
export const NUMERIC = 'numeric';
export const PATIENT_FIELD_CHANGES = 'patient_field_changes';
export const PATIENT_MATCH_COLLABORATION = 'patient_match_collaboration';
export const PROGRESS_UPDATE = 'progress_update';
export const PROJECTED_DISCHARGE_UPDATES = 'projected_discharge_updates';
export const RANGE = 'range';
export const REHAB_STATE_CHANGE = 'rehab_state_change';
export const SERVICE_REFUSAL = 'service_refusal';
export const STATUS = 'status';
export const YES_NO = 'yes_no';
