// !! DEPRECATED - Use constants/rehabStates instead !!

// Valid lane name constants
export const QUEUE = 'Queue';
export const ADMISSION = 'Admission';
export const IN_TREATMENT = 'In Treatment';
export const DISCHARGED = 'Discharged';

export const REHAB_STATE_NAMES = [
  QUEUE,
  ADMISSION,
  IN_TREATMENT,
  DISCHARGED
];
