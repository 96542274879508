import React from 'react';
import PropTypes, { InferProps } from 'prop-types';
import ReactTooltip from 'react-tooltip';
import styled from 'styled-components';

import CaseManagerModal from '~/components/patients/patientProfile/PatientStory/CaseManager/CaseManagerModal';
import { InfoIcon, PencilIcon } from '~/components/shared/svg';
import { BodySmallBold, Label, LabelBold } from '~/components/shared/typography';
import { useSwitch } from '~/lib/hooks';
import Client from '~/models/Client';
import LocationEpisode from '~/models/LocationEpisode';
import colors from '~/styles/theme/colors';

function CaseManager({ locationEpisode, showUpdateButton = false }: Props) {
  const modalSwitch = useSwitch();
  const client = locationEpisode.owner.client as Client;
  const toolTip = client.name + ' case manager';
  const caseManagerUserNameDisplay = locationEpisode.caseManager ? (
    locationEpisode.caseManager.fullName
  ) : (
    <span>&#8212;</span>
  );

  const actionLink = (text: string) => {
    return (
      <ActionContainer onClick={modalSwitch.turnOn}>
        <PencilIcon size={12} fill={colors.primaryBlue} />
        <StyledLabel color={colors.primaryBlue}>{text}</StyledLabel>
      </ActionContainer>
    );
  };

  return (
    <Card data-cy='caseManager'>
      <Container>
        <LabelContainer>
          <Label data-cy='caseManagerLabel'>Case Manager</Label>
          <InfoContainer data-tip={toolTip}>
            <InfoIcon />
          </InfoContainer>
          <ReactTooltip place='right' effect='solid' multiline />
        </LabelContainer>
        {showUpdateButton && actionLink('Update')}
        {modalSwitch.state && (
          <CaseManagerModal
            locationEpisodeId={locationEpisode.id}
            caseManager={locationEpisode.caseManager}
            onCancel={modalSwitch.turnOff}
            onSuccess={modalSwitch.turnOff}
            show={modalSwitch.state}
            ownerGroupId={locationEpisode.owner.id}
          />
        )}
      </Container>
      <UserContainer>
        <StyledUser data-cy='caseManagerUser'>{caseManagerUserNameDisplay}</StyledUser>
      </UserContainer>
    </Card>
  );
}

CaseManager.propTypes = {
  locationEpisode: PropTypes.instanceOf(LocationEpisode).isRequired,
  showUpdateButton: PropTypes.bool,
};

type Props = InferProps<typeof CaseManager.propTypes>;

export default CaseManager;

const Card = styled.div`
  border: 1px solid ${colors.black10};
  border-radius: 4px;
  background-color: ${colors.white};
  margin-bottom: 24px;
`;

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 24px 24px 8px 24px;
  align-items: center;
`;

const LabelContainer = styled.div`
  display: flex;
`;

const ActionContainer = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
`;

const StyledLabel = styled(LabelBold)`
  margin-left: 8px;
  cursor: pointer;
`;

const InfoContainer = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-left: 8px;
`;

const UserContainer = styled.div`
  display: flex;
`;
const StyledUser = styled(BodySmallBold)`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 0 24px 24px 24px;
`;
