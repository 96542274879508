export interface ISortOption {
  attributeName: string;
  direction: string;
  key: string;
}

interface ISortOptionOptions {
  attributeName: string;
  direction: string;
}

const defaults: ISortOptionOptions = {
  attributeName: '',
  direction: ''
};

export class SortOption implements ISortOption {
  attributeName: string;
  direction: string;
  key: string;

  constructor(options: ISortOption) {
    const opts = { ...defaults, ...options };

    this.attributeName = opts.attributeName;
    this.direction = opts.direction;
    this.key = opts.key || this.attributeName;
  }
}
