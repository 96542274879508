import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import styled from 'styled-components';

import { Button, ButtonGroup } from '~/components/shared/buttons';
import { ConfirmationList, DocumentSummary, NeedItem } from '~/components/shared/ConfirmationList';
import { FormHeader } from '~/components/shared/form';
import { FormPageContentContainer } from '~/components/shared/pageLayout';
import { addToast } from '~/ducks/toasts';
import { capitalize } from '~/helpers/capitalize';
import { simpleDate } from '~/lib/formatDate';
import { ALL_FLAGS, ImportedPatient, Patient } from '~/models';
import { PERMISSIONS, ProfileService } from '~/services/profile';

function PatientConfirm(props) {
  const { onComplete, onRevert, patient, profileSvc } = props;

  const canAssignCaseManager =
    profileSvc.hasFlag(ALL_FLAGS.caseManagerAssignment) && profileSvc.has(PERMISSIONS.locationEpisodeUserEdit);

  const buildPatientDataItems = () => {
    const locationType = patient.rehabFacility?.groupType?.displayName || '';
    const connecting = patient instanceof ImportedPatient;

    const items = [
      { label: 'Date of Birth', value: simpleDate(patient.dateOfBirth) },
      { label: 'Gender', value: capitalize(patient.sex) },
      { label: 'Hospital Admission Date', value: simpleDate(patient.admittedOn) },
      { label: 'Payer', value: patient.payer?.name },
      { label: 'Physician Group', value: patient.physicianGroup?.name },
      { label: 'Hospital', value: patient.hospital?.name },
    ];

    if (canAssignCaseManager) {
      items.push({ label: 'Case Manager', value: patient.caseManager?.name || <>&mdash;</> });
    }

    items.push(
      { label: 'Physician', value: patient.physicianTeam?.name },
      { label: `${locationType} Location`, value: patient.rehabFacility?.name },
      { label: 'Plan Type', value: patient.planTypeClassification?.name || <>&mdash;</> },
      { label: 'Episode Type', value: patient.episodeClassification?.name || <>&mdash;</> }
    );

    if (connecting && patient.antHospitalDischarge) {
      items.push({ label: 'Anticipated Hospital Discharge', value: simpleDate(patient.antHospitalDischarge) });
    }

    if (patient.needs.length) {
      items.push({
        label: 'Patient Needs',
        value: patient.needs.map((need) => <NeedItem key={need} need={need} />),
      });
    }

    if (patient.note?.text) {
      items.push({ label: 'Note', value: patient.note.text });
    }

    if (patient.note?.attachments.length) {
      patient.note.attachments.map((attachment) => {
        items.push({ label: 'Attachment', key: attachment.key, value: <DocumentSummary document={attachment} /> });
      });
    }

    return items;
  };

  const patientDataItems = useMemo(() => buildPatientDataItems(), []);

  const handleConfirm = () => onComplete(patient, { manualNavigation: true });

  if (!patient) return null;

  return (
    <FormPageContentContainer>
      <FormHeader title='Review and Confirm Details' subtitle={`Patient: ${patient.name}`} />
      <ConfirmationListContainer data-cy='patientConfirmationList'>
        <ConfirmationList items={patientDataItems} />
      </ConfirmationListContainer>
      <ButtonGroup>
        <Button color='transparent' onClick={onRevert} text='Back' />
        <Button onClick={handleConfirm} data-cy='save' text={'Confirm and Save'} />
      </ButtonGroup>
    </FormPageContentContainer>
  );
}

PatientConfirm.propTypes = {
  addToast: PropTypes.func.isRequired,
  onAbort: PropTypes.func.isRequired,
  onComplete: PropTypes.func.isRequired,
  onRevert: PropTypes.func.isRequired,
  patient: PropTypes.oneOfType([PropTypes.instanceOf(Patient), PropTypes.instanceOf(ImportedPatient)]),
  profileSvc: PropTypes.instanceOf(ProfileService).isRequired,
};

const mapDispatchToProps = { addToast };

export default connect(null, mapDispatchToProps)(PatientConfirm);

const ConfirmationListContainer = styled.div`
  width: 100%;
  margin-bottom: 36px;
`;
