import React from 'react';
import PropTypes from 'prop-types';

const NoteIcon = ({ fill = '#000', size = 24, viewBox = '0 0 24 24', opacity = '1' }) => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width={size} height={size} viewBox={viewBox}>
      <g fill={fill} fillRule='nonzero' opacity={opacity}>
        <path d='M15 0H2a1 1 0 0 0-1 1v22a1 1 0 0 0 1 1h20a1 1 0 0 0 1-1V8h-7a1 1 0 0 1-1-1V0zM5 17h14v2H5v-2zm0-5h14v2H5v-2zm6-3H5V7h6v2z' />
        <path d='M22.414 6H17V.586z' />
      </g>
    </svg>
  );
};

NoteIcon.propTypes = {
  fill: PropTypes.string,
  opacity: PropTypes.string,
  size: PropTypes.number,
  viewBox: PropTypes.string,
};

export default NoteIcon;
