import * as _ from 'lodash-es';

import { Chart } from '~/models';

export const getChartData = (activities, questions) => {
  if (_.isNil(questions) || _.isNil(activities)) return [];

  const chartQuestions = (qs) => {
    return qs.filter((q) => {
      return q.config?.chart?.chartName;
    });
  };

  return chartQuestions(questions).map((question) => {
    const data = activities
      .filter((activity) => activity.isProgressUpdate)
      .map((progressUpdate) => {
        const matchingQuestion = progressUpdate.questions.find((plq) => plq.id === question.id);

        if (matchingQuestion) {
          return {
            date: progressUpdate.createdAt,
            score: matchingQuestion.answer.text,
          };
        }

        return null;
      })
      .filter((q) => q);

    return new Chart(data, {
      title: question.config.chart.chartName,
      yAxis: question.config.chart.yAxis,
      min: question.config.min,
      max: question.config.max,
      question: question,
    });
  });
};

export const shouldShowPerformanceChart = (progressUpdates) => {
  return progressUpdates.some((pu) => pu.questions.some((q) => q.kind === 'yes_no'));
};
