import React, { useEffect } from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import styled from 'styled-components';

import { StandardModal } from '~/components/shared/modal';
import { H3 } from '~/components/shared/typography';
import { createActivity, getActivityCreating } from '~/ducks/activities';
import { usePrevious } from '~/lib/hooks';
import { LocationEpisode, ProgressUpdate, QuestionTemplate } from '~/models';

import ProgressUpdateForm from './PatientStory/ProgressUpdateForm';

function ProgressUpdateModal(props) {
  const { activityCreating, locationEpisode, onCancel, onClose, progressTemplate, showModal } = props;

  const { instructions, questions } = progressTemplate;
  const patientName = locationEpisode.patient.name;
  const prevActivityCreating = usePrevious(activityCreating);

  useEffect(() => {
    if (showModal && prevActivityCreating && !activityCreating) {
      onClose();
    }
  }, [activityCreating]);

  const handleSave = (activityProps) => {
    const progressUpdate = new ProgressUpdate({
      ...activityProps,
      // date objects are automatically converted to UTC
      // when stringified for api requests, so we need to
      // send just the date portion of the date object
      enteredAt: moment().format('YYYY-MM-DD'),
      locationEpisodeId: locationEpisode.id,
    });

    props.createActivity({ ...progressUpdate.serialize(), include: 'attachments.blob' });
  };

  return (
    <StyledStandardModal
      show={showModal}
      data-cy='progressUpdateModal'
      title={'Update Progress'}
      onCancel={onCancel}
      disableBackdropClick>
      <PatientName data-cy='progressUpdateModalPatientName'>Patient: {patientName}</PatientName>
      {instructions && (
        <InstructionContainer data-cy='instructions'>
          <InstructionsLabel>Instructions:</InstructionsLabel>
          <InstructionContent>{instructions}</InstructionContent>
        </InstructionContainer>
      )}
      <ProgressUpdateForm
        locationEpisode={locationEpisode}
        questions={questions}
        onSave={handleSave}
        onCancel={onCancel}
      />
    </StyledStandardModal>
  );
}

ProgressUpdateModal.propTypes = {
  activityCreating: PropTypes.bool,
  createActivity: PropTypes.func,
  locationEpisode: PropTypes.instanceOf(LocationEpisode),
  onCancel: PropTypes.func,
  onClose: PropTypes.func,
  progressTemplate: PropTypes.instanceOf(QuestionTemplate).isRequired,
  showModal: PropTypes.bool,
};

const mapStateToProps = (state) => ({
  activityCreating: getActivityCreating(state),
});

const mapDispatchToProps = {
  createActivity,
};

export default connect(mapStateToProps, mapDispatchToProps)(ProgressUpdateModal);

const StyledStandardModal = styled(StandardModal)`
  width: 700px;
`;

const PatientName = styled(H3)`
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
`;

const InstructionContainer = styled.div`
  padding: 16px 24px;
`;

const InstructionsLabel = styled.div`
  line-height: 1.5;
  font-size: 16px;
  color: #0f1226;
`;

const InstructionContent = styled.div`
  font-style: italic;
  font-size: 16px;
  line-height: 1;
  padding-top: 10px;
`;
