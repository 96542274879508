import React from 'react';
import { Form, Formik } from 'formik';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Button, ButtonGroup } from '~/components/shared/buttons';
import NeedsList from '~/components/shared/NeedsAndDocuments/NeedsList';

import ContentHeading from './ContentHeading';
import { FormContainer } from './DischargeForm';

function DischargeNeeds(props) {
  const { formValues, onComplete, onRevert, patientName, updateFormValues } = props;

  const handleNext = (values) => {
    const updatedValues = { needs: values.needs };

    updateFormValues(updatedValues);
    onComplete();
  };

  const initialFormValues = { needs: formValues.needs || [] };

  return (
    <Formik initialValues={initialFormValues} onSubmit={handleNext} enableReinitialize validateOnMount>
      <Form>
        <FormContainer>
          <ContentHeading title='' subtitle={`Patient: ${patientName}`} />
          <div>
            <NeedsList name='needs' />
            <Actions>
              <Button text='Back' color='transparent' onClick={onRevert} />
              <Button type='submit' text={'Next'} data-cy='dischargeNeedsNext' />
            </Actions>
          </div>
        </FormContainer>
      </Form>
    </Formik>
  );
}

DischargeNeeds.propTypes = {
  formValues: PropTypes.shape({
    needs: PropTypes.instanceOf(Array),
  }),
  onComplete: PropTypes.func,
  onRevert: PropTypes.func,
  patientName: PropTypes.string,
  updateFormValues: PropTypes.func,
};

export default DischargeNeeds;

export const Actions = styled(ButtonGroup)`
  justify-content: flex-end;
`;
