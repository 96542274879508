import { Api } from './core';

export const url = 'imported_patients';

const {
  fetch,
  fetchById
} = new Api({ url });

export default {
  fetch,
  fetchById
};
