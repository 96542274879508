import React, { useMemo } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import {
  AverageLengthOfStayDetail,
  InsightsFilterBar,
  MetricsDashboard,
  OlioEngagementScoreDetail,
  ReadmissionRateDetail,
} from '~/components/insights';
import { useInsightsContext } from '~/components/insights/InsightsContainer';
import { Filter } from '~/components/shared/FilterBar';
import FilterChips from '~/components/shared/FilterChips';
import SimplePage from '~/components/shared/pageLayout/SimplePage';
import { END_DATE, START_DATE } from '~/constants/filterKeysConstants';

import { FilterMultiSelectFilter } from '../shared/FilterMultiSelect/FilterMultiSelect';

import { RouteConstants } from './constants';
import { renderableFilterDropdowns } from './renderableFilterDropdowns';

export default function InsightsPage() {
  const insightsContext: any = useInsightsContext();
  const { filters, setSelectedFilters, selectedGroupType, profileSvc } = insightsContext;

  const renderableDropdowns = useMemo(
    () => renderableFilterDropdowns(selectedGroupType, profileSvc.actingClient.clientType),
    [selectedGroupType]
  );

  const renderableDropdownNames: string[] = renderableDropdowns.reduce(
    (acc: string[], { filters }: { filters: FilterMultiSelectFilter[] }) => {
      return [...acc, ...filters.map((filter) => filter.name)];
    },
    []
  );

  const filtersForSelectedGroupType: { [key: string]: Filter[] } = Object.entries(filters).reduce(
    (acc, [key, val]) =>
      [...renderableDropdownNames, START_DATE, END_DATE].includes(key) ? { ...acc, [key]: val } : acc,
    {}
  );

  return (
    <SimplePage>
      <SimplePage.Header>
        <InsightsFilterBar filters={filtersForSelectedGroupType} filterSections={renderableDropdowns} />
      </SimplePage.Header>
      <SimplePage.SubHeader>
        <FilterChips
          filters={filtersForSelectedGroupType}
          onClickRemoveFilter={({ filterType, id }) => {
            const newFilters = {
              ...filtersForSelectedGroupType,
              [filterType]: filtersForSelectedGroupType[filterType].filter((filter: Filter) => filter.id !== id),
            };

            setSelectedFilters(newFilters);
          }}
        />
      </SimplePage.SubHeader>
      <SimplePage.Content>
        <Switch>
          <Route exact path={RouteConstants.OLIO_ENGAGEMENT_SCORE} component={OlioEngagementScoreDetail} />
          <Route exact path={RouteConstants.AVERAGE_LENGTH_OF_STAY} component={AverageLengthOfStayDetail} />
          <Route exact path={RouteConstants.READMISSION_RATE} component={ReadmissionRateDetail} />
          <Route exact path={RouteConstants.INSIGHTS_BASE} component={MetricsDashboard} />
          <Route render={() => <Redirect to={RouteConstants.INSIGHTS_BASE} />} />
        </Switch>
      </SimplePage.Content>
    </SimplePage>
  );
}
