import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Select } from '~/components/shared/form';
import { LocationEpisode } from '~/models';

import StaySelectorOptionLabel from './StaySelectorOptionLabel';

function StaySelector({ locationEpisode, locationEpisodes, onSelect }) {
  const byId = ({ id }) => id;

  const handleChange = (value) => {
    onSelect(byId(value));
  };

  return (
    <Container data-cy='staySelector'>
      <Select
        options={locationEpisodes}
        onChange={handleChange}
        getOptionValue={byId}
        formatOptionLabel={StaySelectorOptionLabel}
        value={locationEpisode}
      />
    </Container>
  );
}

StaySelector.propTypes = {
  locationEpisode: PropTypes.instanceOf(LocationEpisode),
  locationEpisodes: PropTypes.arrayOf(PropTypes.instanceOf(LocationEpisode)),
  onSelect: PropTypes.func.isRequired,
};

export default StaySelector;

const Container = styled.div`
  margin-bottom: 24px;
`;
