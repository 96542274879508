import {
  AUTHORIZATION_UPDATES,
  DISCHARGE_QUESTIONS,
  NOTE,
  PATIENT_FIELD_CHANGES,
  PATIENT_MATCH_COLLABORATION,
  PROGRESS_UPDATE,
  PROJECTED_DISCHARGE_UPDATES,
  REHAB_STATE_CHANGE,
  STATUS,
} from '~/constants/activities';
import GroupType from '~/models/GroupType';

import Attachment from './Attachment';
import Escalation from './Escalation';
import Patient from './Patient';

const defaults = {
  id: '',
  createdAt: null,
  updatedAt: null,
  admittedAt: null,
  attachments: [],
  attachmentRemoved: false,
  data: {},
  escalated: false,
  locationEpisodeId: '',
  patient: new Patient(),
  type: NOTE,
};

const buildAttachments = ({ attachments, createdByUser }) => {
  return attachments.map((attachment) => {
    return buildAttachment({ ...attachment, createdByUser });
  });
};

const buildAttachment = (attachment) => {
  if (attachment) {
    return new Attachment(attachment);
  }
  return null;
};

const buildEscalation = ({ escalated, escalation, data }) => {
  if (!(escalation || escalated)) return null;
  const { plaintext, text } = data;

  return new Escalation({ ...(escalation || {}), text: plaintext || text });
};

export default class Activity {
  constructor(options = {}) {
    const { createdByLocation = null, createdByUser = null } = options;
    const opts = { ...defaults, ...options };

    this.id = opts.id;
    this.admittedAt = opts.admittedAt;
    this.createdAt = opts.createdAt;
    this.updatedAt = opts.updatedAt;
    this.data = {
      createdByLocation,
      createdByUser,
      ...opts.data,
    };
    this.enteredAt = opts.enteredAt;
    this.escalated = opts.escalated;
    this.locationEpisodeId = opts.locationEpisodeId;
    this.patient = opts.patient;
    this.type = opts.type;
    this.attachments = buildAttachments({ ...opts, createdByUser: this.createdBy });
    this.escalation = buildEscalation(opts);
  }

  get createdBy() {
    return this.data.createdByUser;
  }

  get createdByGroupType() {
    const groupType = this.data.createdByUser?.groupType;

    return groupType ? new GroupType(groupType) : null;
  }

  get createdByLocation() {
    return this.data.createdByLocation;
  }

  get hasTextOrAttachment() {
    return !!(this.text?.length > 0 || this.attachments.length);
  }

  get isAuthorizationUpdates() {
    return this.type === AUTHORIZATION_UPDATES;
  }

  get isDischargeQuestions() {
    return this.type === DISCHARGE_QUESTIONS;
  }

  get isNote() {
    return this.type === NOTE;
  }

  get isPatientFieldChange() {
    return this.type === PATIENT_FIELD_CHANGES;
  }

  get isPatientMatchCollaboration() {
    return this.type === PATIENT_MATCH_COLLABORATION;
  }

  get isProgressUpdate() {
    return this.type === PROGRESS_UPDATE;
  }

  get isProjectedDischargeUpdates() {
    return this.type === PROJECTED_DISCHARGE_UPDATES;
  }

  get isStatusUpdate() {
    return this.isProgressUpdate && this.questions[0]?.kind === STATUS;
  }

  get isStateChange() {
    return this.type === REHAB_STATE_CHANGE;
  }

  get typeLabel() {
    return 'Note';
  }

  get ageInHours() {
    return Math.floor((new Date() - new Date(this.createdAt)) / 1000 / 60 / 60);
  }

  get edited() {
    return this.updatedAt > this.createdAt;
  }

  canEditOrRemove(user) {
    return this.canEdit(user) || this.canRemove(user);
  }

  canEdit(_user) {
    return false;
  }

  canRemove(user) {
    return (
      this.createdBy.id === user.id &&
      !this.escalated &&
      this.hasTextOrAttachment &&
      (this.type === PROGRESS_UPDATE || this.type === NOTE)
    );
  }

  serialize() {
    return {
      ...this,
      ...this.data,
      escalated: !!this.escalation,
      escalationType: this.escalation?.type,
      attachments: this.attachments.map((attachment) => attachment.serialize()),
    };
  }
}
