import React, { Fragment } from 'react';
import { useFormikContext } from 'formik';

import { InputGroup, MultiSelect, MultiSelectLabel } from '~/components/shared/form';
import { HOSPITAL, PAYER, PHYSICIAN_GROUP } from '~/constants/locationTypes';
import { fetchLocations } from '~/ducks/admin/locations';
import { getId, getName } from '~/helpers';
import { useAddressOptions, useAsyncOptions, useDeepEffect } from '~/lib/hooks';

type AcuteAssociatedLocationsFormValues = {
  hospitals: Location[],
  payers: Location[],
  physicianGroups: Location[],
  locationType: { kind: string, name: string }
}

function AcuteAssociatedLocations() {
  const {
    setFieldTouched,
    setFieldValue,
    status: { isEdit },
    values
  } = useFormikContext<AcuteAssociatedLocationsFormValues>();

  const hospitalsFieldName = 'hospitals';
  const payersFieldName = 'payers';
  const physicianGroupsFieldName = 'physicianGroups';
  const addressProps = useAddressOptions();

  const setField = (fieldName: string, value: string[]) => {
    setFieldValue(fieldName, value, false);
    setFieldTouched(fieldName, false, false);
  };

  useDeepEffect(() => {
    if (!isEdit && values?.locationType) {
      setField(hospitalsFieldName, []);
      setField(payersFieldName, []);
      setField(physicianGroupsFieldName, []);
    }
  }, [values.locationType]);

  const asyncHospitalOptions = useAsyncOptions(fetchLocations, {
    params: { type: HOSPITAL }
  });

  const asyncPhysicianGroupsOptions = useAsyncOptions(fetchLocations, {
    params: { type: PHYSICIAN_GROUP }
  });

  const asyncPayerOptions = useAsyncOptions(fetchLocations, {
    params: { type: PAYER }
  });

  return (
    <Fragment>
      <InputGroup { ...asyncHospitalOptions }
        { ...addressProps }
        closeMenuOnSelect={ false }
        name='hospitals'
        label='Associated Hospitals'
        data-cy='associatedHospitals'
        placeholder='Associated Hospitals'
        getOptionLabel={ getName }
        getOptionValue={ getId }
        component={ MultiSelect }
        labelComponent={ MultiSelectLabel } />

      <InputGroup { ...asyncPhysicianGroupsOptions }
        { ...addressProps }
        closeMenuOnSelect={ false }
        name='physicianGroups'
        label='Associated Physician Groups'
        data-cy='associatedPhysicianGroups'
        placeholder='Associated Physician Groups'
        getOptionLabel={ getName }
        getOptionValue={ getId }
        component={ MultiSelect }
        labelComponent={ MultiSelectLabel } />

      <InputGroup { ...asyncPayerOptions }
        { ...addressProps }
        closeMenuOnSelect={ false }
        name='payers'
        label='Associated Payers'
        data-cy='associatedPayers'
        placeholder=' Associated Payers'
        getOptionLabel={ getName }
        getOptionValue={ getId }
        component={ MultiSelect }
        labelComponent={ MultiSelectLabel } />
    </Fragment>
  );
}

export default AcuteAssociatedLocations;
