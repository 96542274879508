import moment from 'moment';

import { createSlice } from '@reduxjs/toolkit';

import { END_DATE, START_DATE } from '~/constants/filterKeysConstants';

const sliceName = 'insights/filters';

const now = moment().milliseconds(0);
const thirtyDaysAgo = moment(now).subtract(30, 'days');

export const initialState = {
  selectedGroupType: null,
  filters: {
    [START_DATE]: thirtyDaysAgo.toDate(),
    [END_DATE]: now.toDate(),
  },
};

const filtersSlice = createSlice({
  name: sliceName,
  initialState,
  reducers: {
    setSelectedGroupType(state, action) {
      state.selectedGroupType = action.payload;
    },
    setSelectedFilters(state, action) {
      state.filters = { ...state.filters, ...action.payload };
    },
    resetFilters(state) {
      state.filters = initialState.filters;
    },
  },
});

export const { setSelectedGroupType, setSelectedFilters, resetFilters } = filtersSlice.actions;

export const getFilters = (state: any) => state[sliceName].filters;
export const getSelectedGroupType = (state: any) => state[sliceName].selectedGroupType;

export default filtersSlice;
