import React from 'react';
import PropTypes from 'prop-types';

function PatientMatchFoundIcon({ size = 40 }) {
  return (
    <svg width={ `${size}px` } height={ `${size}px` } viewBox='0 0 40 40' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlnsXlink='http://www.w3.org/1999/xlink'>
      <title>a-check</title>
      <g id='match-on-add-/-connect' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
        <g id='3---confirm-match-details' transform='translate(-655.000000, -161.000000)' fillRule='nonzero'>
          <g id='Group-8' transform='translate(426.000000, 140.000000)'>
            <g id='Group-9' transform='translate(50.000000, 21.000000)'>
              <g id='a-check' transform='translate(179.500000, 0.000000)'>
                <path d='M17.3916554,35 C17.3916554,31.1725 18.8277093,27.69 21.1730982,25.04 C20.7407914,25.0275 20.3233916,25 19.8761776,25 C11.9455828,25 6.11440914,26.9875 2.88949932,28.455 C1.11803499,29.2625 0,31.0425 0,33 L0,40 L18.2587537,40 C17.7071897,38.435 17.3916554,36.755 17.3916554,35 Z' id='Path' fill='#3253EF' />
                <ellipse id='Oval' fill='#3253EF' cx='19.8761776' cy='10' rx='9.93808881' ry='10' />
                <path d='M27.3297442,40 C26.6937065,40 26.0576689,39.755 25.573187,39.2675 L20.6041426,34.2675 L24.117257,30.7325 L27.3297442,33.965 L35.5112758,25.7325 L39.0243902,29.2675 L29.0863014,39.2675 C28.6018196,39.755 27.9657819,40 27.3297442,40 Z' id='Path' fill='#61BD15' />
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}

PatientMatchFoundIcon.propTypes = {
  size: PropTypes.number
};

export default PatientMatchFoundIcon;
