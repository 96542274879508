import { createEntityAdapter, createSlice } from '@reduxjs/toolkit';

import { createAsyncThunk } from '~/lib';
import { RehabState } from '~/models';
import rehabStatesAPI from '~/sources/api/rehabStatesAPI';

const SLICE_NAME = 'rehabStates';
const getRehabStatesSlice = (state) => state[SLICE_NAME];

export const fetchRehabStates = createAsyncThunk(
  `${SLICE_NAME}/fetch`,
  async () => {
    const res = await rehabStatesAPI.fetchRehabStates();

    return res.data.items;
  },
  {
    condition: (_, { getState }) => {
      const rehabStates = getRehabStatesSlice(getState());

      return !rehabStates.ids.length;
    },
    modelClass: RehabState,
  }
);

export const fetchRehabStatesNoCache = createAsyncThunk(
  `${SLICE_NAME}/fetch`,
  async () => {
    const res = await rehabStatesAPI.fetchRehabStates();

    return res.data.items;
  },
  {
    modelClass: RehabState,
  }
);

export const getRehabStatesLoaded = (state) => !!getRehabStatesSlice(state).ids.length;

const rehabStatesAdapter = createEntityAdapter();

const rehabStatesSlice = createSlice({
  name: SLICE_NAME,
  initialState: rehabStatesAdapter.getInitialState(),
  reducers: {},
  extraReducers: {
    [fetchRehabStates.fulfilled]: rehabStatesAdapter.setAll,
  },
});

export const { selectAll: getRehabStates } = rehabStatesAdapter.getSelectors(getRehabStatesSlice);

export default rehabStatesSlice.reducer;
