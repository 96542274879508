import { immerable } from 'immer';
import moment from 'moment';

import Classification from './Classification';
import Location from './Location';
import LocationEpisode from './LocationEpisode';
import Patient from './Patient';
import RehabState from './RehabState';

const defaults = {
  id: '',
  admittedOn: moment(),
  currentRehabState: new RehabState(),
  episodeClassification: null,
  episodeGroups: [],
  hospital: {
    name: '',
  },
  latestLocationEpisodeId: '',
  latestLocationEpisode: null,
  locationEpisodes: [],
  owner: new Location(),
  patient: new Patient(),
  planTypeClassification: null,
  physicianTeam: new Location(),
  physicianGroup: {},
  affiliates: [],
  rehabInformation: {
    latestRehabFacility: {
      name: '',
    },
  },
  rehabStates: [
    {
      current: true,
      position: 0,
      state: '',
    },
  ],
  vendors: [],
  updatedAt: '',
  archived: false,
  archivedBy: null,
};

export default class Episode {
  [immerable] = true;

  constructor(options = {}) {
    const opts = { ...defaults, ...options };

    this.id = opts.id;
    this.archived = opts.archived;
    this.archivedBy = opts.archivedBy;
    this.archivedByLocation = opts.archivedByLocation;
    this.admittedOn = opts.admittedOn;
    this.currentRehabState = new RehabState(opts.currentRehabState);
    this.episodeClassification = new Classification(opts.episodeClassification);
    this.episodeGroups = opts.episodeGroups;
    this.hospital = opts.hospital;
    this.latestLocationEpisodeId = opts.latestLocationEpisodeId;
    this.latestLocationEpisode = opts.latestLocationEpisode
      ? new LocationEpisode(opts.latestLocationEpisode)
      : opts.latestLocationEpisode;
    this.locationEpisodes = opts.locationEpisodes.map((le) => new LocationEpisode(le));
    this.owner = new Location(opts.owner);
    this.patient = opts.patient;
    this.planTypeClassification = new Classification(opts.planTypeClassification);
    this.physicianTeam = opts.physicianTeam;
    this.physicianGroup = opts.physicianGroup;
    this.rehabInformation = opts.rehabInformation;
    this.rehabStates = opts.rehabStates;
    this.trackLos = opts.trackLos;
    this.affiliates = opts.affiliates;
    this.updatedAt = opts.updatedAt;
  }

  get type() {
    return 'Episode';
  }

  get latestRehabFacility() {
    return this.rehabInformation.latestRehabFacility;
  }
}
