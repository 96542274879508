import * as _ from 'lodash-es';

import { ALL, FLAGGED_OLIO_ATTRS, LOCATION_TYPE, PATIENT_STATE, PatientState } from '../constants/filterKeysConstants';

class LocationEpisodesParamsBuilder {
  constructor({ active, rehabState, filters = {}, hasImportImprovements, sorts = {}, ...rest } = {}) {
    this.active = active;
    this.filters = filters;
    this.locationType = filters[LOCATION_TYPE];
    this.patientState = filters[PATIENT_STATE];
    this.rehabState = rehabState;
    this.hasImportImprovements = hasImportImprovements;
    this.rest = rest;
    this.sorts = sorts;
  }

  build() {
    const queryParams = {};

    this.addFilters(queryParams);
    this.addRehabState(queryParams);
    this.addSortParams(queryParams);
    this.addActiveFlag(queryParams);

    return { ...queryParams, ...this.rest };
  }

  addFilters(params) {
    Object.entries(this.filters)
      .filter(([_filterKey, filterValue]) => (Array.isArray(filterValue) ? Boolean(filterValue?.length) : filterValue))
      .forEach(([filterKey, filterValue]) => {
        if (Array.isArray(filterValue)) {
          if (filterValue[0]?.type === 'Location::RehabFacility') {
            // for rehab facility / care provider filters, only include it if it matches the location type
            // filter or applies to the current patient state filter
            if (this.showProviderTypeForPatientState(filterKey)) {
              params[filterKey] = filterValue.map((value) => value.id).join(',');
            }
          } else {
            // filter is an attr_value, use the name instead of the id
            if (this.useNameAsValue(filterKey)) {
              params[filterKey] = filterValue.map((value) => value['name']);
              // default case for array filters - use the id
            } else {
              params[filterKey] = filterValue.map((value) => value['id']).join(',');
            }
          }
        } else {
          params[filterKey] = filterValue;
        }
      });
  }

  addRehabState(params) {
    if (this.rehabState) {
      params.currentRehabState = this.rehabState;
    }
  }

  addSortParams(params) {
    const emptySorts = Object.keys(this.sorts).length === 0;

    if (!this.rehabState || emptySorts) {
      return;
    }

    if (this.rehabState !== ALL) {
      const { attributeName, direction } = this.sorts[_.camelCase(this.rehabState)];

      params.sortBy = `${attributeName} ${direction}`;
    } else {
      params.sortBy = Object.values(this.sorts)
        .map(({ attributeName, direction, rehabStateApiName }) => {
          const attrName = this.rehabState === ALL ? `${rehabStateApiName}.${attributeName}` : attributeName;

          return `${attrName} ${direction}`;
        })
        .toString();
    }
  }

  addActiveFlag(params) {
    if (this.active) {
      params.active = this.active;
    }
  }

  showProviderTypeForPatientState(filterKey) {
    switch (this.patientState) {
      // CURRENT if post acute user is on provider tab.
      // NULL if acute user is on provider tab.
      case PatientState.CURRENT:
      case null:
        return _.snakeCase(filterKey) === this.filters[LOCATION_TYPE];

      // Don't show provider type on CONTINUED or LATEST. We no longer return providers
      // that are not associated to the user's enabled care options for performance reasons.
      default:
        return false;
    }
  }

  useNameAsValue(filterKey) {
    return FLAGGED_OLIO_ATTRS.includes(filterKey);
  }
}

export default LocationEpisodesParamsBuilder;
