import { createSelector, createSlice } from '@reduxjs/toolkit';

import * as clientTypes from '~/constants/clientTypes';
import { sortBy } from '~/helpers';

import {
  HOSPITALS,
  LOCATION_TYPE,
  PATIENT_STATE,
  PAYERS,
  PHYSICIAN_GROUP,
  SEARCH,
  SORTS,
} from '../constants/filterKeysConstants';

import { getProfile } from './profile';

export const initialState = {
  [LOCATION_TYPE]: null,
  [PATIENT_STATE]: null,
  [SEARCH]: '',
  [SORTS]: {},
};

const filtersForUser = (filters, user) => {
  switch (user.actingClient.clientType) {
    case clientTypes.PAYOR:
      return {
        [PAYERS]: filters[PAYERS],
        [HOSPITALS]: filters[HOSPITALS],
        [PHYSICIAN_GROUP]: filters[PHYSICIAN_GROUP],
        ...filters,
      };
    case clientTypes.PHYSICIAN_GROUP:
      return {
        [PHYSICIAN_GROUP]: filters[PHYSICIAN_GROUP],
        [HOSPITALS]: filters[HOSPITALS],
        [PAYERS]: filters[PAYERS],
        ...filters,
      };
    default:
      return filters;
  }
};

export const makeFiltersSlice = (sliceName, extraReducers, filtersInitialState = initialState) => {
  const filtersSlice = createSlice({
    name: sliceName,
    initialState: filtersInitialState,
    reducers: {
      clearFilters: (state) => ({
        ...filtersInitialState,
        [SEARCH]: state[SEARCH],
        [LOCATION_TYPE]: state[LOCATION_TYPE],
        [PATIENT_STATE]: state[PATIENT_STATE],
        [SORTS]: state[SORTS],
      }),
      removeFilter: (state, { payload }) => {
        const nonOptionBasedFilterTypes = [LOCATION_TYPE, PATIENT_STATE, SEARCH];

        if (nonOptionBasedFilterTypes.includes(payload.filterType)) {
          state[payload.filterType] = filtersInitialState[payload.filterType];
        } else {
          const idxToRemove = state[payload.filterType].findIndex((filter) => filter.id === payload.id);

          if (idxToRemove !== -1) {
            state[payload.filterType].splice(idxToRemove, 1);
          }
        }
      },
      setFilter: (state, { payload }) => {
        const { filterType, value } = payload;

        const filterTypeToReset = filterType === PATIENT_STATE ? LOCATION_TYPE : PATIENT_STATE;

        return {
          ...state,
          [filterType]: value,
          [filterTypeToReset]: null,
        };
      },
      // If the reducer action name needs to change, make sure to update
      // the reducer case in profile ducks.
      setFilters: (state, { payload }) => {
        if (payload) {
          return {
            ...state,
            ...payload,
          };
        }

        return state;
      },
      // Sets the filters like the setFilters above but profile reducer does not
      // hook into this action therefore the filters are not persisted to the profile
      // and profile requests are not made.
      setUnpersistedFilters: (state, { payload }) => {
        if (payload) {
          return {
            ...state,
            ...payload,
          };
        }

        return state;
      },
      setSort: (state, { payload }) => {
        state[SORTS] = { ...state[SORTS], [payload.key]: payload };
      },
    },
    extraReducers,
  });

  const getFiltersSlice = (state) => state[sliceName];
  const getFilters = createSelector(getFiltersSlice, (filters) => {
    const { [SORTS]: _sorts, ...otherFilters } = filters;

    return otherFilters;
  });

  const getSorts = createSelector(getFiltersSlice, (filters) => filters[SORTS]);

  const getFiltersOrderedByName = createSelector(getFilters, (patientFilters) => {
    const sortableFilters = Object.entries(patientFilters)
      .filter(([_filterKey, filterValue]) => Array.isArray(filterValue) && filterValue.length > 1)
      .reduce((acc, [filterKey, filterValue]) => ({ ...acc, [filterKey]: sortBy(filterValue, 'name') }), {});

    return { ...patientFilters, ...sortableFilters };
  });

  const getFiltersOrderedForUser = createSelector(getFiltersOrderedByName, getProfile, (filters, profile) =>
    filtersForUser(filters, profile)
  );

  return {
    slice: filtersSlice,
    getFilters,
    getFiltersOrderedByName,
    getFiltersOrderedForUser,
    getSorts,
  };
};
