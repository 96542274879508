import React, { useMemo } from 'react';
import { Formik } from 'formik';
import moment from 'moment';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import styled from 'styled-components';

import ActivityInput from '~/components/shared/ActivityInput';
import { Button, ButtonGroup } from '~/components/shared/buttons';
import { DatePicker, Form, FormHeader, FormSection, Input, InputGroup } from '~/components/shared/form';
import { InputContainer } from '~/components/shared/form/DatePicker/DatePickerInput';
import { ErrorMessageContainer } from '~/components/shared/form/InputGroup';
import StandardModal from '~/components/shared/modal/StandardModal';
import { ACCEPTED } from '~/constants/reviews';
import { updateReview } from '~/ducks/reviews';
import { addToast } from '~/ducks/toasts';
import { unwrapResult } from '~/lib';
import daysRemaining from '~/lib/daysRemaining';
import { Authorization, Escalation, LocationEpisode, Note } from '~/models';
import { PERMISSIONS, useProfileContext } from '~/services/profile';
import colors from '~/styles/theme/colors';

import buildAuthorizationValidation from './buildAuthorizationValidation';

function AuthorizationUpdateModal(props) {
  const { locationEpisode, onCancel, onSuccess, show, authorization } = props;

  const initialValues = {
    authorizationNumber: authorization.authorizationNumber ?? '',
    approvedThroughDate: authorization.approvedThroughDate,
    note: new Note(),
  };
  const profileSvc = useProfileContext();
  const userCanEscalate = profileSvc.has(PERMISSIONS.escalationCreate);
  const escalationType = useMemo(() => Escalation.typeForUser(profileSvc.profile), [profileSvc.profile]);

  const handleSubmit = async (values) => {
    try {
      const note = new Note({
        ...values.note,
        locationEpisodeId: locationEpisode.id,
      });

      const payload = {
        approvedThroughDate: values.approvedThroughDate,
        authorizationNumber: values.authorizationNumber,
        id: authorization.id,
        include: 'activities.attachments',
        status: ACCEPTED,
        ...(note.hasContent && { note: note.serialize() }),
      };

      await props.updateReview(payload).then(unwrapResult);

      onSuccess();
    } catch (e) {
      props.addToast({ text: 'Something went wrong. Please try again.' });
      onCancel();
    }
  };

  const isAdmittedOrInTreatment = locationEpisode.inAdmission || locationEpisode.inTreatment;

  return (
    <StandardModal
      show={show}
      disableBackdropClick
      title='Update Authorization'
      onCancel={onCancel}
      data-cy='authorizationUpdateModal'>
      <FormContainer>
        <Formik
          initialValues={initialValues}
          validationSchema={buildAuthorizationValidation(isAdmittedOrInTreatment)}
          onSubmit={handleSubmit}
          enableReinitialize
          status={{ uploading: false }}
          validateOnMount>
          {({ values, isSubmitting, status, setStatus }) => {
            const approvedThroughDateIsValid =
              values.approvedThroughDate && moment(values.approvedThroughDate).isValid();
            const remainingApprovedDays = approvedThroughDateIsValid ? (
              daysRemaining(new Date(), values.approvedThroughDate)
            ) : (
              <>&mdash;</>
            );

            return (
              <Form>
                <FormHeader subtitle={`Patient: ${locationEpisode.patient.name}`} />
                <FormGroup>
                  <AuthorizationNumberContainer>
                    <InputGroup
                      name='authorizationNumber'
                      label='Authorization Number'
                      placeholder='Authorization Number'
                      component={Input}
                    />
                  </AuthorizationNumberContainer>
                </FormGroup>
                {isAdmittedOrInTreatment && (
                  <FormGroup>
                    <DatePickerContainer>
                      <StyledInputGroup
                        component={DatePicker}
                        label='Approved Through Date'
                        minDate={new Date()}
                        name='approvedThroughDate'
                        data-cy='approvedThroughDate'
                      />
                      <ApprovedDaysText>Approved days remaining: {remainingApprovedDays}</ApprovedDaysText>
                    </DatePickerContainer>
                  </FormGroup>
                )}
                <FormGroup>
                  <ActivityInput
                    escalationType={escalationType}
                    isTaggable
                    label='Notes (optional)'
                    locationEpisodeId={locationEpisode.id}
                    onUploading={(uploading) => setStatus({ uploading })}
                    placeholder='Start typing here to leave a note...'
                    showEscalationToggle={userCanEscalate}
                    customStyles={{ borderColor: colors.black25 }}
                  />
                </FormGroup>
                <FormActions>
                  <Button data-cy='cancelAuthorization' color='transparent' text='Cancel' onClick={onCancel} />
                  <Button
                    data-cy='submitAuthorization'
                    type='submit'
                    disabled={status?.uploading || isSubmitting}
                    loading={isSubmitting}
                    text='Confirm'
                  />
                </FormActions>
              </Form>
            );
          }}
        </Formik>
      </FormContainer>
    </StandardModal>
  );
}

AuthorizationUpdateModal.propTypes = {
  addToast: PropTypes.func.isRequired,
  authorization: PropTypes.instanceOf(Authorization).isRequired,
  locationEpisode: PropTypes.instanceOf(LocationEpisode).isRequired,
  onCancel: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
  show: PropTypes.bool.isRequired,
  updateReview: PropTypes.func.isRequired,
};

const mapDispatchToProps = {
  addToast,
  updateReview,
};

const FormContainer = styled.div``;

const FormActions = styled(ButtonGroup)`
  margin-top: 60px;
`;

const ApprovedDaysText = styled.p`
  color: ${colors.black75};
  font-size: 12px;
  font-style: italic;
  margin: 8px 0 20px;
`;

const AuthorizationNumberContainer = styled.div`
  width: 50%;
  margin-right: auto;
`;

const FormGroup = styled(FormSection)`
  margin-bottom: 0;
`;

const DatePickerContainer = styled(AuthorizationNumberContainer)`
  ${InputContainer} {
    max-width: 100%;
  }
`;

const StyledInputGroup = styled(InputGroup)`
  ${ErrorMessageContainer} {
    min-height: auto;
  }
`;

export default connect(null, mapDispatchToProps)(AuthorizationUpdateModal);
