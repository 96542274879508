import React from 'react';
import PropTypes from 'prop-types';

const SNFIcon = ({ fill, size }) => {
  return (
    <svg width={size} height={size} viewBox='0 0 24 24' version='1.1' xmlns='http://www.w3.org/2000/svg'>
      <g id='snf-icon-black100' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
        <path
          d='M20.9578125,5.25 C19.3546875,5.2734375 18.0375,6.58125 18.0046875,8.184375 C17.98125,9.3234375 18.590625,10.321875 19.5046875,10.846875 L19.5046875,16.125 C19.5046875,18.8109375 17.1515625,21 14.2546875,21 C11.4421875,21 9.1359375,18.9328125 9.009375,16.35 C12.421875,15.646875 15,12.61875 15,9 L15,1.715625 C15,1.18125 14.6203125,0.7171875 14.0953125,0.6140625 L11.146875,0.0234375 C10.5375,-0.0984375 9.946875,0.2953125 9.825,0.9046875 L9.675,1.640625 C9.553125,2.25 9.946875,2.840625 10.55625,2.9625 L11.9953125,3.2484375 L11.9953125,8.9390625 C11.9953125,11.41875 10.0171875,13.471875 7.5375,13.4953125 C5.034375,13.51875 2.9953125,11.49375 2.9953125,8.9953125 L2.9953125,3.253125 L4.434375,2.9671875 C5.04375,2.8453125 5.4375,2.2546875 5.315625,1.6453125 L5.1703125,0.909375 C5.0484375,0.3 4.4578125,-0.09375 3.8484375,0.028125 L0.9046875,0.609375 C0.3796875,0.7171875 0,1.1765625 0,1.715625 L0,9 C0,12.6234375 2.5828125,15.65625 6.0046875,16.35 C6.1265625,20.5875 9.778125,24 14.25,24 C18.796875,24 22.5,20.465625 22.5,16.125 L22.5,10.846875 C23.3953125,10.3265625 24,9.3609375 24,8.25 C24,6.5765625 22.63125,5.2265625 20.9578125,5.25 Z M21,9 C20.5875,9 20.25,8.6625 20.25,8.25 C20.25,7.8375 20.5875,7.5 21,7.5 C21.4125,7.5 21.75,7.8375 21.75,8.25 C21.75,8.6625 21.4125,9 21,9 Z'
          fill={fill}
          fillRule='nonzero'
        />
      </g>
    </svg>
  );
};

SNFIcon.defaultProps = {
  fill: '#0F1226',
  size: 14,
};

SNFIcon.propTypes = {
  fill: PropTypes.string,
  size: PropTypes.number,
};

export default SNFIcon;
