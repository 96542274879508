import moment from 'moment';

export const parseDob = (date) => {
  const parsedDate = moment(date);

  if (parsedDate.isValid()) {
    const splitDate = parsedDate
      .format('YYYY-MM-DD')
      .split('-');

    return {
      month: Number(splitDate[1]),
      day:   Number(splitDate[2]),
      year:  Number(splitDate[0])
    };
  }
  return {
    month: '',
    day: '',
    year: ''
  };
};

export const yearLimit = () => {
  return moment().year() + 2;
};
