import { useMemo, useState } from 'react';

type Config = IntersectionObserverInit & {
  initialState?: boolean;
};

const useIntersectionObserver = (config: Config = {}) => {
  const { initialState = false, ...restConfig } = config;

  const [isIntersecting, setIsIntersecting] = useState(initialState);
  const intersectionCallback = (entries: any[]) => {
    const entry = entries?.[0];

    if (isIntersecting || !entry || !entry.isIntersecting) return;

    setIsIntersecting(true);

    // In the case of attachments, they only need to be loaded once,
    // so unobserve once this callback has been triggered.
    observer.unobserve(entry.target);
  };

  const defaultOptions = {
    root: null,
    rootMargin: '0px',
    // Trigger when 50% of the element has intersected
    threshold: 0.5,
  };

  const options = { ...defaultOptions, ...restConfig };

  const observer = useMemo(() => new IntersectionObserver(intersectionCallback, options), []);

  return [observer, isIntersecting, setIsIntersecting] as [
    IntersectionObserver,
    boolean,
    React.Dispatch<React.SetStateAction<boolean>>,
  ];
};

export default useIntersectionObserver;
