import { REHAB_FACILITY } from '~/constants/locationTypes';

import Location from '../Location';

const defaults = {
  type: REHAB_FACILITY,
};

class RehabFacility extends Location {
  constructor(options = {}) {
    const opts = { ...defaults, ...options };

    super(opts);
  }

  static get type() {
    return REHAB_FACILITY;
  }
}

export default RehabFacility;
