import React, { useState } from 'react';
import styled from 'styled-components';

import {
  ColumnDef,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  SortingState,
  useReactTable,
} from '@tanstack/react-table';

import CircleSpinner from '~/components/shared/CircleSpinner';
import {
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableFooterCell,
  TableHeader,
  TableHeaderCell,
  TableHeaderFooterRow,
  TableRow,
} from '~/components/shared/DataTable/styledTableComponents';
import ArrowUpDownIcon from '~/components/shared/svg/ArrowUpDownIcon';

interface TableProps<T> {
  data: T[];
  columns: ColumnDef<T>[];
}

type DataTableProps<T> = TableProps<T> & { defaultSortBy?: SortingState } & { loading?: boolean };

export default function DataTable<T>(props: DataTableProps<T>) {
  const { defaultSortBy = [], loading, ...reactTableProps } = props;

  const [sorting, setSorting] = useState<SortingState>(defaultSortBy);

  const table = useReactTable({
    ...reactTableProps,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    onSortingChange: setSorting,
    state: {
      sorting,
    },
  });

  const hasTableRows = !!table.getRowModel().rows.length;

  return loading ? (
    <StyledCircleSpinner />
  ) : (
    <Table>
      <TableHeader>
        {table.getHeaderGroups().map((headerGroup) => (
          <TableHeaderFooterRow key={headerGroup.id}>
            {headerGroup.headers.map((header) => (
              <TableHeaderCell
                key={header.id}
                isSorted={!!header.column.getIsSorted()}
                isSortable={header.column.getCanSort()}
                onClick={header.column.getToggleSortingHandler()}
                width={header.getSize()}>
                {flexRender(header.column.columnDef.header, header.getContext())}
                {header.column.getCanSort() && <ArrowUpDownIcon size={10} style={{ marginLeft: 8 }} />}
              </TableHeaderCell>
            ))}
          </TableHeaderFooterRow>
        ))}
      </TableHeader>

      <TableBody>
        {!hasTableRows && (
          <TableRow>
            <TableCell colSpan={table.getVisibleFlatColumns().length} style={{ justifyContent: 'center' }}>
              No rows found
            </TableCell>
          </TableRow>
        )}
        {hasTableRows &&
          table.getRowModel().rows.map((row) => (
            <TableRow key={row.id}>
              {row.getVisibleCells().map((cell) => (
                <TableCell key={cell.id} {...cell.column.columnDef.meta} width={cell.column.getSize()}>
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </TableCell>
              ))}
            </TableRow>
          ))}
      </TableBody>
      {hasTableRows && (
        <TableFooter>
          {table.getFooterGroups().map((footerGroup) => (
            <TableHeaderFooterRow key={footerGroup.id}>
              {footerGroup.headers.map((footer) => (
                <TableFooterCell key={footer.id} {...footer.column.columnDef.meta} width={footer.column.getSize()}>
                  {flexRender(footer.column.columnDef.footer, footer.getContext())}
                </TableFooterCell>
              ))}
            </TableHeaderFooterRow>
          ))}
        </TableFooter>
      )}
    </Table>
  );
}

const StyledCircleSpinner = styled(CircleSpinner)`
  flex: 1;
`;
