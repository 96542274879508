import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Button, ButtonGroup } from '~/components/shared/buttons';
import StandardModal from '~/components/shared/modal/StandardModal';
import { Body } from '~/components/shared/typography';

function ConfirmAcknowledgeModal({
  onConfirm,
  onCancel,
  showModal,
  confirmationButtonDisabled = false,
  confirmationButtonLoading = false,
}) {
  return (
    <StandardModal show={showModal} title='Confirm Acknowledgement' onCancel={onCancel} disableBackdropClick>
      <Content>
        <Paragraph>Are you sure you want to acknowledge this escalation?</Paragraph>
      </Content>
      <Actions>
        <Button color='transparent' text='Cancel' onClick={onCancel} />
        <Button
          color='primary'
          text='Confirm'
          onClick={onConfirm}
          disabled={confirmationButtonDisabled}
          loading={confirmationButtonLoading}
        />
      </Actions>
    </StandardModal>
  );
}

ConfirmAcknowledgeModal.propTypes = {
  confirmationButtonDisabled: PropTypes.bool,
  confirmationButtonLoading: PropTypes.bool,
  onCancel: PropTypes.func,
  onConfirm: PropTypes.func,
  showModal: PropTypes.bool,
};

export default ConfirmAcknowledgeModal;

const Content = styled.div`
  margin: 26px 0 50px;
  text-align: center;
`;

const Actions = styled(ButtonGroup)`
  justify-content: flex-end;
`;

const Paragraph = styled(Body)`
  display: block;
`;
