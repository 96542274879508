import React from 'react';
import styled from 'styled-components';

import { Footer } from '~/components/layouts';

type SimplePageProps = {
  children: React.ReactNode;
};

const SimplePage = ({ children }: SimplePageProps) => {
  return (
    <SimplePageWrapper>
      {children}
      <FooterContainer>
        <Footer />
      </FooterContainer>
    </SimplePageWrapper>
  );
};

const SimplePageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100%;
`;

const SimplePageHeader = styled.div``;
const SimplePageSubHeader = styled.div`
  padding: 12px 24px 0px 24px;
`;
const SimplePageContent = styled.div`
  padding: 0px 24px 0px 24px;
`;

const FooterContainer = styled.div`
  margin-top: auto;
  width: 100%;
`;

SimplePage.Header = SimplePageHeader;
SimplePage.SubHeader = SimplePageSubHeader;
SimplePage.Content = SimplePageContent;

export default SimplePage;
