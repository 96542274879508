import * as Yup from 'yup';

export const activityValidationSchema = Yup.object().shape({
  note: Yup.object().shape({
    text: Yup.string().when('attachments', {
      is: (attachments) => !attachments?.length,
      then: () => Yup.string().required(),
      otherwise: () => Yup.string()
    }),
    plaintext: Yup.string(),
    mentions: Yup.array().min(0),
    attachments: Yup.array().when('text', {
      is: (text) => !text,
      then: () => Yup.array().min(1),
      otherwise: () => Yup.array().min(0)
    })
  }, ['attachments', 'text'])
});
