import React from 'react';
import PropTypes from 'prop-types';

import useCollapsible from './useCollapsible';

function Collapsible({ children, open, ...rest }) {
  const { collapsed, toggleCollapse } = useCollapsible({ open });

  const renderChildren = () => {
    if (typeof children === 'function') {
      return children({ collapsed, toggleCollapse });
    }

    return React.Children.map(children, (child) => {
      if (child.type?.name === 'CollapsibleTrigger') {
        return React.cloneElement(child, { onClick: toggleCollapse });
      }
      if (child.type?.name === 'CollapsibleContent') {
        return React.cloneElement(child, { collapsed, ...rest });
      }
      return child;
    });
  };

  return (
    <React.Fragment>
      { renderChildren() }
    </React.Fragment>
  );
}

Collapsible.propTypes = {
  open: PropTypes.bool
};

Collapsible.defaultProps = {
  open: false
};

export default Collapsible;
