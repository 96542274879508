import React from 'react';

function ActivityProgressUpdateIcon() {
  return (
    <svg width='24px' height='24px' viewBox='0 0 24 24' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlnsXlink='http://www.w3.org/1999/xlink'>
      <title>Group 9</title>
      <defs>
        <path d='M13.4,2.46666667 L12.2,2.46666667 L12.2,5.46666667 L3.8,5.46666667 L3.8,2.46666667 L2.6,2.46666667 C2.2682,2.46666667 2,2.73486667 2,3.06666667 L2,14.4666667 C2,14.7984667 2.2682,15.0666667 2.6,15.0666667 L13.4,15.0666667 C13.7318,15.0666667 14,14.7984667 14,14.4666667 L14,3.06666667 C14,2.73486667 13.7318,2.46666667 13.4,2.46666667 Z M11.6,12.0666667 L4.4,12.0666667 L4.4,10.8666667 L11.6,10.8666667 L11.6,12.0666667 Z M11.6,7.86666667 L11.6,9.06666667 L4.4,9.06666667 L4.4,7.86666667 L11.6,7.86666667 Z M8,0.666666667 C8.663,0.666666667 9.2,1.20366667 9.2,1.86666667 L9.2,1.86666667 L11,1.86666667 L11,4.26666667 L5,4.26666667 L5,1.86666667 L6.8,1.86666667 C6.8,1.20366667 7.337,0.666666667 8,0.666666667 Z' id='activity-progress-update-path-1' />
      </defs>
      <g id='Escalations/Priority-Notes' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
        <g id='Unacknowledged-Escalation' transform='translate(-574.000000, -728.000000)'>
          <g id='Group-11' transform='translate(560.000000, 709.000000)'>
            <g id='Group-9' transform='translate(14.000000, 19.000000)'>
              <rect id='Rectangle' fillOpacity='0.25' fill='#F5825E' x='0' y='0' width='24' height='24' rx='3' />
              <g id='Icon/Report' transform='translate(4.000000, 4.000000)'>
                <mask id='activity-progress-update-mask-2' fill='white'>
                  <use xlinkHref='#activity-progress-update-path-1' />
                </mask>
                <g id='Mask' fillRule='nonzero' />
                <g id='Mixin/Fill/Black' mask='url(#activity-progress-update-mask-2)' fill='#F5825E' fillRule='evenodd'>
                  <rect id='Rectangle' x='0' y='0' width='16' height='16' />
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default ActivityProgressUpdateIcon;
