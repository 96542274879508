import activities from './activities';
import admin from './admin';
import api from './api';
import attachments from './attachments';
import classificationFilters from './classificationFilters';
import clientsFilters from './clientsFilters';
import dischargeTemplatesFilters from './dischargeTemplatesFilters';
import episode from './episode';
import escalations from './escalations';
import filterOptions from './filterOptions';
import flags from './flags';
import hospitals from './hospitals';
import insights from './insights';
import insightsV1 from './insightsV1';
import locationEpisode from './locationEpisode';
import locationEpisodes from './locationEpisodes';
import navigation from './navigation';
import patientEscalations from './patientEscalations';
import patientFilters from './patientFilters';
import portfolioFilterOptions from './portfolioFilterOptions';
import portfolioFilters from './portfolioFilters';
import profile from './profile';
import progressTemplatesFilters from './progressTemplatesFilters';
import questionTemplates from './questionTemplates';
import rehabStates from './rehabStates';
import toasts from './toasts';

export default {
  activities,
  ...admin,
  api,
  attachments: attachments.reducer,
  [classificationFilters.name]: classificationFilters.reducer,
  [clientsFilters.name]: clientsFilters.reducer,
  [dischargeTemplatesFilters.name]: dischargeTemplatesFilters.reducer,
  episode,
  escalations,
  [filterOptions.name]: filterOptions.reducer,
  flags,
  hospitals,
  ...insights,
  ...insightsV1,
  locationEpisode,
  locationEpisodes,
  [navigation.name]: navigation.reducer,
  patientEscalations,
  [patientFilters.name]: patientFilters.reducer,
  [portfolioFilters.name]: portfolioFilters.reducer,
  [portfolioFilterOptions.name]: portfolioFilterOptions.reducer,
  profile,
  questionTemplates,
  [progressTemplatesFilters.name]: progressTemplatesFilters.reducer,
  rehabStates,
  toasts,
};
