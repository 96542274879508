import * as clientTypes from '~/constants/clientTypes';
import {
  COMANAGEMENT_TEAM,
  HOSPITAL_DROPDOWN,
  PAYER_DROPDOWN,
  PHYSICIAN_GROUP_DROPDOWN,
} from '~/constants/filterDropdownConfigs';

export const renderableFilterDropdowns = (actingClientType: string) => {
  let filters = [];

  switch (actingClientType) {
    case clientTypes.PAYOR:
      filters = [PAYER_DROPDOWN];
      break;
    case clientTypes.PHYSICIAN_GROUP:
      filters = [PHYSICIAN_GROUP_DROPDOWN];
      break;
    default:
      filters = [HOSPITAL_DROPDOWN];
      break;
  }

  return [{ title: COMANAGEMENT_TEAM, filters }];
};
