import React from 'react';
import { connect, ConnectedProps } from 'react-redux';

import { Button, ButtonGroup } from '~/components/shared/buttons';
import { fetchActivities } from '~/ducks/activities';
import { useSwitch } from '~/lib/hooks';

import AltcsApprovalConfirmationModal from './reviews/AltcsApprovalConfirmationModal';
import AltcsDenialConfirmationModal from './reviews/AltcsDenialConfirmationModal';
import { Container, Text } from './AltcsInfo';

interface Props extends ConnectedProps<typeof connector> {
  locationEpisodeId: string;
}

function AltcsPendingButtons({ locationEpisodeId, ...props }: Props) {
  const altcsApprovalConfirmationSwitch = useSwitch(false);
  const altcsDenialConfirmationSwitch = useSwitch(false);

  const handleSuccess = ({ shouldFetchActivities }: { shouldFetchActivities: boolean }) => {
    if (shouldFetchActivities) {
      props.fetchActivities({ locationEpisodeId: locationEpisodeId, include: 'attachments' });
    }

    altcsApprovalConfirmationSwitch.turnOff;
    altcsDenialConfirmationSwitch.turnOff;
  };

  return (
    <>
      <Container>
        <Text>
          Indicate below once a <strong>final determination</strong> for ALTCS has been made for the patient
        </Text>
        <ButtonGroup>
          <Button
            onClick={altcsDenialConfirmationSwitch.turnOn}
            data-cy='altcsDenyButton'
            fontSize='12px'
            fontWeight={500}
            borderWidth='1px'
            color='accentWhite'
            text={'Denied'}
          />
          <Button
            onClick={altcsApprovalConfirmationSwitch.turnOn}
            data-cy='altcsApproveButton'
            fontSize='12px'
            fontWeight={500}
            borderWidth='1px'
            color='primaryWhite'
            text={'Approved'}
          />
        </ButtonGroup>
      </Container>
      <AltcsDenialConfirmationModal
        show={altcsDenialConfirmationSwitch.state}
        onCancel={altcsDenialConfirmationSwitch.turnOff}
        onSuccess={handleSuccess}
      />
      <AltcsApprovalConfirmationModal
        show={altcsApprovalConfirmationSwitch.state}
        onCancel={altcsApprovalConfirmationSwitch.turnOff}
        onSuccess={handleSuccess}
      />
    </>
  );
}

const mapDispatchToProps = {
  fetchActivities,
};

const connector = connect(null, mapDispatchToProps);

export default connector(AltcsPendingButtons);
