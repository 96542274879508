import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { LocationEpisode } from '~/models';

import DischargeOverdue from './DischargeOverdue';
import Question from './Question';

function QuestionList(props) {
  const { questionComponentConfig, locationEpisode } = props;

  return (
    <React.Fragment>
      {locationEpisode?.dischargeOverdue && (
        <DischargeOverdue projectedDischarge={locationEpisode.projectedDischargeReview?.date} />
      )}
      <StyledQuestionList>
        {questionComponentConfig.hasAdmissionDateQuestion && (
          <AdmissionDate>
            <Question {...questionComponentConfig.admissionDateQuestion} />
          </AdmissionDate>
        )}
        {questionComponentConfig.questions.map((question) => (
          <Question key={question.name} locationEpisodeId={locationEpisode?.id} {...question} />
        ))}
      </StyledQuestionList>
    </React.Fragment>
  );
}

QuestionList.propTypes = {
  locationEpisode: PropTypes.instanceOf(LocationEpisode),
  questionComponentConfig: PropTypes.instanceOf(Object),
};

export default QuestionList;

const StyledQuestionList = styled.div`
  & > * {
    box-shadow: inset 0 -1px 0 0 rgba(15, 18, 38, 0.25);
    padding: 16px 16px 16px 0px;

    &:last-child {
      box-shadow: none;
    }
  }
`;

const AdmissionDate = styled.div`
  padding-top: 16px;
  justify-content: center;
  display: flex;
`;
