import client from './client';

const BASE_URL = 'episodes';

export default {
  delete(id) {
    return client.delete(`${BASE_URL}/${id}`);
  },

  fetchById(id, params) {
    return client.get(`/${BASE_URL}/${id}`, { params });
  }
};
