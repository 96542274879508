import React, { useCallback, useContext, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import styled from 'styled-components';

import ConfirmAcknowledgeModal from '~/components/patients/patientProfile/ConfirmAcknowledgeModal';
import { getLocationEpisode } from '~/ducks/locationEpisode';
import { Escalation } from '~/models';
import { ProfileContext } from '~/services/profile';
import { colors } from '~/styles/theme';

import { usePatientProfileContext } from '../../../PatientProfileContext';

import EscalationAcknowledgment from './EscalationAcknowledgment';
import EscalationCheckbox from './EscalationCheckbox';
import EscalationLabel from './EscalationLabel';

function EscalationFooter({ escalation, locationEpisode }) {
  const [showModal, setShowModal] = useState(false);
  const [checked, setChecked] = useState(false);
  const [submitting, setSubmitting] = useState(false);

  const { onAcknowledgeEscalation } = usePatientProfileContext();
  const profileSvc = useContext(ProfileContext);
  const canAcknowledge = useMemo(() => {
    return profileSvc.canAcknowledge(escalation, locationEpisode.locationId);
  }, [escalation, locationEpisode, profileSvc.profile]);
  const { acknowledged } = escalation;
  const showLabel = !canAcknowledge && !acknowledged;
  const showCheckbox = !acknowledged && canAcknowledge;

  const handleCloseModal = useCallback(() => {
    setShowModal(false);
    setChecked(false);
    setSubmitting(false);
  }, []);

  const handleAcknowledgeConfirm = useCallback(() => {
    setSubmitting(true);
    onAcknowledgeEscalation(escalation.id).finally(() => {
      handleCloseModal();
    });
  }, [escalation]);

  const handleChecked = useCallback(() => {
    if (escalation.isDefault) {
      setShowModal(true);
    } else {
      handleAcknowledgeConfirm();
    }
  }, [escalation]);

  return (
    <EscalationBar isPriority={escalation.isPriorityNote} isAcknowledged={acknowledged}>
      {showLabel && <EscalationLabel isPriority={escalation.isPriorityNote} />}

      {showCheckbox && (
        <EscalationCheckbox checked={checked} isPriority={escalation.isPriorityNote} onChange={handleChecked} />
      )}

      {acknowledged && <EscalationAcknowledgment escalation={escalation} />}

      <ConfirmAcknowledgeModal
        confirmationButtonDisabled={submitting}
        confirmationButtonLoading={submitting}
        onCancel={handleCloseModal}
        onConfirm={handleAcknowledgeConfirm}
        showModal={showModal}
      />
    </EscalationBar>
  );
}

EscalationFooter.propTypes = {
  escalation: PropTypes.instanceOf(Escalation),
  locationEpisode: PropTypes.instanceOf(Object),
};

const mapStateToProps = (state) => ({
  locationEpisode: getLocationEpisode(state),
});

export default connect(mapStateToProps)(EscalationFooter);

const getBackgroundColor = ({ isPriority, isAcknowledged }) => {
  if (isPriority) {
    return isAcknowledged ? colors.black05 : colors.accentRed10;
  }
  return colors.accentRed;
};

const EscalationBar = styled.div`
  display: flex;
  justify-content: space-between;
  background-color: ${getBackgroundColor};
  padding: 10px;
  border-radius: 4px;
`;
