import client from '~/sources/api/client';

import { Api } from './core';

const url = 'reviews';
const { update } = new Api({ url });

export default {
  create(payload) {
    return client.post(`location_episodes/${payload.locationEpisodeId}/${url}`, payload);
  },
  update,
};
