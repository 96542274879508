import React from 'react';
import PropTypes from 'prop-types';

import useField from '~/lib/hooks/useField';

function Question({ QuestionComponent, ...props }) {
  const [field, meta, helpers] = useField(props);
  const { name, value } = field;

  return (
    <QuestionComponent name={ name }
      value={ value }
      meta={ meta }
      helpers={ helpers }
      { ...props } />
  );
}

Question.propTypes = {
  QuestionComponent: PropTypes.elementType,
  name: PropTypes.string.isRequired
};

export default Question;
