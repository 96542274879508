import { createEntityAdapter, createSlice } from '@reduxjs/toolkit';

import { addToast } from '~/ducks/toasts';
import { createAsyncThunk } from '~/lib';
import { Location } from '~/models';
import { adminLocationsApi } from '~/services/api';

import { API_STATES, createApiHasStatusSelector } from '../api';
import PaginationState from '../PaginationState';

const sliceName = 'admin/locations';

export const createLocation = createAsyncThunk(`${sliceName}/create`, async (payload) => {
  const { data } = await adminLocationsApi.create.invoke(payload);

  return data;
});

export const fetchLocation = createAsyncThunk(
  `${sliceName}/fetchById`,
  async ({ id, ...params }) => {
    const { data } = await adminLocationsApi.fetchById.invoke(id, params);

    return data;
  },
  {
    modelClass: Location,
  }
);

export const fetchLocations = createAsyncThunk(
  `${sliceName}/fetch`,
  async (params) => {
    const defaults = { pageSize: 5000, sortBy: 'name asc' };
    const { data } = await adminLocationsApi.fetch.invoke({ ...defaults, ...params });

    return data;
  },
  {
    defaultValue: [],
    modelClass: Location,
  }
);

export const updateLocation = createAsyncThunk(`${sliceName}/update`, async (payload) => {
  const { data } = await adminLocationsApi.update.invoke(payload.id, payload);

  return data;
});

export const deleteLocation = createAsyncThunk(`${sliceName}/delete`, async (payload, { dispatch }) => {
  const { data } = await adminLocationsApi.delete.invoke(payload).catch((e) => {
    dispatch(addToast({ text: 'There was an error archiving the group. Please try again.' }));
    throw e;
  });

  return data;
});

export const locationsAdapter = createEntityAdapter();

export const initialState = locationsAdapter.getInitialState({
  ids: [],
  entities: {},
  pagination: new PaginationState(),
});

const locationsSlice = createSlice({
  name: sliceName,
  initialState,
  reducers: {
    clearLocations: () => initialState,
  },
  extraReducers: {
    [fetchLocations.fulfilled]: (state, { payload: { links, meta, data } }) => {
      state.pagination = { links, meta };
      locationsAdapter.setAll(state, data);
    },
    [deleteLocation.fulfilled]: locationsAdapter.upsertOne,
  },
});

export const { clearLocations } = locationsSlice.actions;

const getLocationsState = (state) => state[sliceName];

export const { selectAll: getLocations } = locationsAdapter.getSelectors(getLocationsState);

export const getLocationsLoaded = createApiHasStatusSelector(fetchLocations, [API_STATES.complete, API_STATES.failed]);

export const getLocationsPageCount = (state) => getLocationsState(state).pagination.meta.totalPages;
export const getLocationsTotalRecords = (state) => getLocationsState(state).pagination.meta.totalRecords;

export default locationsSlice;
