import React, { Fragment, useMemo, useState } from 'react';
import { useFormikContext } from 'formik';
import PropTypes from 'prop-types';

import { InputGroup, Select, ToggleGroup } from '~/components/shared/form';
import { fetchLocations } from '~/ducks/locations';
import { getId, getName } from '~/helpers';
import { useAddressOptions, useAsyncOptions } from '~/lib/hooks';
import { useProfileContext } from '~/services/profile';
import { QUEUE } from '~/services/rehabState/constants';

import { formFields } from './constants';

function RehabFacilityInputGroup(props) {
  const profileSvc = useProfileContext();
  const { owner, isPostAcuteUser } = props;
  const {
    setFieldValue,
    status: { connecting, editing },
    values: { currentRehabState, locationType, hasActiveServiceRefusals },
  } = useFormikContext();
  const { locationTypeField, rehabFacilityField } = formFields;

  const rehabState = currentRehabState?.state;
  const patientInQueue = typeof rehabState == 'undefined' || rehabState == QUEUE;
  const editingNonQueuePatient = editing && !patientInQueue;

  const locationTypeIsDisabled = !owner?.id || editingNonQueuePatient;

  const addressProps = useAddressOptions();
  const asyncLocationOptions = useAsyncOptions(fetchLocations, {
    condition: Boolean(owner?.id) && Boolean(locationType?.value),
    debounce: 50,
    params: { ownerId: owner?.id, type: locationType?.value, include: 'group_type' },
  });

  const locationTypeOptions = useMemo(() => {
    let { enabledProviderTypes } = profileSvc;

    if (isPostAcuteUser) {
      const ownerClientEnabledProviderTypes = owner?.client?.enabledProviderTypes.map(
        (enabledProviderType) => enabledProviderType.apiName
      );

      enabledProviderTypes = enabledProviderTypes.filter((userProviderType) =>
        ownerClientEnabledProviderTypes?.includes(userProviderType.apiName)
      );
    }

    return enabledProviderTypes.map((groupType) => ({ label: groupType.displayName, value: groupType.apiName }));
  }, [profileSvc.enabledProviderTypes, owner?.client?.enabledProviderTypes, isPostAcuteUser]);

  const handleLocationTypeChanged = (newLocationType) => {
    if (newLocationType.value != locationType.value) {
      setFieldValue(rehabFacilityField.name, null);
    }
  };

  const rehabFacilityIsDisabled =
    locationTypeIsDisabled || !locationType || editingNonQueuePatient || hasActiveServiceRefusals;
  const isVisible = patientInQueue && locationTypeOptions.length > 1;

  return (
    <Fragment>
      <InputGroup
        component={ToggleGroup}
        data-cy={locationTypeField.name}
        autoSelectFirstOption={connecting || !editing}
        disabled={rehabFacilityIsDisabled}
        label='What care is the patient receiving?'
        name={locationTypeField.name}
        onChange={handleLocationTypeChanged}
        options={locationTypeOptions}
        visible={isVisible}
      />
      <InputGroup
        {...asyncLocationOptions}
        name={rehabFacilityField.name}
        label={locationType?.label || locationTypeOptions[0]?.label || rehabFacilityField.label}
        {...addressProps}
        component={Select}
        disabled={rehabFacilityIsDisabled}
        getOptionLabel={getName}
        getOptionValue={getId}
        data-cy={rehabFacilityField.name}
        placeholder={locationType?.label || locationTypeOptions[0]?.label || rehabFacilityField.label}
      />
    </Fragment>
  );
}

RehabFacilityInputGroup.propTypes = {
  isPostAcuteUser: PropTypes.bool,
  owner: PropTypes.object,
};

export default RehabFacilityInputGroup;
