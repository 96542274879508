import { createAsyncThunk } from '~/lib';
import { LocationEpisode } from '~/models';
import { locationEpisodesApi } from '~/services/api';

const cancellableFetchAllPatients = locationEpisodesApi.allPatients.cancellable();

export const fetchAllPatients = createAsyncThunk(
  'locationEpisodes/allPatients/fetch',
  async (params) => {
    const defaults = { sortBy: 'patientName asc' };
    const res = await cancellableFetchAllPatients({ ...defaults, ...params });

    return res.data;
  },
  {
    defaultValue: [],
    modelClass: LocationEpisode,
  }
);
